import * as d3 from 'd3';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../API';
import {ReactComponent as AnchorIcon} from '../../assets/anchor.svg';
import {ReactComponent as RatingIcon} from '../../assets/rating.svg';
import {ReactComponent as PositiveIcon} from '../../assets/positiveSentiment.svg';
import {ReactComponent as NegativeIcon} from '../../assets/negativeSentiment.svg';
import {ReactComponent as NeutralIcon} from '../../assets/neutralSentiment.svg';
import {ReactComponent as EditIcon} from '../../assets/edit.svg';
import {ReactComponent as DeleteIcon} from '../../assets/delete.svg';
import { Sentiment } from '../Sentiment/Sentiment';
import Modal from '../Modal';
import { AvatarGroup } from '../AvatarGroup/AvatarGroup';
import 'react-tooltip/dist/react-tooltip.css'

import { Tooltip } from 'react-tooltip';

import styles from './OpinionCard.module.scss';

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);

export const OpinionCard = ({
  report,
  story,
  opinion,
  isAdmin=false,
  onClick,
  onDelete,
  onEdit,
  selectedOpinions,
  onCheckboxClick,
  viewMode='rating'
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(opinion?.title);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const sentimentMap = {
    'POSITIVE': {text: t('Positive'), className: styles.positive, icon: <PositiveIcon/>, score: 0.4},
    'NEGATIVE': {text: t('Negative'), className: styles.negative, icon: <NegativeIcon/>, score: -0.5},
    'NEUTRAL': {text: t('Neutral'), className: styles.neutral, icon: <NeutralIcon/>, score: 0.2},
  }

  const editOpinion = (e) => {
    e.stopPropagation();
    e.preventDefault();

    let body = {
      title: title,
    }

    API.fetch('PATCH', `/API/v1/topic-modeling/${report.id}/events/${story.id}/opinions/${opinion.id}`, null, body).then(data => {
      setIsModalOpen(false);
      onEdit()
    });
  }

  const deleteOpinion = (e) => {
    e.stopPropagation();
    e.preventDefault();

    API.fetch('DELETE', `/API/v1/topic-modeling/${report.id}/events/${story.id}/opinions/${opinion.id}`).then(data => {
      setIsDeleteModalOpen(false);
      onDelete(opinion.id);
    });
  }

  return (
    <div className={styles.opinionCardContainer}>
      {isAdmin ? (
          <div className={styles.opinionControls}>
            <input
              className={
                selectedOpinions.includes(opinion.id)
                  ? `${styles.adminCheckbox} ${styles.adminCheckboxChecked}`
                  : styles.adminCheckbox
              }
              type="checkbox"
              checked={selectedOpinions.includes(opinion.id)}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                e.stopPropagation();
                onCheckboxClick(opinion, e.target.checked);
              }}
            />
            <button
              className={styles.opinionCardButton}
              onClick={() => setIsModalOpen(true)}
            >
              <EditIcon />
            </button>
            <button
              className={styles.opinionCardButton}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <DeleteIcon />
            </button>
          </div>
        ) : (
          ''
        )}
      <div className={styles.opinionCardTitle} onClick={() => onClick(opinion)}>
        {opinion?.title}
      </div>
      <div className={styles.opinionCardInfo}>
        {viewMode === 'rating' ? <div className={styles.opinionCardRatingContainer}>
          <div className={styles.opinionCardRating}>
            <div className={styles.opinionCardRatingIcon}>
              <RatingIcon />
            </div>
            <div className={styles.opinionCardRatingValue}>{Math.round(opinion?.rating)}</div>
          </div>
          <div className={styles.opinionCardHint}>
            <div className={styles.opinionCardHintText}>{t('Rating')}</div>
            <div
              className={styles.opinionCardHintIcon}
              data-tooltip-id="rating-tooltip"
              data-tooltip-content={
                'Based on the number of reactions and comments'
              }
              data-tooltip-place="bottom-start"
            >
              <AnchorIcon />
              <Tooltip id="rating-tooltip" style={{ width: '200px' }} />
            </div>
          </div>
        </div> : <div className={styles.opinionCardMessagesContainer}>
          <div className={styles.opinionCardMessages}>
            <div className={styles.opinionCardMessagesValue}>{formatNumberSignificant(opinion?.messages_count)}</div>
          </div>
          <div className={styles.opinionCardHint}>
            <div className={styles.opinionCardHintText}>{t('Messages')}</div>
          </div>
        </div>}
        {viewMode === 'rating' ? <div className={styles.opinionCardSourcesContainer}>
          <AvatarGroup sources={opinion?.top_sources} />
          <div className={styles.opinionCardHint}>
            <div className={styles.opinionCardHintText}>{t('Actors')}</div>
            <div
              className={styles.opinionCardHintIcon}
              data-tooltip-id="actors-tooltip"
              data-tooltip-content={
                'Top actors based on the number of reactions and comments'
              }
              data-tooltip-place="bottom"
            >
              <AnchorIcon />
              <Tooltip id="actors-tooltip" style={{ width: '200px' }} />
            </div>
          </div>
        </div> : ''}
        <div className={styles.opinionCardSentimentContainer}>
          <Sentiment
            sentimentScore={sentimentMap[opinion.sentiment.sentiment].score}
            messageId={story.id}
            narrative={report}
            opinionId={opinion.id}
            isStories={true}
          />
          <div className={styles.opinionCardHint}>
            <div className={styles.opinionCardHintText}>{t('Sentiment')}</div>
          </div>
        </div>
      </div>
      {isModalOpen ? <Modal 
        isVisible={isModalOpen}
        title={t('Edit opinion')}
        className={styles.storyCardModal}
        content={
          <form className={styles.modalForm} id='modal-form' onSubmit={(e) =>  editOpinion(e)}>
            <div className='form-element'>
              <input
                type="text"
                required
                placeholder={t('Title')}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className={styles.modalInput}
              />
            </div>
          </form>
        }

        footer={
          <>
            <button
              className='new-button'
              form='modal-form'
              type='submit'
              disabled={title.trim().length === 0}
              onClick={(e) => {
                editOpinion(e)
              }}
            >
              {t('Save')}
            </button>
            <button
              className='btn-reset'
              onClick={() => {
                setTitle(opinion?.title)
                setIsModalOpen(false)
              }}
            >
              {t('Cancel')}
            </button>
          </>
        }
        
        onClose={() => {
          setTitle(opinion?.title)
          setIsModalOpen(false)
        }}
      /> : ''}

      {isDeleteModalOpen ? (
        <Modal
          isVisible={isDeleteModalOpen}
          title={t('Delete')}
          content={<p>{t('Are you sure you want to delete this opinion?')}</p>}
          footer={
            <>
              <button
                className="btn-danger"
                onClick={(e) => {
                  deleteOpinion(e);
                  setIsDeleteModalOpen(false);
                }}
              >
                {t('Delete')}
              </button>
              <button
                className="btn-reset"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                {t('Cancel')}
              </button>
            </>
          }
          onClose={() => setIsDeleteModalOpen(false)}
        />
      ) : (
        ''
      )}
    </div>
  );
}