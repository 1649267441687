import styles from './NarrativePage.module.scss';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';

const caseStatuses = [
  {name: "SEARCH_CONTENT_BY_URL", message: 'Searching content in our database. Stage 1'},
  {name: "EXTRACT_IDS_FROM_URL", message: 'Searching content in our database. Stage 2'},
  {name: "SEARCH_CONTENT_BY_ID", message: 'Searching content in our database. Stage 3'},
  {name: "CREATE_SOURCE", message: 'Fetching data. Stage 1'},
  {name: "REQUEST_FETCHING", message: 'Fetching data. Stage 2'},
  {name: "AWAITING_DATA", message: 'Fetching data. Stage 3'},
  {name: "SEARCHING_MESSAGES", message: 'Processing data. Stage 1'},
  {name: "AWAITING_SEARCH", message: 'Processing data. Stage 2'},
  {name: "ENRICHING_CASE", message: 'Processing data. Stage 3'},
]


export const CaseStatusbar = ({status}) => {
  const { t } = useTranslation();

  if(!status) {
    return <Loader/>
  }

  let statusIndex = caseStatuses.findIndex(x => x.name === status);
  let stage = caseStatuses[statusIndex];
  let progressPercentage = 100 / caseStatuses.length * (statusIndex + 1);

  return(
    
    <div className='report-section'>
      {status === 'FAILED' ? '' : <div className={styles.progressBarMessage}>{t('Please wait, the case is being collected. This can take up to 30 minutes depending on the amount of data to be collected.')}</div>}
      <div className={styles.statusBarContainer}>
        {status === 'FAILED' ? <h3 className={`${styles.progressBarStatus} ${styles.failed}`}>{t(`The case failed to be collected. Please contact your workspace administrator.`)}</h3> : <h3 className={styles.progressBarStatus}>{t(stage?.message)}</h3>}
        <div className={styles.progressBarWrapper}>
          {status === 'FAILED' ?  <div className={`${styles.progressBar} ${styles.emptyBar}`} style={{width: `${100}%`}}>FAILED</div>: <div className={styles.progressBar} style={{width: `${progressPercentage}%`}}>{progressPercentage.toFixed(2)}%</div>}

        </div>
      </div>
    </div>
  )
}