

export const initialState = {
  narrative: null,
  messages: null,
  selectedMessages: {},
  deduplication: false,
  narrativeShares: null,
  narrativeStats: null,
  narrativeStatsStories: null,
  mostMentionedEntities: [],
  originalSources: null,
  error: null,
  isSearchApplied: false,
  isLoading: false,
  showStoriesMessage: false,
}


const messagesReducer = (state=initialState, action) => {

  switch (action.type) {
    case 'GET_NARRATIVE':
      return Object.assign({}, state, {
        narrative: action.narrative
      })
    case 'GET_MESSAGES':
      return Object.assign({}, state, {
        messages: action.newMessages
      })

    case 'APPEND_MESSAGES':
      return Object.assign({}, state, {
        messages: state.messages ? {...action.newMessages, objects: [...state.messages?.objects, ...action.newMessages?.objects]} : action.newMessages
      })

    case 'GET_ORIGINAL_SOURCES': 
      return Object.assign({}, state, {
        originalSources: action.messages
      })

    case 'GET_MOST_MENTIONED_ENTITIES':
      return Object.assign({}, state, {
        mostMentionedEntities: action.payload
      })

    case 'GET_NARRATIVE_STATS':
      return Object.assign({}, state, {
        narrativeStats: action.payload
      })
    
    case 'GET_NARRATIVE_STATS_STORIES':
      return Object.assign({}, state, {
        narrativeStatsStories: action.payload
      })

    case 'GET_NARRATIVE_SHARES':
      return Object.assign({}, state, {
        narrativeShares: action.payload
      })

    case 'SELECT_ALL_MESSAGES':
      return Object.assign({}, state, {
        selectedMessages: {...state.selectedMessages, ...action.payload}
      })

    case 'DESELECT_ALL_MESSAGES':
      const messagesToRemove = {...state.messages};
      const updatedSelectedMessages = { ...state.selectedMessages};

      for (const message of messagesToRemove.objects) {
        const messageId = message.id;
        if (updatedSelectedMessages.hasOwnProperty(messageId)) {
          delete updatedSelectedMessages[messageId];
        }
      }
      return Object.assign({}, state, {
        selectedMessages: updatedSelectedMessages
      })

    case 'CLEAR_ALL_SELECTED_MESSAGES': 
      return Object.assign({}, state, {
        selectedMessages: {}
      })

    case 'TOGGLE_SELECTED':
      if(action.messageId in state.selectedMessages) {
        let newMessagesList = Object.fromEntries(
          Object.entries(state.selectedMessages).filter(
            ([k, v]) => k !== action.messageId.toString(),
          )
        )
        return Object.assign({}, state, {
          selectedMessages: newMessagesList
        })
      } else {
        return Object.assign({}, state, {
          selectedMessages: {...state.selectedMessages, [action.messageId]: true}
        })
      }

    case 'PATCH_MESSAGES':
      return Object.assign({}, state, {
        selectedMessages: {}
      })

    case 'DELETE_MESSAGES':
      return Object.assign({}, state, {
        selectedMessages: {}
      })
    case 'DELETE_ENTITIES': 
      return Object.assign({}, state, {
      mostMentionedEntities: []
    })
    case 'TOGGLE_DEDUPLICATION': 
      return Object.assign({}, state, {
        messages: null,
        deduplication: action.payload,
        
      })

    case 'TOGGLE_TAB': 
      return Object.assign({}, state, {
        messages: null
      })
      
    case 'TRANSLATE_MESSAGE': {
      return Object.assign({}, state, {
        messages: {...state.messages, objects: state.messages.objects?.map(message => {
          if(message.id === action.payload.messageId) {
            message.isTranslation = true;
            message.translated = action.payload.data.destination_text;
            return message;
          } else {
            return message;
          }
        })
      }
      })
    }

    case 'TRANSLATE_STORIES_CHART_MESSAGE': {
      return Object.assign({}, state, {
        narrativeStatsStories: {...state.narrativeStatsStories, objects: state.narrativeStatsStories.objects?.map(obj => { 
          return {...obj, stories: obj.stories.map(message => {
          if(message.id === action.payload.messageId) {
            message.isTranslation = true;
            message.translated = action.payload.data.destination_text;
            return message;
          } else {
            return message;
          }
        })}})
      }
      })
    }

    case 'TRANSLATE_CHART_MESSAGE': {
      return Object.assign({}, state, {
        narrativeStats: {...state.narrativeStats, objects: state.narrativeStats.objects.map(obj => {
          return {...obj, messages: obj.messages.map(message => {
          if(message.id === action.payload.messageId) {
            message.isTranslation = true;
            message.translated = action.payload.data.destination_text;
            return message;
          } else {
            return message;
          }
        })}
      })
      }})
    }

    case 'TRANSLATION_ERROR': {
      return Object.assign({}, state, {
        messages: {...state.messages, objects: state.messages.objects?.map(message => {
          if(message.id === action.payload.messageId) {
            message.isTranslation = true;
            message.error = true;
            message.translated = action.payload.data;
            return message;
          } else {
            return message;
          }
        })
      }
      })
    }

    case 'TRANSLATION_ERROR_STORIES_CHART_MESSAGE': {
      return Object.assign({}, state, {
        narrativeStatsStories: {...state.narrativeStatsStories, objects: state.narrativeStatsStories.objects?.map(obj => { 
          return {...obj, stories: obj.stories.map(message => {
          if(message.id === action.payload.messageId) {
            message.isTranslation = true;
            message.error = true;
            message.translated = action.payload.data;
            return message;
          } else {
            return message;
          }
        })
      }
      })}})
    }

    case 'TRANSLATION_ERROR_CHART_MESSAGE': {
      return Object.assign({}, state, {
        narrativeStats: {...state.narrativeStats, objects: state.narrativeStats.objects.map(obj => {
          return {...obj, messages: obj.messages.map(message => {
          if(message.id === action.payload.messageId) {
            message.isTranslation = true;
            message.error = true;
            message.translated = action.payload.data;
            return message;
          } else {
            return message;
          }
        })
      }
      })}})
    }


    case 'TOGGLE_TRANSLATE':
      return Object.assign({}, state, {
        messages: {...state.messages, objects: state.messages.objects?.map(message => {
          if(message.id === action.payload.messageId) {
            message.isTranslation = action.payload.isTranslation;
            return message;
          } else {
            return message;
          }
        })}
      })

      case 'TOGGLE_TRANSLATE_STORIES_CHART_MESSAGES':
        return Object.assign({}, state, {
          narrativeStatsStories: {...state.narrativeStatsStories, objects: state.narrativeStatsStories.objects?.map(obj => { 
            return {...obj, stories: obj.stories.map(message => {
            if(message.id === action.payload.messageId) {
              message.isTranslation = action.payload.isTranslation;
              return message;
            } else {
              return message;
            }})}
          })}
        })
         

      case 'TOGGLE_TRANSLATE_CHART_MESSAGES':
      return Object.assign({}, state, {
        narrativeStats: {...state.narrativeStats, objects: state.narrativeStats.objects.map(obj => {
          return {...obj, messages: obj.messages.map(message => {
          if(message.id === action.payload.messageId) {
            message.isTranslation = action.payload.isTranslation;
            return message;
          } else {
            return message;
          }})}
        })
      }})

      case 'TOGGLE_FAVORITE':
      return Object.assign({}, state, {
        messages: {...state.messages, objects: state.messages.objects?.map(message => {
          if (action.payload.ids.includes(message.id)) {
            message.favorite = action.payload.favorite;
            return message;
          } else {
            return message;
          }
        })}
      })

    case 'ERROR': 
      return Object.assign({}, state, {
        error: action.error,
      })
    
    case 'APPLY_SEARCH': 
    return Object.assign({}, state, {
      isSearchApplied: action.isSearchApplied,
      
    })

    case 'SET_LOADING': 
    return Object.assign({}, state, {
      isLoading: action.isLoading,
    })

    case 'SHOW_STORIES_MESSAGE':
      return Object.assign({}, state, {
        showStoriesMessage: action.showStoriesMessage,
      })

    default: 
      return state;
  }
}

export default messagesReducer;
