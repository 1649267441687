import './DropdownMenu.scss';

export const SortingOrder = ({itemsList, current, onClick}) => {
  return (
    <ul className='dropdown-menu-sorting-order'>
      {itemsList.map(item => {
        return(
          <li
            key={`sort_${item.name}`}
            className={current === item.value ? 'checked' : ''}
            onClick={() => onClick(item.value)}
          >
            <span>{item.icon}</span>
            <span>{item.name}</span>
          </li>
        )
      })}
    </ul>
  )
}