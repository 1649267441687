import AsyncCreatableSelect from 'react-select/async-creatable';
import { useCallback, useEffect, useState,} from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../API';
import { ReactComponent as PlusIcon } from './assets/plus.svg';

import styles from './AddCaseToNarrative.module.scss';


export const AddCaseToNarrative = ({ onChange, value, case_id, type='NARRATIVE' }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [narratives, setNarratives] = useState(value);
  const [options, setOptions] = useState(null);


  const getNarrativesOptions = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', 100);
    urlParams.set('page', 1);
    urlParams.set('is_threat', true);
    urlParams.set('types', type);

    API.fetch('GET', `/API/v1/narratives?${urlParams.toString()}`).then(data => {
      let newOptions = data.objects.filter(n => n.id !== case_id).map(
        narrative => {return {label: narrative.name, value: narrative.id}}
      );
      setOptions(newOptions)
    })
  }, [])

  useEffect(getNarrativesOptions, [getNarrativesOptions]);
  useEffect(() => {
    onChange(narratives)
  }, [narratives]);

  const getNarratives = (inputValue, callback) => {
    const urlParams = new URLSearchParams();
    urlParams.set('q', inputValue);
    urlParams.set('is_threat', true);
    urlParams.set('types', type);

    return API.fetch('GET', `/API/v1/narratives?${urlParams.toString()}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .filter(n => n.id !== case_id)
          .map((narrative) => {
            return {value: narrative.id, label: narrative.name};
          })
      );
    });
  };

  const handleCreate = (inputvalue) => {
    setIsLoading(true);
    API.fetch('POST', `/API/v1/narratives`, null, {
      name: inputvalue,
      description: '',
      type: type,
      is_manual: true,
      parameters: null,
      is_threat: true,
      is_active: false,
    }).then(data => {
      let newOption = {value: data.id, label: data.name};
      setIsLoading(false);
      setOptions(prev => [...prev, newOption])
      setNarratives([...narratives, newOption]);
    })
    .catch(e => {
      setIsLoading(false);
    });
  }

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions={options}
      value={narratives}
      loadOptions={getNarratives}
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      isMulti={true}
      loadingMessage={() => t('Loading...')}
      placeholder={
        type === 'NARRATIVE' ? t('Start typing an existing narrative name or create a new one') : t('Start typing an existing campaign name or create a new one')
      }
      onChange={(newValue) => setNarratives(newValue)}
      onCreateOption={handleCreate}
      className='AddCaseToNarrative'
      classNamePrefix="AddCaseToNarrative"
      formatCreateLabel={(inputValue) => <span className={styles.create}><PlusIcon/>{t('Create a new case')} "{inputValue}"</span>}
      createOptionPosition='first'
    />
  )
}


