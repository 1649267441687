import * as d3 from 'd3';
import { useContext, useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paginator } from '../../components/Paginator/Paginator';
import { Loader } from '../../components/Loader/Loader';
import { Filter } from './Filter/Filter';
import { Search } from '../../components/Search/Search';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { ThreatsContext } from './contexts/ThreatsContext';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ThreatEditModal } from '../../pages/ThreatsPage/ThreatEditModal';
import { useCurrentUser } from '../../contexts/CurrentUser';
import Modal from '../../components/Modal';
import Tooltip from '../../components/Tooltip/Tooltip';

import styles from './ThreatsPage.module.scss';
import threatsTableStyles from '../../components/ThreatsTable/ThreatsTable.module.scss';

export const Narratives = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { narratives, getNarratives, deleteThreat, patchThreat } =
    useContext(ThreatsContext);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [sorting, setSorting] = useState({
    isAscending: false,
    fieldName: 'total_messages',
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const searchQuery = searchParams.get('q');

  const [incidentType, setIncidentType] = useState(null);
  const [editModal, setEditModal] = useState({ isOpen: false, threat: null });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    threatId: null,
  });
  const [topics, setTopics] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [caseTypes, setCaseTypes] = useState(null);
  const [geoScope, setGeoScope] = useState(null);

  const formatNumber = d3.format(',d');

  const [currentUser] = useCurrentUser();

  const sortingQuery = sorting.fieldName
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

  useEffect(() => {
    getNarratives(
      searchQuery,
      sortingQuery,
      page,
      incidentType,
      topics,
      statuses,
      caseTypes,
      geoScope,
    );
  }, [
    page,
    searchQuery,
    sortingQuery,
    sorting,
    incidentType,
    topics,
    statuses,
    caseTypes,
    geoScope,
  ]);

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'id',
    },
    {
      name: t('Name'),
      value: 'name',
    },
    // {
    //   name: t('Views'),
    //   value: 'views',
    // },
    {
      name: t('Messages'),
      value: 'messages',
    },
    {
      name: t('Threats'),
      value: 'threats'
    },
  ];

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon />,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon />,
    },
  ];

  const searchPanel = (
    <div className={styles.sortingControls}>
      <div className={styles.search}>
        <Search
          onChange={(value) => {
            setSearchParams({ q: value, page: 1 });
          }}
        />
      </div>

      <DropdownMenu
        isOpen={dropdownMenu}
        header={t('Sort by')}
        onClick={() => setDropdownMenu(!dropdownMenu)}
        buttonName={t('Sort')}
        icon={<SortIcon />}
      >
        <Radiobutton
          itemsList={sortingMenu}
          current={sorting.fieldName}
          onChange={(value) => setSorting({ ...sorting, fieldName: value })}
        />

        <SortingOrder
          itemsList={sortingOrder}
          onClick={(value) => setSorting({ ...sorting, isAscending: value })}
          current={sorting.isAscending}
        />
      </DropdownMenu>
      <Filter
        onChange={(incidentType, topics, statuses, caseTypes, geoScope) => {
          setIncidentType(incidentType);
          setTopics(topics);
          setStatuses(statuses);
          setCaseTypes(caseTypes);
          setGeoScope(geoScope);
          searchParams.set('page', 1);
          navigate(`?${searchParams.toString()}`);
        }}
      />
    </div>
  );

  if (!narratives) {
    return <Loader />;
  }

  if(narratives.objects?.length === 0) {
    return <div className={threatsTableStyles.noData}>{t('There is no data to display')}</div>
  }

  return (
    <>
      {searchPanel ? searchPanel : ''}
      <div className={threatsTableStyles.tableWrapper}>
        <table className={threatsTableStyles.threats}>
          <thead>
            <tr>
              <td>{t('Narratives')}</td>
              <td>{t('Threats')}</td>
              <td>{t('Messages')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {narratives.objects?.map((narrative) => {
              return (
                <tr key={narrative.id}>
                  <td className={threatsTableStyles.mainInfo}>
                    <div className={`${threatsTableStyles.threatNameWrapper}`}>
                      <div className={threatsTableStyles.threatNameContainer}>
                        <span>
                          <Link to={`/threats/narrative/${narrative.id}`}>
                            {narrative.name}
                          </Link>
                        </span>
                        <div className={threatsTableStyles.threatInfo}>
                          <span>#{narrative.id}</span>
                          <span>&#8226;</span>
                          <span>
                            {t('created by ')}
                            <span className={threatsTableStyles.userName}>
                              {' '}
                              {narrative?.user?.first_name}{' '}
                              {narrative?.user?.last_name}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{formatNumber(narrative.threats)}</td>
                  <td>{formatNumber(narrative.total_messages)}</td>
                  <td>
                    <div className={threatsTableStyles.controls}>
                      <Tooltip content={t('Edit')} position="left">
                        <button
                          className={`${threatsTableStyles.edit} btn-primary`}
                          onClick={() => {
                            setEditModal({ isOpen: true, threat: narrative });
                          }}
                          disabled={
                            narrative.review_status === 'DECLINED' &&
                            !currentUser.is_super_admin
                              ? true
                              : false
                          }
                        >
                          <EditIcon />
                        </button>
                      </Tooltip>
                      <Tooltip content={t('Delete')} position="left">
                        <button
                          className={`${threatsTableStyles.delete} btn-primary`}
                          onClick={() =>
                            setDeleteModal({
                              isOpen: true,
                              threatId: narrative.id,
                            })
                          }
                        >
                          <DeleteIcon />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Paginator
        size={narratives.size}
        page={narratives.page}
        total={narratives.total}
        onPageChange={(newPage) => {
          const params = new URLSearchParams();

          if (searchQuery) {
            params.set('q', searchQuery);
          }

          params.set('page', newPage);
          navigate(`?${params.toString()}`);
        }}
      />

      {editModal.isOpen ? (
        <ThreatEditModal
          isOpen={editModal.isOpen}
          title={t('Edit the threat')}
          threat={editModal.threat}
          isAdmin={currentUser.is_super_admin}
          onChange={(bool) => setEditModal({ isOpen: bool, threat: null })}
          handleThreat={({
            threat,
            threatName,
            threatDescription,
            caseType,
            incidentType,
            topics,
            geoScope,
            narratives,
            campaigns,
            counterEntities,
            reviewStatus,
            declineReason,
            organizations,
            isCreatedByUcbiReporter,
            threatPublicNameEn,
            threatPublicNameUk,
            threatPublicDescriptionEn,
            threatPublicDescriptionUk,
            threatIsPublic,
          }) => {
            patchThreat({
              threat,
              threatName,
              threatDescription,
              caseType,
              incidentType,
              topics,
              geoScope,
              narratives,
              campaigns,
              counterEntities,
              reviewStatus,
              declineReason,
              organizations,
              isCreatedByUcbiReporter,
              threatPublicNameEn,
              threatPublicNameUk,
              threatPublicDescriptionEn,
              threatPublicDescriptionUk,
              threatIsPublic,
              searchQuery,
              sorting: sortingQuery,
              page,
              workspaceId: currentUser.is_super_admin ? threat.workspace_id : null,
              mode: 'Narratives',
            });
          }}
        />
      ) : (
        ''
      )}

      <Modal
        isVisible={deleteModal.isOpen}
        title={t('Delete the threat')}
        content={<p>{t('Are you sure you want to delete the threat?')}</p>}
        footer={
          <>
            <button
              className="btn-danger"
              onClick={() => {
                deleteThreat(
                  deleteModal.threatId,
                  searchQuery,
                  sortingQuery,
                  page,
                  'Narratives'
                );
                setDeleteModal({ isOpen: false, threatId: null });
              }}
            >
              {t('Delete')}
            </button>
            <button
              className="btn-reset"
              onClick={() => setDeleteModal({ isOpen: false, threatId: null })}
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => setDeleteModal({ isOpen: false, threatId: null })}
      />
    </>
  );
};
