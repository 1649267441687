import { ReactComponent as PositiveIcon } from '../assets/positiveSentiment.svg';
import { ReactComponent as NegativeIcon } from '../assets/negativeSentiment.svg';
import { ReactComponent as NeutralIcon } from '../assets/neutralSentiment.svg';
import styles from "../components/MessagesTable/ExtendedMessageView.module.scss";

export const sentimentNameMapping = {
  'POSITIVE': 'Positive',
  'NEUTRAL': 'Neutral',
  'NEGATIVE': 'Negative',
}

export const sentimentIconMapping = {
  'POSITIVE': <span className={styles.positive} > <PositiveIcon /></span>,
  'NEUTRAL': <span className={styles.neutral}><NeutralIcon /></span>,
  'NEGATIVE': <span className={styles.negative}><NegativeIcon /></span>
}

export const sentimentIconMappingBubbleChart = {
  'POSITIVE': <span className='positive_sentiment'> <PositiveIcon /></span>,
  'NEUTRAL': <span className='neutral_sentiment'><NeutralIcon /></span>,
  'NEGATIVE': <span className='negative_sentiment'><NegativeIcon /></span>
}

export const getSentiment = (sentiment_score) => {
  if (sentiment_score === null) {
    return ''
  }

  if (sentiment_score >= -1 && sentiment_score < -0.3) {
    return 'NEGATIVE';
  }

  if (sentiment_score >= -0.3 && sentiment_score < 0.3) {
    return 'NEUTRAL';
  }

  if (sentiment_score >= 0.3 && sentiment_score < 1.000001) {
    return 'POSITIVE';
  }
}

export const getSentimentIcon = (sentiment_score) => {
  if (sentiment_score === null) {
    return ''
  }

  return sentimentIconMapping[getSentiment(sentiment_score)];
}

export const getSentimentIconBubbleChart = (sentiment_score) => {
  if (sentiment_score === null) {
    return ''
  }

  return sentimentIconMappingBubbleChart[getSentiment(sentiment_score)];
}

export const getSentimentName = (sentiment_score) => {
  if (sentiment_score === null) {
    return ''
  }

  return sentimentNameMapping[getSentiment(sentiment_score)];
}
