import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';

import { API } from '../../API';
import { Paginator } from '../../components/Paginator/Paginator';
import { Loader } from '../../components/Loader/Loader';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';

import styles from './NarrativeEditHistoryPage.module.scss';

const isArray = (value) => Array.isArray(value);
const isObject = (value) => typeof value === 'object' && value !== null;

function objectDifference(before, after) {
  const diff = {};

  const compareValues = (key, beforeValue, afterValue) => {
    if (isArray(beforeValue) && isArray(afterValue)) {
      // Compare arrays
      if (JSON.stringify(beforeValue) !== JSON.stringify(afterValue)) {
        diff[key] = {
          before: beforeValue,
          after: afterValue,
        };
      }
    } else if (isObject(beforeValue) && isObject(afterValue)) {
      // Recursively compare nested objects
      diff[key] = objectDifference(beforeValue, afterValue);
    } else if (beforeValue !== afterValue) {
      // Compare non-object, non-array values
      diff[key] = {
        before: beforeValue,
        after: afterValue,
      };
    }
  };

  for (const key in after) {
    if (before.hasOwnProperty(key)) {
      compareValues(key, before[key], after[key]);
    } else {
      diff[key] = {
        before: undefined,
        after: after[key],
      };
    }
  }

  for (const key in before) {
    if (!after.hasOwnProperty(key)) {
      diff[key] = {
        before: before[key],
        after: undefined,
      };
    }
  }

  return diff;
}

export function NarrativeEditHistory({ narrativeId }) {
  const { t } = useTranslation();
  const [auditLogs, setAuditLogs] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const displayParameter = (value) => {
    if (isArray(value)) {
      return <span>{value.join(', ') || t('Not selected')}</span>;
    } else if (value === true || value === false) {
      return value.toString();
    }
  
    return <span>{value || t('Not selected')}</span>;
  };

  const NARRATIVE_FIELDS = {
    name: t('Name'),
    description: t('Description'),
    start_date: t('Start date'),
    end_date: t('End date'),
    keywords_query_origin_language: t('Native language'),
    languages: t('Languages'),
    keywords: t('Keywords'),
    platforms: t('Platforms'),
    content_types: t('Content types'),
    actors: t('Actors'),
    sentiment: t('Sentiment'),
    source_group_ids: t('Actor groups'),
    source_types: t('Platforms'),
    examples: t('Examples'),
    apply_supervised_classifier: t(
      'Enhance search results based on user feedback',
    ),
    translate_keywords_query: t('Multilanguage search'),
    source_ids: t('Actors'),
    source_origin_country_ids: t('Actors origin countries'),
    is_manual: t('Manual'),
    is_active: t('Active'),
    review_status: t('Status'),
    disable_filters: t('Disable filters'),
    create_stories: t('Create stories from case'),
    stories_candidates_min_threshold: t('Candidates min threshold'),
    stories_grouping_max_distance: t('Grouping max distance'),
    source_bots: t('Inauthentic behavior'),
    source_discreditated_entity_types: t('Compromised accounts'),
    source_state_affiliated_country_ids: t('State-affiliated actors'),
    source_state_affiliated_all_countries: t('Select all affiliation countries')

  };

  const fetchAuditLogs = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '20');
    urlParams.set('page', page);
    urlParams.append('operation', 'create');
    urlParams.append('operation', 'update');

    API.fetch(
      'GET',
      `/API/v1/audit_logs/narratives/${narrativeId}?${urlParams.toString()}`,
    ).then((data) => {
      setAuditLogs(data);
    });
  }, [page]);

  useEffect(fetchAuditLogs, [fetchAuditLogs]);

  if (!auditLogs) {
    return <Loader />;
  }

  return (
    <div className="list-content">
      <div className="page-header">
      
      </div>

      <div className={styles.tableWrapper}>
        <table className={styles.cases}>
          <thead>
            <tr>
              <td>{t('Case')}</td>
              <td>{t('Changes')}</td>
            </tr>
          </thead>
          <tbody>
            {auditLogs.objects.map((auditLog) => {
              const diff = objectDifference(
                auditLog?.before || {},
                auditLog?.after || {},
              );

              return (
                <tr key={auditLog.id}>
                  <td>
                    <div className={styles.caseNameWrapper}>
                      <div className={styles.caseNameContainer}>
                        <span>
                          {format(
                            parseISO(auditLog.date_created + 'Z'),
                            'dd LLL yyyy HH:mm',
                          )}
                        </span>
                        <div className={styles.caseInfo}>
                          <span>#{auditLog.id}</span>
                          <span>&#8226;</span>
                          <span>
                            {auditLog.operation === 'create' ? t('created by') : t('updated by')}{' '}
                            <span className={styles.userName}>
                              {auditLog?.user?.first_name}{' '}
                              {auditLog?.user?.last_name}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {Object.entries(diff).map(([key, value]) => {
                      if (key === 'parameters') {
                        return (
                          <>
                            {Object.entries(value).map(([k, v]) => {
                              if (v?.before === undefined && v?.after === undefined) {
                                return;
                              }

                              return (
                                <>
                                  <p className={styles.history}>
                                    <span className={styles.field}>
                                      {NARRATIVE_FIELDS[k]}
                                    </span>
                                    <span className={styles.before}>
                                      {displayParameter(v?.before)}
                                    </span>
                                    <span className={styles.arrow}>
                                      <ArrowIcon />
                                    </span>
                                    <span className={styles.after}>
                                      {displayParameter(v?.after)}
                                    </span>
                                  </p>
                                </>
                              );
                            })}
                          </>
                        );
                      }
                      return (
                        <>
                          <p className={styles.history}>
                            <span className={styles.field}>
                              {NARRATIVE_FIELDS[key]}
                            </span>
                            <span className={styles.before}>
                              {displayParameter(value.before)}
                            </span>
                            <span className={styles.arrow}>
                              <ArrowIcon />
                            </span>
                            <span className={styles.after}>
                              {displayParameter(value.after)}
                            </span>
                          </p>
                        </>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator
        size={auditLogs.size}
        page={auditLogs.page}
        total={auditLogs.total}
      />
    </div>
  );
}
