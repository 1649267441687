import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../API';
import Modal from '../../components/Modal';
import { Loader } from '../../components/Loader/Loader';
import styles from './GroupsPage.module.scss';
import Switch from "../../components/Switch";
import {useCurrentUser} from "../../contexts/CurrentUser";

export const GroupEditModal = ({editModal, handleEdit, clearEdit}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [name, setName] = useState(editModal.group ? editModal.group.name : '');
  const [isPublic, setIsPublic] = useState(editModal.group && editModal.group.is_public);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if(name.length === 0) {
      setError('Please fill in this field')
      return
    }

    handleEdit(name, isPublic, editModal.group)
  }

  return(
    <Modal
        isVisible={editModal.isOpen}
        title={t('Edit actor group')}
        content={
          <form method="post" onSubmit={e => handleSubmit(e)}>
            <div className={error ? 'form-element error' : 'form-element'}>
              <label htmlFor="name">{t('Name')}</label>
              <input
                className=""
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            { currentUser?.is_super_admin && (
              <div className={error ? 'form-element error' : 'form-element'}>
                <label htmlFor="is_public">{t('Is public')}</label>
                <Switch
                    id="is_public"
                    value={isPublic}
                    onChange={setIsPublic}
                  />
              </div> )}
            <div className="error-message">{t(error)}</div>
          </form>
        }
        footer={
          <>
            <button className="new-button" onClick={(e) => handleSubmit(e)}>
              {t('Save')}
            </button>
            <button className="btn-reset" onClick={() => clearEdit()}>
              {t('Cancel')}
            </button>
          </>
        }
        onClose={clearEdit}
      />
  )
}