import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { API } from '../../../API';

import { AdjustmentSuggestion } from './AdjustmentSuggestion'

import styles from './AdjustmentSuggestion.module.scss';

export function AdjustmentSuggestions({
  narrative,
  messages,
  onSuggestionApply = null,
  limit = 3
}) {
  const { t } = useTranslation();

  if (narrative.adjustment_suggestions === null) {
    return <></>;
  }

  if (narrative.adjustment_suggestions.length === 0) {
    if (messages?.objects?.length === 0) {
      return (
        <div className={styles.root}>
          <div>
            {t("No messages found. Please try to edit narrative's query")}
          </div>
          <Link
            to={`/narratives/${narrative.id}/edit`}
            className={cn(styles.buttonText, 'button', 'new-button')}
          >
            {/* <EditIcon /> */}
            {t('Edit narrative')}
          </Link>
        </div>
      )
    }

    return <></>;
  }

  return (
    <>
    { narrative.adjustment_suggestions.slice(0, limit).map((suggestion, i) => (
        <AdjustmentSuggestion
          key={`adjustment_suggestion_${i}`}
          narrative={narrative}
          suggestion={suggestion}
          onSuggestionApply={onSuggestionApply}
        />
      ))
    }
    </>
  );
}
