export const initialState = {
  threats: null,
  narratives: null,
  narrative: null,
  // deduplication: false,
};

const threatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_THREATS':
      return Object.assign({}, state, {
        threats: action.newThreats,
      });
    case 'GET_NARRATIVES':
      return Object.assign({}, state, {
        narratives: action.payload,
      });
    case 'GET_NARRATIVE':
      return Object.assign({}, state, {
        narrative: action.payload,
      });

    case 'TOGGLE_TAB':
      return Object.assign({}, state, {
        threats: null,
        narratives: null,
      });

    default:
      return state;
  }
};

export default threatsReducer;
