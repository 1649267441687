import styles from './PowerBIDashboardPage.module.scss';
import { useCallback, useEffect, useState } from "react";
import { API } from "../../API";
import { Link, useParams } from "react-router-dom";
import { Loader } from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";

export function PowerBIDashboardPage() {
  const { t } = useTranslation();
  const { dashboardId} = useParams();
  const [dashboard, setDashboard] = useState(null);

  const fetchData = useCallback((dashboardId) => {
    API.fetch('GET', `/API/v1/powerbi-dashboards/${dashboardId}`).then(setDashboard);
  }, []);

  useEffect(() => {
    fetchData(dashboardId)
  }, [fetchData, dashboardId])

  if (dashboard === null) {
    return <Loader />;
  }

  return(
    <div className={styles.root}>
      <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/dashboards">{t('Dashboards')}</Link>
          </span>
          <span>{dashboard.name}</span>
        </div>
        </div>
      </div>
      <iframe width={'100%'} height={'100%'} title={dashboard.name} allowFullScreen src={dashboard.embed_link}></iframe>
    </div>
  )
}
