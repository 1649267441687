import React from 'react';
import cn from 'classnames';

import { Checkbox } from './Checkbox';
import styles from './CheckboxField.module.scss';

interface Props {
  children: React.ReactNode;
  inputClassName?: string;
  className?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  isLoading?: boolean;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}

export const CheckboxField: React.FC<Props> = ({
  className,
  inputClassName,
  children,
  checked,
  onChange,
  isLoading,
  inputProps,
}) => {
  return (
    <label className={cn(styles.root, className)}>
      <Checkbox
        className={inputClassName}
        checked={checked}
        onChange={onChange}
        isLoading={isLoading}
        inputProps={inputProps}
      />

      {children}
    </label>
  );
};
