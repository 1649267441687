import { createContext, useReducer, useRef } from "react";
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { format } from 'date-fns';
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import messagesReducer, { initialState } from "./MessagesReducer";
import { useCurrentUser } from "../../../contexts/CurrentUser";

export const MessagesContext = createContext(initialState);

export const areAllIdsSelected = (messages, selectedMessages, deduplication) => { 
  if(deduplication) {
    for (const message of messages) {
      if (!(message.id in selectedMessages)) {
        return false;
      }
    }
  } else {
    for (const message of messages) {
      if (!(message.id in selectedMessages)) {
        return false;
      }
    }
  }
  
  return true;
}

export const MessagesProvider = ({isShare, children}) => {
  const [state, dispatch] = useReducer(messagesReducer, initialState);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [currentUser] = useCurrentUser();

  const api = isShare ? ShareAPI : API;

  const languageMapping = {
    'UK': 1,
    'EN': 2,
    'RU': 3,
    'FR': 4,
    'PL': 5,
    'ES': 6,
    'PT': 7,
    'DE': 8,
    'HU': 9,
    'AR': 10,
    'FA': 11,
    'NO': 12,
    'SV': 13,
    'RO': 14,
    'FI': 15,
    'ET': 16,
    'LV': 17,
    'LT': 18,
    'BG': 19,
    'CS': 20,
    'EL': 21,
    'NL': 22,
    'HY': 23,
    'ID': 24,
    'KK': 25,
    'IT': 26,
    'HI': 27,
    'TH': 28,
    'TR': 29,
    'DA': 30,
    'BN': 31,
    'CKB': 32,
    'BE': 33,
  }

  const isValueSet = (value) => {
    if (Array.isArray(value) && !value.some(isValueSet)) {
      return false;
    }

    return value !== "" && value !== undefined && value !== null;
  }
  const isAnyFilterApplied = (filters) => {
    let filters_copy = { ...filters }

    // Drop keys don't used in filters check
    delete filters_copy.manipulation_index_lte;
    delete filters_copy.manipulation_index_gte;

    const values = Object.values(filters_copy);

    return  values.some(isValueSet);
  }

  const abortControllerRef = useRef();
  

  const fetchNarrative = (narrativeId) => {
    const onRequestError = (resp) => {
      if (isShare && (resp.status === 401 || resp.status === 403)) {
        const hash = searchParams.get('h');
        const with_password = searchParams.get('with_password');
        navigate(`/narratives/${narrativeId}/share/login?h=${hash}&with_password=${with_password}`);
      }
    }

    api.fetch('GET', `/API/v1/narratives/${narrativeId}`, null, null, null, onRequestError)
    .then((data) => {
      dispatch({type: 'GET_NARRATIVE', narrative: data})
    })
    .catch(e => dispatch({type: 'ERROR', error: e.message}));
  }

  const getMessages = (
    narrative,
    searchQuery,
    sortingQuery,
    statuses,
    deduplication,
    page,
    showOnlyFavorites,
    start,
    end,
    platforms,
    sourceGroups,
    manipulation,
    isValid,
    languages,
    sources,
    countries,
    sentiment,
    contentTypes,
    showOnlyBots,
    discreditations,
    affiliationCountries,
    languagesExclude,
    countriesExclude,
    sourceGroupsExclude,
    sourcesExclude,
    affiliationCountriesExclude,
    cursor,
    isLoading,
    isSearchApplied,
    size=100,
  ) => {
    if (!narrative) {
      return;
    }

    const keyword = searchQuery ? searchQuery.replace(/(\r\n|\n|\r)/gm, "") : "";
    const bodyRequest = {}

    if (keyword) {
        bodyRequest['keywords'] = [keyword]
    }

    if (start) {
      bodyRequest['start_date'] = format(start, 'yyyy-LL-dd')
    }

    if (end) {
        bodyRequest['end_date'] = format(end, 'yyyy-LL-dd')
    }


    if (platforms && platforms.length > 0) {
      bodyRequest.platforms = platforms;
    }

    if (sentiment && sentiment.length > 0) {
      bodyRequest.sentiment = sentiment;
    }

    if (sourceGroups && sourceGroups.length > 0) {
      bodyRequest.source_group_ids = sourceGroups;
    }

    if (manipulation) {
      bodyRequest.manipulation_index_lte = manipulation[1];
      bodyRequest.manipulation_index_gte = manipulation[0];
    }

    if(languages && languages.length > 0) {
      bodyRequest.languages = languages.map(lang => languageMapping[lang]);
    }

    if (contentTypes && contentTypes.length > 0) {
      bodyRequest.content_types = contentTypes;
    }

    if(sources && sources.length > 0) {
      bodyRequest.source_ids = sources;
    }

    if (countries && countries.length > 0) {
      bodyRequest.source_origin_country_ids = countries;
    }

    if (showOnlyBots === true) {
      bodyRequest.source_bots = showOnlyBots;
    }

    if (discreditations && discreditations?.length > 0) {
      bodyRequest.source_discreditated_entity_types = discreditations;
    }

    if(affiliationCountries?.length > 0 && affiliationCountries[0] === 'all') {
      bodyRequest.source_state_affiliated_all_countries = true;
    } else {
      bodyRequest.source_state_affiliated_country_ids = affiliationCountries?.map(v => v.value);
    }

    let excludedFilters = [];

    if(languagesExclude) {
      excludedFilters.push('languages');
    }
    
    if(countriesExclude) {
      excludedFilters.push('source_origin_country_ids');
    }

    if(sourceGroupsExclude) {
      excludedFilters.push('source_group_ids');
    }

    if(sourcesExclude) {
      excludedFilters.push('source_ids');
    }

    if(affiliationCountriesExclude) {
      excludedFilters.push('source_state_affiliated_country_ids');
    }

    if(excludedFilters.length > 0) {
      bodyRequest.exclusive_filters = excludedFilters;
    }

  
    const urlParams = new URLSearchParams();
    urlParams.set('narrative_id', narrative.id);
    urlParams.set('size', size);

    if (cursor) {
      urlParams.set('cursor', cursor);
    }

    if (page) {
      urlParams.set('page', page);
    }

    if(statuses) {
      statuses.forEach(status => urlParams.append('message_status', status));
    }

    if(deduplication) {
      urlParams.set('deduplicated', true);
    }

    if(showOnlyFavorites) {
      urlParams.set('favorite', showOnlyFavorites);
    }

    if (sortingQuery) {
      urlParams.set('sorting', sortingQuery);
    }

    // Check if there's an existing request and abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    api.fetch(
      'POST',
      `/API/v1/messages/with_search?${urlParams.toString()}`,
      null,
      isAnyFilterApplied(bodyRequest) ? bodyRequest : null,
      signal
    ).then(
      (data) => {
        dispatch({type: 'ERROR', error: null})
        if (cursor && isSearchApplied) {
          dispatch({type: 'APPEND_MESSAGES', newMessages: data})
        } else {
          dispatch({type: 'GET_MESSAGES', newMessages: data})
        }
         setLoading(false)
      },
    ).catch((e) => {
      if (e.name === 'AbortError') {
        return;
      }
      dispatch({type: 'ERROR', error: e.message})
    });
  };

  const getStories = (
    narrative,
    searchQuery,
    start_date,
    end_date,
    statuses,
    sorting,
    page,
    size=100
  ) => {

    const urlParams = new URLSearchParams();
    urlParams.set('size', size);
    urlParams.set('page', page);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    if (start_date) {
      urlParams.set('start_date', format(start_date, 'yyyy-LL-dd 00:00:00'));
    }

    if (end_date) {
      urlParams.set('end_date', format(end_date, 'yyyy-LL-dd 23:59:59'));
    }

    if(statuses) {
      statuses.forEach(status => urlParams.append('message_status', status));
    }

    if (sorting) {
      if (Array.isArray(sorting.fieldName)) {
        sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${sorting.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sorting);
      }
    }

    // Check if there's an existing request and abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    api.fetch('GET', `/API/v1/narrative_stories/${narrative.id}?${urlParams.toString()}`, null, null, signal)
      .then((data) => {
        dispatch({type: 'GET_MESSAGES', newMessages: data});
      }).catch((error) => {
        if (error.name === 'AbortError') {
          return;
        }
        dispatch({type: 'ERROR', error: error.message})
      });
  };

  const fetchOriginalSources = (narrative, ids) => {
    if (!narrative) {
      return;
    }

    if (ids === null || ids.length === 0) {
      return;
    }

    const urlParams = new URLSearchParams();
    urlParams.set('narrative_id', narrative.id);
    urlParams.set('ids', ids)

    api.fetch('POST', `/API/v1/messages/with_search?${urlParams.toString()}`).then(
      (data) => {
        dispatch({type: 'GET_ORIGINAL_SOURCES', messages: data})
      },
    );
  }

  const fetchNarrativeStats = (narrative) => {
    const urlParams = new URLSearchParams();
    urlParams.append('narrative_id', narrative.id)

    if (narrative.type === "IMPACT_ASSESSMENT") {
      api.fetch(
        'GET',
        `/API/v1/dashboard/narrative_stories/${narrative.id}/sentiment`,
      )
      .then((data) => {
        dispatch({type: 'GET_NARRATIVE_STATS_STORIES', payload: data})
      });
    } else {
      Promise.all([
        api.fetch('GET', `/API/v1/dashboard/narratives?${urlParams.toString()}`),
        api.fetch('GET', `/API/v1/dashboard/narrative_stories/${narrative.id}/sentiment`)
      ])
      .then(([messagesData, storiesData]) => {
        dispatch({type: 'GET_NARRATIVE_STATS', payload: messagesData})
        dispatch({type: 'GET_NARRATIVE_STATS_STORIES', payload: storiesData})
      });
    }
  }

  const fetchMostMentionedEntities = (narrative, entity_type) => {
    const urlParams = new URLSearchParams();

    urlParams.set('entity_type', entity_type)

    dispatch({type: 'DELETE_ENTITIES'})

    api.fetch(
      'GET',
      `/API/v1/dashboard/narratives/${narrative.id}/entities?${urlParams.toString()}`,
    )
    .then((data) => {
      dispatch({type: 'GET_MOST_MENTIONED_ENTITIES', payload: data})
    });
  }

  const fetchNarrativeShares = (narrative, statsAggregation, statsBreakdown) => {
    const urlParams = new URLSearchParams();
    urlParams.append('narrative_id', narrative.id)

    urlParams.set('aggregation', statsAggregation);

    let url = `/API/v1/stats/narratives/shares?${urlParams.toString()}`;

    if (statsBreakdown !== null) {
      urlParams.set('breakdown', statsBreakdown);
      url = `/API/v1/stats/narratives/${narrative.id}/shares?${urlParams.toString()}`;
    }

    api.fetch('GET', url)
    .then((data) => {
      dispatch({type: 'GET_NARRATIVE_SHARES', payload: data})
    });
  }

  const deleteMessages = (narrative, ids, deduplication=false, deleteFromCase = true) => {
    let headers = { 'Content-Type': 'application/json' };

    if(narrative.is_threat && currentUser.is_super_admin && narrative.workspace_id !== currentUser.workspace_id) {
      headers['workspace-id'] = narrative.workspace_id;
    }

    let body = {
      delete_from_case: deleteFromCase,
    };

    if (deduplication) {
      body['narrative_story_ids'] = ids;
    } else {
      body['message_ids'] = ids;
    }

    return api.fetch('DELETE', `/API/v1/narratives/${narrative.id}/messages`, headers, body);
  };

  const patchMessages = (narrative, status, ids, deduplication=false) => {
    let headers = { 'Content-Type': 'application/json' };

    if(narrative.is_threat && currentUser.is_super_admin && narrative.workspace_id !== currentUser.workspace_id) {
      headers['workspace-id'] = narrative.workspace_id;
    }

    let body;

    if (deduplication) {
      body = {
        status: status,
        narrative_story_ids: ids,
      }
    } else {
      body = {
        status: status,
        message_ids: ids
      }
    }

    return api.fetch('PATCH', `/API/v1/narratives/${narrative.id}/messages`, headers, body);
  }

  const editBubbleChartSentiment = (sentiment, messageId, narrative) => {
    api.fetch('PATCH', `/API/v1/narrative_stories/${narrative.id}/story/${messageId}`, null, {sentiment: sentiment}).then(data => {
      fetchNarrativeStats(narrative)
    })
  }

  const toggleFavotireMessage = (narrative, favorite, ids, deduplication=false) => {
    let body;

    if (deduplication) {
      body = {
        favorite: favorite,
        narrative_story_ids: ids,
      }
    } else {
      body = {
        favorite: favorite,
        message_ids: ids
      }
    }

    dispatch({type: 'TOGGLE_FAVORITE', payload: {ids: ids, favorite: favorite}});

    return api.fetch('PATCH', `/API/v1/narratives/${narrative.id}/messages`, null, body);
  }

  const toggleAll = (messages, selectedMessages) => {
      if(areAllIdsSelected(messages.objects, selectedMessages)) {
        dispatch({type: 'DESELECT_ALL_MESSAGES'})
      } else {
        if(state.deduplication) {
          const newSelectedMessages = Object.fromEntries(
            messages.objects.map(message => [message.id, true])
          );
          dispatch({type: 'SELECT_ALL_MESSAGES', payload: newSelectedMessages});
        } else {
          const newSelectedMessages = Object.fromEntries(
            messages.objects.map(message => [message.id, true])
          );
          dispatch({type: 'SELECT_ALL_MESSAGES', payload: newSelectedMessages});
        }
        
      }
  }

  const clearAllSelectedMessages = () => {
    dispatch({type: 'CLEAR_ALL_SELECTED_MESSAGES'})
  }

  const translateMessage = (messageId, text, nativeLang, sourceLang='') => {
    let body;
    if(sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang
      }
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      }
    }
    return api.fetch('POST', '/API/v1/translations/translate', null, body)
  }

  const createStories = (narrative) => {
    api.fetch('POST', `/API/v1/narratives/${narrative.id}/enable_stories`).then(() => {
      fetchNarrative(narrative.id)
      dispatch({type: 'SHOW_STORIES_MESSAGE', showStoriesMessage: true})
    }).catch(e => dispatch({type: 'ERROR', error: e.message}))
  };

  const toggleTranslate = (messageId, isTranslation) => {
    dispatch(
      {type: 'TOGGLE_TRANSLATE', payload: {messageId: messageId, isTranslation: isTranslation}}
    )
  }

  const toggleSelected = (messageId) => {
    dispatch({type: 'TOGGLE_SELECTED', messageId: messageId })
  }

  const toggleDeduplication = (bool) => {
    dispatch({type:'TOGGLE_DEDUPLICATION', payload: bool})
  }

  const toggleTab = () => {
    dispatch({type:'TOGGLE_TAB'})
  }

  const clearSelectedMessages = () => {
    dispatch({type:'PATCH_MESSAGES'})
  }

  const applySearch = (bool) => {
    dispatch({type: 'APPLY_SEARCH', isSearchApplied: bool})
  }

  const setLoading = (bool) => {
    dispatch({type: 'SET_LOADING', isLoading: bool})
  }

  const cancelRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }

  const setStoriesMessage = (bool) => {
    dispatch({type: 'SHOW_STORIES_MESSAGE', showStoriesMessage: bool})
  }

  const value = {
    narrative: state.narrative,
    messages: state.messages,
    narrativeShares: state.narrativeShares,
    narrativeStats: state.narrativeStats,
    narrativeStatsStories: state.narrativeStatsStories,
    mostMentionedEntities: state.mostMentionedEntities,
    error: state.error,
    selectedMessages: state.selectedMessages,
    deduplication: state.deduplication,
    isSearchApplied: state.isSearchApplied,
    isLoading: state.isLoading,
    originalSources: state.originalSources,
    showStoriesMessage: state.showStoriesMessage,

    dispatch: dispatch,
    fetchNarrativeStats: fetchNarrativeStats,
    fetchNarrativeShares: fetchNarrativeShares,
    fetchMostMentionedEntities: fetchMostMentionedEntities,
    editBubbleChartSentiment: editBubbleChartSentiment,
    getMessages: getMessages,
    getStories: getStories,
    patchMessages: patchMessages,
    toggleFavotireMessage: toggleFavotireMessage,
    deleteMessages: deleteMessages,
    toggleAll: toggleAll,
    fetchOriginalSources: fetchOriginalSources,
    toggleSelected: toggleSelected,
    toggleDeduplication: toggleDeduplication,
    clearSelectedMessages: clearSelectedMessages,
    toggleTranslate: toggleTranslate,
    translateMessage: translateMessage,
    toggleTab: toggleTab,
    clearAllSelectedMessages: clearAllSelectedMessages,
    applySearch: applySearch,
    setLoading: setLoading,
    cancelRequest: cancelRequest,
    createStories: createStories,
    fetchNarrative: fetchNarrative,
    setStoriesMessage: setStoriesMessage,
  }

  return (
    <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>
  )
}

