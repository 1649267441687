import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';

import './BarChart.scss';

const parseDate = d3.timeFormat('%B %d, %Y');
const parseDateShort = d3.timeFormat('%d.%m');
let formatNumber = d3.format(',d');
let formatDecimal = d3.format(',.2f');

const palette = ['#2463eb', '#40b870', '#dc2625', '#8825eb'];
let margin = {
  top: 50,
  right: 70,
  bottom: 70,
  left: 70,
};


function wrap(text, dimensions, padding) {
  text.each(function() {
    var text = d3.select(this);
    var pieces,
        piece,
        line_width,
        x_offset,
        tspan,
        previous_content;

    pieces = text.text().split(' ').reverse();
    text.text('');
    tspan = text.append('tspan');
    tspan
        .attr('dx', -10)
        .attr('dy', -8);
    x_offset = 10;
    while (pieces.length > 0) {
        piece = pieces.pop();
        tspan.text(tspan.text() + ' ' + piece);
        line_width = tspan.node().getComputedTextLength() || 0;
        if (line_width > dimensions.width) {
            previous_content = tspan.text()
                .split(' ')
                .slice(0, -1)
                .join(' ');
            tspan.text(previous_content);
            x_offset = tspan.node().getComputedTextLength() * -1;
            tspan = text.append('tspan');
            tspan
                .attr('dx', x_offset)
                .attr('dy', '1em')
                .text(piece);
        }
    }
    if (typeof padding === 'number') {
        text
            .attr('y', +text.attr('y') + padding)
            .attr('x', +text.attr('x') + padding);
    }
  })
};


export function BarChart({ rawdata }) {
  const svgWrapper = useRef(null);
  const parentWrapper = useRef(null);
  const uuid = 'a' + Math.floor(Math.random() * 10000);

  const [width, setWidth] = useState(600);
  const [height] = useState(400 - margin.top - margin.bottom);

  useEffect(() => {
    if (
      !parentWrapper.current ||
      typeof window.ResizeObserver === 'undefined'
    ) {
      return;
    }

    const resizeObserver = new window.ResizeObserver((entries) => {
      const rootEntry = entries.find((e) => e.target === parentWrapper.current);

      if (!rootEntry) {
        return;
      }

      setWidth(rootEntry.contentRect.width - margin.left - margin.right);
      // setHeight(rootEntry.contentRect.height);
    });

    resizeObserver.observe(parentWrapper.current);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    d3.select(svgWrapper.current).selectAll('*').remove();

    // Specify the chart’s dimensions, based on a bar’s height.
    const barHeight = 45;
    const marginTop = 10;
    const marginRight = 45;
    const marginBottom = 50;
    const marginLeft = 170;
    const width = 928;
    const height =
      Math.ceil((rawdata.length + 0.1) * barHeight) + marginTop + marginBottom;

    // Create the scales.
    const x = d3
      .scaleLinear()
      .domain([0, d3.max(rawdata, (d) => d.value)])
      .range([marginLeft, width - marginRight]);

    const y = d3
      .scaleBand()
      .domain(d3.sort(rawdata, (d) => -d.value).map((d) => d.name))
      .rangeRound([marginTop, height - marginBottom])
      .padding(0.3);

    // Create a value format.
    const format = x.tickFormat(20, "i");

    // Create the SVG container.
    const svg = d3
      .select(svgWrapper.current)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr(
        'style',
        'max-width: 100%; height: auto; font-family: Inter; font-size: 10px;',
      );

    // svg
    //   .append('g')
    //   .attr('class', 'grid')
    //   .call(
    //     d3
    //       .axisLeft(y)
    //       .tickSize(-width)
    //       .ticks(4)
    //       .tickPadding(15)
    //       .tickSizeOuter(0),
    //   );

    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        d3
          .axisBottom(x)
          .tickSize(height - marginBottom)
          .tickFormat(format)
          .tickPadding(15)
          .ticks(8),
      );

    // Append a rect for each name.
    svg
      .append('g')
      .attr('fill', palette[0])
      .selectAll()
      .data(rawdata)
      .join('rect')
      .attr('x', x(0))
      .attr('y', (d) => y(d.name))
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', (d) => x(d.value) - x(0))
      .attr('height', y.bandwidth());

    // Append a label for each name.
    svg
      .append('g')
      .attr('fill', '#a0a3a6')
      .attr('text-anchor', 'end')
      .selectAll()
      .data(rawdata)
      .join('text')
      .attr('x', (d) => x(d.value))
      .attr('y', (d) => y(d.name) + y.bandwidth() / 2)
      .attr('dy', '0.35em')
      .attr('dx', (d) => d.value.toString().length * 7)
      .text((d) => format(d.value))
      .call((text) =>
        text
          .filter((d) => x(d.value) - x(10) < 20) // short bars
          .attr('dx', +4)
          .attr('text-anchor', 'start')
      );

    svg.append("g")
      .attr('transform', `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y).tickSizeOuter(0))
      .attr(
        'style',
        'font-size: 12px;',
      )
    .selectAll(".tick text")
      .call(wrap, {width: 150}, 10);

    d3.selectAll('.bar-chart .domain').each(function (d, i) {
      this.remove();
    });

    // end of d3
  }, [rawdata, width]);

  return (
    <div className="bar-chart" ref={parentWrapper}>
      <svg width={width} height={height} ref={svgWrapper}></svg>
    </div>
  );
}
