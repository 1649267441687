import { useTranslation } from 'react-i18next';

import './Filter.scss';


export const PlatformChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const platformList = [
    {name:'Telegram', value: 1},
    {name:'Facebook', value: 2},
    {name:'VK', value: 4},
    {name:'Twitter', value: 3},
    {name:'WEB', value: 5},
    {name:'YouTube', value: 7},
    {name:'TikTok', value: 8},
  ]

  const handleSelect = () => {
    if (platformList.length !== selected.length) {
      onChange(platformList.map(platform => platform.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{platformList.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {platformList.map(platform => {
          return(
            <li key={platform.value}>
              <input 
                id={`platform_${platform.value}`}
                type='checkbox'
                checked={selected.includes(platform.value)}
                onChange={() => {
                  if (selected.includes(platform.value)) {
                    onChange(selected.filter(v => v !== platform.value)) 
                  } else {
                    onChange([...selected, platform.value]) 
                  }
                }}
              />
              <label htmlFor={`platform_${platform.value}`}>{platform.name}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}