import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState, useReducer, useRef } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API } from '../../API';
import { Loader } from '../../components/Loader/Loader';
import { SourcesTable } from '../../components/SourcesTable/SourcesTable';
import { Search } from '../../components/Search/Search';
import { CheckboxField } from '../../components/Checkbox/CheckboxField';
import Tooltip from '../../components/Tooltip/Tooltip';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { Filter } from '../SourcesPage/Filter/Filter';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { Tabs } from '../../components/Tabs/Tabs';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import { ReactComponent as AddToIcon } from '../../assets/addTo.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import Switch from '../../components/Switch';
import { AddToSourceGroupModal } from '../../components/AddToSourceGroupModal/AddToSourceGroupModal';

import styles from './GroupPageEdit.module.scss';
import tableStyles from '../../components/SourcesTable/SourcesTable.module.scss';
import bulkPanelStyles from '../NarrativePage/NarrativePage.module.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';


const sortingFieldNames = {
  ID: 'id',
  AUDIENCE: 'audience',
  ACTOR: 'name',
  PLATFORM: 'source_type',
  STATUS: 'status',
  URL: 'url',
  COUNTRY: 'country',
  RELEVANCE: 'relevance',
};

const sortingFieldNamesBack = {
  id: 'ID',
  audience: 'AUDIENCE',
  name: 'ACTOR',
  source_type: 'PLATFORM',
  status: 'STATUS',
  url: 'URL',
  country: 'COUNTRY',
  relevance: 'RELEVANCE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'APPLY_FILTER': {
      return Object.assign({}, state, {
        platforms: action.payload.platforms,
        countries: action.payload.countries,
        audience_lte: action.payload.audience_lte,
        audience_gte: action.payload.audience_gte,
        status: action.payload.status,
        affiliationCountries: action.payload.affiliationCountries,
        IoCs: action.payload.IoCs,
        showOnlyBots: action.payload.showOnlyBots,
        sourceTags: action.payload.sourceTags,
        affiliationCountriesExclude: action.payload.affiliationCountriesExclude,
        countriesExclude: action.payload.countriesExclude,
        audienceExclude: action.payload.audienceExclude,
        page: action.payload.page,
      });
    }

    case 'UPDATE_QUERY': {
      return Object.assign({}, state, {
        searchQuery: action.payload,
      });
    }

    case 'SORT_BY': {
      return Object.assign({}, state, {
        sorting: { ...state.sorting, fieldName: action.fieldName },
      });
    }

    case 'IS_ASCENDING': {
      return Object.assign({}, state, {
        sorting: { ...state.sorting, isAscending: action.isAscending },
      });
    }

    case 'CHANGE_PAGE':
      return Object.assign({}, state, {
        page: action.page,
      });
    
    case 'CHANGE_TAB':
      return Object.assign({}, state, {
        tabIndex: action.tabIndex,
      });
    default:
      return state;
  }
};

export const GroupPageEdit = () => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [sources, setSources] = useState(null);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSources, setSelectedSources] = useState([]);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({isActive: false, group: null, ids: []});
  const [copyGroupModal, setCopyGroupModal] = useState({ isActive: false, sources: [] });
  const [name, setName] = useState(group?.name || '');
  const [isPublic, setIsPublic] = useState(group?.is_public);
  const [description, setDescription] = useState(group?.description || '');

  useEffect(() => {
    API.fetch('GET', `/API/v1/groups/${groupId}`)
      .then((data) => {
        setGroup(data)
        setName(data?.name)
        setIsPublic(data?.is_public)
        setDescription(data?.description)
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [groupId]);

  const [state, dispatch] = useReducer(reducer, {
    sorting: {
      isAscending: false,
      fieldName: sortingFieldNamesBack['relevance'],
    },
    searchQuery: '',
    platforms: [],
    countries: [],
    audience_lte: null,
    audience_gte: null,
    status: ['ACTIVE'],
    affiliationCountries: [],
    IoCs: [],
    showOnlyBots: false,
    sourceTags: [],
    affiliationCountriesExclude: false,
    countriesExclude: false,
    audienceExclude: false,
    page: 1,
    tabIndex: 0,
  });

  const handleEditGroup = (name, isPublic, description, group) => {
    API.fetch('PATCH', `/API/v1/groups/${group.id}`, null, {name: name, is_public: isPublic, description: description}).then(() => {
      API.fetch('GET', `/API/v1/groups/${groupId}`).then(setGroup);
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(name.length === 0) {
      setError('Please fill in this field')
      return
    }

    handleEditGroup(name, isPublic, description, group)
  }

  const sortingQuery = state.sorting.fieldName
    ? `${state?.sorting?.isAscending ? '' : '-'}${
        sortingFieldNames[state?.sorting?.fieldName]
      }`
    : null;

    const page = searchParams.get('page') ?? 1;
    const searchQuery = searchParams.get('q');

  const abortControllerRef = useRef();
  const getSources = useCallback(
    (
      searchQuery,
      platforms,
      countries,
      audience_lte,
      audience_gte,
      status,
      affiliationCountries,
      IoCs,
      showOnlyBots,
      sourceTags,
      affiliationCountriesExclude,
      countriesExclude,
      audienceExclude,
      sorting,
      tabIndex,
      page = 1,
    ) => {

      const urlParams = new URLSearchParams();
      urlParams.set('size', '100');
      urlParams.set('page', page);

      setSources(null);

      if (searchQuery) {
        urlParams.set('q', searchQuery);
      }

      if (platforms?.length) {
        platforms.forEach((platform) => {
          urlParams.append('source_types', platform);
        });
      }

      if (countries?.length) {
        countries.forEach((country) => {
          urlParams.append('origin_country_ids', country);
        });
      }

      if (audience_lte !== null && audience_lte?.length > 0) {
        urlParams.set('audience_lte', audience_lte);
      }

      if (audience_gte !== null && audience_gte?.length > 0) {
        urlParams.set('audience_gte', audience_gte);
      }

      if (status?.length) {
        status.forEach((status_) => {
          urlParams.append('statuses', status_);
        });
      }

      if (affiliationCountries?.length) {
        affiliationCountries.forEach((country) => {
          urlParams.append('country_ids', country);
        });
      }

      if (IoCs?.length) {
        IoCs.forEach((IoC) => {
          urlParams.append('entity_types', IoC);
        });
      }

      if (showOnlyBots) {
        urlParams.set('is_bot', showOnlyBots);
      }

      if (sourceTags?.length) {
        sourceTags.forEach((tag) => {
          urlParams.append('tags', tag);
        });
      }

      let excludedFilters = [];
      if (countriesExclude === true) {
        excludedFilters.push('origin_country_ids');
      }

      if (affiliationCountriesExclude === true) {
        excludedFilters.push('country_ids');
      }


      if (audienceExclude === true) {
        excludedFilters.push('audience_lte', 'audience_gte');
      }

      if (excludedFilters.length > 0) {
        excludedFilters.forEach((filter) => {
          urlParams.append('exclusive_filters', filter);
        });
      }

      urlParams.set('source_group_id', groupId);
      urlParams.append('exclusive_filters', 'source_group_id');

      if (sorting) {
        if (Array.isArray(sorting?.fieldName)) {
          sorting.fieldName.forEach((element) => {
            urlParams.append(
              'sorting',
              `${sorting?.isAscending ? '' : '-'}${element}`,
            );
          });
        } else {
          urlParams.set('sorting', sortingQuery);
        }
      }

      const url = `/API/v1/sources?${urlParams.toString()}`;
      
      if (abortControllerRef?.current) {
        abortControllerRef.current.abort();
      }
  
      // Create a new AbortController
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      const signal = abortController.signal;

      API.fetch('GET', url, null, null, signal).then(setSources);
    },
    [groupId, state.page, state.sorting, state.tabIndex, state.searchQuery],
  );

  useEffect(getSources, [getSources]);

  useEffect(() => {
    getSources(
      state.searchQuery,
      state.platforms,
      state.countries,
      state.audience_lte,
      state.audience_gte,
      state.status,
      state.affiliationCountries,
      state.IoCs,
      state.showOnlyBots,
      state.sourceTags,
      state.affiliationCountriesExclude,
      state.countriesExclude,
      state.audienceExclude,
      state.sorting,
      state.tabIndex,
      page,
    );
  }, [state, page, state.tabIndex]);


  const checkIsSelected = (source) => {
    return selectedSources?.some((item) => item.id === source.id);
  };

  const selectedSourcesHandler = (source, value) => {
    if (value) {
      setSelectedSources([...selectedSources, source]);
    } else {
      setSelectedSources(selectedSources?.filter((item) => item.id !== source.id));
    }
  };

  const clearAllSelectedSources = () => {
    setSelectedSources([]);
  };

  const areAllIdsSelected = (sources, selectedSources) => {
    return sources.every((source) => selectedSources?.some((item) => item.id === source.id));
  };

  const toggleAll = (sources, selectedSources) => {
    if (areAllIdsSelected(sources, selectedSources)) {
      setSelectedSources([]);
    } else {
      setSelectedSources(sources);
    }
  };
  const handleAddingSourcesToGroup = (sources) => {
    const sourceIds = sources?.map((source) => source.id);
    API.fetch('POST', `/API/v1/groups/${groupId}/sources`, null, {
      source_ids: sourceIds,
    }).then(() => {
      setSelectedSources([]);
      getSources(
        state.searchQuery,
        state.platforms,
        state.countries,
        state.audience_lte,
        state.audience_gte,
        state.status,
        state.affiliationCountries,
        state.IoCs,
        state.showOnlyBots,
        state.sourceTags,
        state.affiliationCountriesExclude,
        state.countriesExclude,
        state.audienceExclude,
        state.sorting,
        state.tabIndex,
        page
      );
    });
  };

  const handleCopySourcesToGroup = (groupId) => {
    const sourceIds = copyGroupModal?.sources.map((source) => source.id);
    API.fetch('POST', `/API/v1/groups/${groupId}/sources`, null, {
      source_ids: sourceIds,
    }).then(() => {
      setCopyGroupModal({ isActive: false, sources: [] });
      clearAllSelectedSources();
    });
  };

  const buttonsOnScroll = () => {
    if(Object.keys(selectedSources).length > 0) {
      const buttons = document.getElementById('bulk');
      let scrolly = window.pageYOffset;
      if (!buttons) {
        return
      }

      if(scrolly > 150) {
        buttons.classList.add(bulkPanelStyles.floating)
      } else {
        buttons.classList.remove(bulkPanelStyles.floating)
      }
    } else {
      return
    }
  }

  useEffect(() => {
      window.addEventListener('scroll', buttonsOnScroll);
      window.addEventListener('mousemove', buttonsOnScroll);

    return() => {
      window.removeEventListener('scroll', buttonsOnScroll);
      window.removeEventListener('mousemove', buttonsOnScroll);
    }
  })

  if (!group && error) {
    return <div className="noData">{t(error)}</div>;
  }

  if (!group) {
    return <Loader />;
  }

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'ID',
    },
    {
      name: t('Audience'),
      value: 'AUDIENCE',
    },
    {
      name: t('Platform'),
      value: 'PLATFORM',
    },
    {
      name: t('Name'),
      value: 'ACTOR',
    },
    {
      name: t('Link'),
      value: 'URL',
    },
    {
      name: t('Country'),
      value: 'COUNTRY',
    },
    {
      name: t('Status'),
      value: 'STATUS',
    },
    {
      name: t('Relevance'),
      value: 'RELEVANCE',
    },
  ]

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]

  const tabslist = [
    {
      title: t('Add sources'),
      content: (
        <>
          <div className={styles.searchWrapper}>
            <Search
              className={styles.searchInput}
              onChange={value => {
                dispatch({type: 'UPDATE_QUERY', payload: value});
                dispatch({type: 'CHANGE_PAGE', page: 1})
                setSearchParams({ q: value, page: 1});
              }}
            />

            <DropdownMenu  
              isOpen={dropdownMenu}
              header={t('Sort by')}
              onClick={() => setDropdownMenu(!dropdownMenu)}
              buttonName={t('Sort')}
              icon={<SortIcon/>}
            >
              <Radiobutton 
                itemsList={sortingMenu}
                current={state.sorting.fieldName}
                onChange={(value) => dispatch({type: 'SORT_BY', fieldName: value})}
              />

              <SortingOrder 
                itemsList={sortingOrder}
                onClick={value => dispatch({type: 'IS_ASCENDING', isAscending: value})}
                current={state.sorting.isAscending}
              />
            </DropdownMenu>


            <Filter
              state={state}
              onChange={(platforms, countries, audience, status, affiliationCountries, IoCs, showOnlyBots, sourceTags, affiliationCountriesExclude, countriesExclude, audienceExclude) => {
              dispatch({type: 'APPLY_FILTER', 
              payload: {
                platforms: platforms, 
                countries: countries, 
                audience_lte: audience[1], 
                audience_gte: audience[0],
                affiliationCountries: affiliationCountries,
                IoCs: IoCs,
                showOnlyBots: showOnlyBots,
                sourceTags: sourceTags,
                affiliationCountriesExclude: affiliationCountriesExclude,
                countriesExclude: countriesExclude,
                audienceExclude: audienceExclude,
                status: status,
                page: 1,
              }})
              if (state.searchQuery) {
                setSearchParams({ q: state.searchQuery, page: 1});
              } else {
                setSearchParams({ page: 1});
              }
            }}
            />
          </div>
          <SourcesTable
            rowClassName={styles.tableRow}
            sources={sources}
            withHeader={false}
            searchQuery={state.searchQuery}
            checkIsSelected={checkIsSelected}
            renderPrefixRow={(source) => {
              const isSelected = checkIsSelected(source);

              if (! group?.owned_by_workspace) {
                return <td></td>;
              }

              return (
                <td className={tableStyles.tableCheckbox}>
                  <CheckboxField
                  className={styles.tableRow}
                  checked={isSelected}
                  onChange={(value) => {selectedSourcesHandler(source, value)}}
                />
                </td>
              );
            }}
            selectAllCheckbox={() => {
              if (! group?.owned_by_workspace) {
                return <td></td>;
              }
              return (
                <td className={tableStyles.tableCheckbox}>
                  <CheckboxField
                    className={styles.tableRow}
                    checked={areAllIdsSelected(sources?.objects, selectedSources)}
                    onChange={() => {toggleAll(sources?.objects, selectedSources)}}
                  />
                </td>
                
              );
            }}
            controls={(source) => {
              if (! group?.owned_by_workspace) {
                return '';
              }
              return (
                <button
                  className={cn('button', 'new-button')}
                  onClick={() => handleAddingSourcesToGroup([source])}
                  data-tooltip-id="add-source"
                  data-tooltip-content={t('Add')}
                >
                  <AddToIcon />
                </button>
              );
            }}
            onPageChange={(page) => {
              dispatch({type: 'CHANGE_PAGE', page: page})
            }}
          />
        </>
      )
    },
    {
      title: t('Edit group info'),
      content: (
        <form method="post" onSubmit={e => handleSubmit(e)}>
          <div className={error ? 'form-element error' : 'form-element'}>
            <label htmlFor="name">{t('Name')}</label>
            <input
              className=""
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className={error ? 'form-element error' : 'form-element'}>
            <label htmlFor="description">{t('Description')}</label>
            <textarea
              id="description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          { currentUser?.is_super_admin && (
            <div className={error ? 'form-element error' : 'form-element'}>
              <label htmlFor="is_public">{t('Is public')}</label>
              <Switch
                  id="is_public"
                  value={isPublic}
                  onChange={setIsPublic}
                />
            </div> )}
          <div className="error-message">{t(error)}</div>
          <button className="new-button" onClick={(e) => handleSubmit(e)}>
            {t('Save')}
          </button>
        </form>
      )
    },
  ]

  if(!group?.owned_by_workspace) {
    window.location.href = `/groups/${groupId}`;
  }


  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/groups">{t('Actor groups')}</Link>
          </span>
          <span>
            <Link to={`/groups/${group.id}`}>{group.name}</Link>
          </span>
          <span>{t('Edit')}</span>
        </div>
        <div className="controls">
          {currentUser?.is_super_admin === true ? (
            <Tooltip content={t('Copy')} position="bottom">
              <button
                type="button"
                // className={cn('new-button')}
                // onClick={() =>
                //   setCopyGroupModal({
                //     isActive: true,
                //   })
                // }
              >
                <CopyIcon />
              </button>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="next-card-header">
        <div className="row">
          <h2></h2>
          {selectedSources.length > 0 ? (
            <div className={bulkPanelStyles.bulkButtonsWrapper} id="bulk">
              <button>
                {selectedSources.length} {t('selected')}{' '}
                <span
                  className={bulkPanelStyles.clear}
                  onClick={() => clearAllSelectedSources()}
                >
                  <CloseIcon />
                </span>{' '}
              </button>
              <button
                onClick={() =>
                  setCopyGroupModal({
                    isActive: true,
                    sources: selectedSources,
                  })
                }
              >
                <CopyIcon />
                {t('Copy to group')}
              </button>
             <button
              onClick={() => handleAddingSourcesToGroup(selectedSources)}
            >
              <AddToIcon />
              {t('Add')}
            </button>
            <button
              disabled={true}
              onClick={() => handleAddingSourcesToGroup(selectedSources)}
            >
              <DeleteIcon />
              {t('Delete')}
            </button>
            </div>
            
          ) : (
            ''
          )}
        </div>
      </div>

      <Tabs
        tabslist={tabslist}
        onIndexChange={(index) => {
          clearAllSelectedSources()

          dispatch({type: 'CHANGE_TAB', tabIndex: index})

          dispatch({type: 'APPLY_FILTER', payload: {
            sorting: {isAscending: false, fieldName: sortingFieldNamesBack['relevance']},
            searchQuery: '',
            platforms: [], 
            countries: [], 
            audience_lte: null, 
            audience_gte: null,
            affiliationCountries: [],
            IoCs: [],
            showOnlyBots: false,
            sourceTags: [],
            affiliationCountriesExclude: false,
            countriesExclude: false,
            audienceExclude: false,
            status: ['ACTIVE'],
            page: 1,
          }})

          setSearchParams({ q: '', page: 1});
          
        }}
      />

      {copyGroupModal.isActive && (
        <AddToSourceGroupModal
          isOpen={copyGroupModal.isActive}
          sources={copyGroupModal.sources}
          onChange={(isOpen) => setCopyGroupModal({ isActive: isOpen, sources: [] })}
          onSave={handleCopySourcesToGroup}
        />
      )}
      <ReactTooltip id="add-source" place="bottom"/>
    </div>
  );
};
