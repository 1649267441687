import React, { useState, useRef } from 'react';

export const Code = ({ onChange = () => {} }) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([...Array(6)].map(() => React.createRef()));

  const handleChange = (index, value) => {
    console.log('----->', index, value);
    if (isNaN(value)) {
      return; // Only allow numeric input
    }

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    onChange(newCode);

    // Move to the next input field
    if (index < 5 && value !== '') {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && code[index] === '') {
      // Move to the previous input field on backspace
      inputRefs.current[index - 1].current.focus();
    }
  };

  return (
    <div>
      {code.map((digit, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={inputRefs.current[index]}
        />
      ))}
    </div>
  );
};

