export const metricsMap = {
  "inauthentic": 'Inauthentic',
  "state_affiliated": 'State-affiliated', 
  "disinfo_spreaders": 'Disinfo spreaders',
  "influence_operations": 'Influence operations',
  "influential": 'Influential',
  "social_media_original_posts": 'Original posts',
  "social_media_reposts": 'Reposts',
  "media_articles": 'Media articles',
  "comments": 'Comments',
  "videos": 'Videos',
  "ads": 'Ads',
  "chat_forum_messages": 'Chat messages',
  "reposts": 'Reposts',
  "likes_emoji": 'Likes',
}