import { format } from 'date-fns';
import { API, ShareAPI } from '../../API';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { OpinionCard } from '../OpinionCard/OpinionCard';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { ampli } from '../../ampli';
import Tooltip from '../Tooltip/Tooltip';
import { ReactComponent as MergeIcon } from '../../pages/NarrativePage/assets/merge.svg';
import { WidgetContentModal } from '../WidgetContentModal/WidgetContentModal';
import Modal from '../Modal';
import { Paginator } from '../Paginator/Paginator';

import styles from './OpinionsWidget.module.scss';

export const OpinionsWidget = ({
  report,
  story,
  isShare,
  sourceGroups,
  countries,
  startDate,
  endDate,
  viewMode='rating',
}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [opinions, setOpinions] = useState(null);
  const [modal, setModal] = useState({ isVisible: false, opinion: null });
  const [selectedOpinions, setSelectedOpinions] = useState([]);
  const [mergeOpinionsModal, setMergeOpinionsModal] = useState({
    isActive: false,
    opinion_ids: [],
    new_title: '',
    isLoading: false,
    error: null,
  });

  const api = isShare ? ShareAPI : API;

  const fetchOpinions = (page=1) => {
    let size = 5;
    if (!story) return;
    if (opinions !== null) {
      setOpinions(null);
    }
    const urlParams = new URLSearchParams();

    urlParams.set('size', size);
    urlParams.set('page', page);
    
    if(viewMode === 'messages') {
      urlParams.set('sort', '-messages_count');
    } 
    
    if (sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if (countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if (startDate) {
      urlParams.append('date_from', format(startDate, 'yyyy-LL-dd'));
    }
    if (endDate) {
      urlParams.append('date_to', format(endDate, 'yyyy-LL-dd'));
    }
    api
      .fetch(
        'GET',
        `/API/v1/topic-modeling/${report?.id}/events/${
          story?.id
        }/opinions?${urlParams.toString()}`,
      )
      .then((data) => {
        setOpinions(data);
      });
  };

  useEffect(() => {
    fetchOpinions();
  }, [story]);

  return (
    <div className="report-section">
      <div className={styles.widgetHeader} id='opinionsWidget'>
        <h3>{t('Top opinions')}</h3>
        <div className={styles.widgetControls}>
          {currentUser?.is_super_admin && selectedOpinions?.length > 0 ? (
            <Tooltip content={t('Merge')} position="bottom">
              <button
                className={styles.mergeButton}
                onClick={() =>
                  setMergeOpinionsModal({
                    isActive: true,
                    opinion_ids: selectedOpinions,
                    new_title:
                      opinions?.objects
                        ?.filter((opinion) =>
                          selectedOpinions.includes(opinion.id),
                        )
                        .sort((a, b) => b.rating - a.rating)?.[0]?.title || '',
                    isLoading: false,
                    error: null,
                  })
                }
              >
                <MergeIcon />
              </button>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      </div>

      {opinions ? (
        opinions?.objects?.length > 0 ? (
          opinions?.objects?.map((opinion, index) => (
            <OpinionCard
              key={index}
              opinion={opinion}
              viewMode={viewMode}
              onClick={(opinion) => {
                setModal({ isVisible: true, opinion: opinion });
                ampli.track({
                  event_type: 'Open wiget popup in event',
                  event_properties: {
                    user_id: currentUser?.id,
                    workspace_id: currentUser?.workspace_id,
                    report_id: report?.id,
                    popup_type: 'Opinion card',
                    popup_value: 'Opinion: ' + opinion.id,
                  },
                });
              }}
              isAdmin={currentUser?.is_super_admin}
              report={report}
              story={story}
              selectedOpinions={selectedOpinions}
              onDelete={() => fetchOpinions()}
              onEdit={() => fetchOpinions()}
              onCheckboxClick={(opinion) => {
                if (selectedOpinions.includes(opinion.id)) {
                  setSelectedOpinions(
                    selectedOpinions.filter((item) => item !== opinion.id),
                  );
                } else {
                  setSelectedOpinions([...selectedOpinions, opinion.id]);
                }
              }}
            />
          ))
        ) : (
          <div className={styles.noData}>
            {t('Opinions have not been detected')}
          </div>
        )
      ) : (
        <div className={styles.widgetLoader}>
          <LoaderSmall />
        </div>
      )}
      <Paginator
        size={opinions?.size}
        page={opinions?.page}
        total={opinions?.total}
        onPageChange={(page) => {
          fetchOpinions(page)
        }}
        id={'opinionsWidget'}
      />

      {modal.isVisible ? (
        <WidgetContentModal
          tabIndex={1}
          infoModal={modal.isVisible}
          isChart={true}
          platformOption={null}
          type={null}
          subType={null}
          stateAffiliatedId={null}
          countryId={countries?.length > 0 ? countries : null}
          // platformsOptions={platformOptions}
          audienceRange={null}
          narrative={report}
          sourceGroup={
            sourceGroups.length > 0
              ? sourceGroups?.map((sourceGroup) => sourceGroup.value)
              : null
          }
          source={null}
          sentimentOptions={null}
          eventIdsOptions={[story.id]}
          opinionIdsOptions={modal.opinion ? [modal.opinion.id] : null}
          startDate={startDate}
          endDate={endDate}
          isShare={isShare}
          onChange={(bool) => {
            setModal({ isVisible: bool, opinion: null });
          }}
          opinion={modal.opinion}
          event={story}
        />
      ) : (
        ''
      )}

      {mergeOpinionsModal.isActive ? (
        <Modal
          isVisible={mergeOpinionsModal.isActive}
          className={styles.mergeEventsModal}
          title={t('Merge stories')}
          content={
            mergeOpinionsModal?.opinion_ids?.length < 2 ? (
              <p className={styles.errorStoriesMerge}>
                {t('Please select at least 2 opinions to merge')}
              </p>
            ) : (
              <div className={styles.mergeStoriesModal}>
                {mergeOpinionsModal.error ? (
                  <p className={styles.errorStoriesMerge}>
                    {mergeOpinionsModal.error}
                  </p>
                ) : (
                  ''
                )}
                <div className="form-element">
                  <label htmlFor="new-story-title">{t('Title')}&#42;</label>
                  <input
                    type="text"
                    id="new-story-title"
                    value={mergeOpinionsModal.new_title}
                    required
                    onChange={(e) =>
                      setMergeOpinionsModal({
                        ...mergeOpinionsModal,
                        new_title: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )
          }
          footer={
            <>
              {mergeOpinionsModal.opinion_ids?.length < 2 ? (
                ''
              ) : (
                <button
                  className="btn-primary"
                  disabled={mergeOpinionsModal.isLoading}
                  onClick={() => {
                    setMergeOpinionsModal({
                      ...mergeOpinionsModal,
                      isLoading: true,
                    });

                    API.fetch(
                      'POST',
                      `/API/v1/topic-modeling/${report.id}/events/${story.id}/opinions/merge`,
                      null,
                      {
                        opinion_ids: mergeOpinionsModal.opinion_ids,
                        new_title: mergeOpinionsModal.new_title,
                        new_description: '',
                      },
                    )
                      .then(() => {
                        setMergeOpinionsModal({
                          isActive: false,
                          opinion_ids: [],
                          new_title: '',
                          isLoading: false,
                          error: null,
                        });

                        setSelectedOpinions([]);

                        fetchOpinions();
                      })
                      .catch(() => {
                        setMergeOpinionsModal({
                          ...mergeOpinionsModal,
                          isLoading: false,
                          error: t(
                            'Something went wrong. Please, try again...',
                          ),
                        });
                      });
                  }}
                >
                  {mergeOpinionsModal.isLoading ? <LoaderSmall /> : t('Merge')}
                </button>
              )}
              <button
                className="btn-reset"
                disabled={mergeOpinionsModal.isLoading}
                onClick={() =>
                  setMergeOpinionsModal({
                    isActive: false,
                    opinion_ids: [],
                    new_title: '',
                    isLoading: false,
                    error: null,
                  })
                }
              >
                {t('Cancel')}
              </button>
            </>
          }
          onClose={() =>
            setMergeOpinionsModal({
              isActive: false,
              opinion_ids: [],
              new_title: '',
              isLoading: false,
              error: null,
            })
          }
        />
      ) : (
        ''
      )}
    </div>
  );
};
