import Modal from '../Modal';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState, useRef} from 'react';
import { MessagesContext } from '../../pages/NarrativePage/contexts/MessagesContext';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip/Tooltip';
import * as d3 from 'd3';
import DOMPurify from 'dompurify';
import {platformIconsMapping, platformNamesMapping} from '../../utils/platforms';
import {contentTypesIconsMapping, contentTypesMapping} from '../../utils/contentTypes';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { Multichoice } from '../DropdownMenu/Multichoice';

import { ReactComponent as ApproveIcon } from '../../pages/NarrativePage/assets/approve.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../assets/engagement.svg';
import { ReactComponent as ManipulationIcon } from '../../assets/manipulation.svg';
import { ReactComponent as SimilarityIcon } from '../../assets/similarity.svg';
import { ReactComponent as FollowersIcon } from '../../assets/followers.svg';
import { ReactComponent as EnvelopeOpenIcon } from '../../assets/envelopeOpen.svg';
import { ReactComponent as RecoverIcon } from '../../assets/recover.svg';
import { ReactComponent as ChevronIcon } from '../../pages/SearchPage/assets/chevron-down.svg';
import { Sentiment } from '../Sentiment/Sentiment';

import styles from './ExtendedMessageView.module.scss';
import { useCurrentUser } from '../../contexts/CurrentUser';

const statusMapping = {
  NEW: {class: styles.new, name: 'New'},
  READ: {class: styles.approved, name: 'Approved'},
  DELETED: {class: styles.deleted, name: 'Deleted'}
}

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);

export const MessageModal = ({message, isOpen, onChange, selected, narrativeMessage, isShare }) => {
  const {
    deduplication,
    patchMessages,
    deleteMessages,
    getMessages,
    dispatch,
  } = useContext(MessagesContext);
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [highlightsMenu, setHighlightsMenu] = useState(false);
  const [highlights, setHighlights] = useState(selected ? selected : ['KEYWORDS']);

  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showNer = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');

  
  let followers_cnt = message?.source?.audience;

  const translatedText = message?.translated;
  const messageText = message.highlighted_text || message.text || message.message;
  let newText;

  if(message.isTranslation && translatedText.length > 0) {
    newText = translatedText.replace(/\n+(\s*\n+)?/g, '\n').split('\n');
  } else {
    newText = messageText.replace(/\n+(\s*\n+)?/g, '\n').split('\n');
    
  }
  const sanitizedText = newText.map(item => DOMPurify.sanitize(item, { ALLOWED_TAGS: ['mark', 'span'],  ALLOWED_ATTR: ['class']}));

  const nerTypesList = [
    {value: 'KEYWORDS', label: t('Matched keywords')},
  ]

  if(showNer) {
    nerTypesList.push(
      {value: 'PERSON', label: t('Persons')},
      {value: 'ORGANIZATION', label: t('Organizations')},
      {value: 'LOCATION', label: t('Locations')}
    )
  }

  useEffect(() => {
    const keywords = document.querySelectorAll('#message-modal-text .keywords-highlight');
    const ner = document.querySelectorAll('#message-modal-text .ner-highlight');
    const person = document.querySelectorAll('#message-modal-text .ner-highlight[data-ner-type="Person"]');
    const location = document.querySelectorAll('#message-modal-text .ner-highlight[data-ner-type="Location"]');
    const organization = document.querySelectorAll('#message-modal-text .ner-highlight[data-ner-type="Organization"]');

    if (highlights.includes('KEYWORDS')) {
      keywords.forEach(item => {
        item.classList.add('active')
      })
    } else {
      keywords.forEach(item => {
        item.classList.remove('active')
      })
    }

    if (highlights.includes('PERSON')) {
      person.forEach(item => {
        item.classList.add('active')
      })
    } else {
      person.forEach(item => {
        item.classList.remove('active')
      })
    }

    if (highlights.includes('LOCATION')) {
      location.forEach(item => {
        item.classList.add('active')
      })
    } else {
      location.forEach(item => {
        item.classList.remove('active')
      })
    }

    if (highlights.includes('ORGANIZATION')) {
      organization.forEach(item => {
        item.classList.add('active')
      })
    } else {
      organization.forEach(item => {
        item.classList.remove('active')
      })
    }

   }, [highlights])

   useEffect(() => {
      const container = document.querySelector('#message-modal-text');
      const content = document.querySelector(".dropdown-menu-container");
    if(highlightsMenu) {
      container.style.height=container.offsetHeight + content.offsetHeight + "px";
    } else {
      container.style.height=''
    }
  
   }, [highlightsMenu])

  
  return (
    <Modal
      isVisible={isOpen}
      title={
        <>
          <div className={styles.messageInfo}>
            {narrativeMessage ? (
              <div
                className={`${styles.status} ${
                  statusMapping[message.status].class
                }`}
              >
                {t(statusMapping[message.status].name)}
              </div>
            ) : (
              ''
            )}
            {message.content_type !== null ? (
              <div className={styles.contentType}>
                <Tooltip
                  content={t(contentTypesMapping[message.content_type])}
                  position="bottom"
                >
                  {contentTypesIconsMapping[message.content_type]}
                </Tooltip>
              </div>
            ) : (
              ''
            )}
            <div className={styles.date}>
              <span className={styles.anchor}>
                <Tooltip content={t('Publication date')} position="bottom">
                  <CalendarIcon />
                </Tooltip>
              </span>
              {message.date_publicated
                ? format(
                    parseISO(message.date_publicated + 'Z'),
                    'dd LLL yyyy HH:mm',
                  )
                : format(
                    parseISO(message.published_at + 'Z'),
                    'dd LLL yyyy HH:mm',
                  )}
            </div>
            <div className={styles.platform}>
              <span className={styles.anchor}>
                <Tooltip
                  content={platformNamesMapping[message.source?.source_type]}
                  position="bottom"
                >
                  {platformIconsMapping[message.source?.source_type]}
                </Tooltip>
              </span>
              {followers_cnt ? (
                <span className={styles.anchor}>
                  <Tooltip content={t('Followers')} position="bottom">
                    <FollowersIcon />
                  </Tooltip>
                  <span>{formatNumberSignificant(followers_cnt)}</span>
                </span>
              ) : (
                ''
              )}
            </div>
            <div>
              <span className={styles.anchor}>
                <Tooltip
                  content={
                    message.source?.name ? message.source?.name : t('N/A')
                  }
                  position="bottom"
                >
                  <span className={styles.cutText}>
                    {isShare ? (
                      message.source?.name
                    ) : message.source ? (
                      <Link to={`/sources/${message.source?.id}`}>
                        {message.source?.name}
                      </Link>
                    ) : (
                      t('N/A')
                    )}
                  </span>
                </Tooltip>
              </span>
              <a
                href={message.url ? message.url : message.message_url}
                target="_blank"
                rel="noreferrer"
              >
                <LinkIcon />
              </a>
            </div>
          </div>
        </>
      }
      content={
        <>
          <div className={styles['metrics-container']}>
            <div className={styles.messageMetrics}>
              {message.sentiment_score !== null ? (
                <Sentiment
                  sentimentScore={message.sentiment_score}
                  messageId={message.id}
                  narrative={narrativeMessage?.narrative}
                  isShare={isShare}
                />
              ) : (
                ''
              )}
              <span className={styles.anchor}>
                <Tooltip content={t('Views')} position="bottom">
                  <ViewsIcon />
                </Tooltip>
                {message.impressions !== null &&
                message.impressions !== undefined
                  ? formatNumberSignificant(message.impressions)
                  : formatNumberSignificant(message.views)}
              </span>
              <span className={styles.anchor}>
                <Tooltip content={t('Reactions')} position="bottom">
                  <EngagementIcon />
                </Tooltip>
                {message.engagement !== null && message.engagement !== undefined
                  ? formatNumberSignificant(message.engagement)
                  : formatNumberSignificant(
                      parseInt(message?.forwards ? message.forwards : 0) +
                        parseInt(message?.reactions ? message?.reactions : 0) +
                        parseInt(message?.replies ? message?.replies : 0),
                    )}
              </span>
              {showMessageManipulationIndex ? (
                <span className={styles.anchor}>
                  <Tooltip content={t('Manipulation')} position="bottom">
                    <ManipulationIcon />
                  </Tooltip>
                  {message.manipulation_index
                    ? message.manipulation_index.toFixed(2)
                    : '0'}
                </span>
              ) : (
                ''
              )}
              {message.similarity_score ? (
                <span className={styles.anchor}>
                  <Tooltip content={t('Similarity')} position="bottom">
                    <SimilarityIcon />
                  </Tooltip>
                  {(message.similarity_score + '').slice(0, 4)}
                </span>
              ) : (
                ''
              )}
            </div>
            {selected === null ? (
              ''
            ) : (
              <DropdownMenu
                buttonName={t('Highlights')}
                isOpen={highlightsMenu}
                onClick={() => setHighlightsMenu(!highlightsMenu)}
                menuClassName="highlights"
                icon={<ChevronIcon />}
              >
                <Multichoice
                  itemsList={nerTypesList}
                  selected={highlights}
                  onChange={(selectedList) => {
                    setHighlights(selectedList);
                  }}
                />
              </DropdownMenu>
            )}
          </div>

          <div className={styles.messageText} id="message-modal-text">
            {' '}
            {sanitizedText.map((item, index) => (
              <p
                key={`paragraph_${index}`}
                dangerouslySetInnerHTML={{ __html: item }}
              ></p>
            ))}
          </div>
        </>
      }
      footer={
        <>
          {(narrativeMessage &&
            narrativeMessage.narrative.review_status === 'PENDING' &&
            !isShare &&
            narrativeMessage.narrative.is_threat) ||
          (narrativeMessage &&
            !narrativeMessage.narrative.is_threat &&
            !isShare) ? (
            <div className={styles.controls}>
              <button
                className={`${styles.approve} btn-primary`}
                disabled={message.status === 'DELETED' ? true : false}
                onClick={() => {
                  let status = message.status === 'NEW' ? 'READ' : 'NEW';
                  let messageIds = [message.id];

                  patchMessages(
                    narrativeMessage.narrative,
                    status,
                    messageIds,
                    deduplication,
                  ).then(() => {
                    dispatch({ type: 'PATCH_MESSAGES' });
                    getMessages(
                      narrativeMessage.narrative,
                      narrativeMessage.searchQuery,
                      narrativeMessage.sorting,
                      narrativeMessage.statuses,
                      deduplication,
                      narrativeMessage.page,
                    );
                  });
                  onChange(false);
                }}
              >
                {message.status === 'NEW' ? (
                  <ApproveIcon />
                ) : (
                  <EnvelopeOpenIcon />
                )}
              </button>
              <button
                className={`${styles.delete} btn-danger`}
                onClick={() => {
                  let messageIds = [message.id];
                  if (message.status === 'DELETED') {
                    patchMessages(
                      narrativeMessage.narrative,
                      'NEW',
                      messageIds,
                      deduplication,
                    ).then(() => {
                      dispatch({ type: 'PATCH_MESSAGES' });
                      getMessages(
                        narrativeMessage.narrative,
                        narrativeMessage.searchQuery,
                        narrativeMessage.sorting,
                        narrativeMessage.statuses,
                        deduplication,
                        narrativeMessage.page,
                      );
                    });
                    onChange(false);
                  } else {
                    deleteMessages(
                      narrativeMessage.narrative,
                      messageIds,
                      deduplication,
                    ).then(() => {
                      dispatch({ type: 'DELETE_MESSAGES' });
                      getMessages(
                        narrativeMessage.narrative,
                        narrativeMessage.searchQuery,
                        narrativeMessage.sorting,
                        narrativeMessage.statuses,
                        deduplication,
                        narrativeMessage.page,
                      );
                    });
                    onChange(false);
                  }
                }}
              >
                {message.status === 'DELETED' ? (
                  <RecoverIcon />
                ) : (
                  <DeleteIcon />
                )}
              </button>
            </div>
          ) : (
            ''
          )}
        </>
      }
      onClose={() => {
        onChange(false, highlights);
      }}
      className={styles.messageModal}
    />
  );
}
