import { useTranslation } from 'react-i18next';
import {useState, useContext, useRef} from 'react';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import { API } from '../../API';
import { UserSettingsContext } from './contexts/UserSettingsContext';

import styles from './UserSettingsPage.module.scss';

export const ChangePasswordSettings = () => {
  const {
    user,
    error
  } = useContext(UserSettingsContext);

  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [errors, setErrors] = useState({});
  const[successMessage, setSuccessMessage] = useState(null);
  const formRef = useRef(null);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    setSuccessMessage(null);
    setErrors({})

    if(!oldPassword || oldPassword?.length === 0) {
      setErrors(errors => ({...errors, old_password: t('Please, fill in this field')}))
      return
    }

    if(!newPassword || newPassword?.length === 0) {
      setErrors(errors => ({...errors, new_password: t('Please, fill in this field')}))
      return
    }

    let body = {
      old_password: oldPassword,
      new_password: newPassword
    }

    API.fetch('PATCH', `/API/v1/users/me/change-password`, null, body).then(data => {
      setOldPassword(null);
      setNewPassword(null);

      formRef.current.reset();
      setErrors({})

      setSuccessMessage(t('Password changed successfully'))
    }).catch(error => setErrors(errors => ({...errors, general: error.message})))
  }

  return (
    <>
      <h3>{t('Change password')}</h3>
      <div className="form-wrapper">
        <form ref={formRef} method="post">
          <div className="form-element">
            <label className={styles.label} htmlFor="name">
              {t('Old password')}
            </label>
            <input
              className=""
              id="old_password"
              name="old_password"
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            <div className="error-message">{errors.old_password}</div>
          </div>
          <div className="form-element">
            <label className={styles.label} htmlFor="name">
              {t('New password')}
            </label>
            <input
              className=""
              id="new_password"
              name="new_password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <div className="error-message">{errors.new_password}</div>
          </div>
          <div className="form-element">
            <div className="submit-wrapper">
              <button
                type="button"
                className="new-button"
                onClick={(e) => handlePasswordSubmit(e)}
              >
                {t('Change password')}
              </button>
            </div>
            {errors.general ? <div className={styles.errorMessage}>{t(errors.general)} <span
              onClick={() => setErrors({...errors, general: null})}> <CloseIcon/> </span></div> : ''}
          </div>
        </form>
        {successMessage ?
          <div className={styles.successMessage}>{successMessage}
            <span onClick={() => setSuccessMessage(null)}>
              <CloseIcon/>
            </span>
          </div> : ''
        }
      </div>
    </>
  );
};
