import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { API, ShareAPI } from '../../../API';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader/Loader';
import styles from '../../NarrativePage/NarrativePage.module.scss';
import settingsStyles from '../../NarrativePage/Filter/Filter.module.scss';
import { DistributionTabChooser } from './PDFSettings/DistributionTabChooser';
import { Collapsible } from '../../NarrativePage/Filter/Collapsible';
import { Summary } from './Components/Summary';
import { EvolutionChart } from './Components/EvolutionChart';
import { SentimentBar } from './Components/SentimentBar';
import { Messages } from './Components/Messages';
import { MessagesTabChooser } from './PDFSettings/MessagesTabChooser';
import {ReactComponent as OsavulLogo} from '../../../assets/osavulLogo.svg';
import { BubbleChart } from './Components/BubbleChart';
import { Narratives } from './Components/Narratives';

export const ExportPDFReportPage = ({ isShare = false }) => {
  const api = isShare ? ShareAPI : API;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { reportId } = useParams();
  const [report, setReport] = useState(null);
  const [dashboard, setDashboard] = useState(null);
  const [error, setError] = useState(null);
  const [isMessagesTabOpen, setIsMessagesTabOpen] = useState(true);
  const [isDistributionTabOpen, setIsDistributionTabOpen] = useState(true);
  const [messagesTab, setMessagesTab] = useState([]);
  const [distributionTab, setDistributionTab] = useState([
    'summary',
    'narratives',
    'bubbleChart',
    'evolutionMessages',
    'evolutionViews',
    'evolutionReactions',
    'evolutionShare',
    'sentimentMessages',
    'sentimentViews',
    'sentimentReactions',
  ]);

  const generalDistributionTab = [
    'summary',
    'narratives',
    'bubbleChart',
    'evolutionMessages',
    'evolutionViews',
    'evolutionReactions',
    'evolutionShare',
    'sentimentMessages',
    'sentimentViews',
    'sentimentReactions',
  ];

  const [messagesTabOptions, setMessagesTabOptions] = useState([]);

  const onRequestError = useCallback(
    (resp) => {
      if (isShare && (resp.status === 401 || resp.status === 403)) {
        const hash = searchParams.get('h');
        const with_password = searchParams.get('with_password');
        navigate(`/reports/${reportId}/share/login?h=${hash}&with_password=${with_password}`);
      }
    },
    [navigate, reportId, searchParams, isShare],
  );

  const fetchReport = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    api
      .fetch('GET', `/API/v1/reports/${reportId}`, null, null, null, onRequestError)
      .then((data) => {
        setError(null);
        setReport(data);

        const urlParams = new URLSearchParams();
        data.parameters.narrative_ids.forEach((narrative_id) =>
          urlParams.append('narrative_id', narrative_id),
        );
        if (data.parameters.start_date !== null) {
          urlParams.set(
            'start',
            data.parameters.start_date.split('T')[0] + 'T00:00:00'
          );
        }
        if (data.parameters.end_date !== null) {
          urlParams.set(
            'end',
            data.parameters.end_date.split('T')[0] + 'T23:59:59'
          );
        }

        api
          .fetch(
            'GET',
            `/API/v1/dashboard/narratives?${urlParams.toString()}`,
            null,
            null,
            null,
            onRequestError,
          )
          .then((data) => {
            data.objects.sort((a, b) => (a.id - b.id));
            setDashboard(data)
            // if (data.objects.length > 0) {
              // setCurrentNarrative({id: data.objects[0]?.id, name: data.objects[0]?.name})
            // }
          });
      })
      .catch((e) => {
        setError(e.message);
      });
      
  }, [isShare, reportId, onRequestError]);



  useEffect(() => {
    fetchReport();
  }, [reportId]);

  useEffect(() => {
    if (dashboard) {
      setMessagesTabOptions(dashboard.objects.map((item) => { return {value: item.id, label: item.name} }));
    }
  }, [dashboard]);

  useEffect(() => {
    setMessagesTab(messagesTabOptions.map(item => item.value));
  }, [messagesTabOptions]); 

  if (!report) {
    return <Loader />;
  }

  return (
    <div className={styles.exportContainer}>
      <div className={styles.pdfPageContainer}>
        {report ? (
          <div className="report-section">
            <div className={styles.exportHeaderCard}>
              <div className={styles.logoContainer}>
                <OsavulLogo />
                <h2 className={styles.logoTitle}>OSAVUL</h2>
              </div>
              <h2 className={styles.headerInfoTitle}>{report?.name}</h2>
            </div>
          </div>
        ) : (
          ''
        )}

        {new Set(distributionTab).has('summary') ? (
          <Summary report={report} isShare={isShare} />
        ) : (
          ''
        )}

        {new Set(distributionTab).has('narratives') ? (
          <Narratives report={report} isShare={isShare} />
        ) : (
          ''
        )}
      
        {new Set(distributionTab).has('bubbleChart') ? (
          <BubbleChart
            dashboard={dashboard}
            isShare={isShare}
          />
        ) : (
          ''
        )}

        {new Set(distributionTab).has('evolutionViews') ? (
          <EvolutionChart
            report={report}
            isShare={isShare}
            mode="impressions"
          />
        ) : (
          ''
        )}

        {new Set(distributionTab).has('evolutionShare') ? (
          <EvolutionChart
            report={report}
            isShare={isShare}
            mode="narrative_share"
          />
        ) : (
          ''
        )}
        
        {new Set(distributionTab).has('evolutionReactions') ? (
          <EvolutionChart
            report={report}
            isShare={isShare}
            mode="engagement"
          />
        ) : (
          ''
        )}

        {new Set(distributionTab).has('evolutionMessages') ? (
          <EvolutionChart
            report={report}
            isShare={isShare}
            mode="publications"
          />
        ) : (
          ''
        )}

        {new Set(distributionTab).has('sentimentMessages') ? (
          <SentimentBar
            report={report}
            isShare={isShare}
            mode="messages"
          />
        ) : (
          ''
        )}

        {new Set(distributionTab).has('sentimentViews') ? (
          <SentimentBar report={report} isShare={isShare} mode="views" />
        ) : (
          ''
        )}

        {new Set(distributionTab).has('sentimentReactions') ? (
          <SentimentBar report={report} isShare={isShare} mode="likes" />
        ) : (
          ''
        )}

        {dashboard?.objects?.map((item) => {  
          return new Set(messagesTab).has(item.id) ? (
            <Messages report={report} isShare={isShare} narrative={item} isSubtitle={true}/>
          ) : (
            ''
          )
        })}

      </div>
      <div className={styles.pdfSettingsContainer}>
        <div className={settingsStyles.root}>
          <div
            className={`${settingsStyles.container} ${settingsStyles.show}`}
            style={{ top: '0px', right: '0px' }}
          >
            <div className={settingsStyles.header}>
              <span>{t('PDF settings')}</span>

              <span
                onClick={() => {
                  if (
                    !isMessagesTabOpen ||
                    !isDistributionTabOpen
                    
                  ) {
                    setIsMessagesTabOpen(true);
                    setIsDistributionTabOpen(true);
                  } else {
                    setIsMessagesTabOpen(false);
                    setIsDistributionTabOpen(false);
                  }
                }}
              >
                {isMessagesTabOpen &&
                isDistributionTabOpen
                  ? `${t('Minimize all')}`
                  : `${t('Expand all')}`}
              </span>
            </div>

            <div className={settingsStyles.collapsiblesWrapper}>
              <Collapsible
                name="Overview"
                isOpen={isDistributionTabOpen}
                summ={distributionTab.length}
                onChange={(open) => setIsDistributionTabOpen(open)}
              >
                <DistributionTabChooser
                  selected={distributionTab}
                  onChange={(checklist) => setDistributionTab(checklist)}
                />
              </Collapsible>

              {dashboard?.objects?.length > 0 ? <Collapsible
                name="Messages"
                isOpen={isMessagesTabOpen}
                summ={messagesTab?.length}
                onChange={(open) => setIsMessagesTabOpen(open)}
              >
                <MessagesTabChooser
                  report={report}
                  options={messagesTabOptions}
                  selected={messagesTab}
                  onChange={(checklist) => setMessagesTab(checklist)}
                />
              </Collapsible> : ''}
            </div>

            <div className={settingsStyles.controls}>
              <button
                className={`${settingsStyles.button} ${settingsStyles.apply}`}
                style={{ fontSize: '14px' }}
                onClick={() => {
                  window.print();
                }}
              >
                {t('Download PDF')}
              </button>

              <button
                className={`${settingsStyles.button} ${settingsStyles.reset}`}
                style={{ fontSize: '14px' }}
                onClick={() => {
                  setDistributionTab(generalDistributionTab);
                  setMessagesTab(messagesTabOptions.map(item => item.value));
                }}
              >
                {t('Reset all')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
