import './DropdownMenu.scss';

 export const Radiobutton = ({itemsList, current, onChange}) => {
  return (
    <ul className='dropdown-menu-list'>
      {itemsList.map(item => {
        return(
          <li>
            <input 
              type='radio'
              id={item.name}
              key={`sort_${item.name}`}
              onChange={() => onChange(item.value)}
              name='sort'
              checked={item.value === current ? true : false}
            />
            <label htmlFor={item.name}>{item.name}</label>
          </li>
          
        )
      })}
    </ul>
  )
}