import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState, useContext } from 'react';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import { API } from '../../API';
import Select from 'react-select';
import styles from './UserSettingsPage.module.scss';
import { UserSettingsContext } from './contexts/UserSettingsContext';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { languageNamesMapping } from '../../utils/languages';
import { changeLanguage } from 'i18next';

export const BasicSettings = () => {
  const { t } = useTranslation();

  const {
    user,
    error
  } = useContext(UserSettingsContext);

  const [currentUser, setCurrentUser] = useCurrentUser();
  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastName] = useState(user?.last_name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [userLanguage, setUserLanguage] = useState(user?.language ? {value: user.language, label: t(languageNamesMapping[user.language])} : {value: null, label: t('Not selected')});
  const [translationLanguage, setTranslationLanguage] = useState(user?.translation_language ? {value: user.translation_language, label: t(languageNamesMapping[user.translation_language])} : {value: null, label: t('Not selected')});
  const [errors, setErrors] = useState({});

  const languageOptions = [
    {value: null, label: t('Not selected')},
    {value: 'EN', label: t('English')},
    {value: 'UK', label: t('Ukrainian')}
  ]

  const languageTranslationOptions = [
    {value: null, label: t('Not selected')},
    {value: 'EN', label: t('English')},
    {value: 'UK', label: t('Ukrainian')},
    {value: 'FR', label: t('French')},
    {value: 'PL', label: t('Polish')},
    {value: 'RO', label: t('Romanian')},
  ]

  const handleProfileSubmit = (e) => {
    e.preventDefault();

    if(firstName.length === 0) {
      setErrors(errors => ({...errors, firstName: t('Please, fill in this field')}))
      return
    }

    if(lastName.length === 0) {
      setErrors(errors => ({...errors, lastName: t('Please, fill in this field')}))
      return
    }

    let body = {};

    if(user.first_name !== firstName?.trim()) {
      body.first_name = firstName;
    }
    if(user.last_name !== lastName?.trim()) {
      body.last_name = lastName;
    }

    if(userLanguage.value !== user.language) {
      body.language = userLanguage.value;
    }

    if(translationLanguage.value !== user.translation_language) {
      body.translation_language = translationLanguage.value;
    }


    API.fetch('PATCH', `/API/v1/users/me`, null, body).then(data => {
      
      API.fetch('GET', '/API/v1/users/me').then((data) => {
        setCurrentUser(data);
        if(data.language === null) {
          return
        }

        changeLanguage(data.language.toLowerCase())
      });

    }).catch(error => setErrors(errors => ({...errors, general: error.message})))
  }

  return (
    <>
      <h3>{t('Profile')}</h3>
      <div className="form-wrapper">
        <form>
          <div className="form-element">
            <label className={styles.label} htmlFor="name">{t('First name')}&#42;</label>
            <input
              className=""
              id="firstName"
              name="first name"
              type="text"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              required
            />
            <div className="error-message">
              {errors.firstName}
            </div>
          </div>
          <div className="form-element">
            <label className={styles.label} htmlFor="name">{t('Last name')}&#42;</label>
            <input
              className=""
              id="lastName"
              name="last name"
              type="text"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              required
            />
            <div className="error-message">
              {errors.lasttName}
            </div>
          </div>
          <div className="form-element">
            <label className={styles.label} htmlFor="name">{t('Email')}</label>
            <input
              className=""
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled
            />
            <div className="error-message">
              {errors.email}
            </div>
          </div>
          <div className="form-element">
            <label className={styles.label} htmlFor="language">{t('System language')}</label>
            <Select
              className="chart-select"
              onChange={(data) => {
                setUserLanguage(data);
              }}
              value={userLanguage}
              options={languageOptions}
            />
          </div>
          <div className="form-element">
            <label className={styles.label} htmlFor="languageTranslation">{t('Language for translation')}</label>
            <Select
              className="chart-select"
              onChange={(data) => {
                setTranslationLanguage(data);
              }}
              value={translationLanguage}
              options={languageTranslationOptions}
            />
          </div>
          <div className="form-element">
            <div className="submit-wrapper">
              <button
                type="button"
                className='new-button'
                onClick={(e) => handleProfileSubmit(e)}
              >
                {t('Save')}
              </button>
            </div>
            {errors.general ? <div className={styles.errorMessage}>{t(errors.general)} <span
              onClick={() => setErrors({...errors, general: null})}> <CloseIcon/> </span></div> : ''}
          </div>
        </form>
      </div>
    </>
  );
};
