import { useTranslation } from 'react-i18next';

export const ThreatsTypesChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const types = [
    {
      value: 'FAKE_NEWS',
      label: t('Fake news'),
    },
    {
      value: 'DATA_LEAK',
      label: t('Data leak'),
    },
    {
      value: 'HOSTILE_NARRATIVE',
      label: t('Malign narrative'),
    },
    {
      value: 'COORDINATED_ATTACK',
      label: t('Coordinated online attack'),
    },
    {
      value: 'COORDINATED_ACTIVITY',
      label: t('Coordinated offline activity'),
    },
    {
      value: 'DEBUNK',
      label: t('Debunk'),
    },
    {
      value: 'POSITIVE_NARRATIVE',
      label: t('Positive narrative'),
    },
    {
      value: 'GOVERNMENT_COMMUNICATION',
      label: t('Government communication'),
    },
    {
      value: 'FAKE_DISINFORMATION',
      label: t('Fake/Disinformation'),
      is_ucbi: true,
    },
    {
      value: 'MANIPULATION_MISINFORMATION',
      label: t('Manipulation/Misinformation'),
      is_ucbi: true,
    },
    {
      value: 'DISPROOF_DEBUNK',
      label: t('Disproof/Debunk'),
      is_ucbi: true,
    },
    {
      value: 'STRATEGIC_NARRATIVE_MESSAGE',
      label: t('Strategic narrative/message (reporting)'),
      is_ucbi: true,
    },
    {
      value: 'UNCLASSIFIED_THREAT',
      label: t('Unclassified threat'),
      is_ucbi: true,
    },
    {
      value: 'OTHER',
      label: t('Other_'),
      is_ucbi: true,
    },
    {
      value: 'CONSUMER_BACKLASH',
      label: t('Consumer backlash'),
    },
    {
      value: 'NEGATIVE_MEDIA_COVERAGE',
      label: t('Negative media coverage'),
    },
    {
      value: 'INSIDER_LEAK',
      label: t('Insider leak'),
    },
    {
      value: 'NATURAL_DISASTER',
      label: t('Natural disaster'),
    },
    {
      value: 'POLITICAL_CIVIL_UNREST',
      label: t('Political/Civil unrest'),
    },
    {
      value: 'CRIMINAL_ACTIVITY',
      label: t('Criminal activity'),
    },
    {
      value: 'LEGAL_REGULATORY',
      label: t('Legal/Regulatory'),
    },
    {
      value: 'PRODUCT_MALFUNCTION',
      label: t('Product malfunction'),
    },
    {
      value: 'CYBER_ATTACK',
      label: t('Cyber attack'),
    },
    {
      value: 'GEOPOLITICAL_ESCALATION',
      label: t('Geopolitical escalation'),
    },
    {
      value: 'LARGE_SCALE_ECOLOGICAL_INDUSTRIAL_DISASTER',
      label: t('Large-scale ecological/industrial disaster'),
    },
    {
      value: 'DETERIORATION_OF_INTERNATIONAL_RELATIONS',
      label: t('Deterioration of international relations'),
    },
    {
      value: 'GLOBAL_MALFUNCTION',
      label: t('Global malfunction'),
    },
    {
      value: 'DISINFORMATION_CAMPAIGN',
      label: t('Disinformation campaign'),
    },
  ]

  const handleSelect = () => {
    if (types.length !== selected.length) {
      onChange(types.map(type => type.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{types.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {types.map(type => {
          return(
            <li key={type.value}>
              <input 
                id={`type_${type.value}`}
                type='checkbox'
                checked={selected.includes(type.value)}
                onChange={() => {
                  if (selected.includes(type.value)) {
                    onChange(selected.filter(v => v !== type.value)) 
                  } else {
                    onChange([...selected, type.value]) 
                  }
                }}
              />
              <label htmlFor={`type_${type.value}`}>{t(type.label)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}

