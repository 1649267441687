import './DropdownMenu.scss';

export const Multichoice = ({itemsList, selected=[], header='', onChange}) => {
  return(
    <div className='multichooser'>
      <ul>
        {itemsList.map(item => {
          return(
            <>
             {header ? <div className="multichooser-header">{header}</div> : ''}
              <li key={item.value}>
                <input 
                  id={`item_${item.value}`}
                  type='checkbox'
                  checked={selected?.includes(item.value)}
                  onChange={() => {
                    if (selected?.includes(item.value)) {
                      onChange(selected.filter(v => v !== item.value)) 
                    } else {
                      onChange([...selected, item.value]) 
                    }
                  }}
                />
                <label htmlFor={`item_${item.value}`}>{item.label}</label>
              </li>
            </>
            
          )
        })}
        
      </ul>
    </div>
  )
}