import { useTranslation } from 'react-i18next';
import { useState, useRef, useContext, useTransition } from 'react';

import { Collapsible } from '../../../pages/NarrativePage/Filter/Collapsible';
import { SourceGroupsChooser } from '../../../pages/NarrativePage/Filter/SourceGroupsChooser';
import { ReactComponent as FilterIcon } from '../../../pages/NarrativePage/assets/filter.svg';
import { CountryChooser } from "../../../pages/NarrativePage/Filter/CountryChooser";
import { Sentiment } from './Sentiment';
import { useClickOutside } from '../../../utils/useClickOutside';
import '../../../pages/NarrativePage/Filter//Filter.scss';
import styles from '../../../pages/NarrativePage/Filter/Filter.module.scss';
import { MessagesContext } from '../../../pages/NarrativePage/contexts/MessagesContext';
import { useCurrentUser } from '../../../contexts/CurrentUser';


export const Filter = ({
  onlyIcon=false,
  className='',
  isShare,
  sourceGroupsFilter, 
  countriesFilter, 
  sentimentFilter,
  onChange, isThreat=false}) => {
  const { t } = useTranslation();
  const clickRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [sourceGroups, setSourceGroups] = useState(sourceGroupsFilter || []);
  const [countries, setCountries] = useState(countriesFilter || []);
  const [sentiment, setSentiment] = useState(sentimentFilter || []);
  const [isSourceGroupsOpen, setIsSourceGroupsOpen] = useState(false);
  const [isCountriesOpen, setIsCountriesOpen] = useState(false);
  const [isSentimentOpen, setIsSentimentOpen] = useState(false);

  const [currentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');
  const [isPending, startTransition] = useTransition();

  const {
    messages,
    selectedMessages,
    getMessages,
    toggleAll,
    toggleSelected,
    deduplication,
    patchMessages,
    deleteMessages,
    translateMessage,
    toggleTranslate,
    dispatch,
    isLoading,
    setLoading,
  } = useContext(MessagesContext);
  
  let filtersSumm = sourceGroups?.length + countries?.length + sentiment?.length;

  useClickOutside(clickRef, () => {
    if(isActive) {
      setIsActive(false)
    }
  })

  return (
    <div ref={clickRef} className={className ? `${styles.root} ${styles[className]}` : styles.root}>
      <button 
        className={isActive ? `${styles.button} ${styles.filters} ${styles.active}` : `${styles.button} ${styles.filters}`}
        onClick={() => setIsActive(!isActive)}
      >
        {filtersSumm > 0 ? <span className='summ'>{filtersSumm }</span> : ''}
        {onlyIcon ? '' : t('Filters')}
        <FilterIcon className={isActive ? 'iconActive' : 'icon'}/>
      </button>

      <div className={isActive ? `${styles.container} ${styles.show}` : `${styles.container}`}>
        <div className={styles.header}>
          <span>{t('Filter by')}</span>

          <span onClick={()=> {
            if (!isSourceGroupsOpen || !isCountriesOpen || !isSentimentOpen) {
              setIsSourceGroupsOpen(true);
              setIsCountriesOpen(true);
              setIsSentimentOpen(true);
            } else {
              setIsSourceGroupsOpen(false);
              setIsCountriesOpen(false);
              setIsSentimentOpen(false);
            }
          }}>
            {isSourceGroupsOpen && isCountriesOpen && isSentimentOpen ? `${t('Minimize all')}` : `${t('Expand all')}`}
          </span>
        </div>

        <div className={styles.collapsiblesWrapper}>
          <Collapsible
            name='Country'
            summ={countries.length}
            isOpen={isCountriesOpen}
            onChange={(open) => setIsCountriesOpen(open)}
          >
            <CountryChooser
              selected={countries}
              onChange={(checklist) => setCountries(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible>

          {isShare ? '' : <Collapsible 
            name='Actor group' 
            summ={sourceGroups.length}
            isOpen={isSourceGroupsOpen}
            onChange={(open) => setIsSourceGroupsOpen(open)}
          >
            <SourceGroupsChooser 
              selected={sourceGroups}
              onChange={(checklist) => setSourceGroups(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible>}

          <Collapsible 
            name='Sentiment'
            isOpen={isSentimentOpen}
            onChange={(open) => setIsSentimentOpen(open)}
          >
            <Sentiment
              selected={sentiment}
              onChange={(checklist) => setSentiment(checklist)}
            />
          </Collapsible>
        </div>

        <div className={styles.controls}>
          <button 
            className={`${styles.button} ${styles.apply}`}
            onClick={() => {
              setIsActive(false)
              startTransition(() => {
                onChange(
                  sourceGroups,
                  countries,
                  sentiment,
                )
              }
              )
            }}
          >
            {t('Apply')}
          </button>

          <button 
            className={`${styles.button} ${styles.reset}`}
            onClick={() => {
              setSourceGroups([]);
              setCountries([]);
              setSentiment([]);
            }}
          >
            {t('Reset all')}
          </button>
        </div>
      </div>


    </div>
  )
}
