import * as d3 from 'd3';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { decodeSourceName } from '../../utils/decodeURI';
import { palette } from '../../utils/colors';
import { ReactComponent as ActorIcon } from '../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../pages/SearchPage/assets/person.svg';

import './CircleChart.scss';
import {Link} from "react-router-dom";

let formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s',);

const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

// let formatPercentage = d3.format('.1f');

let legend = [
  {
    title: 'Last',
  },
  {
    title: 'New',
  },
  {
    title: 'Old',
  },
  {
    title: 'Older',
  },
  {
    title: 'Oldest',
  },
];

export function CircleChart({ data, onCircleClick = () => {}, onTranslationClick, narrativeColor }) {
  const { t } = useTranslation();

  const svgWrapper = useRef(null);
  const popup = useRef(null);
  const parentWrapper = useRef(null);

  const [width, setWidth] = useState(900);
  const [height] = useState(600);
  const [currentNarrative, setCurrentNarrative] = useState('');
  const [popupOpen, togglePopup] = useState(false);
  const [isExpanded, toggleExpanded] = useState(false);
  const [shouldShowReset, setShouldShowReset] = useState(false);
  const [reference, updateReference] = useState({});

  const realData = useMemo(() => {
    const realdata = [];

    data.objects.forEach((narrative, i) => {
      narrative.messages.forEach((item) => {
        item.narrative = i;
        item.narrative_id = narrative.id;

        realdata.push(item);
      });
    });

    return realdata;
  }, [data.objects]);


  const handleResetClick = useCallback(() => {
    setShouldShowReset(false);

    updateReference({});

    void realData;
    void width;
    void height;
  }, [realData, width, height]);

  const dateScale = useMemo(() => {
    let dateRange = d3.extent(realData, function (d) {
      return new Date(d.date_publicated);
    });

    return d3
      .scaleTime()
      .domain([new Date(dateRange[0]), new Date(dateRange[1])])
      .range([0.1, 1]);
  }, [realData]);

  useEffect(() => {
    if (
      !parentWrapper.current ||
      typeof window.ResizeObserver === 'undefined'
    ) {
      return;
    }

    const resizeObserver = new window.ResizeObserver((entries) => {
      const rootEntry = entries.find((e) => e.target === parentWrapper.current);

      if (!rootEntry) {
        return;
      }

      setWidth(rootEntry.contentRect.width);
      // setHeight(rootEntry.contentRect.height);
    });

    resizeObserver.observe(parentWrapper.current);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const leftOffset = parentWrapper.current.getBoundingClientRect().left + 490;
    const topOffset = parentWrapper.current.getBoundingClientRect().top + 20;

    d3.select(svgWrapper.current).selectAll('*').remove();

    const svg = d3
      .select(svgWrapper.current)
      .attr('width', width)
      .attr('height', height)
      .style('width', '100%')
      .style('height', 'auto')
      .attr('font-size', 10)
      .attr('font-family', 'sans-serif')
      .attr('text-anchor', 'middle');

    svg
      .selectAll('circle.legend')
      .data(legend)
      .enter()
      .append('g')
      .attr('transform', (d, i) => `translate(40,${i * 30 + 20})`)
      .append('rect')
      .attr('rx', 5)
      .attr('y', -12)
      .attr('ry', 5)
      .attr('width', 36)
      .attr('height', 20)
      .attr('fill', palette[0])
      .style('opacity', (d, i) => (legend.length - i) / (legend.length + 1))

      .select(function () {
        return this.parentNode;
      })
      .append('text')
      .text((d) => d.title)
      .style('fill', '#2C2E30')
      .attr('text-anchor', 'start')
      .style('font-family', 'sans-serif')
      .style('font-size', '14px')
      .attr('x', 44)
      .attr('y', 3);

    let realdata = [...realData];

    // let scores = d3.extent(realdata, function (d) {
    //   return d.score;
    // });
    // let views = d3.extent(realdata, function (d) {
    //   return d.views;
    // });
    let message_share = d3.extent(realdata, function (d) {
      return d.message_share;
    });

    // let dateScore = d3.scalePow().exponent(3).domain(scores).range([20, 50]);
    // let dateScore = d3.scaleLinear().domain(views).range([20, 50]);
    let dateScore = d3.scaleLinear().domain(message_share).range([20, 50]);

    realdata = realdata.map((item) => {
      let offset = item.narrative - 0.5;
      let freshness = 1 - dateScale(new Date(item.date_publicated));
      item.cx = item.x = 140 * offset * freshness;
      item.cy = item.y = 0;
      return item;
    });

    var node = svg
      .append('g')
      .selectAll('circle')
      .data(realdata)
      .enter()
      .append('circle')
      .attr('r', (d) => dateScore(d.message_share))
      // .attr("cx", 500 + width / 2)
      // .attr("cy", height / 2)
      .attr('cx', (d) => d.cx)
      .attr('cy', (d) => d.cy)
      .attr('fill', (d) => {
        d3.color('steelblue');
        // let color = d.narrative ? "#1101ef" : "#ff9903";
        // return d.narrative ? "#1101ef" : "#ff9903";
        return narrativeColor[d.narrative_id];
        // return 'rgba(.5,0,0,1.)';
      })
      .attr('fill-opacity', (d) => dateScale(new Date(d.date_publicated)))
      .call(
        d3
          .drag() // call specific function when circle is dragged
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended),
      );
    // .x(300)
    // .y(300)
  

    // Features of the forces applied to the nodes:
    var simulation = d3
      .forceSimulation(realdata)
      // .force("position", d3.forcePosition().position([width / 2, height / 2]))
      .force(
        'center',
        d3
          .forceCenter()
          .x(width / 2)
          .y(height / 2),
      )
      .force('charge', d3.forceManyBody().strength(5.5))
      .force(
        'collide',
        d3
          .forceCollide()
          .strength(0.04)
          .radius(function (d) {
            return dateScore(d.message_share) + 2;
          })
          .iterations(3),
      ); // Force that avoids circle overlapping

    simulation.nodes(realdata).on('tick', function (d) {
      node
        .attr('cx', function (d) {
          return d.x;
        })
        .attr('cy', function (d) {
          return d.y;
        });
    });

    // DOM EVENTS
    // ============================
    // ============================
    // ============================
    // ============================

    // let format = d3.format(',d');
    let current_circle = undefined;

    function selectMessage(event, d) {
      popup.current.style.setProperty('--stroke', `var(--color${d.narrative})`);
      popup.current.style.setProperty(
        '--background',
        `var(--color${d.narrative}l)`,
      );

      popup.current.style.transform =
        'translate(' +
        Math.max(event.layerX, leftOffset) +
        'px,' +
        Math.max(event.layerY, 520) +
        'px)';

      if (current_circle !== undefined) {
        // current_circle.attr("fill", d => "#bbccff");
        current_circle.style('stroke-width', 0);
        svg.selectAll('#details-popup').remove();
      }

      // select the circle
      current_circle = d3.select(this);
      // current_circle.attr("fill","#b2e1f9");
      current_circle.attr('stroke', '#000').style('stroke-width', 1);
      onCircleClick(d.id);
      setCurrentNarrative(d);
      togglePopup(true);
      toggleExpanded(false);
    }

    node.on('click', selectMessage);

    // What happens when a circle is dragged?
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.03).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }
    function dragended(event, d) {
      if (!event.active) {
        simulation.alphaTarget(0.03);
      }

      d.fx = null;
      d.fy = null;

      setShouldShowReset(true);
    }
  }, [width, height, realData, dateScale, reference]);

  return (
    <div className="circlechart" ref={parentWrapper}>
      {shouldShowReset && (
        <button className="button reset" onClick={handleResetClick}>
          Restart view
        </button>
      )}

      <div className="popup-position" ref={popup}>
        <div
          className="popup"
          style={{display: popupOpen ? 'block' : 'none'}}
        >
          <div className="popup__close" onClick={() => togglePopup((o) => !o)}>
            <svg viewBox="0 0 32 32">
              <line
                x1="7"
                x2="25"
                y1="7"
                y2="25"
                stroke="#2c2e30"
                strokeWidth={2}
              />
              <line
                x1="7"
                x2="25"
                y1="25"
                y2="7"
                stroke="#2c2e30"
                strokeWidth={2}
              />
            </svg>
          </div>
          <h3>{t('Message')}</h3>
          <p className="popup__title">
            {isExpanded
              ? currentNarrative.text
              : currentNarrative.text?.substring(0, 150)}
            {currentNarrative.text?.length > 150 && (
              <span>
                ...
                <span
                  className="popup__more"
                  onClick={() => toggleExpanded((o) => !o)}
                >
                  {isExpanded ? t('Show less') : t('Show more')}
                </span>
              </span>
            )}
          </p>
          <h3>{t('Sources')}</h3>
          <div className="popup__sources">
            <div>
              <ActorIcon/>
              <Link to={`/sources/${currentNarrative.source?.id}`}>{decodeSourceName(currentNarrative.source?.name)}</Link>
            </div>
            {currentNarrative.actor && currentNarrative.actor?.id !== currentNarrative.source?.id ? <div>
              <SourceIcon/>
              <Link to={`/sources/${currentNarrative.actor?.id}`}>{decodeSourceName(currentNarrative.actor?.name)}</Link>
            </div> : ''
            }
          </div>
          <h3>{t('Date')}</h3>
          <div className="popup__sources">
            {currentNarrative?.date_publicated ? format(parseISO(currentNarrative?.date_publicated + 'Z'), 'dd LLL yyyy, HH:mm',) : ''}
          </div>
          <div className="popup__resume">
            <div className="popup__data">
              <div className="popup__icon">
                <svg width="15" height="18" viewBox="0 0 15 18" fill="none">
                  <path
                    d="M9.125 1.21563V4.52006C9.125 4.9681 9.125 5.19212 9.21356 5.36325C9.29145 5.51378 9.41575 5.63616 9.56863 5.71286C9.74244 5.80006 9.96996 5.80006 10.425 5.80006H13.7811M10.75 9.8H4.25M10.75 13H4.25M5.875 6.6H4.25M4.9 17H10.1C11.4651 17 12.1477 17 12.6691 16.7384C13.1277 16.5083 13.5006 16.1412 13.7343 15.6896C14 15.1762 14 14.5041 14 13.16V6.63752C14 6.10172 13.785 5.58831 13.4032 5.21239L9.70885 1.57487C9.33471 1.20648 8.8307 1 8.30564 1H4.9C3.53487 1 2.85231 1 2.3309 1.26158C1.87225 1.49168 1.49936 1.85883 1.26567 2.31042C1 2.82381 1 3.49587 1 4.84V13.16C1 14.5041 1 15.1762 1.26567 15.6896C1.49936 16.1412 1.87225 16.5083 2.3309 16.7384C2.85231 17 3.53487 17 4.9 17Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="popup__value">
                {formatNumber(currentNarrative.actor?.id !== currentNarrative.source?.id ? 2 : 1)}
              </div>
            </div>
            <div className="popup__data">
              <div className="popup__icon">
                <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
                  <path
                    d="M1.26805 8.71318C1.12971 8.49754 1.06054 8.38972 1.02181 8.22342C0.992729 8.0985 0.992729 7.9015 1.02181 7.77658C1.06054 7.61028 1.12971 7.50246 1.26805 7.28682C2.41127 5.50484 5.81418 1 11 1C16.1858 1 19.5887 5.50484 20.7319 7.28682C20.8703 7.50246 20.9395 7.61028 20.9782 7.77658C21.0073 7.9015 21.0073 8.0985 20.9782 8.22342C20.9395 8.38972 20.8703 8.49754 20.7319 8.71318C19.5887 10.4952 16.1858 15 11 15C5.81418 15 2.41127 10.4952 1.26805 8.71318Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11 11C12.6831 11 14.0475 9.65685 14.0475 8C14.0475 6.34315 12.6831 5 11 5C9.31692 5 7.95251 6.34315 7.95251 8C7.95251 9.65685 9.31692 11 11 11Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="popup__value">
                {formatWithCustomGigaBillion(currentNarrative.impressions)}
              </div>
            </div>
            <div className="popup__data">
              <div className="popup__icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M5.10176 17V8.2M1 9.8V15.4C1 16.2837 1.73457 17 2.6407 17H13.655C14.8697 17 15.9027 16.1357 16.0874 14.9649L16.9709 9.36494C17.2002 7.91112 16.0468 6.6 14.5384 6.6H11.6646C11.2115 6.6 10.8442 6.24183 10.8442 5.8V2.97267C10.8442 1.8832 9.93857 1 8.82137 1C8.5549 1 8.31343 1.15304 8.2052 1.3905L5.31829 7.72491C5.18662 8.01381 4.89283 8.2 4.56864 8.2H2.6407C1.73457 8.2 1 8.91634 1 9.8Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="popup__value">
                {formatWithCustomGigaBillion(currentNarrative?.engagement)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg width={width} height={height} ref={svgWrapper}></svg>
    </div>
  );
}
