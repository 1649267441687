import React from 'react';
import cn from 'classnames';

import styles from './Spinner.module.scss';

export const Spinner = ({
  sizePx = 24,
  thicknessPx = 2,
  ariaHidden = false,
  className = '',
  color = '#4f546c',
}) => (
  <span
    className={cn(styles.ring, className)}
    style={React.useMemo(
      () => ({
        '--color': color,
        '--size': `${sizePx}px`,
        '--partSize': `${sizePx - 3}px`,
        '--thickness': `${thicknessPx}px`,
      }),
      [color, sizePx, thicknessPx],
    )}
    aria-hidden={ariaHidden}
  >
    <span className={styles.ring__part} />
    <span className={styles.ring__part} />
    <span className={styles.ring__part} />
    <span className={styles.ring__part} />
  </span>
);
