import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';

import { Collapsible } from '../../SearchPage/Filter/Collapsible';
import { StatusChooser } from './StatusChooser';
import { ThreatsTypesChooser } from './ThreatsTypesChooser';
import { ThreatsSubjectsChooser } from './ThreatsSubjectsChooser';
import { CaseTypesChooser } from './CaseTypesChooser';
import { GeoScopeChooser } from './GeoScopeChooser';

import { ReactComponent as FilterIcon } from '../../SearchPage/assets/filter.svg';
import {useClickOutside} from '../../../utils/useClickOutside.jsx'
import '../../SearchPage/Filter/Filter.scss';
import styles from '../../SearchPage/Filter/Filter.module.scss';
import { ShowRead } from './ShowRead';


export const Filter = ({
  incidentTypeFilter,
  topicsFilter,
  showReadFilter,
  statusesFilter,
  caseTypesFilter,
  GeoScopeFilter,
  onChange, tabMode}) => {
  const { t } = useTranslation();
  const clickRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [showRead, setShowRead] = useState(showReadFilter || null);
  const [incidentType, setIncidentType] = useState(incidentTypeFilter || []);
  const [topics, setTopics] = useState(topicsFilter || []);
  const [statuses, setStatuses] = useState(statusesFilter || []);
  const [caseTypes, setCaseTypes] = useState(caseTypesFilter || []);
  const [geoScope, setGeoScope] = useState(GeoScopeFilter || []);
  const [isShowReadOpen, setIsShowReadOpen] = useState(false);
  const [isThreatsTypesOpen, setIsThreatsTypesOpen] = useState(false);
  const [isThreatsSubjectsOpen, setIsThreatsSubjectsOpen] = useState(false);
  const [isStatusesOpen, setIsStatusesOpen] = useState(false);
  const [isCaseTypesOpen, setIsCaseTypesOpen] = useState(false);
  const [isGeoScopeOpen, setIsGeoScopeOpen] = useState(false);

  
  let filtersSumm = incidentType.length + topics.length + statuses.length + caseTypes.length + geoScope?.length;

  useClickOutside(clickRef, () => {
    if(isActive) {
      setIsActive(false)
    }
  })

  return (
    <div ref={clickRef} className={styles.root}>
      <button 
        className={isActive ? `${styles.button} ${styles.filters} ${styles.active}` : `${styles.button} ${styles.filters}`}
        onClick={() => setIsActive(!isActive)}
      >
        {filtersSumm > 0 ? <span className='summ'>{filtersSumm }</span> : ''}
        {t('Filters')}
        <FilterIcon className={isActive ? 'iconActive' : 'icon'}/>
      </button>

      <div className={isActive ? `${styles.container} ${styles.show}` : `${styles.container}`}>
        <div className={styles.header}>
          <span>{t('Filter by')}</span>

          <span onClick={()=> {
            if (!isShowReadOpen || !isThreatsTypesOpen || !isThreatsSubjectsOpen || !isStatusesOpen || !isCaseTypesOpen || !isGeoScopeOpen) {
              setIsShowReadOpen(true);
              setIsThreatsTypesOpen(true);
              setIsThreatsSubjectsOpen(true);
              setIsStatusesOpen(true);
              setIsCaseTypesOpen(true);
              setIsGeoScopeOpen(true);

            } else {
              setIsShowReadOpen(false);
              setIsThreatsTypesOpen(false);
              setIsThreatsSubjectsOpen(false);
              setIsStatusesOpen(false);
              setIsCaseTypesOpen(false);
              setIsGeoScopeOpen(false);
            }
          }}>
            {isShowReadOpen && isThreatsTypesOpen && isThreatsSubjectsOpen && isStatusesOpen && isCaseTypesOpen && isGeoScopeOpen ? `${t('Minimize all')}` : `${t('Expand all')}`}
          </span>
        </div>

        <div className={styles.collapsiblesWrapper}>

        { tabMode === 'inbox' ? <Collapsible
            name='Read/Unread'
            isOpen={isShowReadOpen}
            onChange={(open) => setIsShowReadOpen(open)}
          >
            <ShowRead
              selected={showRead}
              onChange={(value) => setShowRead(value)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible> : ''}

          <Collapsible
            name='Case types'
            summ={caseTypes.length}
            isOpen={isCaseTypesOpen}
            onChange={(open) => setIsCaseTypesOpen(open)}
          >
            <CaseTypesChooser
              selected={caseTypes}
              onChange={(checklist) => setCaseTypes(checklist)}
            />
          </Collapsible>

          { tabMode === 'inbox' ? '' : <Collapsible
            name='Status'
            isOpen={isStatusesOpen}
            summ={statuses.length}
            onChange={(open) => setIsStatusesOpen(open)}
          >
            <StatusChooser
              selected={statuses}
              onChange={(checklist) => setStatuses(checklist)}
            />
          </Collapsible> }
          
          <Collapsible
              name='Threat types'
              summ={incidentType.length}
              isOpen={isThreatsTypesOpen}
              onChange={(open) => setIsThreatsTypesOpen(open)}
            >
              <ThreatsTypesChooser
                selected={incidentType}
                onChange={(checklist) => setIncidentType(checklist)}
              />
          </Collapsible>

          <Collapsible
            name='Topic'
            summ={topics.length}
            isOpen={isThreatsSubjectsOpen}
            onChange={(open) => setIsThreatsSubjectsOpen(open)}
          >
            <ThreatsSubjectsChooser
              selected={topics}
              onChange={(checklist) => setTopics(checklist)}
            />
          </Collapsible>
        
          <Collapsible
            name='GEO Scope'
            summ={geoScope.length}
            isOpen={isGeoScopeOpen}
            onChange={(open) => setIsGeoScopeOpen(open)}
          >
            <GeoScopeChooser
              selected={geoScope}
              onChange={(checklist) => setGeoScope(checklist)}
            />
          </Collapsible>

        </div>

        <div className={styles.controls}>
          <button 
            className={`${styles.button} ${styles.apply}`}
            onClick={() => {
              if(tabMode === 'inbox') {
                onChange(showRead === null ? showRead : showRead.value, incidentType, topics, caseTypes, geoScope)
              } else {
                onChange(incidentType, topics, statuses, caseTypes, geoScope)
              }
              setIsActive(false)
            }}
          >
            {t('Apply')}
          </button>

          <button 
            className={`${styles.button} ${styles.reset}`}
            onClick={() => {
              setShowRead(null);
              setIncidentType([]);
              setTopics([])
              setStatuses([])
              setCaseTypes([])
              setGeoScope([])
            }}
          >
            {t('Reset all')}
          </button>
        </div>
      </div>


    </div>
  )
}

