import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DoubleRangeSlider.module.scss';

export const Input = ({min, max, step, manIndexRange, handleMinChange, handleMaxChange}) => {
  const { t } = useTranslation();
  const [inputMin, setInputMin] = useState(0.1);
  const [inputMax, setInputMax] = useState(manIndexRange[1]);

  useEffect(() => {
    setInputMin(manIndexRange[0])
    setInputMax(manIndexRange[1])
  }, [manIndexRange])

  return(
    <form className={styles.inputsContainer}>
        <div className={styles.inputContainer}>
          <label htmlFor='from'>{t('From')}</label>
          <input 
            className={styles.output}
            id={styles.from}
            type='number'
            min={min}
            max={max}
            step='any'
            placeholder={min}
            value={inputMin}
            onChange={(e) => setInputMin(e.target.value)}
            onBlur={(e) => handleMinChange(inputMin)}
          />
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor='to'>{t('To')}</label>
          <input 
            className={styles.output}
            id={styles.to}
            type='number'
            min={min}
            max={max}
            step='any'
            placeholder={max}
            value={inputMax}
            onChange={(e) => setInputMax(e.target.value)}
            onBlur={(e) => handleMaxChange(inputMax)}
          />
        </div>
        
      </form>
  )
}