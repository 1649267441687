import styles from './BreakdownsContainer.module.scss';
import { useTranslation } from 'react-i18next';

export const BreakdownsContainer = ({children, title, onClick, flexGrow=1, flexBasis=0, details=true}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.breakdownContainerWrapper} style={{flexGrow: flexGrow, flexBasis: flexBasis}}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>{t(title)}</div>
        {details ? <div className={styles.headerButton} onClick={() => onClick()}>{t('View details')}</div> : ''}
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  )
}

