import { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { API } from '../../API';
import { Paginator } from '../../components/Paginator/Paginator';
import { Loader } from '../../components/Loader/Loader';
import './WorkspacesPage.scss';

export function WorkspacesPage() {
  const { t } = useTranslation();
  const [workspaces, setWorkspaces] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const page = searchParams.get('page') || 1;
  const searchQuery = searchParams.get('q');

  const fetchData = useCallback(() => {
    const urlParams = new URLSearchParams();

    urlParams.set('size', '100');
    urlParams.set('page', page);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    API.fetch('GET', `/API/v1/workspaces?${urlParams.toString()}`).then(
      (data) => {
        setWorkspaces(data);
      },
    );
  }, [page, searchQuery]);

  useEffect(fetchData, [fetchData]);

  const handleSudo = (workspace) => {
    localStorage.setItem('workspace_id', workspace.id);
    localStorage.setItem('workspace_name', workspace.name);

    navigate('/');
  };

  if (!workspaces) {
    return <Loader />;
  }

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>{t('Workspaces')}</span>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h2>{t('Workspaces')}</h2>
        </div>
        <div className="card-body">
          <table className="workspaces">
            <thead>
              <tr>
                <td>#</td>
                <td>{t('Name')}</td>
                <td>{t('Description')}</td>
                <td>{t('Status')}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {workspaces.objects.map((workspace) => {
                return (
                  <tr key={workspace.id}>
                    <td>{workspace.id}</td>
                    <td className="name">{workspace.name}</td>
                    <td className="description">{workspace.subscribers}</td>
                    <td className="status">{workspace.status ?? '-'}</td>
                    <td className="link">
                      <button onClick={() => handleSudo(workspace)}>
                        {t('Sudo')}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Paginator
        size={workspaces.size}
        page={workspaces.page}
        total={workspaces.total}
      />
    </div>
  );
}
