import cn from 'classnames';
import * as d3 from 'd3';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { format, parseISO, formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { API } from '../../../API';
import { ampli } from '../../../ampli';
import { Loader } from '../../../components/Loader/Loader';
import Modal from '../../../components/Modal';
import { Paginator } from '../../../components/Paginator/Paginator';
import { ExpandableText } from '../../../components/CollapsibleText/ExpandableText';
import { ReactComponent as ApproveIcon } from '../assets/approve.svg';
import { ReactComponent as ArrowIcon } from '../../NarrativesPage/assets/arrow.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import { ReactComponent as ViewIcon } from '../assets/view.svg';
import { ReactComponent as EnvelopeIcon } from '../../../assets/envelope.svg';
import { ReactComponent as EnvelopeOpenIcon } from '../../../assets/envelopeOpen.svg';
import { ReactComponent as RecoverIcon } from '../../../assets/recover.svg';
import { ReactComponent as LinkIcon } from '../../../assets/link.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../../assets/engagement.svg';
import { ReactComponent as SimilarityIcon } from '../../../assets/similarity.svg';
import { ReactComponent as ManipulationIcon } from '../../../assets/manipulation.svg';
import { ReactComponent as FollowersIcon } from '../../../assets/followers.svg';
import { ReactComponent as TranslationIcon } from '../../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../../assets/originalText.svg';
import { ReactComponent as ActorIcon } from '../../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../../pages/SearchPage/assets/person.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/expand.svg';
import { ReactComponent as ChevronIcon } from '../../../pages/SearchPage/assets/chevron-down.svg';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { getSentimentName, getSentimentIcon } from '../../../utils/sentiment';
import { useCurrentUser } from '../../../contexts/CurrentUser';
import { CheckboxField } from '../../../components/Checkbox/CheckboxField';
import { NarrativeStoriesModal } from './NarrativeStoriesModal';
import { MessagesContext } from '../contexts/MessagesContext';
import { areAllIdsSelected } from '../../NarrativePage/contexts/MessagesContext';
import { LoaderSmall } from '../../../components/LoaderSmall/LoaderSmall';
import { DropdownMenu } from '../../../components/DropdownMenu/DropdownMenu';
import { Multichoice } from '../../../components/DropdownMenu/Multichoice';
import { AddToNarrativeModal } from '../../../components/AddToNarrativeModal/AddToNarrativeModal';
import { ThreatEditModal } from '../../ThreatsPage/ThreatEditModal';
import { Sentiment } from '../../../components/Sentiment/Sentiment';
import { replaceNewlinesWithBreaks } from '../../../utils/replaceNewlinesWithBreaks';

import styles from '../NarrativePage.module.scss';
import messageStyles from '../../../components/MessagesTable/ExtendedMessageView.module.scss';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');

export const NarrativeStories = ({
  narrative,
  searchPanel,
  searchQuery,
  start_date,
  end_date,
  statuses,
  sorting,
  page,
  isShare,
  setPage,
  isOpinions = false,
}) => {
  const {
    messages,
    selectedMessages,
    getStories,
    toggleAll,
    toggleSelected,
    deduplication,
    patchMessages,
    deleteMessages,
    translateMessage,
    toggleTranslate,
    error,
    dispatch,
    cancelRequest,
    createStories,
    showStoriesMessage,
  } = useContext(MessagesContext);
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();

  const featureFlags = currentUser?.workspace?.config.featureflags;
  const showNer = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');

  const [addToNarrativeModal, setAddToNarrativeModal] = useState({
    isActive: false,
    storyId: null,
  });
  const [addToThreatModal, setAddToThreatModal] = useState({
    isActive: false,
    storyId: null,
  });

  const [infoModal, setInfoModal] = useState({
    isActive: false,
    id: null,
    title: null,
  });

  const statusMapping = {
    NEW: { class: styles.new, name: 'New' },
    READ: { class: styles.approved, name: 'Approved' },
    DELETED: { class: styles.deleted, name: 'Deleted' },
  };

  useEffect(() => {
    getStories(
      narrative,
      searchQuery,
      start_date,
      end_date,
      statuses,
      sorting,
      page,
    );
  }, [narrative, searchQuery, start_date, end_date, statuses, sorting, page]);

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messages.objects.find((message) => message.id === messageId);
    if (target.translated) {
      toggleTranslate(messageId, isTranslation);
    } else {
      const nativeLanguage = currentUser?.translation_language ? currentUser?.translation_language : narrative.parameters?.translate_keywords_query
        ? narrative.parameters.keywords_query_origin_language
        : window.clientInformation.language.split('-', 1)[0];
      toggleTranslate(messageId, true);
      translateMessage(messageId, text, nativeLanguage).then((data) => {
        dispatch({type: 'TRANSLATE_MESSAGE', payload: {data: data, messageId: messageId}})
      })
      .catch(e => {
        dispatch(
          {
            type: 'TRANSLATION_ERROR',
            payload: {data: t('We were not able to translate this text.'), messageId: messageId}}
        )
      })

      ampli.track({
        event_type: 'Translate message',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        },
      });
    }
  };

  const saveToThreat = (
    narrativeId,
    threatName,
    threatDescription,
    caseType,
    incidentType,
    topics,
    geoScope,
    narratives,
    campaigns,
    counterEntities,
    reviewStatus,
    declineReason,
    organizations,
    isCreatedByUcbiReporter,
    threatPublicNameEn,
    threatPublicNameUk,
    threatPublicDescriptionEn,
    threatPublicDescriptionUk,
    threatIsPublic,
    messages,
  ) => {
    const messagesList = Object.keys(messages);

    let data = {
      name: threatName,
      description: threatDescription,
      is_threat: true,
      public_name_en: threatPublicNameEn,
      public_name_uk: threatPublicNameUk,
      public_description_en: threatPublicDescriptionEn,
      public_description_uk: threatPublicDescriptionUk,
      is_public: threatIsPublic,
    };

    const headers = { 'Content-Type': 'application/json' };

    if (caseType) {
      data.type = caseType.value;
    }

    if (incidentType) {
      data.incident_type = incidentType.value;
    }

    if (topics) {
      data.topic = topics.map((v) => v.value);
    }

    if (geoScope) {
      data.geo_scope = geoScope.map((v) => v.value);
    }

    if (narratives) {
      data.narrative_ids = narratives.map((v) => v.value);
    }

    if (campaigns) {
      data.campaign_ids = campaigns.map((v) => v.value);
    }

    if (counterEntities) {
      data.counter_entity_ids = counterEntities.map((v) => v.value);
    }

    if (reviewStatus) {
      data.review_status = reviewStatus;
    }

    if (declineReason) {
      data.decline_reason = declineReason;
    }

    if (organizations) {
      data.workspace_ids = organizations.map((v) => v.value);
    }

    data.is_created_by_ucbi_reporter = isCreatedByUcbiReporter;

    let body = {
      new_narrative: data,
      existing_narrative_id: null,
      story_ids: messagesList,
    };

    API.fetch(
      'POST',
      `/API/v1/narratives/${narrativeId}/copy_from_stories`,
      null,
      body,
    ).then();
  };

  const nerTypesList = [{ value: 'KEYWORDS', label: t('Matched keywords') }];

  if (showNer) {
    nerTypesList.push(
      { value: 'PERSON', label: t('Persons') },
      { value: 'ORGANIZATION', label: t('Organizations') },
      { value: 'LOCATION', label: t('Locations') },
    );
  }

  const [highlights, setHighlights] = useState(['KEYWORDS']);
  const [modalHighlights, setModalHighlights] = useState([]);
  const [highlightsMenu, setHighlightsMenu] = useState(false);
  
  useEffect(() => {
    ampli.track({
      event_type: 'Open Stories tab',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
        narrative_id: narrative?.id,
      }
    });
  }, []);

  if (!narrative) {
    return <Loader />;
  }

  return (
    <>
      {searchPanel ? searchPanel : ''}
      {error ? (
        <div className={styles.noData}>{t(error)}</div>
      ) : !messages ? (
        <Loader />
      ) : messages?.objects?.length > 0 ? (
        <table className={messageStyles.extendedMessages}>
          <thead>
            <tr>
              <td className="message-controlls">
                {currentUser?.is_super_admin === true ||
                (!isShare &&
                  ((narrative.is_threat &&
                    narrative.review_status === 'PENDING') ||
                    !narrative.is_threat)) ? (
                  <CheckboxField
                    onChange={() => toggleAll(messages, selectedMessages)}
                    checked={areAllIdsSelected(
                      messages?.objects,
                      selectedMessages,
                      true,
                    )}
                  />
                ) : (
                  ''
                )}
              </td>
              <td className={messageStyles.headerInfo}>
                <span>
                  {t('Total stories')}: {formatNumber(messages.total)}
                </span>
              </td>
              <td className="message-controlls">
                <DropdownMenu
                  buttonName={t('Highlights')}
                  isOpen={highlightsMenu}
                  onClick={() => setHighlightsMenu(!highlightsMenu)}
                  menuClassName="highlights"
                  icon={<ChevronIcon />}
                >
                  <Multichoice
                    itemsList={nerTypesList}
                    selected={highlights}
                    onChange={(selectedList) => {
                      setHighlights(selectedList);

                      ampli.track({
                        event_type: 'Change stories highlights settings',
                        event_properties: {
                          user_id: currentUser?.id,
                          workspace_id: currentUser?.workspace_id,
                          narrative_id: narrative.id,
                          highlights: selectedList,
                        },
                      });
                    }}
                  />
                </DropdownMenu>
              </td>
            </tr>
          </thead>
          <tbody>
            {messages?.objects.map((message) => {
              let followers_cnt = message?.source?.audience;
              let textClass = messageStyles.extendedMessage;

              if (message.error && message.isTranslation) {
                textClass = `${messageStyles.extendedMessage} ${messageStyles.error}`;
              } else if (message.error && !message.isTranslation) {
                textClass = messageStyles.extendedMessage;
              }

              const textForTranslation = message.highlighted_summary ? replaceNewlinesWithBreaks(message.highlighted_summary) : replaceNewlinesWithBreaks(message.summary);

              return (
                <tr key={message.id}>
                  <td className="message-controlls">
                    {currentUser?.is_super_admin === true ||
                    (!isShare &&
                      ((narrative.is_threat &&
                        narrative.review_status === 'PENDING') ||
                        !narrative.is_threat)) ? (
                      <CheckboxField
                        checked={message.id in selectedMessages}
                        onChange={() => toggleSelected(message.id)}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    <div className={messageStyles.messageInfoWrapper}>
                      <div className={messageStyles.messageInfo}>
                        <div
                          className={`${messageStyles.status} ${
                            statusMapping[message.status].class
                          }`}
                        >
                          {t(statusMapping[message.status].name)}
                        </div>
                        <div className={messageStyles.date}>
                          <span className={messageStyles.anchor}>
                            <Tooltip
                              content={t('Publication date')}
                              position="bottom"
                            >
                              <CalendarIcon />
                            </Tooltip>
                          </span>
                          {format(
                            parseISO(message.first_date + 'Z'),
                            'dd LLL yyyy HH:mm',
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          'open-message-popup ' + messageStyles.modalIcon
                        }
                      >
                        {deduplication &&
                        message.deduplicated_messages !== null ? (
                          <div className={messageStyles.totalDeduplicated}>
                            Total messages:{' '}
                            <span
                              className={messageStyles.totalDeduplicatedValue}
                            >
                              {formatNumber(message.messages_count)}
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                        <ExpandIcon
                          onClick={() => {
                            ampli.track({
                              event_type: 'Open story messages in a pop-up',
                              event_properties: {
                                user_id: currentUser?.id,
                                workspace_id: currentUser?.workspace_id,
                                narrative_id: narrative.id,
                              },
                            });
                            setInfoModal({
                              isActive: true,
                              id: message.id,
                              title: message.summary.substring(-1, 50),
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className={messageStyles.messageContainer}>
                      <div className={messageStyles.messageMetricsWrapper}>
                        <div className={messageStyles.messageMetrics}>
                          {message.sentiment_score !== null 
                            ? <Sentiment
                                sentimentScore={message.sentiment_score}
                                messageId={message.id}
                                narrative={narrative}
                                isShare={isShare}
                                isStories={true}/> : ''}
                          <span className={messageStyles.anchor}>
                            <Tooltip content={t('Views')} position="bottom">
                              <ViewsIcon />
                            </Tooltip>
                            {formatNumberSignificant(message.impressions_sum)}
                          </span>
                          <span className={messageStyles.anchor}>
                            <Tooltip content={t('Reactions')} position="bottom">
                              <EngagementIcon />
                            </Tooltip>
                            {formatNumberSignificant(message.engagement_sum)}
                          </span>
                          {showMessageManipulationIndex ? <span className={messageStyles.anchor}>
                            <Tooltip
                              content={t('Manipulation')}
                              position="bottom"
                            >
                              <ManipulationIcon />
                            </Tooltip>

                            {message.manipulation_index_avg
                              ? message.manipulation_index_avg.toFixed(2)
                              : '0'}
                          </span> : ''}
                        </div>
                      </div>

                      <div className={messageStyles.textBlock}>
                        {message.isTranslation === true &&
                        !message.translated ? (
                          <div>
                            <LoaderSmall />
                          </div>
                        ) : (
                          <ExpandableText
                            length={100}
                            highlights={highlights}
                            text={
                              message.isTranslation
                                ? message.translated
                                : message.highlighted_summary || message.summary
                            }
                            textClassName={textClass}
                            onExpand={(expanded) => {
                              ampli.track({
                                event_type: expanded
                                  ? 'Expand story message in-place'
                                  : 'Collapse story message in-place',
                                event_properties: {
                                  user_id: currentUser?.id,
                                  workspace_id: currentUser?.workspace_id,
                                  narrative_id: narrative.id,
                                },
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="message-controlls">
                    {isShare ? (
                      ''
                    ) : (
                      <>
                        {currentUser?.is_super_admin === true ||
                        (!isShare &&
                          ((narrative.is_threat &&
                            narrative.review_status === 'PENDING') ||
                            !narrative.is_threat)) ? (
                          <div className={styles.controls}>
                            <button
                              className={`${messageStyles.approve} btn-primary`}
                              disabled={
                                message.status === 'DELETED' ? true : false
                              }
                              onClick={() => {
                                let status =
                                  message.status === 'NEW' ? 'READ' : 'NEW';
                                let messageIds = [message.id];
                                patchMessages(
                                  narrative,
                                  status,
                                  messageIds,
                                  deduplication,
                                ).then(() => {
                                  dispatch({ type: 'PATCH_MESSAGES' });
                                  getStories(
                                    narrative,
                                    searchQuery,
                                    start_date,
                                    end_date,
                                    statuses,
                                    sorting,
                                    page,
                                  );
                                });
                              }}
                            >
                              {message.status === 'NEW' ? (
                                <ApproveIcon />
                              ) : (
                                <EnvelopeOpenIcon />
                              )}
                            </button>
                            <button
                              className={`${messageStyles.delete} btn-danger`}
                              onClick={() => {
                                let messageIds = [message.id];
                                if (message.status === 'DELETED') {
                                  patchMessages(
                                    narrative,
                                    'NEW',
                                    messageIds,
                                    deduplication,
                                  ).then(() => {
                                    dispatch({ type: 'PATCH_MESSAGES' });
                                    getStories(
                                      narrative,
                                      searchQuery,
                                      start_date,
                                      end_date,
                                      statuses,
                                      sorting,
                                      page,
                                    );
                                  });
                                } else {
                                  deleteMessages(
                                    narrative,
                                    messageIds,
                                    deduplication,
                                    !isOpinions,
                                  ).then(() => {
                                    dispatch({ type: 'DELETE_MESSAGES' });
                                    getStories(
                                      narrative,
                                      searchQuery,
                                      start_date,
                                      end_date,
                                      statuses,
                                      sorting,
                                      page,
                                    );
                                  });
                                }
                              }}
                            >
                              {message.status === 'DELETED' ? (
                                <RecoverIcon />
                              ) : (
                                <DeleteIcon />
                              )}
                            </button>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}

                    <div className={messageStyles.translationWrapper}>
                      <span
                        className={
                          message?.isTranslation ? '' : messageStyles.active
                        }
                        onClick={() =>
                          handleTranslation(message.id, textForTranslation, false)
                        }
                      >
                        <OriginalTextIcon />
                      </span>
                      <span
                        className={
                          message?.isTranslation ? messageStyles.active : ''
                        }
                        onClick={() =>
                          handleTranslation(message.id, textForTranslation, true)
                        }
                      >
                        <TranslationIcon />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className={messageStyles.noData}>
          {t('There is no data to display')}
        </div>
      )}
      {!narrative.parameters.create_stories &&
      messages?.objects?.length === 0 ? (
        <div className={styles.createStories}>
          <button
            type="button"
            className="new-button"
            onClick={() => {
              createStories(narrative);
            }}
          >
            {t('Create stories')}
          </button>
        </div>
      ) : (
        ''
      )}

      {showStoriesMessage ? (
        <div className={styles.createStories}>
          {t(
            'Please, wait till our AI builds stories for this case. This takes 5 mins on average.',
          )}
        </div>
      ) : (
        ''
      )}

      {messages?.objects?.length > 0 ? (
        <Paginator
          size={messages.size}
          page={messages?.page}
          total={messages?.total}
          onPageChange={(newPage) => {
            setPage(newPage);
            dispatch({ type: 'TOGGLE_TAB' });
            getStories(
              narrative,
              searchQuery,
              start_date,
              end_date,
              statuses,
              sorting,
              newPage,
            );
          }}
        />
      ) : (
        ''
      )}

      {infoModal.isActive ? (
        <NarrativeStoriesModal
          infoModal={infoModal}
          narrative={narrative}
          onChange={(bool) => {
            setInfoModal({ isActive: bool, id: null, title: null });
          }}
          onAddToCase={(storyId) => {
            setInfoModal({ isActive: false, id: null, title: null });
            setAddToNarrativeModal({ isActive: true, storyId: storyId });
          }}
          onAddToThreat={(storyId) => {
            setInfoModal({ isActive: false, id: null, title: null });
            setAddToThreatModal({ isActive: true, storyId: storyId });
          }}
        />
      ) : (
        ''
      )}
      {addToNarrativeModal.isActive ? (
        <AddToNarrativeModal
          isOpen={addToNarrativeModal}
          messages={addToNarrativeModal.storyId}
          deduplication={deduplication}
          caseId={narrative.id}
          onChange={(bool) => {
            setAddToNarrativeModal(bool);
          }}
        />
      ) : (
        ''
      )}

      {addToThreatModal.isActive ? (
        <ThreatEditModal
          isOpen={addToThreatModal}
          title={t('Add to a threat')}
          threat={narrative}
          isAdmin={currentUser?.is_super_admin}
          onChange={(bool) =>
            setAddToThreatModal({ isActive: bool, storyId: null })
          }
          handleThreat={({
            threat,
            threatName,
            threatDescription,
            caseType,
            incidentType,
            topics,
            geoScope,
            narratives,
            campaigns,
            counterEntities,
            reviewStatus,
            declineReason,
            organizations,
            isCreatedByUcbiReporter,
            threatPublicNameEn,
            threatPublicNameUk,
            threatPublicDescriptionEn,
            threatPublicDescriptionUk,
            threatIsPublic,
          }) =>
            saveToThreat(
              threat.id,
              threatName,
              threatDescription,
              caseType,
              incidentType,
              topics,
              geoScope,
              narratives,
              campaigns,
              counterEntities,
              reviewStatus,
              declineReason,
              organizations,
              isCreatedByUcbiReporter,
              threatPublicNameEn,
              threatPublicNameUk,
              threatPublicDescriptionEn,
              threatPublicDescriptionUk,
              threatIsPublic,
              addToThreatModal.storyId,
            )
          }
        />
      ) : (
        ''
      )}
    </>
  );
};
