import { useTranslation } from 'react-i18next';
import { API } from '../../../API';
import Async from 'react-select/async';
import { useEffect, useState, useCallback, startTransition } from 'react';

export const CountryChooser = ({onChange, selected = [], onClose}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleInputChanged = (input, { action }) => {
    if (action === "set-value") {
      return;
    }

    setDefaultOptions([]);
    setSearchKey(input);
  };

  const getAllCountries = useCallback(() => {
    API.fetch('GET', `/API/v1/countries?page=1&size=100`).then((data) => {
      setCountries(data.objects.slice(0, 10).map(country => {return {value: country.id, label: country.name}}))
    });
  }, []);

  useEffect(getAllCountries, [getAllCountries]);
  

  const getCountries = (inputValue, callback) => {
    return API.fetch('GET', `/API/v1/countries?q=${inputValue}`).then((data) => {
      let optionsList = data.objects
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((source) => {
          return {value: source.id, label: source.name};
        })
        setDefaultOptions(optionsList)
      callback(
        optionsList
      );
    });
  };

  let countriesPromiseTimeout;
  const countriesPromiseOptions = function (inputValue) {
    if (countriesPromiseTimeout !== null) {
      clearTimeout(countriesPromiseTimeout);
    }

    if (! inputValue) {
        return [];
    }

    return new Promise((resolve) => {
      countriesPromiseTimeout = setTimeout(() => {
        getCountries(inputValue, resolve);
      }, 1000);
    });
  }

  if (!countries) return

  return(
    <div className='chooser country-search-filter'>
      <Async
        className="select-filter"
        classNamePrefix="select-filter"
        cacheOptions
        defaultOptions={searchKey ? defaultOptions : countries}
        isMulti={true}
        loadOptions={countriesPromiseOptions}
        placeholder={t('Please enter at least 1 symbol')}
        noOptionsMessage={()=> t('Please enter at least 1 symbol')}
        loadingMessage={() => t('Loading...')}
        name="countries"
        value={selected}
        onChange={(values) => startTransition(() => onChange(values))}
        onMenuClose={() => onClose()}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        inputValue={searchKey}
        onInputChange={handleInputChanged}
      />
    </div>
  )
}