import { useCallback, useRef, useState, useEffect } from 'react';
import { API, ShareAPI } from '../../../../API';
import { TrendChart } from '../../../../components/TrendChart/TrendChart';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { palette } from '../../../../utils/colors';
import { buildImageUrl } from '../../../../utils/downloadChart';
import Select from 'react-select';
import { ampli } from '../../../../ampli';
import { useCurrentUser } from '../../../../contexts/CurrentUser';
import { DropdownMenu } from '../../../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../../../components/DropdownMenu/Radiobutton';
import { ButtonsList } from '../../../../components/DropdownMenu/ButtonsList';
import { ReactComponent as SettingsIcon } from '../../../../assets/settings.svg';
import styles from '../../../NarrativePage/NarrativePage.module.scss';

export const EvolutionChart = ({ report, mode, isShare }) => {
  
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const trendChartRef = useRef();
  const trendChartCanvasRef = useRef();
  const [narrativeShares, setNarrativeShares] = useState(null);

  const [statsAggregationOptions] = useState(() => [
    { value: 'DAY', label: t('Day') },
    { value: 'WEEK', label: t('Week') },
    { value: 'MONTH', label: t('Month') },
  ]);
  const [statsBreakdownOptions] = useState(() => [
    { value: null, label: t('All') },
    { value: 'PLATFORM', label: t('Platform') },
    { value: 'COUNTRY', label: t('Country') },
    { value: 'ACTOR_GROUP', label: t('Actor group') },
  ]);

  const [viewOptions] = useState(() => [
    { value: 'STANDARD', name: t('Standard') },
    { value: 'VALUES', name: t('Values') },
    { value: 'POINTS', name: t('Points') },
  ]);

  const mapViewOptions = {
    STANDARD: { value: 'STANDARD', name: t('Standard') },
    VALUES: { value: 'VALUES', name: t('Values') },
    POINTS: { value: 'POINTS', name: t('Points') },
  };

  const [chartViewOptions, setChartViewOptions] = useState(
    () => viewOptions[0],
  );
  const [statsBreakdown, setStatsBreakdown] = useState(
    () => statsBreakdownOptions[0],
  );
  const [statsAggregation, setStatsAggregation] = useState(
    () => statsAggregationOptions[0],
  );
  const [isTrendChartMenuOpen, setIsTrendChartMenuOpen] = useState(false);

  let chartTitle;
  if (mode === 'publications') {
    chartTitle = t('Evolution: Messages');
  } else if (mode === 'impressions') {
    chartTitle = t('Evolution: Views');
  } else if (mode === 'engagement') {
    chartTitle = t('Evolution: Reactions');
  } else if (mode === 'manipulation_index') {
    chartTitle = t('Evolution: Manipulation index');
  } else if (mode === 'narrative_share') {
    chartTitle = t('Evolution: Percentage');
  }

  const fetchNarrativesShare = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    if (!report) return;

    const urlParams = new URLSearchParams();
    report.parameters.narrative_ids.forEach((narrative_id) =>
      urlParams.append('narrative_id', narrative_id),
    );
    if (report.parameters.start_date !== null) {
      urlParams.set(
        'start',
        report.parameters.start_date.split('T')[0] + 'T00:00:00'
      );
    }
    if (report.parameters.end_date !== null) {
      urlParams.set(
        'end',
        report.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    urlParams.set('aggregation', 'DAY');

    api
      .fetch(
        'GET',
        `/API/v1/stats/narratives/shares?${urlParams.toString()}`,
        null,
        null,
        null,
      )
      .then(data => {
        data.objects.sort((a, b) => (a.id - b.id));
        setNarrativeShares(data);
      });
  }, [isShare, report]);

  useEffect(fetchNarrativesShare, [fetchNarrativesShare]);


  if (!narrativeShares) {
    return <Loader />;
  }

  const aggregationSelect = (
    <Select
      className="chart-select"
      onChange={(data) => {
        ampli.track({
          event_type: 'Change granularity in evolution chart',
          event_properties: {
            user_id: currentUser?.id,
            workspace_id: currentUser?.workspace_id,
            narrative_id: report.id,
            granularity: data.label,
          },
        });
        setStatsAggregation(data);
      }}
      value={statsAggregation}
      options={statsAggregationOptions}
    />
  );
  const breakdownSelect = (
    <Select
      className="chart-select"
      onChange={(data) => {
        ampli.track({
          event_type: 'Change breakdown in evolution chart',
          event_properties: {
            user_id: currentUser?.id,
            workspace_id: currentUser?.workspace_id,
            narrative_id: report.id,
            breakdown: data.label,
          },
        });
        setStatsBreakdown(data);
      }}
      value={statsBreakdown}
      options={statsBreakdownOptions}
    />
  );

  const narrativeColor = Object.fromEntries(
    narrativeShares?.objects?.map((narrative, i) => [
      narrative.id,
      palette[i % palette.length],
    ]),
  );

  return (
    <div className="list-content">
      <div className="report-section">
        <h3>{chartTitle}</h3>
        <div className="card">
          <div className={'card-header ' + styles.cardHeader}>
            <div className={'card-controlls ' + styles.cardControls}>
              {/* {breakdownSelect}{aggregationSelect} */}
              <DropdownMenu
                icon={<SettingsIcon />}
                isOpen={isTrendChartMenuOpen}
                onClick={() => setIsTrendChartMenuOpen(!isTrendChartMenuOpen)}
                menuClassName="chartMenu"
                header={t('Chart view')}
              >
                <Radiobutton
                  itemsList={viewOptions}
                  current={chartViewOptions.value}
                  onChange={(value) =>
                    setChartViewOptions(mapViewOptions[value])
                  }
                />

                <ButtonsList
                  itemsList={[
                    { value: 'PNG', name: t('Download as PNG') },
                    { value: 'SVG', name: t('Download as SVG') },
                  ]}
                  header={t('Download chart')}
                  onClick={(value) => {
                    let name = `${report.name}-evolution-chart-${statsBreakdown.label}-${statsAggregation.label}`;
                    buildImageUrl(
                      trendChartRef,
                      trendChartCanvasRef,
                      value,
                      name,
                    );
                  }}
                />
              </DropdownMenu>
            </div>
          </div>
          <div className="card-body">
            {!narrativeShares ? (
              <Loader />
            ) : (
              <TrendChart
                rawdata={narrativeShares?.objects}
                metric={mode}
                showLegend={true}
                narrativeColor={narrativeColor}
                view={chartViewOptions}
                svgRef={trendChartRef}
                canvasRef={trendChartCanvasRef}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
