import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';

import './TrendChart.scss';

const parseDate = d3.timeFormat('%B %d, %Y');
const parseDateShort = d3.timeFormat('%d.%m');
let formatNumber = d3.format(',d');
let formatDecimal = d3.format(',.2f');
const formatNumberSignificant = d3.format('.3~s',);

let margin = {
  top: 50,
  right: 70,
  bottom: 70,
  left: 70,
};

const svgStyles = {
  backgroundColor: 'white',
  overflow: 'visible',
};

export function TrendChart({rawdata, metric, cumulative, relative, showLegend = false, narrativeColor, view, svgRef, canvasRef }) {
  const parentWrapper = useRef(null);
  const uuid = 'a' + Math.floor(Math.random() * 10000);

  const [width, setWidth] = useState(600);
  const [height] = useState(400 - margin.top - margin.bottom);

  useEffect(() => {
    if (
      !parentWrapper.current ||
      typeof window.ResizeObserver === 'undefined'
    ) {
      return;
    }

    const resizeObserver = new window.ResizeObserver((entries) => {
      const rootEntry = entries.find((e) => e.target === parentWrapper.current);

      if (!rootEntry) {
        return;
      }

      setWidth(rootEntry.contentRect.width - margin.left - margin.right);
      // setHeight(rootEntry.contentRect.height);
    });

    resizeObserver.observe(parentWrapper.current);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    d3.select(svgRef.current).selectAll('*').remove();

    // PREPARE DATA
    let data = [];
    let narrative = rawdata[1];
    let length = narrative?.stats?.length;
    let sumArray = Array(length).fill(0);

    const narrativeTitles = {};

    rawdata.forEach((narrative) => {
      let sum = 0;
      for (let i = 0; i < narrative.stats?.length; i++) {
        sum += narrative.stats[i][metric];
        sumArray[i] += cumulative ? sum : narrative.stats[i][metric];

        narrativeTitles[narrative.id] = narrative.name;

        data.push({
          date: new Date(narrative.stats[i].date_publicated),
          parseddate: parseDate(new Date(narrative.stats[i].date_publicated)),
          metric: cumulative ? sum : narrative.stats[i][metric],
          narrative: narrative.name,
          narrativeid: narrative.id,
        });
      }
    });
    if (relative) {
      data.forEach((d, i) => {
        if (sumArray[i % length] !== 0) {
          d.metric = (100 * d.metric) / sumArray[i % length];
        } else {
          d.metric = 50;
        }
      });
    }

    data.sort((a, b) => a.narrativeid - b.narrativeid || a.date - b.date);

    let sumstat = d3.group(data, (d) => d.narrativeid);

    const svg = d3
      .select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    let svgposition = svgRef.current.getBoundingClientRect();

    // RANGES
    let xScale = d3.scaleUtc().range([15, width - 60]);
    let yScale = d3.scaleLinear().range([height, 0]);
    xScale.domain(d3.extent(data, (d) => d.date));
    yScale.domain([0, d3.max(data, (d) => d.metric + (d.metric / 5))]);

    // let line1 = d3
    //   .line()
    //   .x((d) => xScale(d.date))
    //   .y((d) => {
    //     return yScale(d.metric);
    //   });
    // let line2 = d3
    //   .line()
    //   .x((d) => xScale(d.date))
    //   .y((d) => {
    //     return yScale(d.metric * 1.5);
    //   });
    // let line3 = d3
    //   .line()
    //   .x((d) => xScale(d.date))
    //   .y((d) => {
    //     return yScale(d.metric * 0.5);
    //   });
    // let lines = [line1];

    // Y TICKS
    svg.selectAll('.grid').remove();
    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(-width)
          .ticks(6)
          .tickPadding(15)
          .tickSizeOuter(0)
      )
      .call(g => g.selectAll(".tick")
      .attr('color', '#a0a3a6'))
      .call(g => g.selectAll(".tick line")
          .attr("stroke-opacity", 0.7)
          .attr('stroke', '#e7e8e9')
          .style('shape-rendering', 'crispEdges')
      )
      .call(g => g.selectAll(".grid path")
        .attr("stroke-width", 0)
      )

    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        d3
          .axisBottom(xScale)
          .tickSize(height)
          .tickFormat(d3.timeFormat('%d.%m.%y'))
          .tickPadding(15)
          .ticks(7),
      )
      .call(g => g.selectAll(".tick")
          .attr('color', '#a0a3a6'))
      .call(g => g.selectAll(".tick line")
          .attr("stroke-opacity", 0.7)
          .attr('stroke', '#e7e8e9')
          .style('shape-rendering', 'crispEdges')
      )
      .call(g => g.selectAll(".grid path")
          .attr("stroke-width", 0)
      )

    // hide first
    // const tick_count = d3.selectAll('.grid .tick').size();
    // svg.selectAll('.grid .tick').each(function (d, i) {
    //   if (i == 0) {
    //     this.remove();
    //   }
    // });

    // let svgDefs = svg.append('defs');

    // let filterBlur = svgDefs
    //   .append('filter')
    //   .attr('id', 'blur')
    //   .attr('x', '0')
    //   .attr('y', '0')
    //   .append('feGaussianBlur')
    //   .attr('in', 'SourceGraphic')
    //   .attr('stdDeviation', 10);

    // let mask = svgDefs
    //   .selectAll('mask.forblur')
    //   .data(sumstat)
    //   .join('mask')
    //   .attr('id', (d, i) => 'mask' + uuid + i)
    //   .append('path')
    //   .attr('fill', '#fff')
    //   .attr('d', (d) => {
    //     return d3
    //       .area()
    //       .x(function (d) {
    //         return xScale(d.date);
    //       })
    //       .y0(height + 30)
    //       .y1(function (d) {
    //         return yScale(d.metric);
    //       })(d[1]);
    //   });

    svg
      .selectAll('path.crisp')
      .data(sumstat)
      .join('path')
      .attr('fill', 'none')
      .attr('stroke', (d) => narrativeColor[d[0]])
      .attr('stroke-width', 2)
      .attr('d', (d) => {
        return d3
          .line()
          .x((d) => xScale(d.date))
          .y((d) => yScale(d.metric))(d[1]);
      });
    // gradient
    // svg
    //   .selectAll('path.gradient')
    //   .data(sumstat)
    //   .join('path')
    //   .style('stroke', (d) => narrativeColor[d[0]])
    //   .style('opacity', '0.3')
    //   .style('stroke-width', '15')
    //   .style('fill', 'none')
    //   .attr('filter', 'url(#blur)')
    //   .attr('mask', (d, i) => 'url(#mask' + uuid + i + ')')
    //   .attr('d', (d) => {
    //     return d3
    //       .line()
    //       .x((d) => xScale(d.date))
    //       .y((d) => yScale(d.metric))(d[1]);
    //   });

    function addPoints() {
      svg.selectAll("circle").remove();
      svg.selectAll("myCircles")
      .data(data)
      .enter()
      .append("circle")
        .attr("fill", (d) => narrativeColor[d.narrativeid])
        .attr("stroke", 'none')
        .attr("cx", function(d) { return xScale(d.date) })
        .attr("cy", function(d) { return yScale(d.metric) })
        .attr("r", 4)

      const serie = svg.append("g")
      .selectAll()
      .data(sumstat)
      .join("g");

      serie.append("g")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr("text-anchor", "middle")
      .selectAll()
      .data(data, d => d.metric)
      .join("text")
        .text(d => metric === 'manipulation_index' ? formatDecimal(d.metric) : formatNumberSignificant(d.metric))
        .style("font-size", "10")
        .style("font-weight", 'bold')
        .style("fill", (d) => {
          return narrativeColor[d.narrativeid]
        })
        .attr("dy", "-1em")
        .attr("x", d => {
          return xScale(d.date)
        })
        .attr("y", d => yScale(d.metric))
        // .call(text => text.filter((d, i, data) => i === data.length - 1)
        //   .append("tspan")
        //     .attr("font-weight", "bold")
        //     .text(d => ` ${(d.metric)}`))
        //     .style("font-size", "10")
        //     .style("font-weight", 'bold')
        //     .style("fill", (d) => {
        //       return narrativeColor[d.narrativeid]
        //     })
        .clone(true).lower()
          .attr("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-width", 6)
          .style("padding", '10px')

    }

    function addValues() {
      svg.selectAll("circle").remove();
      const serie = svg.append("g")
        .selectAll()
        .data(sumstat)
        .join("g");

      serie.append("g")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr("text-anchor", "middle")
      .attr("class", "metrics")
      .selectAll()
      .data(data, d => d.metric)
      .join("text")
        .text(d => metric === 'manipulation_index' ? formatDecimal(d.metric) : formatNumberSignificant(d.metric))
        .style("font-size", "10")
        .style("font-weight", 'bold')
        .style("fill", (d) => {
          return narrativeColor[d.narrativeid]
        })
        .attr("dy", "0.35em")
        // .attr("dy", "-1em")
        .attr("x", d => {
          return xScale(d.date)
        })
        .attr("y", d => yScale(d.metric))
        // .call(text => text.filter((d, i, data) => i === data.length - 1)
        //   .append("tspan")
        //     .attr("font-weight", "bold")
        //     .text(d => ` ${(d.metric)}`))
        //     .style("font-size", "10")
        //     .style("font-weight", 'bold')
        //     .style("fill", (d) => {
        //       return narrativeColor[d.narrativeid]
        //     })
        .clone(true).lower()
          .attr("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-width", 6)
          .style("padding", '10px')
    }

    function updateView() {
      if(view.value === 'VALUES') {
        addValues()
      } else if (view.value === 'POINTS') {
        addPoints()
      } else {
        return
      }
    }

    updateView()


    let focusLine = svg
      .append('g')
      .append('line')
      .style('stroke', '#0101EF')
      .style('stroke-dasharray', '4, 4')
      .style('stroke-width', 2)
      .style('opacity', 0)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', height);
    svg
      .selectAll('circle.focus')
      .data(sumstat)
      .join('circle')
      .attr('class', (d, i) => 'focus focus' + i)
      .style('fill', '#fff')
      .attr('stroke', (d) => narrativeColor[d[0]])
      .attr('stroke-width', '2')
      .attr('r', 4)
      .style('opacity', 0)
      .style('pointer-events', 'none');

    let textWidth = [];
    let sum = 0;
    let maxLength = 35;
    svg
      .append('g')
      .selectAll('.dummyText')
      .data(sumstat)
      .enter()
      .append('text')
      .attr('font-family', 'sans-serif')
      .attr('font-size', '14px')
      //.attr("opacity", 0.0)
      .text(function (d) {
        return (
          narrativeTitles[d[0]].slice(0, maxLength).trim() +
          (narrativeTitles[d[0]].length > maxLength ? '...' : '')
        );
      })
      .each(function (d, i) {
        var thisWidth = this.getComputedTextLength();
        textWidth.push(thisWidth);
        this.remove();
      });
    let positions = [];

    textWidth.forEach((t) => {
      positions.push(sum);
      sum += t + 30;
    });

    if (showLegend) {
      let legend = svg
        .selectAll('g.legend')
        .data(sumstat)
        .enter()
        .append('g')
        .attr('transform', (d, i) => `translate(${positions[i]},-30)`)
        .append('circle')
        .attr('class', (d, i) => 'legend legend' + i)
        .style('fill', '#fff')
        .attr('stroke', (d) => narrativeColor[d[0]])
        .attr('stroke-width', '2')
        .attr('r', 4)
        .select(function () {
          return this.parentNode;
        })
        .append('text')
        .text((d) => {
          return (
            narrativeTitles[d[0]].slice(0, maxLength).trim() +
            (narrativeTitles[d[0]].length > maxLength ? '...' : '')
          );
        })
        .style('fill', '#2C2E30')
        .style('font-family', 'sans-serif')
        .style('font-size', '14px')
        .attr('text-anchor', 'left')
        .attr('x', 10)
        .attr('y', 4);
    }

    // end of loop

    // MOUSE MOVE PART!!
    //========================
    //========================
    //========================
    //========================
    svg
      .append('rect')
      .style('fill', 'none')
      .style('pointer-events', 'all')
      // .attr('x', margin.left)
      // .attr('y', margin.top)
      .attr('width', width + 50)
      .attr('height', height + 50)

      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseout);

    // Create the circle that travels along the curve of chart
    let dataBackground = svg.append('g').style('opacity', '0');
    let dataBackgroundRect = dataBackground
      .append('rect')
      .style('pointer-events', 'none')
      .attr('rx', 6)
      .attr('ry', 6)
      .attr('x', -12.5)
      .attr('y', -20)
      .attr('width', 100)
      .attr('height', sumstat.size * 25 + 15 + 15)
      .style('box-shadow', '0 0 10px #000')
      .attr('transform', function (d, i) {
        if (d) {
          return 'scale(' + (1 - d / 25) * 20 + ')';
        } else {
          return 'scale(1)';
        }
      })
      .style('fill', '#fff')
      .style('stroke', '#e7e8e9')
      .style('stroke-width', '1');

    let dataBackgroundDate = dataBackground
      .append('rect')
      .style('pointer-events', 'none')
      .attr('rx', 1)
      .attr('ry', 1)
      .attr('x', -12)
      .attr('y', -19.5)
      .attr('width', 100)
      .attr('height', 25)
      .style('fill', '#2762ea');

    let dataBackgroundDateText = dataBackground
      .append('text')
      .text('Date')
      .attr('x', -4)
      .attr('y', 18 - 20)
      .style('font-family', 'sans-serif')
      .attr('text-anchor', 'left')
      .attr('font-size', 14)
      .attr('fill', '#fff');

    dataBackground
      .selectAll('circle.legend')
      .data(sumstat)
      .join('circle')

      .style('fill', '#fff')
      .attr('stroke', (d) => narrativeColor[d[0]])
      .attr('cy', (d, i) => i * 20 + 20)
      .attr('stroke-width', '2')
      .attr('r', 2);

    let focusTexts = dataBackground
      .selectAll('g.texts')
      .data(sumstat)
      .join('g')
      .append('text')
      .style('fill', '#A0A3A6')
      .style('font-family', 'sans-serif')
      .attr('text-anchor', 'left')
      .attr('x', '10')
      .attr('y', (d, i) => i * 20 + 25)
      .attr('alignment-baseline', 'middle')
      .style('pointer-events', 'none');

    function mouseover() {
      // d3.selectAll("circle.focus").style("opacity", 1);
      dataBackground.style('opacity', 1);
      focusLine.style('opacity', 1);
    }

    const bisect = d3.bisector((d) => d.date).right;
    let selectedData = 0;
    function mousemove(event) {
      const [firstValue] = sumstat.values();
      let x0 = xScale.invert(event.clientX - margin.left - svgposition.left);
      let i = bisect(firstValue, x0, 0);
      selectedData = data[i - 1];

      let maxwidth = 0;
      d3.select(svgRef.current)
        .selectAll('circle.focus')
        .data(sumstat)
        .style('opacity', 1)
        .attr('cx', (d, j) => {
          return d[1][i - 1] ? xScale(d[1][i - 1].date) : 0;
        })
        .attr('cy', (d, j) => {
          return  d[1][i - 1] ? yScale(d[1][i - 1].metric) : 0;
        });

      focusTexts
        .attr('cx', () => 0)
        .html((d) =>
          relative
            ? formatDecimal(d[1][i - 1] ? d[1][i - 1].metric : 0) + '%'
            : metric === 'manipulation_index'
                  ? formatDecimal(d[1][i - 1] ? d[1][i - 1].metric : 0)
                  : formatNumberSignificant(d[1][i - 1] ? d[1][i - 1].metric : 0),
        )
        .each(function (d, i) {
          if (this.getComputedTextLength() > maxwidth) {
            maxwidth = this.getComputedTextLength();
          }
        });

      focusLine.attr('transform', `translate(${xScale(selectedData ? selectedData?.date : 0)},0)`);
      // focusText.html(selectedData.metric);

      const dataBackgroundYOffset = (yScale(selectedData ? selectedData?.metric : 0) > height - sumstat.size * 12.5) ? sumstat.size * 15 : 10;

      dataBackground.attr(
        'transform',
        `translate(${30 + xScale(selectedData ? selectedData?.date : 0)},${
          yScale(selectedData ? selectedData?.metric : 0) - dataBackgroundYOffset
        })`,
      );
      dataBackgroundRect.attr('width', maxwidth + 35);
      dataBackgroundDate.attr('width', maxwidth + 35 - 1);
      dataBackgroundDateText.text(parseDateShort(selectedData ? selectedData?.date : 0));
    }
    function mouseout() {
      d3.selectAll('circle.focus').style('opacity', 0);
      focusLine.style('opacity', 0);
      dataBackground.style('opacity', 0);
    }

    // end of d3
  }, [rawdata, width, metric, view]);

  return (
    <div className="trendchart" ref={parentWrapper}>
      <svg width={width} height={height} ref={svgRef} style={svgStyles}></svg>
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    </div>
  );
}
