import { ampli } from '../../ampli';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { useTranslation } from 'react-i18next';
import { format, sub } from 'date-fns';
import { useState, useEffect, useRef} from 'react';
import { SummaryMainCard } from './SummaryMainCard';
import { SummaryCardSmall } from './SummaryCardSmall';
import { metricsMap } from '../../utils/metrics';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { API, ShareAPI } from '../../API';
import { WidgetContentModal } from '../WidgetContentModal/WidgetContentModal';


import styles from './SummaryPanel.module.scss';

export const SummaryPanel = ({
  narrativeIds,
  report=null,
  tabsList = ['actors', 'publications', 'views', 'reactions'],
  isPDF = false,
  isShare,
  isStory=false,
  sourceGroups,
  countries,
  startDate,
  endDate,
  summaryPanelType='standard'
}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const abortControllerRef = useRef();
  const [summary, setSummary] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [modal, setModal] = useState({
    isVisible: false,
    contentType: null,
    actorType: null,
    tabIndex: 0,
    influential: false,
  });

  const clickableActorTypes = [
    'disinfo_spreaders',
    'inauthentic',
    'influence_operations',
    'state_affiliated',
    'influential',
  ];
  const clickableContentTypes = [
    'social_media_original_posts',
    'social_media_reposts',
    'media_articles',
    'videos',
    'comments',
    'ads',
    'chat_forum_messages',
  ];

  const actorsTypeMap = {
    disinfo_spreaders: {
      type: { value: 'discreditated', bool: true },
      subType: 'DISINFORMATION',
    },
    inauthentic: { type: { value: 'is_bot', bool: true }, subType: null },
    influence_operations: {
      type: { value: 'discreditated', bool: true },
      subType: 'INVOLVED_IN_INFLUENCE_OPERATIONS',
    },
    state_affiliated: {
      type: { value: 'state_affiliated', bool: true },
      subType: null,
    },
    influential: [100000, 1000000000],
  };

  const contentTypeMap = {
    social_media_original_posts: { type: 'POST', subType: null },
    social_media_reposts: { type: 'REPOST', subType: null },
    media_articles: { type: 'ARTICLE', subType: null },
    videos: { type: 'VIDEO', subType: null },
    comments: { type: 'COMMENT', subType: null },
    ads: { type: 'AD', subType: null },
    chat_forum_messages: { type: 'MESSAGE', subType: null },
  };

  const actorsTypesOrder = [ 'influential', 'state_affiliated', 'disinfo_spreaders', 'inauthentic'];

  const fetchSummary = () => {
    const api = isShare ? ShareAPI : API;
    const urlParams = new URLSearchParams();

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    if (!isStory) {
      narrativeIds.forEach((narrative_id) =>
        urlParams.append('narrative_ids', narrative_id),
    )}

    let url = `/API/v1/dashboard/narratives/summary?${urlParams.toString()}`;
    
    if (isStory) {
      if(sourceGroups.length > 0) {
        sourceGroups.forEach((sourceGroup) => {
          urlParams.append('source_group_ids', sourceGroup.value);
        });
      }
      if(countries.length > 0) {
        countries.forEach((country) => {
          urlParams.append('source_country_ids', country.value);
        });
      }
      if(startDate) {
        urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
      }
      if(endDate) {
        urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
      }
      url = `/API/v1/topic-modeling/${report.id}/events/${narrativeIds[0]}/summary?${urlParams.toString()}`;
    }
    api.fetch('GET', url, null, null, signal)
    .then((data) => {
      setSummary(data);
    });
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  if (!summary) {
    return (
      <div className={`${styles.summaryPanel} ${styles.loader}`}>
        <LoaderSmall />
      </div>
    );
  }

  const value = summary[tabsList[tabIndex]]?.breakdown || {};

  let currentBreakdown = Object.keys(value)
    .filter((metric) => value[metric].total)
    .reduce((obj, key) => {
      obj[key] = value[key];
      return obj;
    }, {});

  if (tabsList[tabIndex] === 'actors') {
    currentBreakdown = actorsTypesOrder.reduce((result, key) => {
      if (value[key] !== undefined) {
          result[key] = value[key];
      } else {
          result[key] = {total: 0};
      }
      return result;
    }, {});
  }

  let customTabsList = [
    {
      title: 'publications',
      total: summary['publications'].total,
      modalSettings: {
        isVisible: true,
        contentType: null,
        actorType: null,
        tabIndex: 1,
      },
    },
    {
      title: 'allActors',
      total: summary['actors'].total,
      modalSettings: {
        isVisible: true,
        contentType: null,
        actorType: null,
        tabIndex: 0,
      },
    },
    {
      title: 'inauthenticActors',
      total: summary['actors']?.breakdown?.inauthentic?.total,
      modalSettings: {
        isVisible: true,
        contentType: null,
        actorType: { type: { value: 'is_bot', bool: true }, subType: null },
        tabIndex: 0,
      },
    },
  ];

  if (summaryPanelType === 'custom') {
    return (
      <div className={styles.summaryPanel}>
        <div className={styles.summaryPanelCards}>
          {customTabsList.map((tab, index) => {
            return (
              <SummaryMainCard
                title={tab?.title}
                data={tab?.total}
                isActive={false}
                onClick={() => {
                 setModal(tab?.modalSettings);
                }}
                key={`summary_main_card_${tab.title}`}
              />
            );
          })}
        </div>
        {modal.isVisible ? (
          <WidgetContentModal
            infoModal={modal.isVisible}
            tabIndex={modal.tabIndex}
            isChart={true}
            platformOption={null}
            type={modal.actorType ? modal.actorType.type : null}
            subType={modal.actorType ? modal.actorType.subType : null}
            stateAffiliatedId={null}
            countryId={countries?.length > 0 ? countries : null}
            // platformsOptions={platformOptions}
            audienceRange={modal.influential ? actorsTypeMap.influential : null}
            narrative={report}
            sourceGroup={sourceGroups?.length > 0 ? sourceGroups?.map((sourceGroup) => sourceGroup?.value) : null}
            source={null}
            sentimentOptions={null}
            eventIdsOptions={isStory && report ? [narrativeIds[0]] : null}
            startDate={startDate}
            endDate={endDate}
            contentTypeOptions={modal.contentType ? modal.contentType : null}
            // isShare={isShare}
            onChange={(bool) => {
              setModal({isVisible: bool, contentType: null, actorType: null, influential: false});
            }}
          />
        ) : (
          ''
        )}
      </div>
    );
} else {
  return (
    <div className={styles.summaryPanel}>
      <div className={styles.summaryPanelCards}>
        {tabsList.map((tab, index) => {
          return (
            <SummaryMainCard
              title={tab}
              data={summary[tab].total}
              isActive={
                tabsList.length > 1 && tabIndex === index ? true : false
              }
              onClick={() => setTabIndex(index)}
              key={`summary_main_card_${tab}`}
            />
          );
        })}
      </div>
      <div
        className={
          Object.keys(currentBreakdown).length > 3
            ? styles.summaryPanelBody
            : `${styles.summaryPanelBody} ${styles.center}`
        }
      >
        {Object.keys(currentBreakdown).length > 0 ? (
          Object.entries(currentBreakdown).map(([key, value]) => {
            return (
              <SummaryCardSmall
                title={metricsMap[key]}
                data={value.total}
                key={`summary_small_card_${key}`}
                isClickable={
                  (tabsList[tabIndex] === 'actors' ||
                    tabsList[tabIndex] === 'publications') &&
                  (clickableActorTypes.includes(key) ||
                    clickableContentTypes.includes(key))
                }
                onClick={() => {
                  if (clickableActorTypes.includes(key)) {
                    if(key === 'influential') {
                      setModal({
                        isVisible: true,
                        actorType: actorsTypeMap[key],
                        influential: true,
                      })} else {
                        setModal({
                          isVisible: true,
                          actorType: actorsTypeMap[key],
                        });
                      }
                    ampli.track({
                      event_type: 'Open summary panel popup',
                      event_properties: {
                        user_id: currentUser?.id,
                        workspace_id: currentUser?.workspace_id,
                        entyty_type: isStory ? 'event' : 'narrative',
                        report_id: report?.id,
                        popup_type: 'Summary Panel',
                        popup_value: 'Actors tab: ' + key,
                      }
                    });
                  } else if (clickableContentTypes.includes(key)) {
                    setModal({
                      isVisible: true,
                      contentType: [contentTypeMap[key].type],
                      tabIndex: 1,
                    });
                    ampli.track({
                      event_type: 'Open summary panel popup',
                      event_properties: {
                        user_id: currentUser?.id,
                        workspace_id: currentUser?.workspace_id,
                        narrative_id: report?.id,
                        entyty_type: isStory ? 'story' : 'narrative',
                        popup_type: 'Summary Panel',
                        popup_value: 'Content tab: ' + key,
                      }
                    });
                  }
                }}
              />
            );
          })
        ) : isPDF ? (
          ''
        ) : (
          <div className={styles.noData}>
            {t('There is no data to display')}
          </div>
        )}
      </div>
      {modal.isVisible ? (
        <WidgetContentModal
          infoModal={modal.isVisible}
          tabIndex={modal.tabIndex}
          isChart={true}
          platformOption={null}
          type={modal.actorType ? modal.actorType.type : null}
          subType={modal.actorType ? modal.actorType.subType : null}
          stateAffiliatedId={null}
          countryId={countries?.length > 0 ? countries : null}
          // platformsOptions={platformOptions}
          audienceRange={modal.influential ? actorsTypeMap.influential : null}
          narrative={report}
          sourceGroup={sourceGroups?.length > 0 ? sourceGroups?.map((sourceGroup) => sourceGroup?.value) : null}
          source={null}
          sentimentOptions={null}
          eventIdsOptions={isStory && report ? [narrativeIds[0]] : null}
          startDate={startDate}
          endDate={endDate}
          contentTypeOptions={modal.contentType ? modal.contentType : null}
          // isShare={isShare}
          onChange={(bool) => {
            setModal({isVisible: bool, contentType: null, actorType: null, influential: false});
          }}
        />
      ) : (
        ''
      )}
    </div>
  )};
};
