import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';

import { API } from '../../../API';
import './Filter.scss';

export const TagsChooser = ({onChange, selected}) => {
  const { t } = useTranslation();

  const [tags, setTags] = useState(null);

  const fetchSourceTags = useCallback(() => {

    API.fetch('GET', `/API/v1/source_tags/public`).then(
      (data) => {
        setTags(data);
      },
    );
  }, []);

  useEffect(() => {
    fetchSourceTags()
  }, [])

  const handleSelect = () => {
    if(tags?.objects?.length !== selected.length) {
      onChange(tags?.objects?.map(i => i.full_path))
    } else {
      onChange([])
    }
  }

  return (
    <div className='chooser'>
      <div 
        className='control'  
        onClick={() => handleSelect()}>{tags?.objects?.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {tags?.objects.map(tag => {
          let tagName = null;
          let item = tag?.level_0 !== null ? tag?.level_0 : null;
          
          if(
            localStorage.getItem('i18nextLng') === 'ua-UK' ||
            localStorage.getItem('i18nextLng') === 'ru-RU' ||
            localStorage.getItem('i18nextLng') === 'ru' ||
            localStorage.getItem('i18nextLng') === 'uk'
          ) {
            tagName = item?.name_uk;
          } else {
            tagName = item?.name_en;
          }
          return(
            <li key={tagName}>
              <input 
                id={`source_${tagName}`}
                type='checkbox'
                checked={
                  selected.find(i => i === item.tag) === item.tag ? true : false
                }
                onChange={(e) => {
                  if(selected.includes(item.tag)) {
                    onChange(selected.filter(i => i !== item.tag))
                  } else {
                    onChange([...selected, item.tag])
                  }
                }}
              />
              <label htmlFor={`source_${tagName}`}>{tagName}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}
