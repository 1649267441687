import { useEffect, useState } from 'react';
import styles from './RangeSlider.module.scss';
import { useTranslation } from 'react-i18next';

export const RangeSlider = ({min, max, step, data, onChange, disabled=false}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.rangeSlider}>
        <input 
          className={styles.input} 
          type="range" 
          value={data ? data : 0.5} 
          min={min} 
          max={max} 
          step={step} 
          onChange={e => {
            onChange(e.target.value)
          }}
          disabled={disabled}
        />
        <span className={styles.value}>{data ? data : 0.5}</span>
      </div>
      <div className={styles.description}>
        <div>{t('More results, less accuracy')}</div>
        <div>{t('Less results, more accuracy')}</div>
      </div>
    </div>
  )
}

