import * as d3 from 'd3';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { decodeSourceName } from '../../utils/decodeURI';
import { palette } from '../../utils/colors';
import countriesData from '../../countriesData.json';
import  WebIcon  from '../../assets/web.svg';
import  FacebookIcon  from '../../assets/facebook.svg';
import  YoutubeIcon from '../../assets/youtube.svg';
import  VkontakteIcon  from '../../assets/vkontakte.svg';
import  TwitterIcon from '../../assets/twitter.svg';
import  TelegramIcon from '../../assets/telegram.svg';
import  OkIcon from '../../assets/odnoklassniki.svg';
import  TikTokIcon from '../../assets/tiktok-chart.svg';
import { platformNamesMapping } from '../../utils/platforms';
import {ReactComponent as OriginalTextIcon} from '../../assets/originalText.svg';
import {ReactComponent as TranslationIcon} from '../../assets/translation.svg';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { ExpandableText } from '../CollapsibleText/ExpandableText';
import { Sentiment } from '../Sentiment/Sentiment';
import { ReactComponent as ActorIcon } from '../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../pages/SearchPage/assets/person.svg';

import './CircleChartIcons.scss';
import messageStyles from '../MessagesTable/ExtendedMessageView.module.scss';
import {Link} from "react-router-dom";

export const platformIconsMapping = {
  TELEGRAM: TelegramIcon,
  VK: VkontakteIcon,
  TWITTER: TwitterIcon,
  WEB: WebIcon,
  FACEBOOK: FacebookIcon,
  ADMIXER: WebIcon,
  OK: OkIcon,
  YOUTUBE: YoutubeIcon,
  TIKTOK: TikTokIcon,
}

let formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s',);

const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

// let formatPercentage = d3.format('.1f');

export function CircleChartIcons({ data, narrative, onCircleClick = () => {}, mode, onTranslationClick, narrativeColor, isShare, id='circleChart' }) {
  const { t } = useTranslation();

  const svgWrapper = useRef(null);
  const popup = useRef(null);
  const parentWrapper = useRef(null);

  const [width, setWidth] = useState(900);
  const [height] = useState(600);
  const [currentNarrative, setCurrentNarrative] = useState('');
  const [popupOpen, togglePopup] = useState(false);
  const [isExpanded, toggleExpanded] = useState(false);
  const [shouldShowReset, setShouldShowReset] = useState(false);
  const [reference, updateReference] = useState({});
  const [hovered, setHovered] = useState(null);

  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  };

  const svgFiles = importAll(require.context('../../assets/flags', false, /\.svg$/));


  const realData = useMemo(() => {
    let realdata = [];

    data.objects.forEach((narrative, i) => {
      narrative.messages.forEach((item) => {
        item.narrative = i;
        item.narrative_id = narrative.id;

        realdata.push(item);
      });
    });

    if(mode === 'countries') {
      realdata = realdata.filter(item => {
        return item.country_code !== null
      })
    }

    return realdata;
  }, [data.objects, mode]);


  const handleResetClick = useCallback(() => {
    setShouldShowReset(false);

    updateReference({});

    void realData;
    void width;
    void height;
  }, [realData, width, height]);

  const dateScale = useMemo(() => {
    let dateRange = d3.extent(realData, function (d) {
      return new Date(d.date_publicated);
    });

    return d3
      .scaleTime()
      .domain([new Date(dateRange[0]), new Date(dateRange[1])])
      .range([0.1, 1]);
  }, [realData]);

  useEffect(() => {
    if (
      !parentWrapper?.current ||
      typeof window.ResizeObserver === 'undefined'
    ) {
      return;
    }

    const resizeObserver = new window.ResizeObserver((entries) => {
      const rootEntry = entries.find((e) => e.target === parentWrapper?.current);

      if (!rootEntry) {
        return;
      }

      setWidth(rootEntry.contentRect.width);
      // setHeight(rootEntry.contentRect.height);
    });

    resizeObserver.observe(parentWrapper?.current);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const leftOffset = parentWrapper?.current.getBoundingClientRect().left + 490;
    const topOffset = parentWrapper?.current.getBoundingClientRect().top + 20;
    
    d3.select(svgWrapper?.current).selectAll('*').remove();

    const svg = d3
      .select(svgWrapper?.current)
      .attr('width', width)
      .attr('height', height)
      .style('width', '100%')
      .style('height', 'auto')
      .attr('font-size', 10)
      .attr('font-family', 'sans-serif')
      .attr('text-anchor', 'middle');

    // svg
    //   .selectAll('circle.legend')
    //   .data(legend)
    //   .enter()
    //   .append('g')
    //   .attr('transform', (d, i) => `translate(40,${i * 30 + 20})`)
    //   .append('rect')
    //   .attr('rx', 5)
    //   .attr('y', -12)
    //   .attr('ry', 5)
    //   .attr('width', 36)
    //   .attr('height', 20)
    //   .attr('fill', palette[0])
    //   .style('opacity', (d, i) => (legend.length - i) / (legend.length + 1))

    //   .select(function () {
    //     return this.parentNode;
    //   })
    //   .append('text')
    //   .text((d) => d.title)
    //   .style('fill', '#2C2E30')
    //   .attr('text-anchor', 'start')
    //   .style('font-family', 'sans-serif')
    //   .style('font-size', '14px')
    //   .attr('x', 44)
    //   .attr('y', 3);

    let realdata = [...realData];

    // let scores = d3.extent(realdata, function (d) {
    //   return d.score;
    // });
    // let views = d3.extent(realdata, function (d) {
    //   return d.views;
    // });
    let message_share = d3.extent(realdata, function (d) {
      return d.message_share;
    });

    // let dateScore = d3.scalePow().exponent(3).domain(scores).range([20, 50]);
    // let dateScore = d3.scaleLinear().domain(views).range([20, 50]);
    let dateScore = d3.scaleLinear().domain(message_share).range([15, 50]);

    realdata = realdata.map((item) => {
      let offset = item.narrative - 0.5;
      let freshness = 1 - dateScale(new Date(item.date_publicated));
      item.cx = item.x = 140 * offset * freshness;
      item.cy = item.y = 0;
      return item;
    });

    function addFlagDefinitions() {
      var defs = svg.append("defs");
      defs.selectAll(".flags")
        .data(realdata)
        .enter()
          .append("pattern")
          .attr("id", function(d) { return `${id}-${d.id}` })
          .attr("class", "flag")
          .attr("width", "100%")
          .attr("height", "100%")
          .attr("patternContentUnits", "objectBoundingBox")
            .append("image")
            .attr("width", 1)
            .attr("height", 1)
            // xMidYMid: center the image in the circle
            // slice: scale the image to fill the circle
            .attr("preserveAspectRatio", "xMidYMid slice")
            .attr("xlink:href", function(d) {
              // let urlPath = d.sources[0].origin_country + '.svg';
              // return `../../assets/flags/${urlPath}`;
              if(d.country_code === null) {
                return svgFiles['NoFlag.svg']
              } else {
                return svgFiles[d.country_code + '.svg']
              }
              
            });
    }

    function addPlatformDefinitions() {
      var defs = svg.append("defs");
      defs.selectAll(".platforms")
        .data(realdata)
        .enter()
          .append("pattern")
          .attr("id", function(d) { return `${id}-${d.id}` })
          .attr("class", "platform")
          .attr("width", "100%")
          .attr("height", "100%")
          .attr("patternContentUnits", "objectBoundingBox")
            .append("image")
            .attr("width", 1)
            .attr("height", 1)
            // xMidYMid: center the image in the circle
            // slice: scale the image to fill the circle
            .attr("preserveAspectRatio", "xMidYMid slice")
            .attr("xlink:href", function(d) {
              return platformIconsMapping[d.source_type]
            });
    }

    
    var node = svg
      .append('g')
      .selectAll('circle')
      .data(realdata)
      .enter()
      .append('circle')
      .attr('r', (d) => dateScore(d.message_share))
      // .attr("cx", 500 + width / 2)
      // .attr("cy", height / 2)
      .attr('cx', (d) => d.cx)
      .attr('cy', (d) => d.cy)

      // .attr('fill', (d) => {
      //   d3.color('steelblue');
      //   // let color = d.narrative ? "#1101ef" : "#ff9903";
      //   // return d.narrative ? "#1101ef" : "#ff9903";
      //   return narrativeColor[d.narrative_id];
      //   // return 'rgba(.5,0,0,1.)';
      // })
      // .attr('fill-opacity', (d) => dateScale(new Date(d.date_publicated)))
      .on('mouseenter', (m, d) => {
        if(mode === 'countries') {
          let country = countriesData.find((element => element.CountryCode === d.country_code));
          return setHovered({
            xPos: d.x + dateScore(d.message_share),
            yPos: d.y,
            name: country?.CountryName,
          })
        } else {
          return setHovered({
            xPos: d.x + dateScore(d.message_share),
            yPos: d.y,
            name: platformNamesMapping[d.source_type]
          })
        }
      })
      .on('mouseleave', (d) => {
        return setHovered(null)
      })
      .call(
        d3
          .drag() // call specific function when circle is dragged
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended),
      );
    // .x(300)
    // .y(300)

    function updateDefinitions () {
      if(mode === 'countries') {
        addFlagDefinitions()
      } else {
        addPlatformDefinitions()
      }
    }

    updateDefinitions()

  
    function updateCircles() {
      node
        .attr("fill", function(d) {
          return `url(#${id}-${d.id})`;
        })
        .style('stroke', 'gray')
    }

    updateCircles()
  

    // Features of the forces applied to the nodes:
    var simulation = d3
      .forceSimulation(realdata)
      // .force("position", d3.forcePosition().position([width / 2, height / 2]))
      .force(
        'center',
        d3
          .forceCenter()
          .x(width / 2)
          .y(height / 2),
      )
      .force('charge', d3.forceManyBody().strength(5.5))
      .force(
        'collide',
        d3
          .forceCollide()
          .strength(0.04)
          .radius(function (d) {
            return dateScore(d.message_share) + 2;
          })
          .iterations(3),
      ); // Force that avoids circle overlapping

    simulation.nodes(realdata).on('tick', function (d) {
      node
        .attr('cx', function (d) {
          return d.x;
        })
        .attr('cy', function (d) {
          return d.y;
        });
    });

    // DOM EVENTS
    // ============================
    // ============================
    // ============================
    // ============================

    // let format = d3.format(',d');
    let current_circle = undefined;

    function selectMessage(event, d) {
      popup.current.style.setProperty('--stroke', `var(--clr-gray350)`);
      popup.current.style.setProperty(
        '--background',
        `none`,
      );

      popup.current.style.transform =
        'translate(' +
        Math.max(event.layerX, leftOffset) +
        'px,' +
        Math.max(event.layerY, 520) +
        'px)';

      if (current_circle !== undefined) {
        // current_circle.attr("fill", d => "#bbccff");
        current_circle.style('stroke-width', 1);
        current_circle.style('stroke', 'gray')
        svg.selectAll('#details-popup').remove();
      }

      // select the circle
      current_circle = d3.select(this);
      // current_circle.attr("fill","#b2e1f9");
      current_circle.style('stroke', '#000').style('stroke-width', 1.5);
      onCircleClick(d.id);
      setCurrentNarrative(d);
      togglePopup(true);
      toggleExpanded(false);
    }

    node.on('click', selectMessage);

    // What happens when a circle is dragged?
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.03).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }
    function dragended(event, d) {
      if (!event.active) {
        simulation.alphaTarget(0.03);
      }

      d.fx = null;
      d.fy = null;

      setShouldShowReset(true);
    }
  }, [width, height, realData, dateScale, reference, mode]);

  return (
    <div id={`circlechart-${id}`} className="circlechart" ref={parentWrapper}>
      {hovered ? <div
      className='bubbleChartTooltip'
      style={{
        left: hovered?.xPos,
        top: hovered?.yPos,
      }}
    >
      {hovered?.name}
    </div> : ''}
      {shouldShowReset && (
        <button className="button reset" onClick={handleResetClick}>
          Restart view
        </button>
      )}

      <div className="popup-position" ref={popup}>
        <div
          className="popup"
          style={{ display: popupOpen ? 'block' : 'none' }}
        >
          <div className="popup__close" onClick={() => togglePopup((o) => !o)}>
            <svg viewBox="0 0 32 32">
              <line
                x1="7"
                x2="25"
                y1="7"
                y2="25"
                stroke="#2c2e30"
                strokeWidth={2}
              />
              <line
                x1="7"
                x2="25"
                y1="25"
                y2="7"
                stroke="#2c2e30"
                strokeWidth={2}
              />
            </svg>
          </div>
          <div className="popup__data popup__date">
            <div className="popup__icon">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 8.2H1M12.5556 1V4.6M5.44444 1V4.6M5.26667 19H12.7333C14.2268 19 14.9735 19 15.544 18.7057C16.0457 18.4469 16.4537 18.0338 16.7094 17.5258C17 16.9482 17 16.1921 17 14.68V7.12C17 5.60786 17 4.85179 16.7094 4.27423C16.4537 3.76619 16.0457 3.35314 15.544 3.09428C14.9735 2.8 14.2268 2.8 12.7333 2.8H5.26667C3.77319 2.8 3.02646 2.8 2.45603 3.09428C1.95426 3.35314 1.54631 3.76619 1.29065 4.27423C1 4.85179 1 5.60786 1 7.12V14.68C1 16.1921 1 16.9482 1.29065 17.5258C1.54631 18.0338 1.95426 18.4469 2.45603 18.7057C3.02646 19 3.77319 19 5.26667 19Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
            </div>
            <div className="popup__value">
              {currentNarrative?.date_publicated ? format(parseISO(currentNarrative?.date_publicated + 'Z'), 'dd LLL yyyy, HH:mm',) : ''}
            </div>
          </div>
          {currentNarrative.isTranslation === true && !currentNarrative.translated ? <LoaderSmall/> : <p className="popup__title">
            {/* {isExpanded
              ? currentNarrative.isTranslation ? currentNarrative.translated : currentNarrative.text
              : currentNarrative.isTranslation ? currentNarrative.translated?.substring(0, 150) : currentNarrative.text?.substring(0, 150)}
            {currentNarrative.text?.length > 150 && (
              <span>
                ...
                <span
                  className="popup__more"
                  onClick={() => toggleExpanded((o) => !o)}
                >
                  {isExpanded ? t('Show less') : t('Show more')}
                </span>
              </span>
            )} */}
            {currentNarrative?.text && popupOpen ? <ExpandableText
              length={200}
              text={currentNarrative?.isTranslation ? currentNarrative.translated : currentNarrative.text}
              textClassName={messageStyles.extendedMessage}
              isLabel={true}
            /> : ''}
          </p>}
          <div className={messageStyles.translationContainer}>
            <div className={messageStyles.translationWrapper}>
              <span
                className={
                  currentNarrative?.isTranslation ? '' : messageStyles.active
                }
                onClick={() =>
                  onTranslationClick(currentNarrative.id, currentNarrative.text, false)
                }
              >
                <OriginalTextIcon />
              </span>
              <span
                className={
                  currentNarrative?.isTranslation ? messageStyles.active : ''
                }
                onClick={() =>
                  onTranslationClick(currentNarrative.id, currentNarrative.text, true)
                }
              >
                <TranslationIcon />
              </span>
            </div>
          </div>
          <div className="popup__data popup__total">
            <h3 className='h3_stories'>{t('Sources')}:</h3>
            <div className="popup__sources">
              <div>
                <ActorIcon />
                <Link to={`/sources/${currentNarrative.source?.id}`}>{decodeSourceName(currentNarrative.source?.name)}</Link>
              </div>
              {currentNarrative.actor && currentNarrative.actor?.id !== currentNarrative.source?.id ? <div>
                  <SourceIcon/>
                  <Link to={`/sources/${currentNarrative.actor?.id}`}>{decodeSourceName(currentNarrative.actor?.name)}</Link>
                </div> : ''
              }
            </div>
          </div>
          <div className="popup__resume">
            <div className="popup__data">
              {currentNarrative?.sentiment_score !== null 
                  ? <span className='sentiment_icon'>
                      <Sentiment
                        sentimentScore={currentNarrative?.sentiment_score}
                        messageId={currentNarrative?.id} narrative={narrative}
                        isShare={isShare}
                        isChart={true}
                        isStories={false}
                      />
                    </span> : ''}
            </div>
            <div className="popup__data">
              <div className="popup__icon">
                <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
                  <path
                    d="M1.26805 8.71318C1.12971 8.49754 1.06054 8.38972 1.02181 8.22342C0.992729 8.0985 0.992729 7.9015 1.02181 7.77658C1.06054 7.61028 1.12971 7.50246 1.26805 7.28682C2.41127 5.50484 5.81418 1 11 1C16.1858 1 19.5887 5.50484 20.7319 7.28682C20.8703 7.50246 20.9395 7.61028 20.9782 7.77658C21.0073 7.9015 21.0073 8.0985 20.9782 8.22342C20.9395 8.38972 20.8703 8.49754 20.7319 8.71318C19.5887 10.4952 16.1858 15 11 15C5.81418 15 2.41127 10.4952 1.26805 8.71318Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11 11C12.6831 11 14.0475 9.65685 14.0475 8C14.0475 6.34315 12.6831 5 11 5C9.31692 5 7.95251 6.34315 7.95251 8C7.95251 9.65685 9.31692 11 11 11Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="popup__value">
                {formatWithCustomGigaBillion(currentNarrative.impressions)}
              </div>
            </div>
            <div className="popup__data">
              <div className="popup__icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M5.10176 17V8.2M1 9.8V15.4C1 16.2837 1.73457 17 2.6407 17H13.655C14.8697 17 15.9027 16.1357 16.0874 14.9649L16.9709 9.36494C17.2002 7.91112 16.0468 6.6 14.5384 6.6H11.6646C11.2115 6.6 10.8442 6.24183 10.8442 5.8V2.97267C10.8442 1.8832 9.93857 1 8.82137 1C8.5549 1 8.31343 1.15304 8.2052 1.3905L5.31829 7.72491C5.18662 8.01381 4.89283 8.2 4.56864 8.2H2.6407C1.73457 8.2 1 8.91634 1 9.8Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="popup__value">
                {formatWithCustomGigaBillion(currentNarrative?.engagement)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg id={id} width={width} height={height} ref={svgWrapper}></svg>
    </div>
  );
}
