export const trendData = {
  "1": {
    "objects": [{
      "id": 1,
      "name": "Allegations of misinformation regarding Kamala Harris's McDonald's employment circulate",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-30",
          "engagement": 3305698,
          "engagement_share": 49.61843897177479,
          "impressions": 141276,
          "impressions_share": 4.025869107105455,
          "narrative_share": 100,
          "publications": 398,
          "publications_share": 32.43683781581092,
          "score": 0,
          "score_share": 0,
          "sources": 334,
          "sources_share": 35.72192513368984,
          "views": 145482,
          "views_share": 4.0688575024472105,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-29",
          "engagement": 3130333,
          "engagement_share": 46.98621499055047,
          "impressions": 2371667,
          "impressions_share": 67.58416792407397,
          "narrative_share": 100,
          "publications": 474,
          "publications_share": 38.63080684596577,
          "score": 0,
          "score_share": 0,
          "sources": 372,
          "sources_share": 39.786096256684495,
          "views": 2407923,
          "views_share": 67.34507061949378,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-31",
          "engagement": 217967,
          "engagement_share": 3.2716788670231938,
          "impressions": 981398,
          "impressions_share": 27.966391248160196,
          "narrative_share": 100,
          "publications": 251,
          "publications_share": 20.45639771801141,
          "score": 0,
          "score_share": 0,
          "sources": 230,
          "sources_share": 24.59893048128342,
          "views": 1006918,
          "views_share": 28.16159977625507,
          "manipulation_index": 0.005136610714299957
        },
        {
          "date_publicated": "2024-09-01",
          "engagement": 240,
          "engagement_share": 0.003602393610434453,
          "impressions": 11594,
          "impressions_share": 0.3303882218337202,
          "narrative_share": 100,
          "publications": 87,
          "publications_share": 7.090464547677262,
          "score": 0,
          "score_share": 0,
          "sources": 86,
          "sources_share": 9.197860962566844,
          "views": 11771,
          "views_share": 0.3292126975248217,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-09-04",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 7,
          "impressions_share": 0.0001994753797512542,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.08149959250203749,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.10695187165775401,
          "views": 7,
          "views_share": 0.00019577681443154804,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-10",
          "engagement": 3437,
          "engagement_share": 0.05158927849609673,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 7,
          "publications_share": 0.5704971475142624,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 0.7486631016042781,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.6096096096096096
        },
        {
          "date_publicated": "2024-08-11",
          "engagement": 4562,
          "engagement_share": 0.06847549854500823,
          "impressions": 3263,
          "impressions_share": 0.09298402344690607,
          "narrative_share": 100,
          "publications": 9,
          "publications_share": 0.7334963325183375,
          "score": 0,
          "score_share": 0,
          "sources": 9,
          "sources_share": 0.9625668449197861,
          "views": 3399,
          "views_share": 0.09506362746469026,
          "manipulation_index": 0.2709376042709376
        }
      ]
    }]
  },
  "2": {
    "objects": [{
      "id": 2,
      "name": "McDonald's France considers removing 'Curry' sauce from its menu for four years",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-12",
          "engagement": 566,
          "engagement_share": 63.02895322939867,
          "impressions": 80767,
          "impressions_share": 62.99095304944626,
          "narrative_share": 100,
          "publications": 50,
          "publications_share": 72.46376811594203,
          "score": 0,
          "score_share": 0,
          "sources": 49,
          "sources_share": 76.5625,
          "views": 80868,
          "views_share": 62.88335925349922,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-11",
          "engagement": 108,
          "engagement_share": 12.026726057906458,
          "impressions": 26861,
          "impressions_share": 20.94914989861176,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 5.797101449275362,
          "score": 0,
          "score_share": 0,
          "sources": 4,
          "sources_share": 6.25,
          "views": 26953,
          "views_share": 20.958786936236393,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-13",
          "engagement": 210,
          "engagement_share": 23.385300668151448,
          "impressions": 19226,
          "impressions_share": 14.994540633286539,
          "narrative_share": 100,
          "publications": 12,
          "publications_share": 17.391304347826086,
          "score": 0,
          "score_share": 0,
          "sources": 12,
          "sources_share": 18.75,
          "views": 19407,
          "views_share": 15.090979782270606,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-14",
          "engagement": 2,
          "engagement_share": 0.22271714922049,
          "impressions": 732,
          "impressions_share": 0.5708937763219466,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 2.898550724637681,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 3.125,
          "views": 733,
          "views_share": 0.5699844479004665,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-15",
          "engagement": 12,
          "engagement_share": 1.3363028953229399,
          "impressions": 634,
          "impressions_share": 0.4944626423334893,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.4492753623188406,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.5625,
          "views": 639,
          "views_share": 0.4968895800933126,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "3": {
    "objects": [{
      "id": 3,
      "name": "McDonald's celebrated Ukrainian Independence Day with a special burger giveaway",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-23",
          "engagement": 2099,
          "engagement_share": 16.448554188543216,
          "impressions": 185787,
          "impressions_share": 22.197781978154325,
          "narrative_share": 100,
          "publications": 15,
          "publications_share": 23.076923076923077,
          "score": 0,
          "score_share": 0,
          "sources": 15,
          "sources_share": 23.076923076923077,
          "views": 187115,
          "views_share": 22.165532812664733,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-22",
          "engagement": 10491,
          "engagement_share": 82.21142543687799,
          "impressions": 630594,
          "impressions_share": 75.3432055457715,
          "narrative_share": 100,
          "publications": 48,
          "publications_share": 73.84615384615384,
          "score": 0,
          "score_share": 0,
          "sources": 48,
          "sources_share": 73.84615384615384,
          "views": 636379,
          "views_share": 75.38508193245208,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-21",
          "engagement": 171,
          "engagement_share": 1.3400203745787949,
          "impressions": 20581,
          "impressions_share": 2.4590124760741827,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 3.076923076923077,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 3.076923076923077,
          "views": 20677,
          "views_share": 2.449385254883193,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "4": {
    "objects": [{
      "id": 4,
      "name": "McDonald's unexpected promotion of Solana MemeCoin on Instagram raises eyebrows",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-22",
          "engagement": 986,
          "engagement_share": 10.150298538192299,
          "impressions": 96722,
          "impressions_share": 6.829202852503001,
          "narrative_share": 100,
          "publications": 18,
          "publications_share": 42.857142857142854,
          "score": 0,
          "score_share": 0,
          "sources": 17,
          "sources_share": 44.73684210526316,
          "views": 97394,
          "views_share": 6.840811395499115,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-21",
          "engagement": 8722,
          "engagement_share": 89.78793493926293,
          "impressions": 1317025,
          "impressions_share": 92.9905387276707,
          "narrative_share": 100,
          "publications": 17,
          "publications_share": 40.476190476190474,
          "score": 0,
          "score_share": 0,
          "sources": 16,
          "sources_share": 42.10526315789474,
          "views": 1323770,
          "views_share": 92.97965892169809,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-23",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 820,
          "impressions_share": 0.05789733813457601,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 9.523809523809524,
          "score": 0,
          "score_share": 0,
          "sources": 4,
          "sources_share": 10.526315789473685,
          "views": 820,
          "views_share": 0.057595594639395384,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-29",
          "engagement": 6,
          "engagement_share": 0.06176652254478073,
          "impressions": 1733,
          "impressions_share": 0.12236108169173197,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 7.142857142857143,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 7.894736842105263,
          "views": 1736,
          "views_share": 0.12193408816340291,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "5": {
    "objects": [{
      "id": 5,
      "name": "McDonald's confirms Vice President Harris's claim of past employment is unfounded",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-29",
          "engagement": 13518,
          "engagement_share": 31.910674661253008,
          "impressions": 21615,
          "impressions_share": 60.79826732673267,
          "narrative_share": 100,
          "publications": 28,
          "publications_share": 19.718309859154928,
          "score": 0,
          "score_share": 0,
          "sources": 28,
          "sources_share": 26.41509433962264,
          "views": 22913,
          "views_share": 61.63882387754553,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-30",
          "engagement": 15216,
          "engagement_share": 35.91898399508994,
          "impressions": 3187,
          "impressions_share": 8.96433393339334,
          "narrative_share": 100,
          "publications": 36,
          "publications_share": 25.35211267605634,
          "score": 0,
          "score_share": 0,
          "sources": 33,
          "sources_share": 31.132075471698112,
          "views": 3347,
          "views_share": 9.003846878110457,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-31",
          "engagement": 4418,
          "engagement_share": 10.42915820782777,
          "impressions": 153,
          "impressions_share": 0.4303555355535554,
          "narrative_share": 100,
          "publications": 27,
          "publications_share": 19.014084507042252,
          "score": 0,
          "score_share": 0,
          "sources": 17,
          "sources_share": 16.037735849056602,
          "views": 153,
          "views_share": 0.41158905657331935,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-18",
          "engagement": 80,
          "engagement_share": 0.18884849629384826,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.704225352112676,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.9433962264150944,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-17",
          "engagement": 695,
          "engagement_share": 1.6406213115528068,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 2.112676056338028,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 2.830188679245283,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-16",
          "engagement": 3363,
          "engagement_share": 7.938718662952646,
          "impressions": 14,
          "impressions_share": 0.03937893789378938,
          "narrative_share": 100,
          "publications": 14,
          "publications_share": 9.859154929577464,
          "score": 0,
          "score_share": 0,
          "sources": 13,
          "sources_share": 12.264150943396226,
          "views": 15,
          "views_share": 0.0403518682915019,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-15",
          "engagement": 147,
          "engagement_share": 0.34700911193994616,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 2.816901408450704,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 1.8867924528301887,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-09",
          "engagement": 2545,
          "engagement_share": 6.007742788348048,
          "impressions": 6818,
          "impressions_share": 19.17754275427543,
          "narrative_share": 100,
          "publications": 10,
          "publications_share": 7.042253521126761,
          "score": 0,
          "score_share": 0,
          "sources": 10,
          "sources_share": 9.433962264150944,
          "views": 6868,
          "views_share": 18.475775428402336,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-10",
          "engagement": 1424,
          "engagement_share": 3.361503234030499,
          "impressions": 472,
          "impressions_share": 1.3276327632763276,
          "narrative_share": 100,
          "publications": 7,
          "publications_share": 4.929577464788732,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 6.60377358490566,
          "views": 477,
          "views_share": 1.2831894116697604,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-19",
          "engagement": 104,
          "engagement_share": 0.24550304518200275,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.704225352112676,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.9433962264150944,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-11",
          "engagement": 514,
          "engagement_share": 1.213351588687975,
          "impressions": 249,
          "impressions_share": 0.7003825382538253,
          "narrative_share": 100,
          "publications": 7,
          "publications_share": 4.929577464788732,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 6.60377358490566,
          "views": 256,
          "views_share": 0.688671885508299,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-12",
          "engagement": 180,
          "engagement_share": 0.4249091166611586,
          "impressions": 3044,
          "impressions_share": 8.562106210621062,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 1.408450704225352,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 1.8867924528301887,
          "views": 3144,
          "views_share": 8.457751593898797,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-25",
          "engagement": 157,
          "engagement_share": 0.3706151739766772,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.704225352112676,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.9433962264150944,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-26",
          "engagement": 1,
          "engagement_share": 0.0023606062036731035,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.704225352112676,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.9433962264150944,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "6": {
    "objects": [{
      "id": 6,
      "name": "Calls for a McDonald's boycott emerge over allegations of association with child harm",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-22",
          "engagement": 12303,
          "engagement_share": 7.4789364263048475,
          "impressions": 194039,
          "impressions_share": 71.87614599037646,
          "narrative_share": 100,
          "publications": 107,
          "publications_share": 46.52173913043478,
          "score": 0,
          "score_share": 0,
          "sources": 90,
          "sources_share": 43.0622009569378,
          "views": 197062,
          "views_share": 70.89785286668202,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-11",
          "engagement": 84324,
          "engagement_share": 51.260167049640735,
          "impressions": 54493,
          "impressions_share": 20.185358734345076,
          "narrative_share": 100,
          "publications": 43,
          "publications_share": 18.695652173913043,
          "score": 0,
          "score_share": 0,
          "sources": 43,
          "sources_share": 20.574162679425836,
          "views": 58854,
          "views_share": 21.174159567119503,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-01",
          "engagement": 27238,
          "engagement_share": 16.557853399958663,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 5,
          "publications_share": 2.1739130434782608,
          "score": 0,
          "score_share": 0,
          "sources": 5,
          "sources_share": 2.3923444976076556,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-02",
          "engagement": 116,
          "engagement_share": 0.0705158599895442,
          "impressions": 4620,
          "impressions_share": 1.7113456288454343,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 1.7391304347826086,
          "score": 0,
          "score_share": 0,
          "sources": 4,
          "sources_share": 1.9138755980861244,
          "views": 4662,
          "views_share": 1.6772680174994243,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-03",
          "engagement": 137,
          "engagement_share": 0.08328166222903065,
          "impressions": 2227,
          "impressions_share": 0.8249278604845849,
          "narrative_share": 100,
          "publications": 6,
          "publications_share": 2.608695652173913,
          "score": 0,
          "score_share": 0,
          "sources": 6,
          "sources_share": 2.8708133971291865,
          "views": 2311,
          "views_share": 0.8314385217591527,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-04",
          "engagement": 1,
          "engagement_share": 0.00060789534473745,
          "impressions": 42,
          "impressions_share": 0.015557687534958495,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 1.3043478260869565,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 1.4354066985645932,
          "views": 42,
          "views_share": 0.015110522680174994,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-12",
          "engagement": 34537,
          "engagement_share": 20.99488152119731,
          "impressions": 9,
          "impressions_share": 0.0033337901860625344,
          "narrative_share": 100,
          "publications": 19,
          "publications_share": 8.26086956521739,
          "score": 0,
          "score_share": 0,
          "sources": 19,
          "sources_share": 9.090909090909092,
          "views": 9,
          "views_share": 0.0032379691457517846,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-23",
          "engagement": 3619,
          "engagement_share": 2.1999732526048317,
          "impressions": 3629,
          "impressions_share": 1.3442582872467708,
          "narrative_share": 100,
          "publications": 38,
          "publications_share": 16.52173913043478,
          "score": 0,
          "score_share": 0,
          "sources": 38,
          "sources_share": 18.181818181818183,
          "views": 3713,
          "views_share": 1.335842159797375,
          "manipulation_index": 0.023589378852536747
        },
        {
          "date_publicated": "2024-08-13",
          "engagement": 459,
          "engagement_share": 0.2790239632344895,
          "impressions": 10894,
          "impressions_share": 4.035367809662806,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 1.3043478260869565,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 1.4354066985645932,
          "views": 11289,
          "views_share": 4.061492631821322,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-24",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 10,
          "impressions_share": 0.0037042113178472605,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.43478260869565216,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.4784688995215311,
          "views": 10,
          "views_share": 0.0035977434952797604,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-26",
          "engagement": 1768,
          "engagement_share": 1.0747589694958115,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.43478260869565216,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.4784688995215311,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "7": {
    "objects": [{
      "id": 7,
      "name": "Bill Gates is linked to agriculture, owning land used for McDonald's potato supply",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-25",
          "engagement": 121851,
          "engagement_share": 54.07644753717864,
          "impressions": 3781,
          "impressions_share": 1.975454417212211,
          "narrative_share": 100,
          "publications": 35,
          "publications_share": 56.45161290322581,
          "score": 0,
          "score_share": 0,
          "sources": 34,
          "sources_share": 55.73770491803279,
          "views": 3830,
          "views_share": 1.9247488529401418,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-24",
          "engagement": 99326,
          "engagement_share": 44.0800422489582,
          "impressions": 187618,
          "impressions_share": 98.0245455827878,
          "narrative_share": 100,
          "publications": 26,
          "publications_share": 41.935483870967744,
          "score": 0,
          "score_share": 0,
          "sources": 26,
          "sources_share": 42.622950819672134,
          "views": 195157,
          "views_share": 98.07525114705986,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-26",
          "engagement": 4154,
          "engagement_share": 1.8435102138631614,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.6129032258064515,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.639344262295082,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "8": {
    "objects": [{
      "id": 8,
      "name": "A criminal case saw a sentencing delay request due to the defendant's employment at McDonald's",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-15",
          "engagement": 90477,
          "engagement_share": 80.85089271352743,
          "impressions": 25,
          "impressions_share": 0.4462691895751517,
          "narrative_share": 100,
          "publications": 58,
          "publications_share": 70.73170731707317,
          "score": 0,
          "score_share": 0,
          "sources": 35,
          "sources_share": 62.5,
          "views": 25,
          "views_share": 0.44563279857397503,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-16",
          "engagement": 21418,
          "engagement_share": 19.139277607992423,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 11,
          "publications_share": 13.414634146341463,
          "score": 0,
          "score_share": 0,
          "sources": 10,
          "sources_share": 17.857142857142858,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-04",
          "engagement": 7,
          "engagement_share": 0.006255249941915536,
          "impressions": 1427,
          "impressions_share": 25.47304534094966,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 4.878048780487805,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 5.357142857142857,
          "views": 1432,
          "views_share": 25.525846702317292,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-05",
          "engagement": 3,
          "engagement_share": 0.002680821403678087,
          "impressions": 2947,
          "impressions_share": 52.60621206711889,
          "narrative_share": 100,
          "publications": 5,
          "publications_share": 6.097560975609756,
          "score": 0,
          "score_share": 0,
          "sources": 5,
          "sources_share": 8.928571428571429,
          "views": 2949,
          "views_share": 52.5668449197861,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-06",
          "engagement": 1,
          "engagement_share": 0.0008936071345593623,
          "impressions": 1203,
          "impressions_share": 21.474473402356303,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 4.878048780487805,
          "score": 0,
          "score_share": 0,
          "sources": 4,
          "sources_share": 7.142857142857143,
          "views": 1204,
          "views_share": 21.461675579322637,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "9": {
    "objects": [{
      "id": 9,
      "name": "Bill Clinton humorously anticipates breaking a McDonald's-related record if Hillary becomes president",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-22",
          "engagement": 16688,
          "engagement_share": 100.0,
          "impressions": 319619,
          "impressions_share": 99.80327807424848,
          "narrative_share": 100,
          "publications": 49,
          "publications_share": 98.0,
          "score": 0,
          "score_share": 0,
          "sources": 42,
          "sources_share": 97.67441860465117,
          "views": 324856,
          "views_share": 99.80644328788335,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-23",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 630,
          "impressions_share": 0.19672192575152458,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 2.0,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 2.3255813953488373,
          "views": 630,
          "views_share": 0.19355671211665018,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "10": {
    "objects": [{
      "id": 10,
      "name": "Protests in D\u00fczce target McDonald's for its support to the Israeli military",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-04",
          "engagement": 20648,
          "engagement_share": 89.19222462203024,
          "impressions": 279402,
          "impressions_share": 88.15834311461134,
          "narrative_share": 100,
          "publications": 661,
          "publications_share": 50.03785011355034,
          "score": 0,
          "score_share": 0,
          "sources": 247,
          "sources_share": 48.148148148148145,
          "views": 292799,
          "views_share": 88.18157998560419,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-05",
          "engagement": 1855,
          "engagement_share": 8.012958963282937,
          "impressions": 35881,
          "impressions_share": 11.321356000656293,
          "narrative_share": 100,
          "publications": 573,
          "publications_share": 43.37623012869039,
          "score": 0,
          "score_share": 0,
          "sources": 240,
          "sources_share": 46.78362573099415,
          "views": 37505,
          "views_share": 11.295291846488837,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-06",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 65,
          "publications_share": 4.920514761544284,
          "score": 0,
          "score_share": 0,
          "sources": 23,
          "sources_share": 4.483430799220273,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-03",
          "engagement": 2,
          "engagement_share": 0.008639308855291577,
          "impressions": 271,
          "impressions_share": 0.08550730125074149,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 0.1514004542013626,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 0.3898635477582846,
          "views": 273,
          "views_share": 0.08221876214081995,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-07",
          "engagement": 425,
          "engagement_share": 1.83585313174946,
          "impressions": 1217,
          "impressions_share": 0.38399404288617117,
          "narrative_share": 100,
          "publications": 10,
          "publications_share": 0.757002271006813,
          "score": 0,
          "score_share": 0,
          "sources": 10,
          "sources_share": 1.949317738791423,
          "views": 1303,
          "views_share": 0.3924214178369539,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-08",
          "engagement": 220,
          "engagement_share": 0.9503239740820735,
          "impressions": 161,
          "impressions_share": 0.050799540595458964,
          "narrative_share": 100,
          "publications": 5,
          "publications_share": 0.3785011355034065,
          "score": 0,
          "score_share": 0,
          "sources": 5,
          "sources_share": 0.9746588693957114,
          "views": 161,
          "views_share": 0.04848798792920152,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-09",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.0757002271006813,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.1949317738791423,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-10",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 0.22710068130204392,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 0.5847953216374269,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-11",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.0757002271006813,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.1949317738791423,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "11": {
    "objects": [{
      "id": 11,
      "name": "Allegations of dangerous chemicals in fast food spark concern among consumers",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-24",
          "engagement": 2747,
          "engagement_share": 14.10671185744364,
          "impressions": 5641,
          "impressions_share": 5.1815042069294925,
          "narrative_share": 100,
          "publications": 10,
          "publications_share": 16.949152542372882,
          "score": 0,
          "score_share": 0,
          "sources": 10,
          "sources_share": 17.24137931034483,
          "views": 5680,
          "views_share": 5.145300384085803,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-16",
          "engagement": 77,
          "engagement_share": 0.3954192985158938,
          "impressions": 2991,
          "impressions_share": 2.7473637799904473,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 5.084745762711864,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 5.172413793103448,
          "views": 3021,
          "views_share": 2.7366113486484527,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-17",
          "engagement": 12493,
          "engagement_share": 64.15549735531249,
          "impressions": 57905,
          "impressions_share": 53.188264687511484,
          "narrative_share": 100,
          "publications": 16,
          "publications_share": 27.11864406779661,
          "score": 0,
          "score_share": 0,
          "sources": 16,
          "sources_share": 27.586206896551722,
          "views": 59144,
          "views_share": 53.57634611203711,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-18",
          "engagement": 3637,
          "engagement_share": 18.677142710419556,
          "impressions": 4894,
          "impressions_share": 4.495352169599882,
          "narrative_share": 100,
          "publications": 7,
          "publications_share": 11.864406779661017,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 12.068965517241379,
          "views": 4928,
          "views_share": 4.464091600840641,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-19",
          "engagement": 129,
          "engagement_share": 0.6624557079032507,
          "impressions": 5956,
          "impressions_share": 5.470845427490172,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 3.389830508474576,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 3.4482758620689653,
          "views": 5979,
          "views_share": 5.41615334444525,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-05",
          "engagement": 124,
          "engagement_share": 0.6367791300775433,
          "impressions": 1801,
          "impressions_share": 1.6542969467612154,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 3.389830508474576,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 3.4482758620689653,
          "views": 1898,
          "views_share": 1.719327487499094,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-06",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 36,
          "impressions_share": 0.033067568064077596,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.694915254237288,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.7241379310344827,
          "views": 36,
          "views_share": 0.03261105877237481,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-26",
          "engagement": 67,
          "engagement_share": 0.344066142864479,
          "impressions": 4704,
          "impressions_share": 4.32082889370614,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 5.084745762711864,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 5.172413793103448,
          "views": 4745,
          "views_share": 4.298318718747735,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-09",
          "engagement": 28,
          "engagement_share": 0.14378883582396138,
          "impressions": 7321,
          "impressions_share": 6.724657383253114,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 3.389830508474576,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 3.4482758620689653,
          "views": 7321,
          "views_share": 6.631821146459888,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-13",
          "engagement": 51,
          "engagement_share": 0.2619010938222154,
          "impressions": 12300,
          "impressions_share": 11.298085755226513,
          "narrative_share": 100,
          "publications": 6,
          "publications_share": 10.169491525423728,
          "score": 0,
          "score_share": 0,
          "sources": 6,
          "sources_share": 10.344827586206897,
          "views": 12301,
          "views_share": 11.143017609971738,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-22",
          "engagement": 20,
          "engagement_share": 0.10270631130282956,
          "impressions": 2881,
          "impressions_share": 2.6463239886835432,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 6.779661016949152,
          "score": 0,
          "score_share": 0,
          "sources": 4,
          "sources_share": 6.896551724137931,
          "views": 2883,
          "views_share": 2.611602290021016,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-23",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 13,
          "impressions_share": 0.011941066245361355,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.694915254237288,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.7241379310344827,
          "views": 13,
          "views_share": 0.011776215667802015,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-14",
          "engagement": 22,
          "engagement_share": 0.11297694243311251,
          "impressions": 2425,
          "impressions_share": 2.2274681265385605,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.694915254237288,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.7241379310344827,
          "views": 2443,
          "views_share": 2.2130226828031017,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-25",
          "engagement": 78,
          "engagement_share": 0.40055461408103527,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.694915254237288,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.7241379310344827,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "12": {
    "objects": [{
      "id": 12,
      "name": "A significant gathering of Muslim immigrants occurred outside a Birmingham McDonald's, involving weapons",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-05",
          "engagement": 4536,
          "engagement_share": 100.0,
          "impressions": 0,
          "impressions_share":0,
          "narrative_share": 100,
          "publications": 7,
          "publications_share": 100.0,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 100.0,
          "views": 0,
          "views_share":0,
          "manipulation_index": 0.0
        }
      ]
    }]
  }
}

