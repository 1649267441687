import { useTranslation } from 'react-i18next';
import {
  useNavigate
} from 'react-router-dom';
import './index.css';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { ampli } from "../../ampli";

export default function Export({ isVisible, onClose, reportID, narrativeID }) {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();

  function generatePDF() {
    onClose();

    const eventProperties = {
      user_id: currentUser?.id,
      workspace_id: currentUser?.workspace_id,
    };

    if (narrativeID) {
      eventProperties['narrative_id'] = narrativeID;
    }
    if (reportID) {
      eventProperties['report_id'] = reportID;
    }

    ampli.track({
      event_type: 'Open actors popup',
      event_properties: eventProperties,
    });

    if(narrativeID) {
      setTimeout(() => navigate(`/narratives/${narrativeID}/exportPDF`), 500);
    }

    if(reportID) {
      setTimeout(() => navigate(`/reports/${reportID}/exportPDF`), 500);
    }
  }

  return (
    <div className="export-pdf-container">
      <a className="button controls__export" href="#" onClick={generatePDF}>
        <svg width="12" height="12" viewBox="0 0 16 16" fill="none">
          <path
            d="M4.11111 7.22222C3.3878 7.22222 3.02614 7.22222 2.72942 7.30173C1.92421 7.51749 1.29526 8.14643 1.07951 8.95164C1 9.24837 1 9.61002 1 10.3333V11.2667C1 12.5735 1 13.2269 1.25432 13.726C1.47802 14.165 1.83498 14.522 2.27402 14.7457C2.77315 15 3.42654 15 4.73333 15H11.2667C12.5735 15 13.2269 15 13.726 14.7457C14.165 14.522 14.522 14.165 14.7457 13.726C15 13.2269 15 12.5735 15 11.2667V10.3333C15 9.61002 15 9.24837 14.9205 8.95164C14.7047 8.14643 14.0758 7.51749 13.2706 7.30173C12.9739 7.22222 12.6122 7.22222 11.8889 7.22222M11.1111 4.11111L8 1M8 1L4.88889 4.11111M8 1V10.3333"
            stroke="#2563EB"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {t('Export report')}
      </a>
    </div>
  );
}
