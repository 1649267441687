import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as SaveIcon } from '../../assets/save.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ThreatEditModal } from '../../pages/ThreatsPage/ThreatEditModal';
import {
  mapThreatsTypesConst,
  mapThreatsStatus,
  mapThreatsSubjects,
} from '../../utils/threats';
import Modal from '../../components/Modal';
import { ThreatsContext } from '../../pages/ThreatsPage/contexts/ThreatsContext';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { ReactComponent as AnchorIcon } from '../../assets/anchor.svg';
import Tooltip from '../Tooltip/Tooltip';
import { CurrentUser } from '../../contexts/CurrentUser';

import styles from './ThreatsTable.module.scss';
import { useState, useContext } from 'react';

const formatNumberSignificant = d3.format('.3~s');
const formatNumber = d3.format(',d');

const mapTagsClasses = {
  FAKE_NEWS: styles.fake,
  DATA_LEAK: styles.leak,
  COORDINATED_ATTACK: styles.online,
  COORDINATED_ACTIVITY: styles.offline,
  HOSTILE_NARRATIVE: styles.malign,
  DEBUNK: styles.debunk,
  POSITIVE_NARRATIVE: styles.positive_narrative,
  GOVERNMENT_COMMUNICATION: styles.government_communication,
  FAKE_DISINFORMATION: styles.fake_disinformation,
  MANIPULATION_MISINFORMATION: styles.manipulation_misinformation,
  DISPROOF_DEBUNK: styles.disproof_debunk,
  STRATEGIC_NARRATIVE_MESSAGE: styles.strategic_narrative_message,
  UNCLASSIFIED_THREAT: styles.unclassified_threat,
  OTHER: styles.other,
};

const mapSubjectClasses = {
  POLITICS: styles.politics,
  FINANCE: styles.finance,
  PUBLIC_COMPANIES: styles.publicCompanies,
  ELECTIONS: styles.elections,
  THREAT: styles.threat,
  DEFENSE: styles.defence,
  CYBER: styles.cyber,
  EUROPEAN_INTEGRATION: styles.european_integration,
  UKRAINE__NATO: styles.ukraine__nato,
  HEALTHCARE: styles.healthcare,
  SOCIAL_CULTURAL: styles.social_cultural,
  DECOLONIZATION: styles.decolonization,
  ENERGY_INDEPENDENCE: styles.energy_independence,
  ANTI_CORRUPTION: styles.anti_corruption,
  ECOLOGY: styles.ecology,
  POST_WAR_RECOVERY: styles.post_war_recovery,
  BUSINESS_AND_INVESTMENTS: styles.business_and_investments,
  PUBLIC_SERVICES: styles.public_services,
  VOLUNTEERS: styles.volunteers,
  NATIONAL_MINORITIES: styles.national_minorities,
  COLLABORANTS: styles.collaborants,
  LOCAL_GOVERNMENT: styles.local_government,
  WEAPON_SUPPLY: styles.weapon_supply,
  UKRAINE__PARTNERS: styles.ukraine__partners,
  REFORMS: styles.reforms,
  REGION_SPECIFIC: styles.region_specific,
  RUSSIAN_NARRATIVES: styles.russian_narratives,
  MOBILIZATION: styles.mobilization,
  CONSTITUTIONAL_STATE: styles.constitutional_state,
  DEFENSE_AND_SECURITY: styles.defense_and_security,
};

const mapStatusClasses = {
  ACCEPTED: styles.accepted,
  PENDING: styles.pending,
  DECLINED: styles.declined,
};

export const ThreatsTable = ({
  threatsMode,
  searchQuery,
  sorting,
  page = 1,
}) => {
  const [currentUser] = useCurrentUser();
  const { t } = useTranslation();
  const { threats, saveAsNarrative, deleteThreat, patchThreat } =
    useContext(ThreatsContext);
  const [editModal, setEditModal] = useState({ isOpen: false, threat: null });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    threatId: null,
  });
  const [saveModal, setSaveModal] = useState({ isOpen: false, threat: null });

  if (threats.objects?.length === 0) {
    return (
      <div className={styles.noData}>{t('There is no data to display')}</div>
    );
  }

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.threats}>
        <thead>
          <tr>
            <td>{t('Threat')}</td>
            {threatsMode === 'Inbox' ? <td>{t('Date')}</td> : ''}
            <td>{t('Tags')}</td>
            <td>{t('Topic')}</td>
            <td>{t('Messages')}</td>
            {['Inbox', 'Reported'].includes(threatsMode) ? '' : <td>{t('Views')}</td>}
            {/* {threatsMode !== 'Reported' ? <td>{t('First message')}</td> : ''}
                {threatsMode !== 'Reported' ? <td>{t('Last message')}</td> : ''} */}
            {threatsMode === 'Inbox' &&
            currentUser?.workspace?.config?.is_reporter_only_user ? (
              ''
            ) : (
              <td></td>
            )}
          </tr>
        </thead>
        <tbody>
          {threats.objects?.map((threat) => {
            return (
              <tr key={threat.id}>
                <td className={styles.mainInfo}>
                  <div className={styles.threatNameWrapper}>
                    <div className={styles.threatNameContainer}>
                      <div className={styles.threatname}>
                        {!threat.read && threatsMode === 'Inbox' ? (
                          <div
                            className={
                              !threat.read && threatsMode === 'Inbox'
                                ? styles.new
                                : ''
                            }
                          >
                            <div className={styles.newBullet}></div>
                          </div>
                        ) : (
                          ''
                        )}
                        <Link to={`/threats/${threat.id}`}>{threat.name}</Link>
                      </div>
                      <div className={styles.threatInfo}>
                        <span>#{threat.id}</span>
                        <span>&#8226;</span>
                        <span>
                          {t('created by ')}
                          {threat.workspace?.name ? (
                            <span className={styles.userName}>
                              {' '}
                              {threat.workspace?.name}
                            </span>
                          ) : (
                            <span className={styles.userName}>
                              Organization {threat.workspace_id}
                            </span>
                          )}
                        </span>
                        {threatsMode === 'Reported' ||
                        threatsMode === 'Moderation' ? (
                          <span>&#8226;</span>
                        ) : (
                          ''
                        )}
                        {threatsMode === 'Reported' ||
                        threatsMode === 'Moderation' ? (
                          <div className={styles.statusWrapper}>
                            <span
                              className={` ${
                                mapStatusClasses[threat.review_status]
                              }`}
                            >
                              {t(mapThreatsStatus[threat.review_status])}{' '}
                              {threat.decline_reason ? (
                                <Tooltip content={threat.decline_reason} position="top">
                                  <AnchorIcon />
                                </Tooltip>
                              ) : ''}
                            </span>{' '}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                {threatsMode === 'Inbox' ? (
                  <td>
                    {format(
                      parseISO(threat.date_created + 'Z'),
                      'dd LLL yyyy HH:mm',
                    )}
                  </td>
                ) : (
                  ''
                )}
                <td>
                  <div className={styles.tagsContainer}>
                    {threat.incident_type?.map((tag, index) => {
                      return (
                        <span key={`${tag}${index}`} className={styles.badge}>
                          {t(mapThreatsTypesConst[tag])}
                        </span>
                      );
                    })}
                  </div>
                </td>

                <td>
                  <div className={styles.tagsContainer}>
                    {threat.topic?.map((subject, index) => {
                      return (
                        <span
                          key={`${subject}${index}`}
                          className={`${styles.mark}`}
                        >
                          {t(mapThreatsSubjects[subject])}
                        </span>
                      );
                    })}
                  </div>
                </td>
                <td>{formatNumberSignificant(threat.total_messages)}</td>
                {['Inbox', 'Reported'].includes(threatsMode) ? (
                  ''
                ) : (
                  <td>{formatNumberSignificant(threat.views)}</td>
                )}

                {/* {threatsMode !== 'Reported' ? <td>{threat.first_message_date_publicated !== null ? format(parseISO(threat.first_message_date_publicated + 'Z'), 'dd LLL yyyy HH:mm',) : ''}</td> : ''}
                    {threatsMode !== 'Reported' ? <td>{threat.last_message_date_publicated !== null ? format(parseISO(threat.last_message_date_publicated + 'Z'), 'dd LLL yyyy HH:mm',) : ''}</td> : ''} */}

                {threatsMode === 'Inbox' &&
                currentUser?.workspace?.config?.is_reporter_only_user ? (
                  ''
                ) : (
                  <td>
                    {threatsMode === 'Inbox' &&
                    !currentUser?.workspace?.config?.is_reporter_only_user ? (
                      <Tooltip content={t('Save as case')} position="left">
                        <button
                          className={`${styles.save} btn-primary`}
                          onClick={() =>
                            setSaveModal({ isOpen: true, threat: threat })
                          }
                        >
                          <SaveIcon />
                        </button>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                    {(threatsMode === 'Reported' ||
                      threatsMode === 'Moderation') &&
                    threat.review_status !== 'ACCEPTED' ? (
                      <div className={styles.controls}>
                        <Tooltip content={t('Edit metadata')} position="left">
                          <button
                            className={`${styles.edit} btn-primary`}
                            onClick={() => {
                              setEditModal({ isOpen: true, threat: threat });
                            }}
                            disabled={
                              threat.review_status === 'DECLINED' &&
                              !currentUser.is_super_admin
                                ? true
                                : false
                            }
                          >
                            <EditIcon />
                          </button>
                        </Tooltip>
                        <Tooltip content={t('Edit')} position="left">
                          <Link
                            to={`/threats/${threat.id}/edit/full`}
                            className={`${styles.edit} btn-primary`}
                          >
                             <i className="lar la-edit"></i>
                          </Link>
                        </Tooltip>
                        <Tooltip content={t('Delete')} position="left">
                          <button
                            className={`${styles.delete} btn-primary`}
                            onClick={() =>
                              setDeleteModal({
                                isOpen: true,
                                threatId: threat.id,
                              })
                            }
                          >
                            <DeleteIcon />
                          </button>
                        </Tooltip>
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {editModal.isOpen ? (
        <ThreatEditModal
          isOpen={editModal.isOpen}
          title={t('Edit the threat')}
          threat={editModal.threat}
          isAdmin={currentUser.is_super_admin}
          onChange={(bool) => setEditModal({ isOpen: bool, threat: null })}
          handleThreat={({
            threat,
            threatName,
            threatDescription,
            caseType,
            incidentType,
            topics,
            geoScope,
            narratives,
            campaigns,
            counterEntities,
            reviewStatus,
            declineReason,
            organizations,
            isCreatedByUcbiReporter,
            threatPublicNameEn,
            threatPublicNameUk,
            threatPublicDescriptionEn,
            threatPublicDescriptionUk,
            threatIsPublic,
          }) => {
            patchThreat({
              threat,
              threatName,
              threatDescription,
              caseType,
              incidentType,
              topics,
              geoScope,
              narratives,
              campaigns,
              counterEntities,
              reviewStatus,
              declineReason,
              organizations,
              isCreatedByUcbiReporter,
              threatPublicNameEn,
              threatPublicNameUk,
              threatPublicDescriptionEn,
              threatPublicDescriptionUk,
              threatIsPublic,
              searchQuery,
              sorting,
              page,
              workspaceId: currentUser.is_super_admin ? threat.workspace_id : null,
              mode: threatsMode,
            });
          }}
        />
      ) : (
        ''
      )}

      <Modal
        isVisible={deleteModal.isOpen}
        title={t('Delete the threat')}
        content={<p>{t('Are you sure you want to delete the threat?')}</p>}
        footer={
          <>
            <button
              className="btn-danger"
              onClick={() => {
                deleteThreat(
                  deleteModal.threatId,
                  searchQuery,
                  sorting,
                  page,
                  threatsMode,
                );
                setDeleteModal({ isOpen: false, threatId: null });
              }}
            >
              {t('Delete')}
            </button>
            <button
              className="btn-reset"
              onClick={() => setDeleteModal({ isOpen: false, threatId: null })}
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => setDeleteModal({ isOpen: false, threatId: null })}
      />

      <Modal
        isVisible={saveModal.isOpen}
        title={t('Save as a case')}
        content={
          <p>{t('Are you sure you want to save the threat as a case?')}</p>
        }
        footer={
          <>
            <button
              className="btn-primary"
              onClick={() => {
                saveAsNarrative(saveModal.threat);
                setSaveModal({ isOpen: false, threat: null });
              }}
            >
              {t('Save')}
            </button>
            <button
              className="btn-reset"
              onClick={() => setSaveModal({ isOpen: false, threat: null })}
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => setSaveModal({ isOpen: false, threat: null })}
      />
    </div>
  );
};
