import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { languageOptions, filterLanguages } from '../../../utils/languages';
import { API } from '../../../API';
import Async from 'react-select/async';

export const LanguageChooser = ({onChange, selected = [], onClose}) => {
  const { t } = useTranslation();
  const [languages, setLanguages] = useState([]);
  let userLanguage = 'en';

  if(
    localStorage.getItem('i18nextLng') === 'ua-UK' ||
    localStorage.getItem('i18nextLng') === 'ru-RU' ||
    localStorage.getItem('i18nextLng') === 'ru' ||
    localStorage.getItem('i18nextLng') === 'uk'
  ) {
   userLanguage = 'uk';
  } else {
    userLanguage = 'en';
  }

  const getAllLanguages = useCallback(() => {
    API.fetch('GET', `/API/v1/languages`).then((data) => {
      setLanguages(data.objects.slice(0, 10).map(language => {return {value: language.code, label: t(language.name), id: language.id};}))
    });
  }, []);

  useEffect(getAllLanguages, [getAllLanguages]);

  const getLanguages = (inputValue, callback) => {
    const filteredLanguages = filterLanguages(inputValue, userLanguage);
    const sortedAndMappedLanguages = filteredLanguages
      .sort((a, b) => (a.label > b.label ? 1 : -1))

    callback(sortedAndMappedLanguages);
  };

  let languagesPromiseTimeout;
  const languagesPromiseOptions = function (inputValue) {
    if (languagesPromiseTimeout !== null) {
      clearTimeout(languagesPromiseTimeout);
    }

    if (! inputValue) {
        return [];
    }

    return new Promise((resolve) => {
      languagesPromiseTimeout = setTimeout(() => {
        getLanguages(inputValue, resolve);
      }, 1000);
    });
  }

  if (!languages) return

  return(
    <div className='chooser language-search-filter'>
      <Async
        className="select-filter"
        classNamePrefix="select-filter"
        cacheOptions
        defaultOptions={languages}
        isMulti={true}
        loadOptions={languagesPromiseOptions}
        placeholder={t('Please enter at least 1 symbol')}
        noOptionsMessage={()=> t('Please enter at least 1 symbol')}
        loadingMessage={() => t('Loading...')}
        name="countries"
        value={selected}
        onChange={(values) => onChange(values)}
        onMenuClose={() => onClose()}
      />
    </div>
  )
}
