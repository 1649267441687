export const initialState = {
  isModalOpen: false,
  platformsFilter: null,
  sourcesSubtypesFilter: null,
  countriesFilter: null,
  sourcesTypesFilter: null,
  affiliationCountryId: null,
  audienceFilter: null,
  sourceGroupFilter: null,
  sourceFilter: null,
}


const breakdownsReducer = (state=initialState, action) => {

  switch (action.type) {

    case 'OPEN_MODAL':
      return Object.assign({}, state, {
        isModalOpen: true
      })
    case 'CLOSE_MODAL':
      return Object.assign({}, state, {
        isModalOpen: false,
        platformsFilter: null,
        sourcesSubtypesFilter: null,
        countriesFilter: null,
        sourcesTypesFilter: null,
        affiliationCountryId: null,
        audienceFilter: null,
        sourceGroupFilter: null,
        sourceFilter: null,
      })
      
    case 'SET_PLATFORMS_FILTER':
      return Object.assign({}, state, {
        platformsFilter: action.platformsFilter
      })

    case 'SET_COUNTRIES_FILTER': 
      return Object.assign({}, state, {
        countriesFilter: action.countriesFilter
      })

    case 'SET_TYPES_FILTER':
      return Object.assign({}, state, {
        sourcesTypesFilter: action.typesFilter
      })

    case 'SET_SUBTYPES_FILTER': 
      return Object.assign({}, state, {
        sourcesSubtypesFilter: action.subtypesFilter
      })
    
    case 'SET_AFFILIATION_COUNTRY_ID':
      return Object.assign({}, state, {
        affiliationCountryId: action.countryId
      })

      case 'SET_AUDIENCE_FILTER':
        return Object.assign({}, state, {
          audienceFilter: action.audienceRange
        })

      case 'SET_SOURCE_GROUP_FILTER':
        return Object.assign({}, state, {
          sourceGroupFilter: action.sourceGroupId
        })

      case 'SET_SOURCE_FILTER':
        return Object.assign({}, state, {
          sourceFilter: action.sourceId
        })
    default: 
      return state;
  }
}

export default breakdownsReducer;
