import { useTranslation } from 'react-i18next';

import { ReactComponent as Chevron } from '../assets/chevron-down.svg';
import './Filter.scss';
import styles from './Filter.module.scss';

export const Collapsible = ({name, children, onChange, summ, exclude, isOpen=true}) => {
  const { t } = useTranslation();

  let content = null;

  if (isOpen) {
    content = (
      <>
        {children}
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerSecondary} onClick={() => onChange(!isOpen)}>
        <span>
          <span className={summ > 0 ? (exclude ? styles.exclude : styles.include) : styles.headerName}>{t(name)}</span>
          {summ > 0 ? <span className={`${exclude ? styles.excludeSumm : styles.includeSumm} ${styles.summ}`}>{summ}</span> : ''}
        </span>
        <Chevron 
          className={isOpen ? 'chevron-icon open' : 'chevron-icon'}
          onClick={() => onChange(!isOpen)}
        />
      </div>
      {content}
    </div>
  )
}