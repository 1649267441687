import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../contexts/CurrentUser';

export const ThreatsSubjectsChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const isUcbiUser =
    currentUser?.workspace?.config?.is_ucbi_admin ||
    currentUser?.workspace?.config?.is_reporter_only_user;
  const isSuperAdmin = currentUser.is_super_admin;

  const subjects = [
    {
      value: 'POLITICS',
      label: t('Politics'),
    },
    {
      value: 'FINANCE',
      label: t('Economy and finance'),
    },
    {
      value: 'PUBLIC_COMPANIES',
      label: t('Public companies'),
    },
    {
      value: 'ELECTIONS',
      label: t('Elections'),
    },
    {
      value: 'THREAT',
      label: t('Threat'),
    },
    {
      value: 'DEFENSE',
      label: t('Defence'),
    },
    {
      value: 'CYBER',
      label: t('Cyber'),
    },
    {
      value: 'EUROPEAN_INTEGRATION', 
      label: t('European Integration'),
    },
    {
      value: 'UKRAINE__NATO', 
      label: t('Ukraine <> NATO'),
    },
    {
      value: 'HEALTHCARE', 
      label: t('Healthcare'),
    },
    {
      value: 'SOCIAL_CULTURAL', 
      label: t('Social/cultural'),
    },
    {
      value: 'DECOLONIZATION', 
      label: t('Decolonization'),
    },
    {
      value: 'ENERGY_INDEPENDENCE', 
      label: t('Energy Independence'),
    },
    {
      value: 'ANTI_CORRUPTION', 
      label: t('Anti-corruption'),
    },
    {
      value: 'ECOLOGY', 
      label: t('Ecology'),
    },
    {
      value: 'POST_WAR_RECOVERY', 
      label: t('Reconsruction of Ukraine'),
    },
    {
      value: 'BUSINESS_AND_INVESTMENTS', 
      label: t('Business & investments'),
    },
    {
      value: 'PUBLIC_SERVICES', 
      label: t('Public services'),
    },
    {
      value: 'VOLUNTEERS', 
      label: t('Volunteers'),
    },
    {
      value: 'NATIONAL_MINORITIES', 
      label: t('National minorities'),
    },
    {
      value: 'COLLABORANTS', 
      label: t('Collaborants'),
    },
    {
      value: 'LOCAL_GOVERNMENT', 
      label: t('Local Governance'),
    },
    {
      value: 'WEAPON_SUPPLY', 
      label: t('Weapon supply'),
    },
    {
      value: 'UKRAINE__PARTNERS', 
      label: t('Ukraine <> Partners'),
    },
    {
      value: 'REFORMS', 
      label: t('Reforms'),
    },
    {
      value: 'REGION_SPECIFIC', 
      label: t('Region-specific'),
    },
    {
      value: 'RUSSIAN_NARRATIVES', 
      label: t('Russian narratives'),
    },
    {
      value: 'MOBILIZATION', 
      label: t('Mobilization'),
    },
    {
      value: 'CONSTITUTIONAL_STATE',
      label: 'Constitutional State',
    },
    {
      value: 'DEFENSE_AND_SECURITY',
      label: 'Defense and Security',
    },
    {
      value: 'TECH',
      label: 'Tech'
    },
    {
      value: 'SPORT',
      label: 'Sport'
    },
    {
      value: 'AUTOMOTIVE',
      label: 'Automotive'
    },
    {
      value: 'AEROSPACE',
      label: 'Aerospace'
    },
    {
      value: 'ENTERTAINMENT',
      label: 'Entertainment'
    },
    {
      value: 'PHARMACEUTICS',
      label: 'Pharmaceutics'
    },
    {
      value: 'MEDIA',
      label: 'Media'
    },
    {
      value: 'RETAIL',
      label: 'Retail'
    },
    {
      value: 'FOOD',
      label: 'Food'
    },
    {
      value: 'ECOMMERCE',
      label: 'E-commerce'
    },
    {
      value: "PRESS_FREEDOM",
      label: "Freedom of press and speech"
    },
    {
      value: "LGBT",
      label: "LGBT+"
    },
    {
      value: "CORRUPTION",
      label: "Corruption"
    },
    {
      value: "RELIGIOUS_AFFAIRS",
      label: "Religious Affairs"
    },
    {
      value: "HUMAN_RIGHTS",
      label: "Human Rights"
    },
    {
      value: "CHINA",
      label: "China"
    },
    {
      value: "USA",
      label: "United States"
    },
    {
      value: "Israel_Palestina",
      label: "Israel - Palestina"
    },
    {
      value: "WAR_IN_UKRAINE",
      label: "War in Ukraine"
    },
    {
      value: "KAZAKH_IDENTITY",
      label: "Kazakh identity and sovereignty"
    },
    {
      value: "IRAN",
      label: "Iran"
    },
  ]

  if (isUcbiUser || isSuperAdmin) {
    subjects.push(
      {
        value: 'ARMED_FORCES_REFORM',
        label: 'Reform of the Armed Forces',
      },
      {
        value: 'ARMED_FORCES_CORRUPTION',
        label: 'Corruption in the Armed Forces',
      },
      {
        value: 'FRONTLINE_REPORT',
        label: 'Frontline report',
      },
      {
        value: 'PEACE_TALKS',
        label: 'Peace talks'
      },
    )
  }

  const handleSelect = () => {
    if (subjects.length !== selected.length) {
      onChange(subjects.map(type => type.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{subjects.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {subjects.map(subject => {
          return(
            <li key={subject.value}>
              <input 
                id={`subject_${subject.value}`}
                type='checkbox'
                checked={selected.includes(subject.value)}
                onChange={() => {
                  if (selected.includes(subject.value)) {
                    onChange(selected.filter(v => v !== subject.value)) 
                  } else {
                    onChange([...selected, subject.value]) 
                  }
                }}
              />
              <label htmlFor={`subject_${subject.value}`}>{t(subject.label)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}

