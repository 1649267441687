import cn from 'classnames';
import DOMPurify from 'dompurify';
import { useCallback, useEffect, useMemo, useState, useReducer } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { API } from '../../API';
import { Loader } from '../../components/Loader/Loader';
import { SourcesTable } from '../../components/SourcesTable/SourcesTable';
import { Search } from '../../components/Search/Search';
import { CheckboxField } from '../../components/Checkbox/CheckboxField';
import {ReactComponent as EditIcon} from '../../assets/edit.svg';
import { GroupEditModal } from '../GroupsPage/GroupEditModal';
import Tooltip from '../../components/Tooltip/Tooltip';
import styles from './GroupPage.module.scss';
import tableStyles from '../../components/SourcesTable/SourcesTable.module.scss'
import { useCurrentUser } from '../../contexts/CurrentUser';
import CopyGroupToWorkspacePopup from "../../components/CopyGroupToWorkspacePopup";
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import { ReactComponent as AddToIcon } from '../../assets/addTo.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { Filter } from '../SourcesPage/Filter/Filter';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import bulkPanelStyles from '../NarrativePage/NarrativePage.module.scss';
import { AddToSourceGroupModal } from '../../components/AddToSourceGroupModal/AddToSourceGroupModal';
import Modal from '../../components/Modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const sortingFieldNames = {
  ID: 'id',
  AUDIENCE: 'audience',
  ACTOR: 'name',
  PLATFORM: 'source_type',
  STATUS: 'status',
  URL: 'url',
  COUNTRY: 'country',
  RELEVANCE: 'relevance',
};

const sortingFieldNamesBack = {
  'id': 'ID',
  'audience': 'AUDIENCE',
  'name': 'ACTOR',
  'source_type': 'PLATFORM',
  'status': 'STATUS',
  'url': 'URL',
  'country': 'COUNTRY',
  'relevance': 'RELEVANCE',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'APPLY_FILTER': {
      return Object.assign({}, state, {
        platforms: action.payload.platforms,
        countries: action.payload.countries,
        audience_lte: action.payload.audience_lte,
        audience_gte: action.payload.audience_gte,
        status: action.payload.status,
        affiliationCountries: action.payload.affiliationCountries,
        IoCs: action.payload.IoCs,
        showOnlyBots: action.payload.showOnlyBots,
        sourceTags: action.payload.sourceTags,
        affiliationCountriesExclude: action.payload.affiliationCountriesExclude,
        countriesExclude: action.payload.countriesExclude,
        audienceExclude: action.payload.audienceExclude,
        page: action.payload.page
      })
    }

    case 'UPDATE_QUERY': {
      return Object.assign({}, state, {
        searchQuery: action.payload,
      })
    }

    case 'SORT_BY': {
      return Object.assign({}, state, {
        sorting: {...state.sorting, fieldName: action.fieldName}
      })
    }

    case 'IS_ASCENDING': {
      return Object.assign({}, state, {
        sorting: {...state.sorting, isAscending: action.isAscending}
      })
    }

    case 'CHANGE_PAGE': 
    return Object.assign({}, state, {
      page: action.page,
    })
    default: 
      return state;
  }
}

export function GroupPage() {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [sources, setSources] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editModal, setEditModal] = useState({isOpen: false, group: null});
  const [error, setError] = useState(null);
  const [copyGroupModal, setCopyGroupModal] = useState({
    isActive: false,
    workspaceId: null,
  });
  const [selectedSources, setSelectedSources] = useState([]);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({isActive: false, group: null, ids: []});
  const [copySelectedToGroupModal, setCopySelectedToGroupModal] = useState({isActive: false, group: null, ids: []});

  const [state, dispatch] = useReducer(reducer, {
    sorting: {isAscending: false, fieldName: sortingFieldNamesBack['relevance']},
    searchQuery: searchParams.get('q') ||'',
    platforms: [],
    countries: [],
    audience_lte: null,
    audience_gte: null,
    status: [],
    affiliationCountries: [],
    IoCs: [],
    showOnlyBots: false,
    sourceTags: [],
    affiliationCountriesExclude: false,
    countriesExclude: false,
    audienceExclude: false,
    page: searchParams.get('page') || 1
  });
  
  const sortingQuery = state.sorting.fieldName
    ? `${state.sorting.isAscending ? '' : '-'}${sortingFieldNames[state.sorting.fieldName]}`
    : null;

  const page = searchParams.get('page') ?? 1;
  const searchQuery = searchParams.get('q');
  const [dropdownMenu, setDropdownMenu] = useState(false);

  useEffect(() => {
    API.fetch('GET', `/API/v1/groups/${groupId}`).then(setGroup).catch(error => {
      setError(error.message)
    });
  }, [groupId]);


  const getSources = useCallback((
    searchQuery,
    platforms,
    countries,
    audience_lte,
    audience_gte,
    status,
    affiliationCountries,
    IoCs,
    showOnlyBots,
    sourceTags,
    affiliationCountriesExclude,
    countriesExclude,
    audienceExclude,
    sorting,
    page=1
  ) => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '100');
    urlParams.set('page', page);

    setSources(null);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }
  
    if (platforms?.length) {
      platforms.forEach((platform) => {
        urlParams.append('source_types', platform);
      });
    }
  
    if (countries?.length) {
      countries.forEach((country) => {
        urlParams.append('origin_country_ids', country);
      });
    }
  
    if(audience_lte !== null && audience_lte?.length > 0) {
      urlParams.set('audience_lte', audience_lte);
    }
  
    if(audience_gte !== null && audience_gte?.length > 0) {
      urlParams.set('audience_gte', audience_gte);
    }
  
    if(status?.length) {
      status.forEach((status_) => {
        urlParams.append('statuses', status_);
      });
    }
  
    if (affiliationCountries?.length) {
      affiliationCountries.forEach((country) => {
        urlParams.append('country_ids', country);
      });
    }
  
    if (IoCs?.length) {
      IoCs.forEach((IoC) => {
        urlParams.append('entity_types', IoC);
      });
    }
  
    if (showOnlyBots) {
      urlParams.set('is_bot', showOnlyBots);
    }
  
    if (sourceTags?.length) {
      sourceTags.forEach(tag => {
        urlParams.append('tags', tag)
      })
    }
    let excludedFilters = [];
    if (countriesExclude === true) {
      excludedFilters.push('origin_country_ids');
    }

    if (affiliationCountriesExclude === true) {
      excludedFilters.push('country_ids');
    }


    if (audienceExclude === true) {
      excludedFilters.push('audience_lte', 'audience_gte');
    }

    if (excludedFilters.length > 0) {
      excludedFilters.forEach((filter) => {
        urlParams.append('exclusive_filters', filter);
      });
    }

    if (sorting) {
      if (Array.isArray(sorting?.fieldName)) {
        sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${sorting?.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sortingQuery);
      }
    }

    urlParams.set('source_group_id', groupId);

    const url = `/API/v1/sources?${urlParams.toString()}`;

    API.fetch('GET', url).then(setSources);
  } , [groupId])


useEffect(getSources, [getSources])

  useEffect(() => {
    getSources(
      state.searchQuery,
      state.platforms,
      state.countries,
      state.audience_lte,
      state.audience_gte,
      state.status,
      state.affiliationCountries,
      state.IoCs,
      state.showOnlyBots,
      state.sourceTags,
      state.affiliationCountriesExclude,
      state.countriesExclude,
      state.audienceExclude,
      state.sorting,
      page
    );
  }, [state, page, getSources]);

  const checkIsSelected = (source) => {
    return selectedSources.some((item) => item.id === source.id);
  };

  const selectedSourcesHandler = (source, value) => {
    if (value) {
      setSelectedSources([...selectedSources, source]);
    } else {
      setSelectedSources(selectedSources.filter((item) => item.id !== source.id));
    }
  };

  const clearAllSelectedSources = () => {
    setSelectedSources([]);
  };

  const areAllIdsSelected = (sources, selectedSources) => {
    return sources.every((source) => selectedSources.some((item) => item.id === source.id));
  };

  const toggleAll = (sources, selectedSources) => {
    if (areAllIdsSelected(sources, selectedSources)) {
      setSelectedSources([]);
    } else {
      setSelectedSources(sources);
    }
  };

  const handleDeleteSources = () => {
    const sourceIds = deleteConfirmModal.ids;
    API.fetch('DELETE', `/API/v1/groups/${groupId}/sources`, null, {
      source_ids: sourceIds,
    }).then(() => {
      setDeleteConfirmModal({ isActive: false, group: null, ids: [] });
      setSelectedSources([]);
      getSources(
        state.searchQuery,
        state.platforms,
        state.countries,
        state.audience_lte,
        state.audience_gte,
        state.status,
        state.affiliationCountries,
        state.IoCs,
        state.showOnlyBots,
        state.sourceTags,
        state.affiliationCountriesExclude,
        state.countriesExclude,
        state.audienceExclude,
        state.sorting,
        state.tabIndex,
        page
      );
    });
  };

  const handleCopySourcesToGroup = (groupId) => {
    const sourceIds = copyGroupModal.sources.map((source) => source.id);
    API.fetch('POST', `/API/v1/groups/${groupId}/sources`, null, {
      source_ids: sourceIds,
    }).then(() => {
      setCopySelectedToGroupModal({ isActive: false, sources: [] });
      clearAllSelectedSources();
    });
  };

  const buttonsOnScroll = () => {
    if(Object.keys(selectedSources).length > 0) {
      const buttons = document.getElementById('bulk');
      let scrolly = window.pageYOffset;
      if (!buttons) {
        return
      }

      if(scrolly > 150) {
        buttons.classList.add(bulkPanelStyles.floating)
      } else {
        buttons.classList.remove(bulkPanelStyles.floating)
      }
    } else {
      return
    }
  }

  useEffect(() => {
      window.addEventListener('scroll', buttonsOnScroll);
      window.addEventListener('mousemove', buttonsOnScroll);

    return() => {
      window.removeEventListener('scroll', buttonsOnScroll);
      window.removeEventListener('mousemove', buttonsOnScroll);
    }
  })

  const handleEdit = (name, isPublic, group) => {
    API.fetch('PATCH', `/API/v1/groups/${group.id}`, null, {name: name, is_public: isPublic}).then(() => {
      API.fetch('GET', `/API/v1/groups/${groupId}`).then(setGroup);
    })
  }

  const newText = group?.description?.replace(/\n+(\s*\n+)?/g, '\n').split('\n');
  const sanitizedText = newText?.map(item => DOMPurify.sanitize(item, { ALLOWED_TAGS: ['mark', 'span'],  ALLOWED_ATTR: ['class']}));

  const clean = useMemo(
    // () => DOMPurify.sanitize(newText, { ALLOWED_TAGS: ['mark'] }),
    () => {
      return sanitizedText;
    },
    [sanitizedText, group],
  );

  if(!group && error) {
    return <div className='noData'>{t(error)}</div>
  }

  if (!group) {
    return <Loader />;
  }

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'ID',
    },
    {
      name: t('Audience'),
      value: 'AUDIENCE',
    },
    {
      name: t('Platform'),
      value: 'PLATFORM',
    },
    {
      name: t('Name'),
      value: 'ACTOR',
    },
    {
      name: t('Link'),
      value: 'URL',
    },
    {
      name: t('Country'),
      value: 'COUNTRY',
    },
    {
      name: t('Status'),
      value: 'STATUS',
    },
    {
      name: t('Relevance'),
      value: 'RELEVANCE',
    },
  ]

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]

  return (
    <div className={cn('list-content', styles.root)}>
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/groups">{t('Actor groups')}</Link>
          </span>
          <span>{group.name}</span>
        </div>
        <div className="controls">
          {currentUser?.is_super_admin === true ? (
            <Tooltip content={t('Copy')} position='bottom'>
              <button
                type="button"
                className={cn('new-button')}
                onClick={() =>
                  setCopyGroupModal({
                    isActive: true,
                  })
                }
              >
                <CopyIcon/>
              </button>
            </Tooltip>
          ) : (
            ''
          )}

          {group?.owned_by_workspace ? <Tooltip content={t('Edit')} position="bottom">
            <Link
              to={`/groups/${group.id}/edit`}
              className='button new-button'
            >
              <EditIcon />
            </Link>
          </Tooltip> : ''}
        </div>
      </div>

      {group?.owned_by_workspace ? <div className="next-card-header">
        <div className="row">
          <h1></h1>
          {selectedSources?.length > 0 ? (
            <div className={bulkPanelStyles.bulkButtonsWrapper} id="bulk">
              <button>
                {selectedSources?.length} {t('selected')}{' '}
                <span
                  className={bulkPanelStyles.clear}
                  onClick={() => clearAllSelectedSources()}
                >
                  <CloseIcon />
                </span>{' '}
              </button>
              <button
                onClick={() =>
                  setCopySelectedToGroupModal({
                    isActive: true,
                    sources: selectedSources,
                  })
                }
              >
                <CopyIcon />
                {t('Copy to group')}
              </button>
              <button
                onClick={() =>
                  setDeleteConfirmModal({
                    isActive: true,
                    group: group,
                    ids: selectedSources.map((source) => source.id),
                  })
                }
              >
                <DeleteIcon />
                {t('Delete')}
              </button>
            </div>
          ) : (
            ''
          )}
        </div>

        {group?.description?.length > 0
        ? <div className='report-section'>
          <h3>{t('Description')}</h3>
          <div className={styles.descriptionCard}> 
            {clean.map((item, i) => <p key={`paragraph_${i}`} dangerouslySetInnerHTML={{ __html: item }}></p>)}
          </div>
         
          </div> 
        : ''}

        <div className={styles.searchWrapper}>
          <Search
            className={styles.searchInput}
            onChange={value => {
              dispatch({type: 'UPDATE_QUERY', payload: value});
              dispatch({type: 'CHANGE_PAGE', page: 1})
              setSearchParams({ q: value});
            }}
          />

          <DropdownMenu  
            isOpen={dropdownMenu}
            header={t('Sort by')}
            onClick={() => setDropdownMenu(!dropdownMenu)}
            buttonName={t('Sort')}
            icon={<SortIcon/>}
          >
            <Radiobutton 
              itemsList={sortingMenu}
              current={state.sorting.fieldName}
              onChange={(value) => dispatch({type: 'SORT_BY', fieldName: value})}
            />

            <SortingOrder 
              itemsList={sortingOrder}
              onClick={value => dispatch({type: 'IS_ASCENDING', isAscending: value})}
              current={state.sorting.isAscending}
            />
          </DropdownMenu>


          <Filter
            state={state}
            onChange={(platforms, countries, audience, status, affiliationCountries, IoCs, showOnlyBots, sourceTags, affiliationCountriesExclude, countriesExclude, audienceExclude) => {
            dispatch({type: 'APPLY_FILTER', 
            payload: {
              platforms: platforms, 
              countries: countries, 
              audience_lte: audience[1], 
              audience_gte: audience[0],
              affiliationCountries: affiliationCountries,
              IoCs: IoCs,
              showOnlyBots: showOnlyBots,
              sourceTags: sourceTags,
              affiliationCountriesExclude: affiliationCountriesExclude,
              countriesExclude: countriesExclude,
              audienceExclude: audienceExclude,
              status: status,
              page: 1,
            }})
            if (state.searchQuery) {
              setSearchParams({ q: state.searchQuery, page: 1});
            } else {
              setSearchParams({ page: 1});
            }
          }}
          />
        </div>
        
      </div> : ''}

      <SourcesTable
        rowClassName={styles.tableRow}
        sources={sources}
        withHeader={true}
        searchQuery={state.searchQuery}
        checkIsSelected={checkIsSelected}
        renderPrefixRow={(source) => {
          const isSelected = checkIsSelected(source);

          if (! group?.owned_by_workspace) {
            return <td></td>;
          }

          return (
            <td className={tableStyles.tableCheckbox}>
              <CheckboxField
              className={styles.tableRow}
              checked={isSelected}
              onChange={(value) => {selectedSourcesHandler(source, value)}}
            />
            </td>
          );
        }}
        selectAllCheckbox={() => {
          if (! group?.owned_by_workspace) {
            return <td></td>;
          }
          return (
            <td className={tableStyles.tableCheckbox}>
              <CheckboxField
                // className={styles.tableRow}
                checked={areAllIdsSelected(sources?.objects, selectedSources)}
                onChange={() => {toggleAll(sources?.objects, selectedSources)}}
              />
            </td>
            
          );
        }}
        controls={(source) => {
          if (! group.owned_by_workspace) {
            return;
          }
          return (
            <button
              className={cn('button', 'btn-danger')}
              onClick={() => setDeleteConfirmModal({ isActive: true, group: group, ids: [source.id] })}
              data-tooltip-id="delete-source"
              data-tooltip-content={t('Delete')}
            >
              <DeleteIcon />
            </button>
          );
        }}
        onPageChange={(page) => {
          dispatch({type: 'CHANGE_PAGE', page: page})
        }}
      />

      {editModal.isOpen 
        ? <GroupEditModal
            editModal={editModal}
            handleEdit={(name, isPublic, group) => {
              handleEdit(name, isPublic, group)
              setEditModal({isOpen: false, group: null})
            }}
            clearEdit = {() => setEditModal({isOpen: false, group: null})}
          /> 
        : ''}

        {currentUser?.is_super_admin === true ? (
          <CopyGroupToWorkspacePopup
            isVisible={copyGroupModal.isActive}
            groupId={group.id}
            onClose={() => setCopyGroupModal({ isActive: false })}
          />
        ) : (
          ''
        )}
        {deleteConfirmModal.isActive ?<Modal
        isVisible={deleteConfirmModal.isActive}
        title={t('Confirm Deletion')}
        content={t('Are you sure you want to delete the selected sources from the group?')}
        footer={
          <>
            <button onClick={handleDeleteSources}>
              {t('Confirm')}
            </button>
            <button onClick={() => setDeleteConfirmModal({ isActive: false, group: null, ids: [] })}>
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => setDeleteConfirmModal({ isActive: false, group: null, ids: [] })}
      /> : ''}
      {copySelectedToGroupModal.isActive && (
        <AddToSourceGroupModal
          isOpen={copySelectedToGroupModal.isActive}
          sources={copySelectedToGroupModal.sources}
          onChange={(isOpen) => setCopySelectedToGroupModal({ isActive: isOpen, sources: [] })}
          onSave={handleCopySourcesToGroup}
        />
      )}
      <ReactTooltip id="delete-source" place="bottom"/>
    </div>
  );
}
