import styles from './LoaderSmall.module.scss';

export const LoaderSmall = () => {
  return (
    <span className={styles.spinner}>
      <span></span>
      <span></span>
      <span></span>
    </span>
  )
}