import { useEffect, useState, useContext, useCallback } from "react";
import * as d3 from 'd3';
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import {platformIconsMapping, platformNamesMapping} from '../../../utils/platforms';
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import { decodeSourceName } from '../../../utils/decodeURI';
import { ReactComponent as VerifiedTwitter } from '../../SourcePage/assets/verifiedTwitter.svg';
import Tooltip from "../../../components/Tooltip/Tooltip";
import { cutString } from "../../../utils/cutString";
import { ReactComponent as AnchorIcon } from '../../SourcePage/assets/anchor.svg';
import { Paginator } from "../../../components/Paginator/Paginator";
import styles from '../NarrativePage.module.scss';

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

export const TopsWidget = ({narrative, isShare, isClickable=true}) => {
  const { t } = useTranslation();
  const [actors, setActors] = useState(null);
  const [tags, setTags] = useState(null);
  const [actorsPage, setActorsPage] = useState(1);
  const [tagsPage, setTagsPage] = useState(1);
  const [widgetTab, setWidgetTab] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {openModal, setSourceFilter, setSourceGroupFilter} = useContext(BreakdownsContext);

  const api = isShare ? ShareAPI : API;
  const size = 15;

  const fetchTopsData = useCallback(() => {
    Promise.all([
      api.fetch('GET', `/API/v1/narratives/${narrative.id}/sources/by/posts?size=${size}`),
      api.fetch('GET', `/API/v1/narratives/${narrative.id}/source_groups?size=${size}`)
    ]).then(([actorsData, tagsData]) => {
      if(actorsData.objects?.length > 0) {
        setWidgetTab('actors')
      } else if(tagsData.objects?.length > 0) {
        setWidgetTab('tags')
      } else {
        setWidgetTab('actors')
      }

      setActors(actorsData);
      setTags(tagsData);
    }).catch(error => {
      console.error("Error fetching Tops widget data:", error);
    })

  }, [narrative])

  useEffect(() => {
    fetchTopsData();
  }, [fetchTopsData]);

  const getActors = (page) => {
    setIsLoading(true);
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/sources/by/posts?page=${page}&size=${size}`).then(
      data => {
        setActors(data);
        setIsLoading(false);
      }
    )
  }

  const getTags = (page) => {
    setIsLoading(true);
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/source_groups?page=${page}&size=${size}`).then(
      data => {
        setTags(data);
        setIsLoading(false);
      }
    )
  }


  return(
    <div className="report-section" style={{'width': '100%'}} id="actors"> 
      <h3 className="trend-chart-controlls">
        {t('Tops')}
        {widgetTab ? <button
            type="button"
            className={widgetTab === 'actors' ? 'active' : ''}
            onClick={() => {
              setWidgetTab('actors');
            }}
          >
          <span>{t('Actors')}</span>
          <span className={styles.tooltipControls}>
            <Tooltip 
              content={t('Top actors by number of posts')}
              position='right'
              > 
              <AnchorIcon />
            </Tooltip>
          </span>
        </button> : ''}
        {widgetTab ? <button
            type="button"
            className={widgetTab === 'tags' ? 'active' : ''}
            onClick={() => {
              setWidgetTab('tags');
            }}
          >
            <span>{t('Tags')}</span>
            <span className={styles.tooltipControls}>
            <Tooltip 
              content={t('Top tags by number of posts')}
              position='right'
              > 
              <AnchorIcon />
            </Tooltip>
          </span>
          </button> : ''}
          
        </h3>
        {!actors || !tags 
          ? <div className="card"><div className={styles.widdgetLoader}><LoaderSmall/></div></div>
          : actors?.objects?.length > 0 || tags?.objects?.length > 0 
          ? <div>
              {widgetTab === 'actors'
                ? <div>
                <BreakdownsContainer title='Top actors by number of posts' onClick={() => openModal()} details={false}>
                {isLoading 
                ? <div className={styles.widdgetLoader}><LoaderSmall /></div> 
                :(actors?.objects?.length > 0 
                  ? <div className={styles.topSourcesContainer}>
                      <div className={styles.nameHeader}><h5>{t('Actor')}</h5></div>
                      <div className={styles.countryHeader}><h5>{t('Country')}</h5></div>
                      <div className={styles.audienceHeader}><h5>{t('Audience')}</h5></div>
                      <div className={styles.messagesHeader}><h5>{t('Messages')}</h5></div>
                      <div className={styles.name}>
                        <div className={styles.breakdownsListMain}>
                          {actors.objects.map(source => {
                            return(
                              <div key={`topSources_${source?.id}`} className={styles.listItem}>
                                <div className={styles.info}>
                                  <span>{platformIconsMapping[source?.source_type]}</span>
                                  <span 
                                    className={styles.title}
                                    onClick={() => {
                                      setSourceFilter(source?.id)
                                      openModal()
                                    }}
                                    >
                                      <Tooltip content={source.name} position='right'>
                                        {cutString(source.name, 70)}
                                      </Tooltip>
                                    </span>
                                </div>
                                {/* <div className={styles.count}>{source.messages_count}</div> */}
                              </div>
                            )
                          })}
                        </div>
                        
                      </div>
                      
                      <div className={styles.country}>
                        <div className={styles.breakdownsListMain}>
                          {actors?.objects?.map(source => {
                            return(
                              <div key={`topSources_country_${source?.id}`} className={styles.listItem}>
                                <div className={styles.count}>{source?.origin_country ? source?.origin_country.name : ''} &nbsp; </div>
                              </div>
                            )
                          })}
                        </div>
                        
                      </div>

                      <div className={styles.audience}>
                        <div className={styles.breakdownsListMain}>
                          {actors?.objects?.map(source => {
                            let followers_cnt = source?.audience;

                            return(
                              <div key={`topSources_audience_${source?.id}`} className={styles.listItem}>
                                <div className={styles.count}>{followers_cnt === null ? '' : formatNumberSignificant(followers_cnt)} &nbsp;</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className={styles.messages}>
                        <div className={styles.breakdownsListMain}>
                            {actors?.objects?.map(source => {
                              return(
                                <div key={`topSources_messages_${source?.id}`} className={styles.listItem}>
                                  <div className={styles.count}>{source.messages_count}</div>
                                </div>
                              )
                            })}
                          </div>
                      </div>
                    </div>

                  : <div className={styles.noData}>{t('There is no data to display')}</div>
                  )}
                </BreakdownsContainer>
                <div className={styles.paginator}>
                  <Paginator
                    size={size}
                    page={actorsPage}
                    total={actors?.total}
                    id='actors'
                    onPageChange={(page) => {
                      setActorsPage(page);
                      getActors(page);
                    }}
                  />
                </div>
              </div> 
              : <div>
                {widgetTab === 'tags' && !isShare
                ? <div>
                   <BreakdownsContainer title='Top tags by number of posts' onClick={() => openModal()} details={false}>
                    {isLoading 
                      ? <div className={styles.widdgetLoader}><LoaderSmall /></div> 
                      : tags?.objects?.length > 0 
                      ? <div className={styles.topTagsContainer}>
                          <div className={styles.nameHeader}><h5>{t('Tag')}</h5></div>
                          <div className={styles.sourcesHeader}><h5>{t('Actors')}</h5></div>
                          <div className={styles.messagesHeader}><h5>{t('Messages')}</h5></div>
                          <div className={styles.name}>
                            <div className={styles.breakdownsListMain}>
                              {tags?.objects?.map(tag => {
                                return(
                                  <div key={`tags_name_${tag?.id}`} className={styles.listItem}>
                                    <div className={styles.info}>
                                      {/* <span>{platformIconsMapping[source?.source_type]}</span> */}
                                      <span 
                                        className={styles.title}
                                        onClick={() => {
                                          setSourceGroupFilter(tag.id)
                                          openModal()
                                        }}
                                        >
                                          <Tooltip content={tag.name} position='right'>
                                            {cutString(tag.name, 70)}
                                          </Tooltip>
                                        </span>
                                    </div>
                                    {/* <div className={styles.count}>{tag.messages_count}</div> */}
                                  </div>
                                )
                              })}
                            </div> 
                          </div>

                          <div className={styles.sources}>
                            <div className={styles.breakdownsListMain}>
                              {tags?.objects?.map(tag => {
                                return(
                                  <div key={`tags_name_${tag?.id}`} className={styles.listItem}>
                                    
                                    <div className={styles.count}>{formatNumberSignificant(tag.number_of_sources)}</div>
                                  </div>
                                )
                              })}
                            </div> 
                          </div>
                          <div className={styles.messages}>
                            <div className={styles.breakdownsListMain}>
                              {tags?.objects?.map(tag => {
                                return(
                                  <div key={`tags_name_${tag?.id}`} className={styles.listItem}>
                                    
                                    <div className={styles.count}>{tag.messages_count}</div>
                                  </div>
                                )
                              })}
                            </div> 
                          </div>
                      </div> : <div className={styles.noData}>{t('There is no data to display')}</div>}
                   </BreakdownsContainer>
                   <div className={styles.paginator}>
                    <Paginator
                      size={size}
                      page={tagsPage}
                      total={tags?.total}
                      id='actors'
                      onPageChange={(page) => {
                        setTagsPage(page);
                        getTags(page);
                      }}
                    />
                  </div>
                </div> : ''}
          </div>} 
      </div> : <div className="card"><div className={styles.noData}>{t('There is no data to display')}</div></div>}
        
    </div>
  )
}
