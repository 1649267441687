import * as d3 from 'd3';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useMemo, useEffect, useState, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { API, ShareAPI } from '../../../../API';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}


export const Summary = ({ report, isShare }) => { 
  const { t } = useTranslation();
  const [narrativeShares, setNarrativeShares] = useState(null);


  const fetchNarrativesShare = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    if (!report) return;

    const urlParams = new URLSearchParams();
    report.parameters.narrative_ids.forEach((narrative_id) =>
      urlParams.append('narrative_id', narrative_id),
    );
    if (report.parameters.start_date !== null) {
      urlParams.set(
        'start',
        report.parameters.start_date.split('T')[0] + 'T00:00:00'
      );
    }
    if (report.parameters.end_date !== null) {
      urlParams.set(
        'end',
        report.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    urlParams.set('aggregation', 'DAY');

    api
      .fetch(
        'GET',
        `/API/v1/stats/narratives/shares?${urlParams.toString()}`,
        null,
        null,
        null,
      )
      .then(data => {
        data.objects.sort((a, b) => (a.id - b.id));
        setNarrativeShares(data);
      });
  }, [isShare, report]);

  useEffect(fetchNarrativesShare, [fetchNarrativesShare]);

  if (!narrativeShares) {
    return <Loader />;
  }

  return (
    <div className="report-section">
      <h3>{t('Summary')}</h3>
      <div className="overview">
        <div className="card-wrapper color0">
          <span className="card-content__icon">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M11.7076 17.3639L10.2933 18.7781C8.34072 20.7308 5.1749 20.7308 3.22228 18.7781C1.26966 16.8255 1.26966 13.6597 3.22228 11.7071L4.63649 10.2929M17.3644 11.7071L18.7786 10.2929C20.7312 8.34024 20.7312 5.17441 18.7786 3.22179C16.826 1.26917 13.6602 1.26917 11.7076 3.22179L10.2933 4.636M7.50045 14.4999L14.5005 7.49994"
                stroke="#2563EB"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
          <div className="card-content">
            <div className="header">{t('Actors')}</div>
            <div className="body">{formatNumber(narrativeShares?.sources)}</div>
          </div>
        </div>
        <div className="card-wrapper color1">
          <span className="card-content__icon">
            <svg width="15" height="18" viewBox="0 0 15 18" fill="none">
              <path
                d="M9.125 1.21563V4.52006C9.125 4.9681 9.125 5.19212 9.21356 5.36325C9.29145 5.51378 9.41575 5.63616 9.56863 5.71286C9.74244 5.80006 9.96996 5.80006 10.425 5.80006H13.7811M10.75 9.8H4.25M10.75 13H4.25M5.875 6.6H4.25M4.9 17H10.1C11.4651 17 12.1477 17 12.6691 16.7384C13.1277 16.5083 13.5006 16.1412 13.7343 15.6896C14 15.1762 14 14.5041 14 13.16V6.63752C14 6.10172 13.785 5.58831 13.4032 5.21239L9.70885 1.57487C9.33471 1.20648 8.8307 1 8.30564 1H4.9C3.53487 1 2.85231 1 2.3309 1.26158C1.87225 1.49168 1.49936 1.85883 1.26567 2.31042C1 2.82381 1 3.49587 1 4.84V13.16C1 14.5041 1 15.1762 1.26567 15.6896C1.49936 16.1412 1.87225 16.5083 2.3309 16.7384C2.85231 17 3.53487 17 4.9 17Z"
                stroke="#2563EB"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
          <div className="card-content">
            <div className="header">{t('Messages')}</div>
            <div className="body">
              {formatNumber(narrativeShares?.publications)}
            </div>
          </div>
        </div>
        <div className="card-wrapper color2">
          <span className="card-content__icon">
            <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
              <path
                d="M1.26805 8.71318C1.12971 8.49754 1.06054 8.38972 1.02181 8.22342C0.992729 8.0985 0.992729 7.9015 1.02181 7.77658C1.06054 7.61028 1.12971 7.50246 1.26805 7.28682C2.41127 5.50484 5.81418 1 11 1C16.1858 1 19.5887 5.50484 20.7319 7.28682C20.8703 7.50246 20.9395 7.61028 20.9782 7.77658C21.0073 7.9015 21.0073 8.0985 20.9782 8.22342C20.9395 8.38972 20.8703 8.49754 20.7319 8.71318C19.5887 10.4952 16.1858 15 11 15C5.81418 15 2.41127 10.4952 1.26805 8.71318Z"
                stroke="#2563EB"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M11 11C12.6831 11 14.0475 9.65685 14.0475 8C14.0475 6.34315 12.6831 5 11 5C9.31692 5 7.95251 6.34315 7.95251 8C7.95251 9.65685 9.31692 11 11 11Z"
                stroke="#2563EB"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
          <div className="card-content">
            <div className="header">{t('Views')}</div>
            <div className="body">
              {formatWithCustomGigaBillion(narrativeShares?.impressions)}
            </div>
          </div>
        </div>
        <div className="card-wrapper color3">
          <span className="card-content__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M5.10176 17V8.2M1 9.8V15.4C1 16.2837 1.73457 17 2.6407 17H13.655C14.8697 17 15.9027 16.1357 16.0874 14.9649L16.9709 9.36494C17.2002 7.91112 16.0468 6.6 14.5384 6.6H11.6646C11.2115 6.6 10.8442 6.24183 10.8442 5.8V2.97267C10.8442 1.8832 9.93857 1 8.82137 1C8.5549 1 8.31343 1.15304 8.2052 1.3905L5.31829 7.72491C5.18662 8.01381 4.89283 8.2 4.56864 8.2H2.6407C1.73457 8.2 1 8.91634 1 9.8Z"
                stroke="#2563EB"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
          <div className="card-content">
            <div className="header">{t('Reactions')}</div>
            <div className="body">
              {formatWithCustomGigaBillion(narrativeShares?.engagement)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
