import { ThreatsProvider } from "./contexts/ThreatsContext";
import { ThreatsPage } from "./ThreatsPage";
import { InboxThreats } from "./InboxThreats";

export const ThreatsPageWrapper = () => {
  return (
    <ThreatsProvider>
      <ThreatsPage/>
    </ThreatsProvider>
  )
}