import { startTransition, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { API } from '../../../API';
import Async from 'react-select/async';
import { components } from 'react-select';
import FacebookIcon from '../../../assets/facebook.svg';
import TelegramIcon from '../../../assets/telegram.svg';
import VkontakteIcon from '../../../assets/vkontakte.svg';
import TwitterIcon from '../../../assets/twitter.svg';
import WebIcon from '../../../assets/web.svg';
import OkIcon from '../../../assets/odnoklassniki.svg';
import YoutubeIcon from '../../../assets/youtube.svg';
import TikTokIcon from '../../../assets/tiktok.svg';
import * as d3 from 'd3';

const platformIconsMapping = {
    TELEGRAM: TelegramIcon,
    VK: VkontakteIcon,
    TWITTER: TwitterIcon,
    WEB: WebIcon,
    FACEBOOK: FacebookIcon,
    ADMIXER: WebIcon,
    OK: OkIcon,
    YOUTUBE: YoutubeIcon,
    TIKTOK: TikTokIcon,
}
const formatNumberSignificant = d3.format('.3~s',);

export const SourceChooser = ({onChange, selected = [], onClose}) => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState("");
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleInputChanged = (input, { action }) => {
    if (action === "set-value") {
      return;
    }

    setDefaultOptions([]);
    setSearchKey(input);
  };

  const getSources = useMemo(() => debounce((inputValue, callback) => {
    API.fetch('GET', `/API/v1/sources?q=${inputValue}&sorting=-audience`).then((data) => {
      let optionsList = data.objects.map((source) => {
        let followers_cnt = source?.audience;

        return {
          value: source.id,
          label: source.name,
          icon: platformIconsMapping[source.source_type] || WebIcon,
          followers_count: followers_cnt || 0,
        };
      });
      setDefaultOptions(optionsList);
      callback(optionsList);
    });
  }, 1000), []);

  const sourcesPromiseOptions = (inputValue) => {
    if (!inputValue) {
      return [];
    }

    return new Promise((resolve) => {
      getSources(inputValue, resolve);
    });
  };

  const { Option } = components;
  const IconOption = props => {
    return <Option {...props}>
      <img
          src={props.data.icon}
          style={{width: 12, position: "relative", top: 2, marginRight: 2}}
          alt={props.data.label}
      /> {props.data.label} <b>({formatNumberSignificant(props.data.followers_count)})</b>
    </Option>
  };

  return(
    <div className='chooser source-search-filter'>
      <Async
        className="select-filter"
        classNamePrefix="select-filter"
        cacheOptions
        defaultOptions={defaultOptions}
        isMulti={true}
        components={{ Option: IconOption }}
        loadOptions={sourcesPromiseOptions}
        placeholder={t('Please enter at least 1 symbol')}
        noOptionsMessage={()=> t('Please enter at least 1 symbol')}
        loadingMessage={() => t('Loading...')}
        name="sources"
        value={selected}
        onChange={(values) => startTransition(() => onChange(values))}
        onMenuClose={() => onClose()}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        inputValue={searchKey}
        onInputChange={handleInputChanged}
      />
    </div>
  )
}