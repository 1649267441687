import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ActorIcon } from './assets/actorsBig.svg';
import { ReactComponent as PublicationIcon } from './assets/publicationsBig.svg';
import { ReactComponent as ViewsIcon } from './assets/viewsBig.svg';
import { ReactComponent as ReactionsIcon } from './assets/reactionsBig.svg';
import styles from './SummaryPanel.module.scss';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

const mapTitle = {
  allActors: { title: 'All actors', icon: <ActorIcon /> },
  inauthenticActors: { title: 'Inauthentic accounts', icon: <ActorIcon /> },
  actors: { title: 'Actors', icon: <ActorIcon /> },
  publications: { title: 'Publications', icon: <PublicationIcon /> },
  views: { title: 'Views', icon: <ViewsIcon /> },
  reactions: { title: 'Reactions', icon: <ReactionsIcon /> },
};

export const SummaryMainCard = ({ title, data, isActive, onClick }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        isActive
          ? `${styles.summaryMainCard} ${styles.active}`
          : styles.summaryMainCard
      }
      onClick={() => onClick()}
    >
      <div className={styles.summaryCardContainer}>
        <div className={styles.summaryMainCardHeader}>
          <div className={styles.summaryMainCardIcon}>
            {mapTitle[title]?.icon}
          </div>
        </div>

        <div className={styles.summaryMainCardBody}>
          <div className={styles.summaryMainCardTitle}>
            {t(mapTitle[title].title)}
          </div>
          <div className={styles.summaryMainCardData}>
            {formatWithCustomGigaBillion(data)}
          </div>
        </div>
      </div>
    </div>
  );
};
