export const affiliationTypesMap = {
  STATE_AGENCY: 'State agency',
  GOVERNMENT_OFFICIAL: 'Government official',
  STATE_OWNED_MEDIA: 'State owned media',
  REPORTEDLY_STATE_AFFILIATED: 'Reportedly state affiliated',
};

export const affiliationTypesOptions = [
  { value: 'STATE_AGENCY', label: 'State agency' },
  { value: 'GOVERNMENT_OFFICIAL', label: 'Government official' },
  { value: 'STATE_OWNED_MEDIA', label: 'State owned media' },
  { value: 'REPORTEDLY_STATE_AFFILIATED', label: 'Reportedly state affiliated' },
]

export const compromisedTypesMap = {
  INVOLVED_IN_INFLUENCE_OPERATIONS: 'Influence operations',
  HACKED_OR_STOLEN: 'Hackers / Hacktivists',
  SANCTIONS: 'Sanctions',
  DISINFORMATION: 'Disinformation',
}

export const compromisedTypesOptions = [
  { value: 'INVOLVED_IN_INFLUENCE_OPERATIONS', label: 'Influence operations' },
  { value: 'HACKED_OR_STOLEN', label: 'Hackers / Hacktivists' },
  { value: 'SANCTIONS', label: 'Sanctions' },
  { value: 'DISINFORMATION', label: 'Disinformation' },
]