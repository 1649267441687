import { useTranslation } from 'react-i18next';
import { contentTypesOptions } from '../../../utils/contentTypes';

export const ContentTypeChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const handleSelect = () => {
    if (contentTypesOptions.length !== selected.length) {
      onChange(contentTypesOptions.map(contentType => contentType.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{contentTypesOptions.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {contentTypesOptions.map(contentType => {
          return(
            <li key={contentType.value}>
              <input 
                id={`contentType_${contentType.value}`}
                type='checkbox'
                checked={selected.includes(contentType.value)}
                onChange={() => {
                  if (selected.includes(contentType.value)) {
                    onChange(selected.filter(v => v !== contentType.value))
                  } else {
                    onChange([...selected, contentType.value])
                  }
                }}
              />
              <label htmlFor={`contentType_${contentType.value}`}>{t(contentType.label)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}