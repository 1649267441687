import { useTranslation } from 'react-i18next';

export const OutputFormatChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const outputFormats = [
    {name: 'CSV', value: "csv"},
    {name: 'JSONL', value: "jsonl"},
    {name: 'XML', value: "xml"},
  ]

  const handleSelect = () => {
    if (outputFormats.length !== selected.length) {
      onChange(outputFormats.map(outputFormat => outputFormat.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{outputFormats.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {outputFormats.map(outputFormat => {
          return(
            <li key={outputFormat.value}>
              <input 
                id={`output_format_${outputFormat.value}`}
                type='checkbox'
                checked={selected.includes(outputFormat.value)}
                onChange={() => {
                  if (selected.includes(outputFormat.value)) {
                    onChange(selected.filter(v => v !== outputFormat.value))
                  } else {
                    onChange([...selected, outputFormat.value])
                  }
                }}
              />
              <label htmlFor={`export_output_format_${outputFormat.value}`}>{t(`export_output_format_${outputFormat.value}`)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}
