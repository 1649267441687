export const extractPlatformName = (url) => {
  try {
    const urlObject = new URL(url);
    const hostname = urlObject.hostname.toLowerCase();

    const platformMap = {
      'youtube.com': 'YouTube',
      'www.youtube.com': 'YouTube',
      'youtu.be': 'YouTube',
      'telegram.org': 'Telegram',
      'www.telegram.org': 'Telegram',
      't.me': 'Telegram',
      'facebook.com': 'Facebook',
      'www.facebook.com': 'Facebook',
      'fb.com': 'Facebook',
      'twitter.com': 'Twitter',
      'www.twitter.com': 'Twitter',
      't.co': 'Twitter',
      'app.osavul.cloud': 'Osavul'
    };

    if (platformMap[hostname]) {
      return platformMap[hostname];
    } else {
      return hostname;
    }
  } catch (error) {
    return 'Invalid URL';
  }
};