import './DropdownMenu.scss';

export const ButtonsList = ({itemsList, onClick}) => {
  return (
    <>
      <ul className='dropdown-menu-sorting-order'>
        {itemsList.map(item => {
          return(
            <li
              key={`sort_${item.name}`}
              onClick={() => onClick(item.value)}
            >
              <span>{item.name}</span>
            </li>
          )
        })}
      </ul>
    </>
    
  )
}