import * as d3 from 'd3';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { ampli } from '../../ampli';
import { format } from 'date-fns';
import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { API, ShareAPI } from "../../API";
import { useTranslation } from "react-i18next";
import styles from './GeoChart.module.scss';
import { LoaderSmall } from "../LoaderSmall/LoaderSmall";
import  { NarrativeTreeChartModal }  from '../../pages/NarrativePage/TreeChartModal/NarrativeTreeChartModal';


export const GeoChart = ({
  report,
  story,
  sourceGroups,
  countries,
  startDate,
  endDate,
  isShare,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [modal, setModal] = useState({isVisible: false, country: null});
  const [countriesData, setCountriesData] = useState(null);
  const [allCountries, setAllCountries] = useState(null);

  const fetchAllCountries = () => {
    API.fetch('GET', '/API/v1/countries').then(data => {
      setAllCountries(data);
    });
  };

  useEffect(() => {
    fetchAllCountries();
  }, []);

  const fetchCountriesData = () => {
    const urlParams = new URLSearchParams();
    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    API.fetch('GET', `/API/v1/topic-modeling/${report.id}/events/${story.id}/countries/breakdown?${urlParams.toString()}`).then(data => {
      setCountriesData(data);
    }
    )
  }

  useEffect(() => {
    fetchCountriesData();
  }, [])

  const getChartData = (data) => {
    if(data.objects.length === 0) return [['Country', 'Sentiment', 'Messages'], ['No data', 0, 0]];
    let chartData = [[t('Country'), t('Sentiment'), t('Messages')]];
    data?.objects?.forEach(country => {
      chartData.push([country.name, Math.floor(country.average_sentiment_score * 100), country.messages_count]);
    });
    return chartData;
  }

  const getCountryOption = (countryName) => {
    if (!allCountries) return null;
    let country = allCountries?.objects?.find(country => country.name === countryName);
    return country ? {value: country.id, label: country.name} : null;
  }

  const options = {
    // sizeAxis: { minValue: 0,},
    region: "world", // Western Europe
    displayMode: "markers",
    colorAxis: { colors: ["#EF4444", "#F5D90A", '#41B871'], values: [-100, 0, 100]},
    keepAspectRatio: true,
    magnifyingGlass: {enable: true, zoomFactor: 7.5},
    datalessRegionColor: '#e9effd',
    legend: 'none',
    markerOpacity: 0.7,
  };

  if (!countriesData) {
    return <div className={styles.widgetLoader}> <LoaderSmall /> </div>
  }

  return (
    <div className={styles.chartWrapper}>
      <Chart
        chartEvents={[
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              const dataTable = chartWrapper.getDataTable();
              if (selection.length > 0) {
                var row = selection[0].row;
                let country = dataTable.getValue(row, 0); // Get country name from the selected row
                setModal({
                  isVisible: true,
                  country: country,
                });
                ampli.track({
                  event_type: 'Open wiget popup in event',
                  event_properties: {
                    user_id: currentUser?.id,
                    workspace_id: currentUser?.workspace_id,
                    report_id: report?.id,
                    popup_type: 'Geochart',
                    popup_value: 'Country: ' + country,
                  }
                });
                console.log('Selected country: ' + country);
              }
            },
          },
        ]}
        options={options}
        chartType="GeoChart"
        width="100%"
        height="500px"
        data={getChartData(countriesData)}
        mapsApiKey="AIzaSyD_zlgApSQgpEZHNntjwmQC1lcwU9c9fms"
        chartVersion="51"
      />

      {countriesData.objects.length === 0 ? (
        <div className={styles.noDataContainer}>
          {t('There is no data to display')}
        </div>
      ) : (
        ''
      )}

      {modal.isVisible ? (
        <NarrativeTreeChartModal
          infoModal={modal.isVisible}
          tabIndex={1}
          isChart={true}
          platformOption={null}
          type={null}
          subType={null}
          stateAffiliatedId={null}
          countryId={modal.country ? [getCountryOption(modal.country)] : null}
          // platformsOptions={platformOptions}
          audienceRange={null}
          narrative={report}
          sourceGroup={
            sourceGroups.length > 0
              ? sourceGroups?.map((sourceGroup) => sourceGroup.value)
              : null
          }
          source={null}
          sentimentOptions={null}
          eventIdsOptions={[story.id]}
          startDate={startDate}
          endDate={endDate}
          contentTypeOptions={null}
          isShare={isShare}
          onChange={(bool) => {
            setModal({ isVisible: bool, country: null });
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
};