import { ReactComponent as PostIcon } from '../assets/post.svg';
import { ReactComponent as ArticleIcon } from '../assets/article.svg';
import { ReactComponent as CommentIcon } from '../assets/comment.svg';
import { ReactComponent as ChatIcon } from '../assets/chat.svg';
import { ReactComponent as VideoIcon } from '../assets/video.svg';
import { ReactComponent as RepostIcon } from '../assets/repost.svg';
import { ReactComponent as AdIcon } from '../assets/ad.svg';

export const contentTypesMapping = {
  POST: 'Post',
  ARTICLE: 'Article',
  MESSAGE: 'Message',
  COMMENT: 'Comment',
  VIDEO: 'Video',
  REPOST: 'Repost',
  AD: 'Ad',
}

export const contentTypesOptions = [
  { value: 'POST', label: contentTypesMapping['POST'] },
  { value: 'ARTICLE', label: contentTypesMapping['ARTICLE'] },
  { value: 'MESSAGE', label: contentTypesMapping['MESSAGE'] },
  { value: 'COMMENT', label: contentTypesMapping['COMMENT'] },
  { value: 'VIDEO', label: contentTypesMapping['VIDEO'] },
  { value: 'REPOST', label: contentTypesMapping['REPOST'] },
  { value: 'AD', label: contentTypesMapping['AD'] },
];

export const contentTypesIconsMapping = {
  POST: <PostIcon />,
  ARTICLE: <ArticleIcon/>,
  MESSAGE: <ChatIcon />,
  COMMENT: <CommentIcon/>,
  VIDEO: <VideoIcon/>,
  REPOST: <RepostIcon />,
  AD: <AdIcon />,
}