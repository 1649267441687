import * as d3 from 'd3';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO, isSameDay, isSameYear } from 'date-fns';
import { API } from '../../API';
import { ReactComponent as AnchorIcon } from '../../assets/anchor.svg';
import { ReactComponent as RatingIcon } from '../../assets/rating.svg';
import { ReactComponent as PositiveIcon } from '../../assets/positiveSentiment.svg';
import { ReactComponent as NegativeIcon } from '../../assets/negativeSentiment.svg';
import { ReactComponent as NeutralIcon } from '../../assets/neutralSentiment.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import {ReactComponent as ExpandIcon} from '../../assets/expand.svg';
import { enGB, uk } from 'date-fns/locale';
import { AvatarGroup } from '../AvatarGroup/AvatarGroup';
import Modal from '../Modal';
import 'react-tooltip/dist/react-tooltip.css';
import { WidgetContentModal } from '../WidgetContentModal/WidgetContentModal';

import { Tooltip } from 'react-tooltip';

import styles from './StoryCard.module.scss';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');

export const StoryCard = ({
  report,
  story,
  isActive = false,
  isStory = true,
  isRead = false,
  isAdmin = false,
  onChange,
  onEdit,
  onDelete,
  onCheckboxClick,
  selectedEvents,
  viewMode='rating',
}) => {
  const { t } = useTranslation();
  const [storyData, setStoryData] = useState(story);
  const [title, setTitle] = useState(story.title);
  const [description, setDescription] = useState(story.description);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isWidgetContentModalOpen, setIsWidgetContentModalOpen] = useState(false);

  let dateLocale = enGB;
  let dateFormat = 'dd LLL yyyy';

  if (
    localStorage.getItem('i18nextLng') === 'ua-UK' ||
    localStorage.getItem('i18nextLng') === 'ru-RU' ||
    localStorage.getItem('i18nextLng') === 'ru' ||
    localStorage.getItem('i18nextLng') === 'uk'
  ) {
    dateLocale = uk;
  } else {
    dateLocale = enGB;
  }


  function formatEventDates(firstDate, lastDate) {
    const first = new Date(firstDate);
    const last = new Date(lastDate);
  
    if (isSameDay(first, last)) {
      return format(parseISO(firstDate + 'Z'), dateFormat, {
        locale: dateLocale,
      })
    }
  
    if (isSameYear(first, last)) {
      return `${format(parseISO(firstDate + 'Z'), 'd MMM', { locale: dateLocale})} - ${format(parseISO(lastDate + 'Z'), 'd MMM yyyy', { locale: dateLocale})}`;
    }
  
    return `${format(parseISO(firstDate + 'Z'), 'd MMM yyyy', { locale: dateLocale})} - ${format(parseISO(lastDate + 'Z'), 'd MMM yyyy', { locale: dateLocale})}`;
  }

  const sentimentMapEvents = {
    controversial: (opinion_balance) => {
      return (
        <>
          <div className={styles.storyCardOpinionBalancePositive}>
            {opinion_balance['positive_share']}%
          </div>
          <div className={styles.storyCardOpinionBalanceNegative}>
            {opinion_balance['negative_share']}%
          </div>
        </>
      );
    },
    mostly_positive: (opinion_balance) => {
      return (
        <div className={styles.storyCardOpinionBalancePositive}>
          {opinion_balance['positive_share']}%
        </div>
      );
    },
    mostly_negative: (opinion_balance) => {
      return (
        <div className={styles.storyCardOpinionBalanceNegative}>
          {opinion_balance['negative_share']}%
        </div>
      );
    },
    neutral: (opinion_balance) => {
      return (
        <div className={styles.storyCardOpinionBalanceNeutral}>
          {opinion_balance['neutral_share']}%
        </div>
      );
    },
  };

  const sentimentMapOpinions = {
    positive: {
      text: t('Positive'),
      className: styles.positive,
      icon: <PositiveIcon />,
    },
    negative: {
      text: t('Negative'),
      className: styles.negative,
      icon: <NegativeIcon />,
    },
    neutral: {
      text: t('Neutral'),
      className: styles.neutral,
      icon: <NeutralIcon />,
    },
  };

  if (isModalOpen) {
    const textarea = document.getElementById('modal-description');

    textarea?.addEventListener('input', function () {
      this.style.height = 'auto';
      this.style.height = this.scrollHeight + 'px';
    });
  }

  const editStory = (e) => {
    e.preventDefault();

    let body = {
      title: title,
      description: description,
    };

    API.fetch(
      'PATCH',
      `/API/v1/topic-modeling/${report.id}/events/${story.id}`,
      null,
      body,
    ).then((data) => {
      setStoryData(data);
      setIsModalOpen(false);
      onEdit(data);
    });
  };

  const deleteStory = (e) => {
    e.preventDefault();
    API.fetch(
      'DELETE',
    `/API/v1/topic-modeling/${report.id}/events`, null, {event_ids: [story.id]}
    ).then(() => {
      onDelete();
    });
  };

  return (
    <div
      className={
        isActive
          ? `${styles.storyCardContainer} ${styles.active}`
          : styles.storyCardContainer
      }
      onClick={() => onChange(story)}
    >
      <div className={styles.storyCardHeader}>
        {isStory ? (
          <div className={styles.storyCardDate}>
            {isAdmin ? (
              <input
                className={
                  selectedEvents.includes(story.id)
                    ? `${styles.adminCheckbox} ${styles.adminCheckboxChecked}`
                    : styles.adminCheckbox
                }
                type="checkbox"
                checked={selectedEvents.includes(story.id)}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  e.stopPropagation();
                  onCheckboxClick(story, e.target.checked);
                }}
              />
            ) : (
              ''
            )}
            {isRead ? (
              ''
            ) : (
              <div className={styles.bullet}>
                <div className={styles.bulletIcon}></div>
              </div>
            )}
            {formatEventDates(
              story?.first_message_date,
              story?.last_message_date,
            )}
          </div>
        ) : (
          ''
        )}
        {isAdmin ? (
          <div className={styles.controls}>
            <button
              className={styles.storyCardButton}
              onClick={(e) => {
                e.stopPropagation();
                setIsWidgetContentModalOpen(true);
              }}
            >
              <ExpandIcon />
            </button>
            <button
              className={styles.storyCardButton}
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen(true);
              }}
            >
              <EditIcon />
            </button>
            <button
              className={styles.storyCardButton}
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(true);
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={styles.storyCardHeader}>{storyData?.title}</div>
      <div className={styles.storyCardInfo}>
        {viewMode === 'rating' ? (
          <div className={styles.storyCardRatingContainer}>
            <div className={styles.storyCardRating}>
              <div className={styles.storyCardRatingIcon}>
                <RatingIcon />
              </div>
              <div className={styles.storyCardRatingValue}>
                {Math.round(story?.rating)}
              </div>
            </div>
            <div className={styles.storyCardHint}>
              <div className={styles.storyCardHintText}>{t('Rating')}</div>
              <div
                className={styles.storyCardHintIcon}
                data-tooltip-id="rating-tooltip"
                data-tooltip-content={
                  'Based on the number of reactions and comments'
                }
                data-tooltip-place="bottom-start"
              >
                <AnchorIcon />
                <Tooltip id="rating-tooltip" style={{ width: '200px' }} />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.opinionCardMessagesContainer}>
            <div className={styles.opinionCardMessages}>
              <div className={styles.opinionCardMessagesValue}>
                {formatNumberSignificant(story?.messages_count)}
              </div>
            </div>
            <div className={styles.storyCardHint}>
              <div className={styles.storyCardHintText}>{t('Messages')}</div>
            </div>
          </div>
        )}
        {viewMode === 'rating' ? (
          <div className={styles.storyCardSourcesContainer}>
            <AvatarGroup sources={story?.top_sources} />
            <div className={styles.storyCardHint}>
              <div className={styles.storyCardHintText}>{t('Actors')}</div>
              <div
                className={styles.storyCardHintIcon}
                data-tooltip-id="actors-tooltip"
                data-tooltip-content={
                  'Top actors based on the number of reactions and comments'
                }
                data-tooltip-place="bottom"
              >
                <AnchorIcon />
                <Tooltip id="actors-tooltip" style={{ width: '200px' }} />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {isStory ? (
          <div className={styles.storyCardOpinionBalanceContainer}>
            <div className={styles.storyCardOpinionBalance}>
              {sentimentMapEvents[story?.opinion_balance.sentiment](
                story?.opinion_balance,
              )}
            </div>
            <div className={styles.storyCardHint}>
              <div className={styles.storyCardHintText}>{t('Sentiment')}</div>
              {/* <div
                className={styles.storyCardHintIcon}
                data-tooltip-id="opinion-balance-tooltip"
                data-tooltip-content={`Based on ${formatNumberSignificant(
                  story?.opinion_balance.reactions_count,
                )} reactions`}
                data-tooltip-place="bottom-end"
              >
                <AnchorIcon />
                <Tooltip
                  id="opinion-balance-tooltip"
                  style={{ width: '250px' }}
                />
              </div> */}
            </div>
          </div>
        ) : (
          <div className={styles.storyCardSentimentContainer}>
            <div
              className={`${styles.storyCardSentiment} ${
                sentimentMapOpinions[story.sentiment.sentiment].className
              }`}
            >
              {sentimentMapOpinions[story.sentiment.sentiment].icon}
              {/* <div className={styles.storyCardSentimentText}>{sentimentMap[story.sentiment.sentiment].text}</div> */}
            </div>
            <div className={styles.storyCardHint}>
              <div className={styles.storyCardHintText}>{t('Sentiment')}</div>
              {/* <div
                className={styles.storyCardHintIcon}
                data-tooltip-id="opinion-balance-tooltip"
                data-tooltip-content={`Based on ${formatNumberSignificant(
                  story?.sentiment.reactions_count,
                )} reactions and ${formatNumberSignificant(
                  story?.sentiment.comments_count,
                )} comments`}
                data-tooltip-place="bottom-end"
              >
                <AnchorIcon />
                <Tooltip
                  id="opinion-balance-tooltip"
                  style={{ width: '250px' }}
                />
              </div> */}
            </div>
          </div>
        )}
      </div>
      {isModalOpen ? (
        <Modal
          isVisible={isModalOpen}
          title={t('Edit event')}
          className={styles.storyCardModal}
          content={
            <form
              className={styles.modalForm}
              id="modal-form"
              onSubmit={(e) => editStory(e)}
            >
              <div className="form-element">
                <input
                  type="text"
                  required
                  placeholder={t('Title')}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className={styles.modalInput}
                />
              </div>
              <div className="form-element">
                <textarea
                  required
                  placeholder={t('Description')}
                  value={description}
                  id="modal-description"
                  onChange={(e) => setDescription(e.target.value)}
                  className={styles.modalTextarea}
                />
              </div>
            </form>
          }
          footer={
            <>
              <button
                className="new-button"
                form="modal-form"
                type="submit"
                disabled={
                  title.trim().length === 0 || description?.trim().length === 0
                }
                onClick={(e) => {
                  editStory(e);
                }}
              >
                {t('Save')}
              </button>
              <button
                className="btn-reset"
                onClick={() => {
                  setDescription(story.description);
                  setTitle(story.title);
                  setIsModalOpen(false);
                }}
              >
                {t('Cancel')}
              </button>
            </>
          }
          onClose={() => {
            setDescription(story.description);
            setTitle(story.title);
            setIsModalOpen(false);
          }}
        />
      ) : (
        ''
      )}

      {isDeleteModalOpen ? (
        <Modal
          isVisible={isDeleteModalOpen}
          title={t('Delete')}
          content={<p>{t('Are you sure you want to delete this event?')}</p>}
          footer={
            <>
              <button
                className="btn-danger"
                onClick={(e) => {
                  deleteStory(e);
                  setIsDeleteModalOpen(false);
                }}
              >
                {t('Delete')}
              </button>
              <button
                className="btn-reset"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                {t('Cancel')}
              </button>
            </>
          }
          onClose={() => setIsDeleteModalOpen(false)}
        />
      ) : (
        ''
      )}

      {isWidgetContentModalOpen ? (
        <WidgetContentModal
          infoModal={isWidgetContentModalOpen}
          tabIndex={1}
          isChart={true}
          platformOption={null}
          type={null}
          subType={null}
          stateAffiliatedId={null}
          countryId={null}
          // platformsOptions={platformOptions}
          audienceRange={null}
          narrative={report}
          sourceGroup={null}
          source={null}
          sentimentOptions={null}
          eventIdsOptions={[story.id]}
          startDate={null}
          endDate={null}
          contentTypeOptions={null}
          onChange={(bool) => {
            setIsWidgetContentModalOpen(bool);
          }}
          event={story}
        />
      ) : (
        ''
      )}
    </div>
  );
};
