import { useMemo, useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import styles from '../../../NarrativePage/NarrativePage.module.scss';
import { CircleChart } from '../../../../components/CircleChart/CircleChart';
import { useCurrentUser } from '../../../../contexts/CurrentUser';
import { palette } from '../../../../utils/colors';

export const BubbleChart = ({ dashboard, isShare}) => {

  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();

  if (!dashboard) {
    return <Loader />
  }

  const narrativeColor = Object.fromEntries(
    dashboard?.objects?.map((narrative, i) => [narrative.id, palette[i % palette.length]])
  );

  return (
    <div className="list-content">
      <div className="report-section">
        <h3>{t('Messages distribution')}</h3>
        <div className="card card-circlechartwrapper">
          <div className="card-header" />
          <div className="card-body">
            <CircleChart data={dashboard} narrativeColor={narrativeColor} />
          </div>
        </div>
      </div>
    </div>
  );
};
