import { useCallback, useEffect, useState, useContext } from 'react';
import { parseISO, formatISO } from 'date-fns';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThreatsContext } from './contexts/ThreatsContext';

import { ampli } from '../../ampli';
import Modal from '../../components/Modal';
import { Filter } from './Filter/Filter';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { Search } from '../../components/Search/Search';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { Tabs } from '../../components/Tabs/Tabs';
import { InboxThreats } from './InboxThreats';
import { ReportedThreats } from './ReportedThreats';
import { ModerationThreats } from './ModerationThreats';
import { Narratives } from './Narratives';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';

import styles from './ThreatsPage.module.scss';

export const ThreatsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();
  const { narrative, getNarrative, clearNarrative, toggleTab, cancelRequest } =
    useContext(ThreatsContext);

  let defaultIndex = 0;
  if (
    currentUser.is_super_admin ||
    currentUser?.workspace?.config?.is_ucbi_admin
  ) {
    defaultIndex = 2;
  } else if (currentUser?.workspace?.config?.is_reporter_only_user) {
    defaultIndex = 1;
  }

  let { narrativeID } = useParams();

  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [threatsMode, setThreatsMode] = useState();

  useEffect(() => {
    ampli.track({
      event_type: 'Open threats',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
      },
    });

    return () => {
      cancelRequest();
    };
  }, []);

  const tabslist = [
    {
      title: t('Inbox'),
      content: <InboxThreats narrativeID={narrativeID} />,
    },
    {
      title: t('Reported'),
      content: <ReportedThreats narrativeID={narrativeID} />,
    },
  ];

  if (
    currentUser.is_super_admin ||
    currentUser?.workspace?.config?.is_ucbi_admin
  ) {
    tabslist.push({
      title: t('Moderation'),
      content: <ModerationThreats narrativeID={narrativeID} />,
    });
  }

  useEffect(() => {
    if (!narrativeID) {
      clearNarrative();
      return;
    }

    getNarrative(narrativeID);
  }, [narrativeID]);

  if (
    !narrativeID &&
    (currentUser.is_super_admin ||
      currentUser?.workspace?.config?.is_ucbi_admin)
  ) {
    tabslist.push({
      title: t('Narratives'),
      content: <Narratives />,
    });
  }

  return (
    <>
      <div className="list-content">
        <div className="page-header">
          {narrative ? (
            <div className="breadcrumb">
              <span>
                <Link to='/threats'>
                  {t('Narratives')}
                </Link>
              </span>
              <span>{narrative.name}</span>
            </div>
          ) : (
            <>
              <div className="breadcrumb">
                <span>
                  {currentUser?.workspace?.config?.is_ucbi_admin ||
                  currentUser?.workspace?.config?.is_reporter_only_user
                    ? t('Instances')
                    : t('Threats')}
                </span>
              </div>
              {currentUser?.workspace?.config?.is_reporter_only_user ? (
                <div className="controls">
                  <Link to="/threats/new" className="button new-button">
                    <PlusIcon />
                    {t('Report an instance')}
                  </Link>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </div>

        <Tabs
          tabslist={tabslist}
          defaultIndex={tabIndex}
          // switchMode={messageModeList}
          onModeIndexChange={(index) => setThreatsMode(index)}
          onIndexChange={(index) => {
            toggleTab();
            setTabIndex(index);
            const params = new URLSearchParams();
            params.set('page', 1);
            navigate(`?${params.toString()}`);
          }}
        />
      </div>
    </>
  );
};
