import cn from 'classnames';
import * as d3 from 'd3';
import { useState, useEffect, useCallback } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { API } from '../../API';
import Modal from '../../components/Modal';
import { Paginator } from '../../components/Paginator/Paginator';
import { Loader } from '../../components/Loader/Loader';
import { Search } from '../../components/Search/Search';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { useCurrentUser } from '../../contexts/CurrentUser';
import Tooltip from '../../components/Tooltip/Tooltip';
import styles from './NarrativesPage.module.scss';

const sortingFieldNames = {
  ID: 'id',
  NAME: 'name',
  AUTHOR: ['last_name', 'first_name'],
  IS_ACTIVE: 'is_active',
  TOTAL_MESSAGES: 'total_messages',
  NEW_MESSAGES: 'new_messages',
};

const mapStatusClasses = {
  active: styles.active,
  inactive: styles.inactive,
};

export function NarrativesModerationPage() {
  const { t } = useTranslation();
  const [narratives, setNarratives] = useState(null);
  const [currentUser] = useCurrentUser();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isActive: false,
    id: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const searchQuery = searchParams.get('q');
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [sorting, setSorting] = useState({
    isAscending: false,
    fieldName: 'id',
  });
  const navigate = useNavigate();

  if (currentUser.type !== 'MODERATOR') {
    navigate('/');
  }

  const sortingQuery = sorting
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

  const fetchNarratives = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '100');
    urlParams.set('page', page);
    urlParams.set('is_threat', false);
    urlParams.set('review_status', 'PENDING');

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    if (sorting) {
      if (Array.isArray(sorting.fieldName)) {
        sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${sorting.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sortingQuery);
      }
    }

    API.fetch('GET', `/API/v1/narratives?${urlParams.toString()}`).then(
      (data) => {
        setNarratives(data);
      },
    );
  }, [page, searchQuery, sortingQuery, sorting]);

  const handleDelete = useCallback(
    (id) => {
      setDeleteConfirmModal(true);

      API.fetch('DELETE', `/API/v1/narratives/${id}`).then(fetchNarratives);
    },
    [fetchNarratives],
  );

  useEffect(fetchNarratives, [fetchNarratives]);

  if (!narratives) {
    return <Loader />;
  }

  const handleSortingClick = (fieldName) => {
    const isAscending =
      sorting && sorting.fieldName === fieldName ? !sorting.isAscending : true;

    setSorting({ isAscending, fieldName });
  };

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'id',
    },
    {
      name: t('Name'),
      value: 'name',
    },
    {
      name: t('Author'),
      value: 'last_name',
    },
    {
      name: t('Status'),
      value: 'is_active',
    },
  ];

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon />,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon />,
    },
  ];

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <div className="breadcrumb">
            <span>{t('Cases moderation')}</span>
          </div>
        </div>
      </div>

      {narratives?.objects?.length > 0 ? <div className="next-card-header">
        <div className={styles.searchWrapper}>
          <Search
            className={styles.searchInput}
            onChange={(value) => {
              setSearchParams({ q: value, page: 1 });
            }}
          />

          <DropdownMenu
            isOpen={dropdownMenu}
            header={t('Sort by')}
            onClick={() => setDropdownMenu(!dropdownMenu)}
            buttonName={t('Sort')}
            icon={<SortIcon />}
          >
            <Radiobutton
              itemsList={sortingMenu}
              current={sorting.fieldName}
              onChange={(value) => setSorting({ ...sorting, fieldName: value })}
            />

            <SortingOrder
              itemsList={sortingOrder}
              onClick={(value) =>
                setSorting({ ...sorting, isAscending: value })
              }
              current={sorting.isAscending}
            />
          </DropdownMenu>
        </div>
      </div> : ''}

      {narratives?.objects?.length > 0 ? <div className={styles.tableWrapper}>
        <table className={styles.cases}>
          <thead>
            <tr>
              <td>{t('Case')}</td>
              <td>{t('Case type')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {narratives?.objects?.map((narrative) => {
              let isNarrativeActive =
                narrative.is_active === true ? 'active' : 'inactive';

              let caseType = null;

              if (
                narrative.type === 'NARRATIVE' &&
                narrative.is_manual === true
              ) {
                caseType = t('Manual');
              } else if (narrative.type === 'NARRATIVE') {
                caseType = t('Narrative');
              } else if (narrative.type === 'INCIDENT') {
                caseType = t('Incident');
              }

              return (
                <tr key={narrative.id}>
                  <td>
                    <div className={styles.caseNameWrapper}>
                      <div className={styles.caseNameContainer}>
                        <span>
                          <Link to={`/narratives/${narrative.id}`}>
                            {narrative.name}
                          </Link>
                        </span>
                        <div className={styles.caseInfo}>
                          <span>#{narrative.id}</span>
                          <span>&#8226;</span>
                          <span>
                            {t('created by1 ')}
                            <span className={styles.userName}>
                              {narrative?.user?.first_name}{' '}
                              {narrative?.user?.last_name}
                            </span>
                          </span>
                          <span>&#8226;</span>
                          <div className={styles.statusWrapper}>
                            <span
                              className={`${mapStatusClasses[isNarrativeActive]}`}
                            >
                              {narrative.is_active
                                ? t('Active')
                                : t('Inactive')}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{caseType}</td>
                  <td>
                    <div className={styles.controls}>
                      <Tooltip content={t('Edit')} position="bottom">
                        <Link
                          to={`/narratives/${narrative.id}/edit`}
                          className="new-button"
                        >
                          <i className="lar la-edit"></i>
                        </Link>
                      </Tooltip>

                      <Tooltip content={t('Delete')} position="bottom">
                        <button
                          className="btn-danger"
                          onClick={() =>
                            setDeleteConfirmModal({
                              isActive: true,
                              id: narrative.id,
                            })
                          }
                        >
                          <i className="las la-trash"></i>
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> : <div className='noData'>{t('There is no data to display')}</div>}

      <Paginator
        size={narratives.size}
        page={narratives.page}
        total={narratives.total}
      />

      <Modal
        isVisible={deleteConfirmModal.isActive}
        title={t('Delete')}
        content={<p>{t('Are you sure you want to delete a narrative?')}</p>}
        footer={
          <>
            <button
              className="btn-danger"
              onClick={() => handleDelete(deleteConfirmModal.id)}
            >
              {t('Delete')}
            </button>
            <button
              className="btn-reset"
              onClick={() =>
                setDeleteConfirmModal({ isActive: false, id: null })
              }
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => setDeleteConfirmModal({ isActive: false, id: null })}
      />
    </div>
  );
}
