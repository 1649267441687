import { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { MessagesContext } from '../../contexts/MessagesContext';
import { CircleChartIcons } from "../../../../components/CircleChartIcons/CircleChartIcons";
import { useCurrentUser } from '../../../../contexts/CurrentUser';
import {ReactComponent as EditIcon} from '../../../../assets/edit.svg';
import {ReactComponent as DeleteIcon} from '../../../../assets/delete.svg';
import { palette } from '../../../../utils/colors';
import { ampli } from '../../../../ampli';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import { API, ShareAPI } from '../../../../API';
import Markdown from 'react-markdown';

export const BubbleChartIcons = ({
  narrative,
  isShare,
  notes,
  settings,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const {
    narrativeStats,
    fetchNarrativeStats,
    narrativeStatsStories,
    dispatch,
    translateMessage,
  } = useContext(MessagesContext);
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [note, setNote] = useState(notes?.length > 0 ? notes[0] : {text: "", position: "bottom"});
  const [isNote, setIsNote] = useState(notes?.length > 0);
  const componentRef = useRef(null);

  const chartTitle =
    settings.mode === 'countries'
      ? t('Messages distribution: Countries')
      : t('Messages distribution: Platforms');
  
  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : chartTitle;
    setTitle(newTitle);
  }, [settings]);
    
  const [title, setTitle] = useState(widgetSettings.title || chartTitle);
  

  useEffect(() => {
    if (!narrativeStats) {
      fetchNarrativeStats(narrative);
    } else {
      return;
    }
  }, []);

  const toggleTranslateChartMessage = (messageId, isTranslation, isStories) => {
    if (isStories === true) {
      dispatch({
        type: 'TOGGLE_TRANSLATE_STORIES_CHART_MESSAGES',
        payload: { messageId: messageId, isTranslation: isTranslation },
      });
    } else {
      dispatch({
        type: 'TOGGLE_TRANSLATE_CHART_MESSAGES',
        payload: { messageId: messageId, isTranslation: isTranslation },
      });
    }
  };

  const handleChartMessageTranslation = (
    messageId,
    text,
    isTranslation,
    isStories,
  ) => {
    let target;
    if (isStories === true) {
      target = narrativeStatsStories?.objects
        ?.map((obj) => obj.stories)
        .flat()
        .find((message) => message.id === messageId);
    } else {
      target = narrativeStats?.objects[0]?.messages?.find(
        (message) => message.id === messageId,
      );
    }

    if (target.translated) {
      toggleTranslateChartMessage(messageId, isTranslation, isStories);
    } else {
      const nativeLanguage = currentUser?.translation_language ? currentUser?.translation_language : narrative.parameters?.translate_keywords_query
        ? narrative.parameters?.keywords_query_origin_language
        : window.clientInformation.language.split('-', 1)[0];
      toggleTranslateChartMessage(messageId, true, isStories);
      translateMessage(messageId, text, nativeLanguage)
        .then((data) => {
          if (isStories === true) {
            dispatch({
              type: 'TRANSLATE_STORIES_CHART_MESSAGE',
              payload: { data: data, messageId: messageId },
            });
          } else {
            dispatch({
              type: 'TRANSLATE_CHART_MESSAGE',
              payload: { data: data, messageId: messageId },
            });
          }
        })
        .catch((e) => {
          if (isStories === true) {
            dispatch({
              type: 'TRANSLATION_ERROR_STORIES_CHART_MESSAGE',
              payload: {
                data: t('We were not able to translate this text.'),
                messageId: messageId,
              },
            });
          } else {
            dispatch({
              type: 'TRANSLATION_ERROR_CHART_MESSAGE',
              payload: {
                data: t('We were not able to translate this text.'),
                messageId: messageId,
              },
            });
          }
        });

      ampli.track({
        event_type: 'Translate circle chart message',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        },
      });
    }
  };

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t(chartTitle)}</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              let newNotes;
              setIsEditingMode(false);
              if (note.text.length > 0) {
                newNotes = [note];
              } else {
                newNotes = [];
              }
              onSettingsChange(widgetSettings, newNotes, componentRef.current);
            }}
            onReset={() => {
              componentRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
              setTitle(settings.title || chartTitle);
              setIsEditingMode(false);
              setWidgetSettings(settings);
              setNote(
                notes?.length > 0 ? notes[0] : { text: '', position: 'bottom' },
              );
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="platforms"
                    checked={isNote}
                    onChange={() => {
                      setIsNote(!isNote);
                    }}
                  />
                  <label htmlFor="create-note">{t('Create a note')}</label>
                </li>
              </ul>
            </div>
            {isNote ? (
              <div className="form-element">
                <div className="form-element">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        id="platforms"
                        checked={note?.position === 'top'}
                        onChange={() => {
                          if (note?.position === 'top') {
                            setNote({ ...note, position: 'bottom' });
                          } else {
                            setNote({ ...note, position: 'top' });
                          }
                        }}
                      />
                      <label htmlFor="note-position">
                        {t('Place a note on the top of the widget')}
                      </label>
                    </li>
                  </ul>
                </div>

                <label htmlFor="note">{t('Note')}</label>
                <TextEditor
                  id={'note-editor'}
                  value={note.text}
                  onChange={(text) => setNote({ ...note, text: text })}
                  isOpen={true}
                />
              </div>
            ) : (
              ''
            )}
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="list-content widget-container">
        <div className="report-section">
          <div className="report-section__header">
            <h3>{widgetSettings.title ? widgetSettings.title : chartTitle}</h3>
            <div className="report-section__controls">
              <button
                className="report-control-button"
                onClick={() => setIsEditingMode(true)}
              >
                <EditIcon />
              </button>
              <button
                className="report-control-button danger"
                onClick={() => onWidgetStatusChange(false)}
              >
                <DeleteIcon />
              </button>
            </div>
          </div>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'top' ? (
                      <div className="report-note-container note-position-top">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}
          <div className="card card-circlechartwrapper">
            <div className="card-header" />
            <div className="card-body">
              {narrativeStats ? (
                <CircleChartIcons
                  isShare={isShare}
                  currentUser={currentUser}
                  data={narrativeStats}
                  mode={settings.mode}
                  id={settings.mode}
                  onCircleClick={(messageID) => {
                    ampli.track({
                      event_type: 'Open bubble in messages bubble-chart',
                      event_properties: {
                        user_id: currentUser?.id,
                        workspace_id: currentUser?.workspace_id,
                        narrative_id: narrative.id,
                      },
                    });
                  }}
                  narrativeColor={{
                    [narrative.id]: palette[0 % palette.length],
                  }}
                  onTranslationClick={(messageId, text, bool) =>
                    handleChartMessageTranslation(messageId, text, bool, false)
                  }
                />
              ) : (
                <Loader />
              )}
            </div>
          </div>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'bottom' ? (
                      <div className="report-note-container note-position-bottom">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}
        </div>
      </div>
    );
  }
};
