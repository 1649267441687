import styles from './PowerBIDashboardsPage.module.scss';
import { useCallback, useEffect, useState } from "react";
import { API } from "../../API";
import { Loader } from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function PowerBIDashboardsPage() {
  const { t } = useTranslation();

  const [dashboards, setDashboards] = useState(null);

  const fetchData = useCallback(() => {
    API.fetch('GET', `/API/v1/powerbi-dashboards/`).then(
      (data) => {
        setDashboards(data.objects);
      },
    );
  }, []);
  useEffect(fetchData, [fetchData]);

  if (dashboards === null) {
    return <Loader />;
  }

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>{t('Dashboards')}</span>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          {dashboards.length > 0 ? <table className={styles.dashboards}>
            <thead>
            <tr>
              <td>{t('Name')}</td>
              <td>{t('Description')}</td>
            </tr>
            </thead>
            <tbody>
            {dashboards.map((dashboard) => {
              return (
                <tr key={dashboard.id}>
                  <td>
                    <Link to={`/dashboards/${dashboard.id}`}>{dashboard.name}</Link>
                  </td>
                  <td>{dashboard.description}</td>
                </tr>
              );
            })}
            </tbody>
          </table> : <div className='noData'>{t('You have no available dashboards yet')}</div>}
        </div>
      </div>
    </div>
  )
}
