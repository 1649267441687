import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Tabs.scss';

export const Tabs = ({
  tabslist,
  heading,
  defaultIndex = 0,
  onIndexChange = () => {},
  switchMode,
  activeMode,
  onModeIndexChange = () => {}
}) => {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(defaultIndex);
  const [activeModeIndex, setActiveModeIndex] = useState(activeMode || 0);

  useEffect(() => {
    if (activeTabIndex >= tabslist.length) {
      setActiveTabIndex(0);
      onIndexChange(0);
    }
  }, [tabslist]);

  return (
    <div className="tabs-container">
      <div className='tabs-header-wrapper'>
        <ul className="tabs-header">
          {heading?.length > 0 ? <span className="heading">{heading}</span> : ''}
          {tabslist.map((tab, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  setActiveTabIndex(index);
                  onIndexChange(index);
                }}
                className={
                  index === activeTabIndex ? 'tabs-item active' : 'tabs-item'
                }
              >
                {tab.icon}
                <span>{tab.title}</span>
              </li>
            );
          })}
        </ul>
        {switchMode?.length > 0 ? <div className='tabs-switch-mode'>
         {switchMode.map((mode, index) => {
            return( 
              <span 
                key={index}
                className={index === activeModeIndex ? 'tabs-active-mode' : ''}
                onClick={() => {
                  setActiveModeIndex(index);
                  onModeIndexChange(index);
                }}

              >
                {mode.icon}
              </span>
            )}
          )}
        </div> : ''}
      </div>
      <div className="tabs-body">{tabslist[activeTabIndex]?.content}</div>
    </div>
  );
};
