import { useTranslation } from 'react-i18next';

export const GeoScopeChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const geoScopeOptions = [
    {
      value: 'Ukraine',
      label: t('Ukraine'),
    },
    {
      value: 'Russia',
      label: t('Russia'),
    },
    {
      value: 'Kherson region',
      label: t('Kherson region'),
    },
    {
      value: 'Zaporizhya region',
      label: t('Zaporizhya region'),
    },
    {
      value: 'Donetsk region',
      label: t('Donetsk region'),
    },
    {
      value: 'Luhansk region',
      label: t('Luhansk region'),
    },
    {
      value: 'Mykolayiv region',
      label: t('Mykolayiv region'),
    },
    {
      value: 'Kharkiv region',
      label: t('Kharkiv region'),
    },
    {
      value: 'Crimea',
      label: t('Crimea'),
    },
    {
      value: 'ToT',
      label: t('ToT'),
    },
    {
      value: 'Moldova',
      label: t('Moldova'),
    },
  ];
  
  const handleSelect = () => {
    if (geoScopeOptions.length !== selected.length) {
      onChange(geoScopeOptions.map(type => type.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{geoScopeOptions.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {geoScopeOptions.map(scope => {
          return(
            <li key={scope.value}>
              <input 
                id={`scope_${scope.value}`}
                type='checkbox'
                checked={selected.includes(scope.value)}
                onChange={() => {
                  if (selected.includes(scope.value)) {
                    onChange(selected.filter(v => v !== scope.value)) 
                  } else {
                    onChange([...selected, scope.value]) 
                  }
                }}
              />
              <label htmlFor={`scope_${scope.value}`}>{t(scope.label)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}

