import { useState, useEffect } from 'react';
import { ampli } from '../../ampli';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { StoryCard } from '../StoryCard/StoryCard';
import { ReactComponent as ListIcon } from '../../assets/list.svg';
import { ReactComponent as MessageIcon } from '../../pages/NarrativePage/assets/messages.svg';
import { ReactComponent as ArrowIcon } from '../../pages/NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../../pages/NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as MergeIcon } from '../../pages/NarrativePage/assets/merge.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import {ReactComponent as SearchIcon} from '../../assets/search.svg';
import { ReactComponent as AddToIcon} from '../../assets/addTo.svg';
import { ReactComponent as AnchorIcon } from '../../assets/anchor.svg';
import { API } from '../../API';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import Modal from '../Modal';
import Tooltip from '../Tooltip/Tooltip';
import { Search } from '../Search/Search';

import { Calendar } from '../../pages/SearchPage/Calendar/Calendar';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { Radiobutton } from '../DropdownMenu/Radiobutton';
import { SortingOrder } from '../DropdownMenu/SortingOrder';
import { Filter } from './Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AddToDashboardModal } from '../AddToDashboardModal/AddToDashboardModal';

import styles from './StoriesFeed.module.scss';
import { SkeletonStoryCard } from '../StoryCard/SkeletonStoryCard';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { set } from 'date-fns';


export const StoriesFeed = ({
  report,
  stories,
  opinions = [],
  currentStory,
  onTabChange,
  onFilterChange,
  onStoryChange,
  onOpinionChange,
  onDateChange,
  onSortingChange,
  sourceGroups,
  countries,
  sentiment,
  startDate,
  endDate,
  sorting,
  searchQuery,
  page,
  onStoryEdit,
  onStoryDelete,
  hasMoreEvents,
  fetchMoreEvents,
  onSearch,
}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [activeTab, setActiveTab] = useState('stories');
  const [activeStory, setActiveStory] = useState();
  const [activeOpinion, setActiveOpinion] = useState(opinions[0]);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [read, setRead] = useState({});
  const [selectedEvents,  setSelectedEvents] = useState([]);
  const [mergeEventsModal, setMergeEventsModal] = useState({
    isActive: false,
    event_ids: [],
    new_summary: '',
    new_title: '',
    isLoading: false,
    error: null,
  });

  const [deleteEventsModal, setDeleteEventsModal] = useState({
    isActive: false,
    event_ids: [],
  });

  const [addToTopicModal, setAddToTopicModal] = useState({
    isActive: false,
    event_ids: [],
    report_id: null,
    error: null,
  });

  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if(showSearch === false && searchQuery?.trim().length > 0) {
      onSearch('');
    }
  }, [showSearch]);
  
  useEffect(() => {
      setActiveStory(currentStory);
      if (currentStory?.is_read === false && !read[currentStory?.id]) {
        setRead({...read, [currentStory.id]: true});
      }
    }, [currentStory]);

  // useEffect(() => {
  //   setActiveStory(stories[0]);
  //   setActiveOpinion(opinions[0]);
  //   onTabChange(activeTab);
  // }, [activeTab]);

  const handleDeleteEvents = (e, events) => {
    e.stopPropagation();
    e.preventDefault();
    API.fetch('DELETE', `/API/v1/topic-modeling/${report.id}/events`, null, {event_ids: events}).then(data => {
      setDeleteEventsModal({
        isActive: false,
        event_ids: [],
      });
      setSelectedEvents([]);
      onStoryDelete();
    });
  }

  const initStartDate = null;
  const initEndDate = null;

  const sortingMenu = [
    {
      name: t('Rating'),
      value: 'rating',
    },
    {
      name: t('Recency'),
      value: 'recency'
    },
    {
      name: t('Event first seen'),
      value: 'first_message_date',
    },
    {
      name: t('Event last update'),
      value: 'last_message_date',
    },
  ];

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon />,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon />,
    },
  ];

  if(report?.widget_config?.event_card === 'messages') {
    sortingMenu.push({
      name: t('Messages count'),
      value: 'messages_count',
    });
  }

  if (mergeEventsModal.isActive) {
    const textarea = document.getElementById('new-story-summary');

    textarea?.addEventListener('input', function () {
      this.style.height = 'auto';
      this.style.height = this.scrollHeight + 'px';
    });
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.filtersWrapper}>
        <div className={styles.filtersContainer}>
          <div className={styles.calendarWrapper}>
            <Calendar
              onChange={(start, end) => {
                onDateChange(start, end);

                ampli.track({
                  event_type: 'Change date range in topic report',
                  event_properties: {
                    user_id: currentUser?.id,
                    workspace_id: currentUser?.workspace_id,
                    report_id: report?.id,
                    new_date_range: `${start} - ${end}`,
                  },
                });
                // if (keywordsRef?.current) {
                //   setSearchQuery(keywordsRef?.current);
                // }
              }}
              startDate={startDate}
              endDate={endDate}
              initStartDate={startDate}
              initEndDate={endDate}
              overrideStartDate={startDate}
              overrideEndDate={endDate}
            />
            <div
              className={styles.hintIcon}
              data-tooltip-id="calendar-tooltip"
              data-tooltip-place="bottom-center" 
            >
              <AnchorIcon />
              <ReactTooltip id="calendar-tooltip" style={{ width: '360px' }} data-tooltip-position-strategy='absolute' >
                <div className={styles.tooltipContent}>
                  <p>{t('Date filtering returns events updated in the selected period, with statistics based only on messages from that time;')}</p>
                  <p>{t('unfiltered events may include earlier or later data.')}</p>
                </div>
              </ReactTooltip>
            </div>
          </div>
          <div className={styles.filters}>
            <button
              className={showSearch ? styles.searchButtonActive : styles.searchButton}
              onClick={() => setShowSearch(!showSearch)}
            >
              <SearchIcon/>
            </button>
            <DropdownMenu
              isOpen={dropdownMenu}
              header={t('Sort by')}
              onClick={() => setDropdownMenu(!dropdownMenu)}
              // buttonName={t('Sort')}
              icon={<SortIcon />}
              menuClassName="only-icon"
            >
              <Radiobutton
                itemsList={sortingMenu}
                key={sorting.fieldName}
                current={sorting.fieldName}
                onChange={(value) => {
                  onSortingChange({ ...sorting, fieldName: value })
                  ampli.track({
                    event_type: 'Change sorting in topic report',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      report_id: report?.id,
                      new_sorting: value,
                    },
                  });
                }}
              />

              <SortingOrder
                itemsList={sortingOrder}
                onClick={(value) => {
                  onSortingChange({ ...sorting, isAscending: value });
                  ampli.track({
                    event_type: 'Change sorting order in topic report',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      report_id: report?.id,
                      new_sorting_order: value,
                    },
                  });
                }}
                current={sorting.isAscending}
              />
            </DropdownMenu>

            <Filter
              onlyIcon={true}
              className="onlyIcon"
              sourceGroupsFilter={sourceGroups}
              countriesFilter={countries}
              sentimentFilter={sentiment}
              onChange={(sourceGroups, countries, sentiment) => {
                onFilterChange(sourceGroups, countries, sentiment);
                let groups = sourceGroups.map((group) => group.label);
                let countriesList = countries.map((country) => country.label);
                ampli.track({
                  event_type: 'Change filters in topic report',
                  event_properties: {
                    user_id: currentUser?.id,
                    workspace_id: currentUser?.workspace_id,
                    report_id: report?.id,
                    source_groups: groups,
                    origin_countries: countriesList,
                    sentiment: sentiment,
                  },
                });
              }}
            />
          </div>
        </div>
        {showSearch ? <div className={styles.searchWrapper}>
          <Search
            className={styles.eventsSearch}
            placeholder={t('Search')}
            onChange={(value) => {
              onSearch(value);
              ampli.track({
                event_type: 'Search Perform in topic report',
                event_properties: {
                  user_id: currentUser?.id,
                  workspace_id: currentUser?.workspace_id,
                  report_id: report?.id,
                },
              });
            }}
          />
        </div> : ''}
      </div>
      <div className={styles.feedMainContainer}>
        <div className={styles.controls}>
          <div
            className={
              activeTab === 'stories'
                ? `${styles.tab} ${styles.active}`
                : styles.tab
            }
            onClick={() => setActiveTab('stories')}
          >
            <ListIcon />
            {t('Stories')}
          </div>
          {/* <div
            className={activeTab === 'opinions' ? `${styles.tab} ${styles.active}` : styles.tab}
            onClick={() => setActiveTab('opinions')}
          >
            <MessageIcon />
            {t('Opinions')}
          </div> */}
          {currentUser?.is_super_admin && selectedEvents?.length > 0 ? (
            <>
              <Tooltip content={t('Delete')} position="bottom">
                <button
                  className={styles.deleteButton}
                  onClick={() =>
                    setDeleteEventsModal({
                      isActive: true,
                      event_ids: selectedEvents,
                    })
                  }
                >
                  <DeleteIcon />
                </button>
              </Tooltip>
              <Tooltip content={t('Merge')} position="bottom">
                <button
                  className={styles.mergeButton}
                  onClick={() =>
                    setMergeEventsModal({
                      isActive: true,
                      event_ids: selectedEvents,
                      new_summary:
                        stories
                          ?.filter((event) => selectedEvents.includes(event.id))
                          .sort((a, b) => b.rating - a.rating)?.[0]
                          ?.description || '',
                      new_title:
                        stories
                          ?.filter((event) => selectedEvents.includes(event.id))
                          .sort((a, b) => b.rating - a.rating)?.[0]?.title ||
                        '',
                      isLoading: false,
                      error: null,
                    })
                  }
                >
                  <MergeIcon />
                </button>
              </Tooltip>
              <Tooltip content={t('Copy to dashboard')} position="bottom">
                <button
                  className={styles.copyButton}
                  onClick={() =>
                    setAddToTopicModal({
                      isActive: true,
                      event_ids: selectedEvents,
                      dashboard_id: null,
                      report_id: report.id,
                      error: null,
                    })
                  }
                >
                  <AddToIcon />
                </button>
              </Tooltip>
            </>
          ) : (
            ''
          )}
        </div>

        {activeTab === 'stories' ? (
          stories ? (
            stories.length > 0 ? (
              <div className={styles.feedContainer} id="scrollableDiv">
                <InfiniteScroll
                  dataLength={stories.length}
                  next={fetchMoreEvents}
                  hasMore={hasMoreEvents}
                  loader={
                    <div className={styles.feedLoader}>
                      <LoaderSmall />
                    </div>
                  }
                  endMessage={
                    <div className={styles.feedLoader}>
                      {t('Yay! You have seen it all')}
                    </div>
                  }
                  scrollableTarget="scrollableDiv"
                  style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                >
                  {stories?.map((story) => (
                    <StoryCard
                      key={story?.id}
                      story={story}
                      report={report}
                      isActive={activeStory?.id === story?.id}
                      isRead={read[story?.id] || story?.is_read}
                      onChange={(story) => onStoryChange(story)}
                      isAdmin={currentUser?.is_super_admin}
                      onEdit={(story) => onStoryEdit(story)}
                      onDelete={() => onStoryDelete()}
                      onCheckboxClick={(story) => {
                        if (selectedEvents.includes(story.id)) {
                          setSelectedEvents(
                            selectedEvents.filter(
                              (event) => event !== story.id,
                            ),
                          );
                        } else {
                          setSelectedEvents([...selectedEvents, story.id]);
                        }
                      }}
                      selectedEvents={selectedEvents}
                      viewMode={report?.widget_config?.event_card || 'rating'}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            ) : (
              <div className={styles.feedContainer}>
                <div className={styles.noData}>
                  {searchQuery?.trim().length > 0 ||
                  sourceGroups?.length > 0 ||
                  countries?.length > 0 ||
                  sentiment?.length > 0 ||
                  startDate !== new Date(report.start_date) ||
                  endDate !== new Date(report.end_date)
                    ? t(
                        'No results were found for this query, refine your search',
                      )
                    : t('There is no data to display')}
                </div>
              </div>
            )
          ) : (
            <div className={styles.feedContainer}>
              {[...Array(3).keys()].map((story) => (
                <SkeletonStoryCard key={story} />
              ))}
            </div>
          )
        ) : (
          ''
        )}
        {activeTab === 'opinions' ? (
          opinions ? (
            opinions.length > 0 ? (
              <div className={styles.feedContainer}>
                {opinions?.map((opinion) => (
                  <StoryCard
                    key={opinion?.id}
                    story={opinion}
                    report={report}
                    isActive={activeOpinion?.id === opinion?.id}
                    onChange={(opinion) => setActiveOpinion(opinion)}
                    isStory={false}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.feedContainer}>
                <div className={styles.noData}>
                  {searchQuery?.trim().length > 0
                    ? t(
                        'No results were found for this query, refine your search',
                      )
                    : t('There is no data to display')}
                </div>
              </div>
            )
          ) : (
            <div className={styles.feedContainer}>
              {[...Array(3).keys()].map((story) => (
                <SkeletonStoryCard key={story} />
              ))}
            </div>
          )
        ) : (
          ''
        )}
      </div>

      {mergeEventsModal.isActive ? (
        <Modal
          isVisible={mergeEventsModal.isActive}
          className={styles.mergeEventsModal}
          title={t('Merge stories')}
          content={
            mergeEventsModal.event_ids.length < 2 ? (
              <p className={styles.errorStoriesMerge}>
                {t('Please select at least 2 events to merge')}
              </p>
            ) : (
              <div className={styles.mergeStoriesModal}>
                {mergeEventsModal.error ? (
                  <p className={styles.errorStoriesMerge}>
                    {mergeEventsModal.error}
                  </p>
                ) : (
                  ''
                )}
                <div className="form-element">
                  <label htmlFor="new-story-title">{t('Title')}&#42;</label>
                  <input
                    type="text"
                    id="new-story-title"
                    value={mergeEventsModal.new_title}
                    required
                    onChange={(e) =>
                      setMergeEventsModal({
                        ...mergeEventsModal,
                        new_title: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-element">
                  <label htmlFor="new-story-summary">
                    {t('Description')}&#42;
                  </label>
                  <textarea
                    id="new-story-summary"
                    value={mergeEventsModal.new_summary}
                    required
                    onChange={(e) =>
                      setMergeEventsModal({
                        ...mergeEventsModal,
                        new_summary: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )
          }
          footer={
            <>
              {mergeEventsModal.event_ids.length < 2 ? (
                ''
              ) : (
                <button
                  className="btn-primary"
                  disabled={mergeEventsModal.isLoading}
                  onClick={() => {
                    setMergeEventsModal({
                      ...mergeEventsModal,
                      isLoading: true,
                    });

                    API.fetch(
                      'POST',
                      `/API/v1/topic-modeling/${report.id}/events/merge`,
                      null,
                      {
                        event_ids: mergeEventsModal.event_ids,
                        new_description: mergeEventsModal.new_summary,
                        new_title: mergeEventsModal.new_title,
                      },
                    )
                      .then(() => {
                        setMergeEventsModal({
                          isActive: false,
                          event_ids: [],
                          new_summary: '',
                          new_title: '',
                          isLoading: false,
                          error: null,
                        });

                        setSelectedEvents([]);

                        onStoryDelete();
                      })
                      .catch(() => {
                        setMergeEventsModal({
                          ...mergeEventsModal,
                          isLoading: false,
                          error: t(
                            'Something went wrong. Please, try again...',
                          ),
                        });
                      });
                  }}
                >
                  {mergeEventsModal.isLoading ? <LoaderSmall /> : t('Merge')}
                </button>
              )}
              <button
                className="btn-reset"
                disabled={mergeEventsModal.isLoading}
                onClick={() =>
                  setMergeEventsModal({
                    isActive: false,
                    event_ids: [],
                    new_summary: '',
                    new_title: '',
                    isLoading: false,
                    error: null,
                  })
                }
              >
                {t('Cancel')}
              </button>
            </>
          }
          onClose={() =>
            setMergeEventsModal({
              isActive: false,
              event_ids: [],
              new_summary: '',
              new_title: '',
              isLoading: false,
              error: null,
            })
          }
        />
      ) : (
        ''
      )}
      {deleteEventsModal.isActive ? (
        <Modal
          isVisible={deleteEventsModal.isActive}
          title={t('Delete')}
          content={<p>{t('Are you sure you want to delete this events?')}</p>}
          footer={
            <>
              <button
                className="btn-danger"
                onClick={(e) => {
                  handleDeleteEvents(e, deleteEventsModal.event_ids);
                }}
              >
                {t('Delete')}
              </button>
              <button
                className="btn-reset"
                onClick={() => {
                  setDeleteEventsModal({
                    isActive: false,
                    event_ids: [],
                  });
                }}
              >
                {t('Cancel')}
              </button>
            </>
          }
          onClose={() => setDeleteEventsModal({ isActive: false, event_ids: [] })}
        />
      ) : (
        ''
      )}
      {addToTopicModal.isActive ? (
        <AddToDashboardModal
          isOpen={addToTopicModal.isActive}
          eventIds={addToTopicModal.event_ids}
          reportId={addToTopicModal.report_id}
          onClose={() => {
            setAddToTopicModal({ isActive: false, event_ids: [], report_id: null });
          }}
          onChange={() => {
            setAddToTopicModal({ isActive: false, event_ids: [], report_id: null });
            setSelectedEvents([]);
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
};
