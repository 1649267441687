import { Link } from 'react-router-dom';
import cn from 'classnames';

import { ReactComponent as ChevronLeft } from './assets/chevron-left.svg';
import styles from './Paginator.module.scss';

export function Paginator({
  size = 100,
  page = 1,
  total = 0,
  pageRangeDisplayed = 5,
  onPageChange = null,
  id,
}) {
  const handlePageChange = (e, newPage) => {
    let element;
    if(id) {
      element = document.getElementById(id);
    } else {
      element = document.querySelector('thead');
    }

    const elementPosition = element?.getBoundingClientRect()?.top;

    if (elementPosition) {
      const offset = 80;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    } else {
      // fallback
      // it's simpler to use scrollIntoView but it does not have offset API
      element?.scrollIntoView({ behavior: 'smooth' });
    }

    if (onPageChange) {
      onPageChange(newPage);
      e.preventDefault();
    }
  };

  let pages = [];

  const totalPages = Math.ceil(total / size);
  const minPageLimit = Math.max(page - Math.floor(pageRangeDisplayed / 2), 0);
  const maxPageLimit = Math.min(
    page + Math.floor(pageRangeDisplayed / 2),
    totalPages,
  );

  for (let i = 1; i <= totalPages; i++) {
    if (i <= maxPageLimit && i >= minPageLimit) {
      pages.push(i);
    }
  }

  return (
    <div className={`${styles.root} pagination`}>
      {page > 1 && (
        <div className={styles.arrow}>
          <Link
            className={styles.link}
            to={`?page=${page - 1}`}
            onClick={(e) => handlePageChange(e, page - 1)}
          >
            <ChevronLeft />
          </Link>
        </div>
      )}

      {minPageLimit > 1 && (
        <>
          <Link
            key={1}
            className={styles.page}
            to={`?page=${1}`}
            onClick={(e) => handlePageChange(e, 1)}
          >
            1
          </Link>
          <span className={styles.dots}>
            <span>...</span>
          </span>
        </>
      )}

      {pages.length > 1 &&
        pages.map((pageNum) => {
          const isActive = page === pageNum;

          return isActive ? (
            <span key={pageNum} className={cn(styles.page, styles.active)}>
              {pageNum}
            </span>
          ) : (
            <Link
              key={pageNum}
              className={styles.page}
              to={`?page=${pageNum}`}
              onClick={(e) => handlePageChange(e, pageNum)}
            >
              {pageNum}
            </Link>
          );
        })}

      {maxPageLimit < totalPages && (
        <>
          <span className={styles.dots}>
            <span>...</span>
          </span>
          <Link
            key={totalPages}
            className={styles.page}
            to={`?page=${totalPages}`}
            onClick={(e) => handlePageChange(e, totalPages)}
          >
            {totalPages}
          </Link>
        </>
      )}

      {page < totalPages && (
        <div className={styles.arrow}>
          <Link
            className={styles.link}
            to={`?page=${page + 1}`}
            onClick={(e) => handlePageChange(e, page + 1)}
          >
            <ChevronLeft className={styles.invert} />
          </Link>
        </div>
      )}
    </div>
  );
}
