import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import Async from 'react-select/async';

import Modal from '../Modal';
import { API } from '../../API';
import './index.css';

export default function CopyNarrativeToWorkspacePopup({ isVisible, onClose, narrativeId }) {
  const { t } = useTranslation();
  const [workspace, setWorkspace] = useState(null);
  const [workspaces, setWorkspaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isVisible) {
        API.fetch('GET', `/API/v1/workspaces?size=100`).then((data) => {
            setWorkspaces(data.objects);
        });
    }
  }, [isVisible]);

  const getWorkspaces = (inputValue, callback) => {
    return API.fetch('GET', `/API/v1/workspaces?q=${inputValue}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((workspace) => {
            return { value: workspace.id, label: workspace.name };
          }),
      );
    });
  }
  
  let workspacesPromiseTimeout;
  const promiseOptions = function (inputValue) {
    if (workspacesPromiseTimeout !== null) {
      clearTimeout(workspacesPromiseTimeout);
    }

    if (! inputValue) {
        return [];
    }

    return new Promise((resolve) => {
      workspacesPromiseTimeout = setTimeout(() => {
        getWorkspaces(inputValue, resolve);
      }, 1000);
    });
  }

  const handleCopyNarrative = (narrative_id, workspaceId) => {
    API.fetch('POST', `/API/v1/admin/narratives/${narrative_id}/workspaces/${workspaceId}`, null, {}).then(() => {
      setIsLoading(false);
      onClose();
    });
  };

  return (
    <Modal
        isVisible={isVisible}
        doNotCloseOnOutsideClick={true}
        title={t('Copy a narrative to anoter workspace')}
        content={
            <div className='copy-narrative-wrapper'>
                <div>
                    {t('Are you sure you want to copy these messages from a narrative?')}
                </div>
                <Async
                  cacheOptions
                  defaultOptions={workspaces.map(i => ({value: i.id, label: i.name}))}
                  onChange={(value) => setWorkspace(value)}
                  classNamePrefix="copy-narrative-modal"
                  value={workspace}
                  loadOptions={promiseOptions}
                  placeholder={t('Please enter at least 1 symbol to search')}
                  noOptionsMessage={() => t('Please enter at least 1 symbol')}
                  loadingMessage={() => t('Loading...')}
                  // menuIsOpen={true}
                />
              </div>
        }
        footer={
          <>
            <button
              className="new-button"
              disabled={isLoading}
              onClick={() => {
                setIsLoading(true)
                handleCopyNarrative(narrativeId, workspace.value)
              }}
            >
              {t('Copy')}
            </button>
            <button
              className="btn-reset"
              onClick={onClose}
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={onClose}
      />
  );
}
