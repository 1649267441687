import './index.css';

export default function SwitchSmall({ value, id, onChange, color }) {
  return (
    <label className="switch-small">
      <input
        type="checkbox"
        id={id}
        checked={value ? value : false}
        onChange={(e) => onChange(!value)}
        className={color ? color : ""}
      />
      <span className={ `${color ? color : ''} slider-small round`}></span>
    </label>
  );
}
