import { useContext, useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThreatsTable } from '../../components/ThreatsTable/ThreatsTableNew';
import { Paginator } from '../../components/Paginator/Paginator';
import { Loader } from '../../components/Loader/Loader';
import { Filter } from './Filter/Filter';
import { Search } from '../../components/Search/Search';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { ThreatsContext } from './contexts/ThreatsContext';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';

import styles from './ThreatsPage.module.scss';

export const InboxThreats = ({ narrativeID }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { threats, getInboxThreats} = useContext(ThreatsContext);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [sorting, setSorting] = useState({isAscending: false, fieldName: 'id'});
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const searchQuery = searchParams.get('q');

  const [showRead, setShowRead] = useState(null);
  const [incidentType, setIncidentType] = useState(null);
  const [topics, setTopics] = useState(null);
  const [caseTypes, setCaseTypes] = useState(null);
  const [geoScope, setGeoScope] = useState(null);

  const sortingQuery = sorting.fieldName
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

  useEffect(() => {
    getInboxThreats(searchQuery, sortingQuery, showRead, incidentType, topics, caseTypes, geoScope, page, narrativeID)
  }, [page, sorting, searchQuery, showRead, incidentType, topics, caseTypes, geoScope, narrativeID])

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'id'
    },
    {
      name: t('Name'),
      value: 'name'
    },
    {
      name: t('Date'),
      value: 'date_created'
    },
    {
      name: t('Messages'),
      value: 'total_messages'
    },
    // {
    //   name: t('First message'),
    //   value: 'first_message_date_publicated'
    // },
    // {
    //   name: t('Last message'),
    //   value: 'last_message_date_publicated'
    // },
  ]

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]

  const searchPanel = <div className={styles.sortingControls}>
    <div className={styles.search}>
      <Search 
        onChange={value => {
          setSearchParams({q: value, page: 1})
        }}
      />
    </div>

    <DropdownMenu  
      isOpen={dropdownMenu}
      header={t('Sort by')}
      onClick={() => setDropdownMenu(!dropdownMenu)}
      buttonName={t('Sort')}
      icon={<SortIcon/>}
    >
      <Radiobutton 
        itemsList={sortingMenu}
        current={sorting.fieldName}
        onChange={(value) => setSorting({...sorting, fieldName: value})}
      />

      <SortingOrder 
        itemsList={sortingOrder}
        onClick={(value) => setSorting({...sorting, isAscending: value})}
        current={sorting.isAscending}
      />
    </DropdownMenu>
    <Filter 
      tabMode='inbox'
      onChange={(showRead, incidentType, topics, caseTypes, geoScope) => {
        setShowRead(showRead);
        setIncidentType(incidentType);
        setTopics(topics);
        setCaseTypes(caseTypes);
        setGeoScope(geoScope)
        searchParams.set('page', 1);
        navigate(`?${searchParams.toString()}`);
      }}
    />
  </div>

  
  if(!threats) {
    return <Loader/>
  }


  return (
    <>
      {searchPanel ? searchPanel : ''}
      <ThreatsTable threats={threats} threatsMode='Inbox'/>
      <Paginator
        size={threats.size}
        page={threats.page}
        total={threats.total}
        onPageChange={(newPage) => {
          const params = new URLSearchParams();

          if (searchQuery) {
            params.set('q', searchQuery);
          }

          params.set('page', newPage);
          navigate(`?${params.toString()}`);
        }}
      />
    </>
  )
}