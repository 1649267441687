import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState, useContext } from 'react';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import { API } from '../../API';
import { Code } from '../../components/Code/Code';
import styles from './UserSettingsPage.module.scss';
import QRCode from 'qrcode';
import { UserSettingsContext } from './contexts/UserSettingsContext';


export const Enable2FA = () => {
  const { enable2FA, confirmEnable2FA, fetchCurrentUser } = useContext(UserSettingsContext);

  const { t } = useTranslation();
  const [otp2fa, setOtp2fa] = useState(null);
  const [qrcodeURL, setQrcodeURL] = useState(null);
  const [code, setCode] = useState(['']);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    enable2FA().then((data) => {
      setOtp2fa(data);

      QRCode.toDataURL(data.otp_2fa_url)
        .then((url) => {
          setQrcodeURL(url);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }, []);

  const handleEnable2FA = () => {
    confirmEnable2FA(code.join(''))
      .then((data) => {
        fetchCurrentUser();
      })
      .catch((error) =>
        setErrors((errors) => ({ ...errors, general: error.message })),
      );
  };

  if (!otp2fa) {
    return <></>;
  }

  return (
    <div className={styles.enable2FAContainer}>
      <h3>{t('Enable 2FA')}</h3>
      <ul>
        <li>
          <div>
            {t(
              'You will need an authenticator mobile app to complete this process, such as one of the following:',
            )}
            <ul>
              <li>
                <a href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid" target='_blank'>
                  Google Authenticator
                </a>
              </li>
              <li>
                <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target='_blank'>
                  Microsoft Authenticator
                </a>
              </li>
              <li>
                <a href="https://help.okta.com/oie/en-us/content/topics/identity-engine/authenticators/configure-okta-verify.htm" target='_blank'>
                  Okta Verify authenticator
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.QRCodeContainer}>
            <div className={styles.QRCode}>
              <img src={qrcodeURL} />
            </div>
          </div>
        </li>
        <li>
          <p>{t('Scan the QR code with your authenticator')}</p>
          <p>
            {t(
              `If you can't scan the code you can enter this secret key into your authentication app`,
            )}
          </p>
          <div className="form-element">
            <input
              className=""
              id='code'
              name="code"
              type="text"
              value={otp2fa.otp_2fa_secret}
              disabled
            />
          </div>
        </li>
        <li>
          <p>
            {t(
              `After scanning the QR code above enter the six-digit code generated by your authenticator`,
            )}
          </p>
          <div className={styles.codeForm}>
            <Code
              onChange={(c) => {
                setCode(c);
              }}
            />
          </div>
        </li>
      </ul>
      <button
        className="new-button"
        onClick={() => handleEnable2FA()}
        disabled={code.join('').length < 6}
      >
        {t('Enable')}
      </button>
      {errors.general ? (
        <div className={styles.errorMessage}>
          {t(errors.general)}
          <span onClick={() => setErrors({ ...errors, general: null })}>
            {' '}
            <CloseIcon />{' '}
          </span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
