import { useTranslation } from 'react-i18next';
import Select from 'react-select';

export const ShowRead = ({onChange, selected=[], onClose}) => {
  const { t } = useTranslation();
  let options = [
    {
      value: true, label: t('Show only read')
    },
    {
      value: false, label: t('Show only unread')
    }
  ]

  return(
    <div className='chooser read-chooser'>
      <Select
        className="select-filter"
        classNamePrefix="select-filter"
        // isMulti={true}
        placeholder={t('Show only...')}
        options={options}
        isClearable={true}
        name="read"
        value={selected}
        onChange={(value) => onChange(value)}
        onMenuClose={() => onClose()}
      />
    </div>
  )
}
