import { useTranslation } from 'react-i18next';
import Select from 'react-select';

export const BotsChooser = ({onChange, checked = false}) => {
  const { t } = useTranslation();

  return(
    <div className='chooser'>
      <ul>
        <li>
          <input
            id='show_only_bots'
            type='checkbox'
            checked={checked}
            onChange={() => {
              onChange(!checked)
            }}
          />
          <label htmlFor='show_only_bots'>{t('Show only inauthentic accounts')}</label>
        </li>

      </ul>
    </div>
  )
}
