import { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import Async from 'react-select/async';
import Modal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import styles from './ThreatsPage.module.scss';
import { API } from '../../API';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { AddCaseToNarrative } from '../../components/AddCaseToNarrative/AddCaseToNarrative';
import { AddCounterCase } from '../../components/AddCounterCase/AddCounterCase';
import {
  mapThreatsStatus,
  mapThreatsTypesConst,
  mapThreatsSubjects,
  GEO_SCOPE_OPTIONS,
  INCIDENT_TYPE_OPTIONS,
  TOPICS_OPTIONS,
  TOPICS_OPTIONS_UCBI
} from '../../utils/threats';
import Switch from '../../components/Switch/index';


export const ThreatEditModal = ({
  isOpen,
  title,
  threat,
  message,
  create = false,
  isAdmin = false,
  onChange,
  handleThreat,
}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();

  const caseTypeOptions = [
    {
      value: 'NARRATIVE',
      label: t('Narrative'),
    },
    {
      value: 'INCIDENT',
      label: t('Incident'),
    },
  ];

  const geoOptions = GEO_SCOPE_OPTIONS.map((v) => {
    return { ...v, label: t(v.label) };
  });
  const mapGeoScopeOptions = Object.fromEntries(
    geoOptions.map((v) => [v.value, v.label]),
  );

  const incidentTypeOptions = INCIDENT_TYPE_OPTIONS.map((v) => {
    return { ...v, label: t(v.label) };
  });

  const topicsOptions = TOPICS_OPTIONS.map((v) => {
    return { ...v, label: t(v.label) };
  });

  const topicsOptionsUCBI = TOPICS_OPTIONS_UCBI.map((v) => {
    return { ...v, label: t(v.label) };
  });

  let defaultCaseType = { value: 'INCIDENT', label: t('Incident') };
  if (!create && threat.type) {
    const mapCaseTypes = Object.fromEntries(
      caseTypeOptions.map((v) => [v.value, v.label]),
    );

    defaultCaseType = {
      value: threat.type,
      label: t(mapCaseTypes[threat.type]),
    };
  }

  const isUcbiUser =
    currentUser?.workspace?.config?.is_ucbi_admin ||
    currentUser?.workspace?.config?.is_reporter_only_user;

  const [threatName, setThreatName] = useState(threat.name || '');
  const [threatPublicNameEn, setThreatPublicNameEn] = useState(threat.public_name_en || '');
  const [threatPublicNameUk, setThreatPublicNameUk] = useState(threat.public_name_uk || '');
  const [threatPublicDescriptionEn, setThreatPublicDescriptionEn] = useState(threat.public_description_en || '');
  const [threatPublicDescriptionUk, setThreatPublicDescriptionUk] = useState(threat.public_description_uk || '');
  const [threatIsPublic, setThreatIsPublic] = useState(threat.is_public || false);
  const [threatDescription, setThreatDescription] = useState(
    threat.description || '',
  );
  const [caseType, setCaseType] = useState(defaultCaseType);
  const [incidentType, setIncidentType] = useState(
    threat.incident_type
      ? threat.incident_type.map((v) => {
          return { value: v, label: t(mapThreatsTypesConst[v]) };
        })
      : null,
  );
  const [topics, setTopics] = useState(
    threat.topic
      ? threat.topic.map((v) => {
          return { value: v, label: t(mapThreatsSubjects[v]) };
        })
      : null,
  );
  const [geoScope, setGeoScope] = useState(
    threat.geo_scope
      ? threat.geo_scope.map((v) => {
          return { value: v, label: t(mapGeoScopeOptions[v]) };
        })
      : null,
  );
  const [reviewStatus, setReviewStatus] = useState(
    threat.review_status || null,
  );
  const [declineReason, setDeclineReason] = useState(
    threat.decline_reason || '',
  );
  const [organizations, setOrganizations] = useState(
    threat.organizations || null,
  );
  const [workspaces, setWorkspaces] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [narratives, setNarratives] = useState(
    threat.narratives
      ? threat.narratives.map((v) => {
          return { value: v.id, label: v.name };
        })
      : null,
  );
  const [campaigns, setCampaigns] = useState(
    threat.campaigns
      ? threat.campaigns.map((v) => {
          return { value: v.id, label: v.name };
        })
      : null,
  );
  const [counterEntities, setCounterEntities] = useState(
    threat.counter_entities
      ? threat.counter_entities.map((v) => {
          return { value: v.id, label: v.name };
        })
      : null,
  );
  const [isCreatedByUcbiReporter, setIsCreatedByUcbiReporter] = useState(
    create ? isUcbiUser : threat.is_created_by_ucbi_reporter,
  );

  const getWorkspaces = (inputValue, callback) => {
    return API.fetch('GET', `/API/v1/workspaces?q=${inputValue}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((workspace) => {
            return { value: workspace.id, label: workspace.name };
          }),
      );
    });
  }

  let workspacesPromiseTimeout;
  const promiseOptions = function (inputValue) {
    if (workspacesPromiseTimeout !== null) {
      clearTimeout(workspacesPromiseTimeout);
    }

    if (! inputValue) {
        return [];
    }

    return new Promise((resolve) => {
      workspacesPromiseTimeout = setTimeout(() => {
        getWorkspaces(inputValue, resolve);
      }, 1000);
    });
  }

  const getAllOrganizations = useCallback(() => {
    if (!isAdmin) {
      return;
    }

    const urlParams = new URLSearchParams();

    urlParams.set('size', '100');
    urlParams.set('page', 1);

    API.fetch('GET', `/API/v1/workspaces?${urlParams.toString()}`).then(
      (data) => {
        setWorkspaces(
          data.objects.map((org) => {
            return { value: org.id, label: org.name };
          }),
        );
      },
    );
  }, []);

  useEffect(getAllOrganizations, [getAllOrganizations]);

  const getOrganizationsList = () => {
    if (!isAdmin) {
      return;
    }

    if(threat?.workspace_ids === null || threat?.workspace_ids.length === 0) {
      return 
    }
    
    const urlParams = new URLSearchParams();
    
    threat?.workspace_ids?.forEach(id => urlParams.append('ids', id))

    API.fetch('GET', `/API/v1/workspaces?${urlParams.toString()}`).then(
      (data) => {
        setOrganizations(
          data.objects.map((org) => {
            return { value: org.id, label: org.name };
          }),
        );
      },
    );

  }

  useEffect(getOrganizationsList, [threat])


  const reviewStatusOptions = [
    {
      value: 'PENDING',
      label: t('Pending'),
    },
    {
      value: 'ACCEPTED',
      label: t('Accepted'),
    },
    {
      value: 'DECLINED',
      label: t('Declined'),
    },
  ];

  if (isOpen) {
    const textarea = document.getElementById('modal-description');

    textarea?.addEventListener('input', function () {
      this.style.height = 'auto';
      this.style.height = this.scrollHeight + 'px';
    });
  }

  return (
    <>
      <Modal
        isVisible={isOpen}
        title={t(title)}
        className={styles.threatEditModal}
        doNotCloseOnOutsideClick={true}
        content={
          <div className={styles.modalForm}>
            <form
              method="post"
              onSubmit={() =>
                handleThreat({
                  threat,
                  threatName,
                  threatDescription,
                  caseType,
                  incidentType,
                  topics,
                  geoScope,
                  narratives,
                  campaigns,
                  counterEntities,
                  reviewStatus,
                  declineReason,
                  organizations,
                  isCreatedByUcbiReporter,
                  threatPublicNameEn,
                  threatPublicNameUk,
                  threatPublicDescriptionEn,
                  threatPublicDescriptionUk,
                  threatIsPublic,
                })
              }
            >
              <div className={styles.formElement}>
                <label htmlFor="modal-name">{t('Name')}&#42;</label>
                <input
                  type="text"
                  id="modal-name"
                  value={threatName}
                  required
                  onChange={(e) => setThreatName(e.target.value)}
                />
              </div>
              <div className={styles.formElement}>
                <label htmlFor="modal-description">{t('Description')}</label>
                <textarea
                  id="modal-description"
                  value={threatDescription}
                  onChange={(e) => setThreatDescription(e.target.value)}
                ></textarea>
              </div>
              { isAdmin ? (
                <div className={styles.formElement}>
                  <label htmlFor="is_public">{t('Is public')}</label>
                  <Switch
                    id="is_public"
                    value={threatIsPublic}
                    onChange={setThreatIsPublic}
                  />
                </div>
              ) : ''}
              { threatIsPublic && isAdmin ? (
                <div className={styles.formElement}>
                  <label htmlFor="modal-public-name-en">{t('Public name EN')}</label>
                  <input
                    type="text"
                    id="modal-public-name-en"
                    value={threatPublicNameEn}
                    required
                    onChange={(e) => setThreatPublicNameEn(e.target.value)}
                  />
                </div>
              ) : ''}
              { threatIsPublic && isAdmin ? (
                <div className={styles.formElement}>
                  <label htmlFor="modal-public-name-uk">{t('Public name UK')}</label>
                  <input
                    type="text"
                    id="modal-public-name-uk"
                    value={threatPublicNameUk}
                    required
                    onChange={(e) => setThreatPublicNameUk(e.target.value)}
                  />
                </div>
              ) : ''}
              { threatIsPublic && isAdmin ? (
                <div className={styles.formElement}>
                  <label htmlFor="modal-public-description-en">{t('Public description EN')}</label>
                  <input
                    type="text"
                    id="modal-public-description-en"
                    value={threatPublicDescriptionEn}
                    required
                    onChange={(e) => setThreatPublicDescriptionEn(e.target.value)}
                  />
                </div>
              ) : ''}
              { threatIsPublic && isAdmin ? (
                <div className={styles.formElement}>
                  <label htmlFor="modal-public-description-uk">{t('Public description UK')}</label>
                  <input
                    type="text"
                    id="modal-public-description-uk"
                    value={threatPublicDescriptionUk}
                    required
                    onChange={(e) => setThreatPublicDescriptionUk(e.target.value)}
                  />
                </div>
              ) : ''}
              {currentUser.is_super_admin ? (
                <div className={styles.formElement}>
                  <label htmlFor="is_created_by_ucbi_reporter">
                    {t('Is UCBI')}
                  </label>
                  <Switch
                    id="is_created_by_ucbi_reporter"
                    value={isCreatedByUcbiReporter}
                    onChange={setIsCreatedByUcbiReporter}
                  />
                </div>
              ) : (
                ''
              )}
              {!create && !isUcbiUser ? (
                <div className={styles.formElement}>
                  <label>{t('Case type')}</label>
                  <Select
                    className="threat-select"
                    classNamePrefix="threat"
                    placeholder={t('Case type')}
                    options={caseTypeOptions}
                    name="caseType"
                    value={caseType}
                    onChange={(value) => setCaseType(value)}
                  />
                </div>
              ) : (
                ''
              )}
              <div className={styles.formElement}>
                <label>
                  {isUcbiUser ? t('Instance type') : t('Threat types')}
                </label>
                <Select
                  className="threat-select"
                  classNamePrefix="threat"
                  isMulti={true}
                  placeholder={
                    isUcbiUser ? t('Instance type') : t('Threat types')
                  }
                  options={
                    isCreatedByUcbiReporter
                      ? incidentTypeOptions.filter((v) => v.is_ucbi)
                      : incidentTypeOptions
                  }
                  name="incidentType"
                  value={incidentType}
                  // menuIsOpen={true}
                  onChange={(value) => setIncidentType(value)}
                />
              </div>
              {!create ? (
                <>
                  <div className={styles.formElement}>
                    <label>{t('Narrative')}</label>
                    <AddCaseToNarrative
                      case_id={threat.id}
                      type="NARRATIVE"
                      value={narratives}
                      onChange={(value) => setNarratives(value)}
                    />
                  </div>
                  <div className={styles.formElement}>
                    <label>{t(`Counter-${threat?.type.toLowerCase()}`)}</label>
                    <AddCounterCase
                      case_id={threat.id}
                      type={threat.type}
                      value={counterEntities}
                      onChange={(value) => setCounterEntities(value)}
                    />
                  </div>
                  {/* <div className={styles.formElement}>
                  <label>{t('Campaign')}</label>
                  <AddCaseToNarrative
                    type='CAMPAIGN'
                    value={campaigns}
                    onChange={(value) => setCampaigns(value)}
                  />
                </div> */}
                </>
              ) : (
                ''
              )}
              <div className={styles.formElement}>
                <label>{isUcbiUser ? t('Instance topic') : t('Topic')}</label>
                <Select
                  className="threat-select"
                  classNamePrefix="threat"
                  isMulti={true}
                  placeholder={isUcbiUser ? t('Instance topic') : t('Topic')}
                  options={isUcbiUser || isCreatedByUcbiReporter ? topicsOptionsUCBI : topicsOptions}
                  name="topics"
                  value={topics}
                  // menuIsOpen={true}
                  onChange={(value) => setTopics(value)}
                />
              </div>
              <div className={styles.formElement}>
                <label>{t('GEO Scope')}</label>
                <Select
                  className="threat-select"
                  classNamePrefix="threat"
                  isMulti={true}
                  placeholder={t('GEO Scope')}
                  options={geoOptions}
                  name="geo_scope"
                  value={geoScope}
                  // menuIsOpen={true}
                  onChange={(value) => setGeoScope(value)}
                />
              </div>
              {isAdmin || currentUser?.workspace?.config?.is_ucbi_admin ? (
                <>
                  <div className={styles.formElement}>
                    <label>{t('Status')}</label>
                    <Select
                      className="threat-select"
                      classNamePrefix="threat"
                      isMulti={false}
                      placeholder={t('Threat status')}
                      options={reviewStatusOptions}
                      name="reviewStatuses"
                      value={{
                        value: reviewStatus,
                        label: t(mapThreatsStatus[reviewStatus]),
                      }}
                      // menuIsOpen={true}
                      onChange={(value) => setReviewStatus(value.value)}
                    />
                  </div>
                  {reviewStatus === 'DECLINED' ? (
                    <div className={styles.formElement}>
                      <label htmlFor="modal-name">
                        {t('Decline reason')}&#42;
                      </label>
                      <input
                        type="text"
                        required
                        value={declineReason}
                        onChange={(e) => setDeclineReason(e.target.value)}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
              {isAdmin ? (
                <div className={styles.formElement}>
                  <label>{t('Organizations')}&#42;</label>
                  <Async
                    className={
                      isAdmin &&
                      (organizations?.length === 0 || organizations === null) &&
                      reviewStatus === 'ACCEPTED'
                        ? 'threat-select error'
                        : 'threat-select'
                    }
                    classNamePrefix="threat"
                    isMulti={true}
                    loadOptions={promiseOptions}
                    placeholder={t('Organizations')}
                    defaultOptions={workspaces}
                    name="threatOrganizations"
                    value={organizations}
                    // menuIsOpen={true}
                    onChange={(values) => setOrganizations(values)}
                  />
                </div>
              ) : (
                ''
              )}
            </form>
            {message ? (
              <div className={styles.cautionMessage}>
                <span className={styles.infoIcon}>
                  <InfoIcon />{' '}
                </span>
                <span>{message}</span>
              </div>
            ) : (
              ''
            )}
          </div>
        }
        footer={
          <>
            <button
              className="btn-primary"
              onClick={() => {
                handleThreat({
                  threat,
                  threatName,
                  threatDescription,
                  caseType,
                  incidentType,
                  topics,
                  geoScope,
                  narratives,
                  campaigns,
                  counterEntities,
                  reviewStatus,
                  declineReason,
                  organizations,
                  isCreatedByUcbiReporter,
                  threatPublicNameEn,
                  threatPublicNameUk,
                  threatPublicDescriptionEn,
                  threatPublicDescriptionUk,
                  threatIsPublic,
                });
                onChange(false);
              }}
              disabled={
                threatName.length === 0 ||
                (threat.is_threat &&
                  reviewStatus === 'ACCEPTED' &&
                  !currentUser?.workspace?.config?.is_ucbi_admin &&
                  (organizations === null || organizations.length === 0))
                  ? true
                  : false
              }
            >
              {t('Save')}
            </button>
            <button className="btn-reset" onClick={() => onChange(false)}>
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => onChange(false)}
      />
    </>
  );
};
