import { API } from '../../../API';

const open = {
  '(': ')',
  '[': ']',
  '{': '}',
};
const close = new Set([')', ']', '}']);

/**
 * @param {string} s
 * @return {boolean}
 */
export const isValidQuery = (s, isAdvanced) => {
  const stack = [];

  for (let i = 0; i < s.length; i += 1) {
    const c = s[i];

    if (c === '\\') {
      return false;
    }

    if(isAdvanced) {
      if(c === '[' || c === ']') {
        continue
      } 
    }

    if (!open[c] && !close.has(c)) {
      continue;
    }

    if (open[c]) {
      stack.push(c);
      continue;
    }

    const pop = stack.pop();

    if (close.has(c) && open[pop] !== c) {
      return false;
    }
  }

  return stack.length === 0;
};

export const getSearchSuggestions = (s) => {
  let isValid = true;
  const stack = [];

  for (let i = 0; i < s.length; i += 1) {
    const c = s[i];

    if (!open[c] && !close.has(c)) {
      continue;
    }

    if (open[c]) {
      stack.push(c);
      continue;
    }

    const pop = stack.pop();

    if (close.has(c) && open[pop] !== c) {
      isValid = false;
      break;
    }
  }

  isValid = isValid && stack.length === 0;

  return {
    isValid,
    closing: stack.length === 1 ? open[stack[0]] : null,
  };
};

let newKeywordsValidatorTimeout;
export const newKeywordsValidator = function (keywordsQuery, setError, t) {
  if (newKeywordsValidatorTimeout !== null) {
    clearTimeout(newKeywordsValidatorTimeout);
  }

  if (!keywordsQuery) {
      setError(null);
      return true;
  }

  return new Promise((resolve) => {
    newKeywordsValidatorTimeout = setTimeout(() => {
      validateKeywords(keywordsQuery, setError, resolve, t);
    }, 300);
  });
}

const validateKeywords = (
  keywordsQuery,
  setError,
  callback,
  translator,
) => {
  API.fetch(
    'POST',
    '/API/v1/validate_keywords',
    null,
    {keywords_query: keywordsQuery},
  ).then((data) => {
    const is_valid = data.is_valid;

    if (is_valid) {
      setError(null);
      callback(data.is_valid);
    } else {
      const error_message_raw = data.error_message_raw
        .replaceAll("{", "{{")
        .replaceAll("}", "}}");
      const error_parameters = data.error_parameters;
      const error_message_translated = translator(error_message_raw, error_parameters)
        .replaceAll("&amp;", "&")
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">")
        .replaceAll("&quot;", '"')
        .replaceAll("&apos;", "'");

      setError(error_message_translated);
      callback(data.is_valid);
    }
  });
};
