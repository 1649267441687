import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { CalendarContainer } from 'react-datepicker';
import Select from 'react-select';
import Switch from '../../components/Switch/index';
import Tooltip from '../../components/Tooltip/Tooltip';

import { ReactComponent as AnchorIcon } from '../../assets/anchor.svg';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tooltip/dist/react-tooltip.css';

import { API } from '../../API';

import {
  mapThreatsTypesConst,
  mapThreatsSubjects,
  GEO_SCOPE_OPTIONS,
  INCIDENT_TYPE_OPTIONS_UCBI,
  TOPICS_OPTIONS,
} from '../../utils/threats';
import styles from '../NarrativeAddPage/NarrativeAddPage.module.scss';

export default function ThreatEditPage() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { threatId } = useParams();

  const geoOptions = GEO_SCOPE_OPTIONS.map((v) => {
    return { value: v.value, label: t(v.label) };
  });
  const mapGeoScopeOptions = Object.fromEntries(geoOptions.map(v => [v.value, v.label]));

  const incidentTypeOptions = INCIDENT_TYPE_OPTIONS_UCBI.map((v) => {
    return { value: v.value, label: t(v.label) };
  });

  const topicsOptions = TOPICS_OPTIONS.map((v) => {
    return { value: v.value, label: t(v.label) };
  });

  const [values, setValues] = useState({
    name: '',
    description: '',
  });
  const [errors, setErros] = useState({});

  const [threat, setThreat] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [examples, setExamples] = useState(['']);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [geoScope, setGeoScope] = useState(null);
  const [incidentType, setIncidentType] = useState(null);
  const [topics, setTopics] = useState(null);

  const deleteExample = (index) => {
    setExamples((old) => {
      return [...old.filter((v, i) => i !== index)];
    });
  };
  const handleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErros({});

    if (examples?.filter((e) => e.length > 0).length === 0) {
      setErros((oldValues) => ({
        ...oldValues,
        general: `To create an instance, you must provide at least one example`,
      }));
      return;
    }

    let parameters = null;

    parameters = {
      keywords: [],
      examples: examples.filter((e) => e.length > 0),
      start_date: startDate ? format(startDate, 'yyyy-LL-dd') : null,
      end_date: endDate ? format(endDate, 'yyyy-LL-dd') : null,
      apply_supervised_classifier: false,
      source_types: [],
      source_group_ids: [],
      disable_filters: false,
      languages: [],
      source_ids: [],
      source_origin_country_ids: [],
      translate_keywords_query: false,
      keywords_query_origin_language: '',
      sentiment: [],
      content_types: [],
      similarity_threshold: null,
    };

    const payload = {
      name: values.name,
      description: values.description,
      is_active: true,
      type: 'INCIDENT',
      is_manual: false,
      is_threat: true,
      geo_scope: geoScope ? geoScope.map((v) => v.value) : null,
      incident_type: incidentType ? incidentType.map((v) => v.value) : null,
      topic: topics ? topics.map((v) => v.value) : null,
      parameters: parameters,
      adjustment_suggestions: null,
    };

    API.fetch('PATCH', `/API/v1/narratives/${threatId}`, null, payload)
      .then((data) => {
        navigate('/threats');
      })
      .catch((e) => {
        setErros({ ...errors, general: e.message });
      });
  };

  useEffect(() => {
    API.fetch('GET', `/API/v1/narratives/${threatId}`).then((data) => {
      setValues({ name: data.name, description: data.description });
      setThreat(data);

      if (data?.parameters?.start_date) {
        setStartDate(new Date(data?.parameters?.start_date));
      }
  
      if (data?.parameters?.end_date) {
        setEndDate(new Date(data?.parameters?.end_date));
      }

      if (data?.parameters?.examples) {
        setExamples(data?.parameters?.examples);
      }

      if (data?.incident_type) {
        setIncidentType(data.incident_type.map(v => { return {value: v, label: t(mapThreatsTypesConst[v])}}));
      }
      if (data?.topic) {
        setTopics(data.topic.map( v => { return {value: v, label: t(mapThreatsSubjects[v])}}));
      }
      if (data?.geo_scope) {
        setGeoScope(data.geo_scope.map( v => { return {value: v, label: t(mapGeoScopeOptions[v])}}));
      }
    });
  }, [threatId]);

  const container = ({ children }) => {
    return (
      <div className="react-datepicker custom-datepicker">
        <CalendarContainer className={styles.container}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <div className="breadcrumb">
            <span>
              <Link to="/threats">{t('Instances')}</Link>
            </span>
            <span>{t('Report an instance')}</span>
          </div>
        </div>
      </div>

      <div className={styles.typeInformationWrapper}>
        <div className={styles.infoIcon}> <AnchorIcon/> </div>
        <div className={styles.typeInformation}>
          <div className={styles.typeDescription}>
            <strong>{t("Use this form to report an instance to Osavul.")}</strong>
            <br />
            <br />
            <p>{t("You can report both constructive and destructive information instances. A good example of a constructive information instance could be an article or a statement released about a certain event that mitigates the adverse effects of a specific issue or a prioritized strategic communications topic or narrative. This could also include a portion of a discussion you observe anywhere on the web or social media. Destructive information instances could be threats, fake news, hostile events, manipulative narratives, etc.")}</p>
            <br />
            <p>{t("Upon reporting any of these instances, Osavul AI will gather similar messages from the web, social media, and Telegram, and evaluate the trends and impact of the reported instance. This information will be shared with the admin team, which includes specialized civil society organizations and analysts, to validate the instance and make an assessment from the perspective of strategic communications priorities.")}</p>
            <br />
            <p>{t("The reported instance with the appropriate machine score will be listed on the StratCom dashboards for visibility. However, the instance can only make it to the priority list of topics and/or narratives after an assessment by the network of StratCom professionals, researchers, and analysts.")}</p>
          </div>
        </div>
      </div>

      <div className="form-wrapper">
        <form method="post" onSubmit={handleSubmit}>
          <div className={errors.name ? 'form-element error' : 'form-element'}>
            <label htmlFor="name">
              {t('Name')}&#42;&nbsp;
              <Tooltip content={t("Give your instance a self-explanatory name. Ideally, it should be a complete sentence answering the 'W' questions")} position='right'>
                <AnchorIcon />
              </Tooltip>
            </label>
            <input
              className=""
              id="name"
              name="name"
              type="text"
              value={values.name}
              onChange={handleChange}
              required
            />
            <div className="error-message">{errors.name}</div>
          </div>
          <div
            className={errors.startDate ? 'form-element error' : 'form-element'}
          >
            <label>
              {t('Start date')}&#42;&nbsp;
              <Tooltip content={t("The instance will include messages starting from this day")} position='right'>
                <AnchorIcon/>
              </Tooltip>
            </label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              calendarContainer={container}
              required
              showDisabledMonthNavigation
            />
            <div className="error-message">{errors.startDate}</div>
          </div>
          <div
            className={errors.endDate ? 'form-element error' : 'form-element'}
          >
            <label>
              {t('End date')}&nbsp;
              <Tooltip content={t("The instance will include messages till this day. Leave it open to keep instance updating.")} position='right'>
                <AnchorIcon/>
              </Tooltip>
            </label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              calendarContainer={container}
              showDisabledMonthNavigation
            />
            <div className="error-message">{errors.endDate}</div>
          </div>
          <div className="form-element">
            <label>
              {t('Example of a post, article or message about the instance')}
            </label>
<div className={styles.typeInformationWrapper}>
              <div className={styles.infoIcon}> <AnchorIcon/> </div>
              <div className={styles.typeInformation}>
                <div className={styles.typeDescription}>
                  <p>{t('This example will be used to find similar content for the instance. Copy paste here post, article or message that describes and instance. Try to insert only relevant information (e.g. only relevant paragraph from the long article). When available - use more than 1 example.')}</p>
                </div>
              </div>
            </div>
            {examples.map((example, i) => (
              <div key={i} className={styles.example}>
                <textarea
                  className=""
                  onChange={(e) =>
                    setExamples((old) => {
                      return [
                        ...old.map((v, idx) =>
                          i === idx ? e.target.value : v,
                        ),
                      ];
                    })
                  }
                  type="text"
                  value={example}
                ></textarea>
                <div>
                  <button
                    className="btn-danger"
                    onClick={() => deleteExample(i)}
                  >
                    <i className="las la-trash"></i>
                  </button>
                </div>
              </div>
            ))}
            <div className="add-example">
              <button
                type="button"
                className="secondary"
                onClick={() => setExamples((old) => [...old, ''])}
              >
                <i className="las la-plus"></i>
                {t('Add another example')}
              </button>
            </div>
          </div>
          <div
            className={
              errors.contentTypes ? 'form-element error' : 'form-element'
            }
          >
            <label htmlFor="is_created_by_ucbi_reporter">
              {t('Show advanced options')}
            </label>
            <Switch
              id="show_advanced"
              value={showAdvanced}
              onChange={setShowAdvanced}
            />
            <div className="error-message">{errors.contentTypes}</div>
          </div>

          {showAdvanced ? (
            <>
              <div
                className={
                  errors.contentTypes ? 'form-element error' : 'form-element'
                }
              >
                <label>
                  {t('Description')}&nbsp;
                  <Tooltip content={t("Description")} position='right'>
                    <AnchorIcon/>
                  </Tooltip>
                </label>
                <textarea
                  className=""
                  id="description"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  type="text"
                ></textarea>
                <div className="error-message">{errors.description}</div>
              </div>
              <div
                className={
                  errors.contentTypes ? 'form-element error' : 'form-element'
                }
              >
                <label>
                  {t('GEO Scope')}&nbsp;
                  <Tooltip content={t("Select all regions to which the instance is relevant. Choose Ukraine for national-level stories.")} position='right'>
                    <AnchorIcon/>
                  </Tooltip>
                </label>
                <Select
                  className="threat-select"
                  classNamePrefix="threat"
                  isMulti={true}
                  placeholder={t('GEO Scope')}
                  options={geoOptions}
                  name="geo_scope"
                  value={geoScope}
                  // menuIsOpen={true}
                  onChange={(value) => setGeoScope(value)}
                />
                <div className="error-message">{errors.contentTypes}</div>
              </div>
              <div
                className={
                  errors.contentTypes ? 'form-element error' : 'form-element'
                }
              >
                <label>
                  {t('Instance type')}&nbsp;
                  <Tooltip content={t("Select a type that descries your instance best")} position='right'>
                    <AnchorIcon/>
                  </Tooltip>
                </label>
                <Select
                  className="threat-select"
                  classNamePrefix="threat"
                  isMulti={true}
                  placeholder={t('Instance type')}
                  options={incidentTypeOptions}
                  name="incidentType"
                  value={incidentType}
                  // menuIsOpen={true}
                  onChange={(value) => setIncidentType(value)}
                />
                <div className="error-message">{errors.contentTypes}</div>
              </div>
              <div
                className={
                  errors.contentTypes ? 'form-element error' : 'form-element'
                }
              >
                <label>
                  {t('Instance topic')}&nbsp;
                  <Tooltip content={t("Select one or more topics relevant to the instance")} position='right'>
                    <AnchorIcon/>
                  </Tooltip>
                </label>
                <Select
                  className="threat-select"
                  classNamePrefix="threat"
                  isMulti={true}
                  placeholder={t('Instance topic')}
                  options={topicsOptions}
                  name="topics"
                  value={topics}
                  // menuIsOpen={true}
                  onChange={(value) => setTopics(value)}
                />
                <div className="error-message">{errors.contentTypes}</div>
              </div>
            </>
          ) : (
            ''
          )}
          <div className="form-element">
            <div className="submit-wrapper">
              <button type="submit" className="new-button">
                {t('Save')}
              </button>
            </div>
            {errors.general ? (
              <div className={styles.errorMessage}>
                {t(errors.general)}{' '}
                <span onClick={() => setErros({ ...errors, general: null })}>
                  {' '}
                  <CloseIcon />{' '}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
