import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import { API } from '../../API';

export const TopicReportsLatest = () => {
  const navigate = useNavigate();

  const fetchReports = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '1');
    urlParams.set('page', 1);

    API.fetch('GET', `/API/v1/topic-modeling?${urlParams.toString()}`).then((data) => {
      if (data?.objects?.length === 0) {
        navigate('/topic_reports');
      } else {
        navigate(`/topic_reports/${data?.objects[0]?.id}`);
      }
      
    });
  }, []);

  useEffect(fetchReports, [fetchReports]);

  return (
    <>
      <Loader />
    </>
  )
};