import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import ExportToPDF from './exportToPDF';
import Share from './share';
import './index.css';

export default function ExportReportPopup({ isVisible, onClose, reportID, narrativeId }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('share_link');
  const tabs = [
    {
      id: 'share_link',
      title: t('Share link'),
      component: (
        <Share
          key={`export_popup_tab_share_link`}
          reportID={reportID}
          narrativeId={narrativeId} />
      ),
    },
    {
      id: 'export_pdf',
      title: t('Export PDF'),
      component: (
        <ExportToPDF
          key={`export_popup_tab_export_pdf`}
          reportID={reportID}
          narrativeID={narrativeId}
          onClose={onClose}
        />
      ),
    },
  ];

  return (
    <Modal
      isVisible={isVisible}
      title={t('Export settings')}
      doNotCloseOnOutsideClick={true}
      content={
        <div className="tabs">
          <div className="tab-headers">
            {tabs.map((tab) => (
              <div
                className={
                  activeTab === tab.id ? 'tab-header active' : 'tab-header'
                }
                key={`export_popup_tab_header_${tab.id}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.title}
              </div>
            ))}
          </div>
          <div className="tab-content">
            {tabs
              .filter((tab) => activeTab === tab.id)
              .map((tab) => tab.component)}
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
}
