import { useCallback, useEffect, useState,} from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../API';
import Modal from "../Modal";
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import Async from 'react-select/async';

import styles from './AddToDashboardModal.module.scss';

export const AddToDashboardModal = ({ isOpen, onClose, eventIds, reportId, onChange }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState(null);
  const [error, setError] = useState();
  const [narrative, setNarrative] = useState(null);
  const [narrativeSelect, setNarrativeSelect] = useState(false);


  const getNarrativesOptions = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '15');
    urlParams.set('page', 1);
    urlParams.set('is_threat', false);
    urlParams.set('types', ['TOPIC_MODELING']);

    API.fetch('GET', `/API/v1/narratives?${urlParams.toString()}`).then(data => {
      let newOptions = data.objects.map(narrative => {return {label: narrative.name, value: narrative.id}});
      setOptions(newOptions)
    })
  }, [])

  useEffect(getNarrativesOptions, [getNarrativesOptions]);

  const getNarratives = (inputValue, callback) => {
    const urlParams = new URLSearchParams();
    urlParams.set('q', inputValue);
    urlParams.set('is_threat', false);
    urlParams.set('types', ['TOPIC_MODELING']);

    return API.fetch('GET', `/API/v1/narratives?${urlParams.toString()}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((narrative) => {
            return {value: narrative.id, label: narrative.name};
          })
      );
    });
  };

  const handleAddToNarrative = () => {
    if(!eventIds) {
      setError('There are no selected events')
      return
    }
    let link = `/API/v1/topic-modeling/${reportId}/events/copy`;

    let body = {
      event_ids: eventIds,
      dashboard_id: narrative?.value,
    }
    

    API.fetch('POST', link, null, body).then().catch(e => {
      setError(t('Something went wrong. Please, try again...'));
    });
  }

  useEffect(() => {
    if(options) {
      const container = document.querySelector('#message-to-narrative-modal');
      if(narrativeSelect === true ) {
        let content = document.querySelector(".addToNarrativeModal__menu");
        if(container.offsetHeight < content.offsetHeight) {
          container.style.height=container.offsetHeight + content.offsetHeight + "px";
        }
      } else {
        container.style.height = ' ';
      }
    }
 }, [narrativeSelect])

return(
  <Modal
      isVisible={isOpen}
      title={t('Copy events to a dashboard')}
      className={styles.addToNarrativeModal}
      doNotCloseOnOutsideClick={true}
      content={
        options ? <div id='message-to-narrative-modal'>
          <label>{t('Dashboard name')}</label>
          <Async
            cacheOptions
            defaultOptions={options}
            value={narrative}
            loadOptions={getNarratives}
            isClearable
            placeholder={t('Start typing a dashboard name')}
            onChange={(newValue) => setNarrative(newValue)}
            onMenuOpen={() => setNarrativeSelect(true)}
            onMenuClose={() => setNarrativeSelect(false)}
            menuIsOpen={narrativeSelect}
            className='addToNarrativeModal'
            classNamePrefix="addToNarrativeModal"
          />
          {error ? <div className={styles.error}>{error}</div> : ''}
        </div> : <LoaderSmall/>
      }
      footer={
        <>
          <button
            className="btn-primary"
            onClick={() => {
              handleAddToNarrative();
              setNarrative(null);
              setError(null);
              onChange(false);
            }}
            
          >
            {t('Save')}
          </button>
          <button
            className="btn-reset"
            onClick={() => {
              setNarrative(null);
              setError(null);
              onChange(false);
            }}
          >
            {t('Cancel')}
          </button>
        </>
      }
      onClose={() => {
        onClose();
        setNarrative(null);
        setError(null);
      }
      }
    />
)
};
