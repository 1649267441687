import { useTranslation } from 'react-i18next';



export const Sentiment = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const sentimentOptions = [
    {value: 'POSITIVE', label: t('Positive')},
    {value: 'NEUTRAL', label: t('Neutral')},
    {value: 'NEGATIVE', label: t('Negative')},
  ]

  const handleSelect = () => {
    if (sentimentOptions.length !== selected.length) {
      onChange(sentimentOptions.map(sentiment => sentiment.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser sentiment-chooser'>
      <div className='control' onClick={() => handleSelect()}>{sentimentOptions.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {sentimentOptions.map(sentiment => {
          return(
            <li key={sentiment.value}>
              <input 
                id={`sentiment_${sentiment.value}`}
                type='checkbox'
                checked={selected.includes(sentiment.value)}
                onChange={() => {
                  if (selected.includes(sentiment.value)) {
                    onChange(selected.filter(v => v !== sentiment.value)) 
                  } else {
                    onChange([...selected, sentiment.value]) 
                  }
                }}
              />
              <label htmlFor={`sentiment_${sentiment.value}`}>{t(sentiment.label)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}