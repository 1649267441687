import { format } from 'date-fns';
import { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import { API, ShareAPI } from '../../../API';
import { Paginator } from '../../../components/Paginator/Paginator';
import { Tabs } from '../../../components/Tabs/Tabs';
import { NarrativeSourcesTable } from './NarrativeSourcesTable';
import { ModalMessagesTable } from './ModalMessagesTable';
import { ampli } from "../../../ampli";
import { useCurrentUser } from '../../../contexts/CurrentUser';
import { platformNamesMapping } from '../../../utils/platforms';


import styles from '../NarrativePage.module.scss';

export const NarrativeTreeChartModal = ({
  tabIndex,
  infoModal,
  isChart=false,
  type=null,
  subType=null,
  platformOption,
  platformsOptions,
  stateAffiliatedId,
  countryId=null,
  audienceRange,
  sourceGroup,
  source,
  narrative,
  sentimentOptions,
  eventIdsOptions,
  opinionIdsOptions,
  startDate,
  endDate,
  contentTypeOptions,
  onChange,
  isShare = false,
  opinion,
  event
}) => {
  const { t } = useTranslation();
  const [tablistIndex, setTablistIndex] = useState(tabIndex ? tabIndex : (source || sourceGroup) ? 1  : 0); 
  const [currentUser] = useCurrentUser();
  const nativeLang = currentUser?.translation_language ? currentUser?.translation_language : window.clientInformation.language.split('-', 1)[0].toUpperCase();
  
  const sourcesTypes = [
    { value: null, label: t('All') },
    { value: 'is_verified', label: t('Verified') },
    { value: 'influential', label: t('Influential') },
    { value: 'state_affiliated', label: t('State-affiliated') },
    { value: 'discreditated', label: t('Compromised accounts') },
    { value: 'is_bot', label: t('Bots') },
    { value: 'audience', label: t('Audience')}
  ];

  const [platformOptions, setPlatformOptions] = useState(() => [
    { value: null, label: t('All') },
  ]);
  useEffect(() => setPlatformOptions(platformsOptions),[])
  const [platform, setPlatform] = useState(platformOption ? platformOption : () => platformOptions[0]);

  const [sourceType, setSourceType] = useState(type || sourcesTypes[0]);
  const [stateAffiliatedID, setStateAffiliatedID] = useState(stateAffiliatedId || null)
  const [countryID, setCountryID] = useState(countryId || null);
  const [sourceSubType, setSourceSubType] = useState(subType || null);
  const [narrativeSources, setNarrativeSources] = useState(null);
  const [narrativeMessages, setNarrativeMessages] = useState(null);
  const [sourcesSorting, setSourcesSorting] = useState({isAscending: false, fieldName: 'audience'});
  const [messagesSorting, setMessagesSorting] = useState(null);
  const [isSourcesLoading, setIsSourcesLoading] = useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [audience, setAudience] = useState(audienceRange || null);
  const [sourceGroupId, setSourceGroupId] = useState(sourceGroup || null);
  const [sourceId, setSourceId] = useState(source || null);
  const [eventIds, setEventIds] = useState(eventIdsOptions || null);
  const [sentiment, setSentiment] = useState(sentimentOptions || null);
  const [start, setStart] = useState(startDate || null);
  const [end, setEnd] = useState(endDate || null);
  const [opinionIds, setOpinionIds] = useState(opinionIdsOptions || null);
  const [contentTypes, setContentTypes] = useState(contentTypeOptions || null);

  const api = isShare ? ShareAPI : API;

  const fetchTopicSourcesStats = useCallback(() => {
    if (!narrative) return;

    const urlParams = new URLSearchParams();

    urlParams.append('ids', narrative.id);

    api.fetch(
      'GET',
      `/API/v1/sources/stats/NARRATIVE?${urlParams.toString()}`,
      null,
      null,
    ).then((data) => {
      // setNarrativeSourcesStats(data);

      const platforms = Object.fromEntries(
        data?.objects?.map((val) => [
          val.stats.source_type,
          val.stats.source_type,
        ]),
      );

      setPlatformOptions([
        { value: null, label: 'All' },
        ...Object.keys(platforms).map((k) => ({
          value: k,
          label: platformNamesMapping[k],
        })),
      ]);
    });
  }, [narrative]);

  // useEffect(fetchTopicSourcesStats, [fetchTopicSourcesStats]);

  useEffect(() => {
    if(!platformsOptions) {
      fetchTopicSourcesStats()
    }
    ampli.track({
      event_type: 'Open actors popup',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
        narrative_id: narrative.id,
        actor_type: type,
      }
    });
  }, [])


  const fetchSources = useCallback(
    (sourceType, sourceSubType, platform, countryID, sourcesSorting, audience, sourceGroup, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes, page = 1) => {
      const urlParams = new URLSearchParams();
      urlParams.set('size', '10');
      urlParams.set('page', page);
      urlParams.set('narrative_id', narrative.id);

      const sourceSortingQuery = sourcesSorting
        ? `${sourcesSorting.isAscending ? '' : '-'}${sourcesSorting.fieldName}`
        : null;

      if (sourcesSorting) {
        urlParams.set('sorting', sourceSortingQuery);
      }

      if (sourceSubType) {
        if (sourceType.value === 'discreditated') {
          urlParams.set('entity_type', sourceSubType);
        } else {
          urlParams.set(sourceSubType, true);
        }
      }
      if (sourceType.value) {
        urlParams.set(sourceType?.value, sourceType?.bool === null ? true : sourceType?.bool);
      }

      if (stateAffiliatedID) {
        urlParams.set('country_id', stateAffiliatedId);
      }

      if(countryID) {
        let countriesIds = countryID.map(country => country.value)
        countriesIds.forEach(id => urlParams.append('origin_country_ids', id))
      }

      if (platform?.value) {
        urlParams.set('source_types', platform.value);
      }

      if (audience) {
        urlParams.set('audience_lte', audience[1])
        urlParams.set('audience_gte', audience[0])
      }

      if(sourceGroup) {
        urlParams.set('source_group_id', sourceGroup)
      }

      if(sourceId) {
        urlParams.set('ids', sourceId)
      }

      if(eventIds) {
        eventIds.forEach(id => urlParams.append('topic_modeling_event_ids', id))
      }

      if(opinionIds) {
        opinionIds.forEach(id => urlParams.append('topic_modeling_opinion_ids', id))
      }

      if(sentiment) {
        sentiment.forEach(sentiment => urlParams.append('sentiment', sentiment))
      }

      if(contentTypes) {
        contentTypes.forEach(contentType => urlParams.append('content_types', contentType))
      }

      if(start) {
        urlParams.append('start',format(start, 'yyyy-LL-dd 00:00:00'));
      }
      if(end) {
        urlParams.append('end',format(end, 'yyyy-LL-dd 23:59:59'));
      }

      setIsSourcesLoading(true)

      api.fetch('GET', `/API/v1/sources?${urlParams.toString()}`).then(
        (data) => {
          setNarrativeSources(data);
          setIsSourcesLoading(false);
        },
      );
    },
    [],
  );

  useEffect(() => {
    fetchSources(sourceType, sourceSubType, platform, countryID, sourcesSorting, audience, sourceGroupId, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes);
  }, [sourceType, sourceSubType, platform, countryID, sourcesSorting, audience, sourceGroupId, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes]);

  const fetchNarrativeMessages = useCallback(
    (sourceType, sourceSubType, platform, countryID, messagesSorting, audience, sourceGroup, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes, page = 1) => {
      if (!narrative) {
        return;
      }

      const urlParams = new URLSearchParams();
      urlParams.set('narrative_id', narrative.id);
      urlParams.set('size', '10');
      urlParams.set('page', page);

      const messagesSortingQuery = messagesSorting
        ? `${messagesSorting.isAscending ? '' : '-'}${
            messagesSorting.fieldName
          }`
        : null;

      if (messagesSorting) {
        urlParams.set('sorting', messagesSortingQuery);
      }

      if (sourceType.value) {
        urlParams.set(sourceType?.value, sourceType.bool === null ? true : sourceType.bool);
      }

      if (stateAffiliatedID) {
        urlParams.set('country_id', stateAffiliatedId);
      }

      if (sourceSubType) {
        if (sourceType.value === 'discreditated') {
          urlParams.set('entity_type', sourceSubType);
        } else {
          urlParams.set(sourceSubType, true);
        }
      }

      if (platform.value) {
        urlParams.set('source_types', platform.value);
      }

      if (countryID) {
        let countriesIds = countryID.map(country => country.value)
        countriesIds.forEach(id => urlParams.append('origin_country_ids', id))
      }

      if (audience) {
        urlParams.set('source_audience_lte', audience[1])
        urlParams.set('source_audience_gte', audience[0])
      }

      if(sourceGroup) {
        urlParams.set('source_group_id', sourceGroup)
      }

      if(sourceId) {
        urlParams.set('source_id', sourceId)
      }

      if(eventIds) {
        eventIds.forEach(id => urlParams.append('topic_modeling_event_ids', id))
      }

      if(opinionIds) {
        opinionIds.forEach(id => urlParams.append('topic_modeling_opinion_ids', id))
      }

      if(sentiment) {
        sentiment.forEach(sentiment => urlParams.append('sentiment', sentiment))
      }

      if(contentTypes) {
        contentTypes.forEach(contentType => urlParams.append('content_types', contentType))
      }

      if(start) {
        urlParams.append('start',format(start, 'yyyy-LL-dd 00:00:00'));
      }
      if(end) {
        urlParams.append('end',format(end, 'yyyy-LL-dd 23:59:59'));
      }

      setIsMessagesLoading(true);

      api.fetch('GET', `/API/v1/messages?${urlParams.toString()}`).then(
        (data) => {
          setNarrativeMessages(data);
          setIsMessagesLoading(false);
        },
      );
    },
    [],
  );

  useEffect(() => {
    fetchNarrativeMessages(sourceType, sourceSubType, platform, countryID, messagesSorting, audience, sourceGroupId, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes);
  }, [platform, sourceSubType, sourceType, countryID, messagesSorting, audience, sourceGroupId, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes]);

  const translateMessage = (messageId, text, nativeLang, sourceLang='') => {
    let body;
    if(sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang
      }
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      }
    }
    API.fetch('POST', '/API/v1/translations/translate', null, body).then((data) => {
      setNarrativeMessages({...narrativeMessages, objects: narrativeMessages.objects.map(message => {
      if(message.id === messageId) {
        message.isTranslation = true;
        message.translated = data.destination_text;
        return message;
      } else {
        return message;
      }
      })})
    })
    .catch(e => {
      setNarrativeMessages({...narrativeMessages, objects: narrativeMessages.objects.map(message => {
        if(message.id === messageId) {
          message.isTranslation = true;
          message.error = true;
          message.translated = t('We were not able to translate this text.');
          return message;
        } else {
          return message;
        }
        })})
    });
  }
  
  const toggleTranslate = (messageId, isTranslation) => {
    setNarrativeMessages({...narrativeMessages, objects: narrativeMessages.objects.map(message => {
      if(message.id === messageId) {
        message.isTranslation = isTranslation;
        return message;
      } else {
        return message;
      }
    })})
  }
  
  const handleTranslation = (messageId, text, isTranslation) => {
    const target = narrativeMessages.objects.find(message => message.id === messageId);
    if (target.translated) {
      toggleTranslate(messageId, isTranslation)
    } else {
      toggleTranslate(messageId, true)
      translateMessage(messageId, text, nativeLang)
    }
  }
  

  const [selectMenu, setSelectMenu] = useState(false);
  const [typesSelectMenu, setTypesSelectMenu] = useState(false);

  const select = (
    <Select
      className="chart-select"
      classNamePrefix="chart-select"
      onChange={(e) => {
        setPlatform(e);
        setCountryID(null);
        setSourceSubType(null);
      }}
      value={platform}
      options={platformOptions}
      onMenuOpen={() => setSelectMenu(true)}
      onMenuClose={() => setSelectMenu(false)}
      menuIsOpen={selectMenu}
    />
  );

  const typesSelect = (
    <Select
      className={styles.chartSelect}
      classNamePrefix="chartSelect"
      onChange={(e) => {
        setSourceType(e);
        setCountryID(null);
        setSourceSubType(null);
      }}
      onMenuOpen={() => setTypesSelectMenu(true)}
      onMenuClose={() => setTypesSelectMenu(false)}
      menuIsOpen={typesSelectMenu}
      value={sourceType}
      options={sourcesTypes}
    />
  );

  const handleSourcesSortingClick = (fieldName) => {
    const isAscending =
      sourcesSorting && sourcesSorting.fieldName === fieldName
        ? !sourcesSorting.isAscending
        : true;

    setSourcesSorting({
      isAscending,
      fieldName: fieldName,
    });
  };

  const handleMessagesSortingClick = (fieldName) => {
    const isAscending =
      messagesSorting && messagesSorting.fieldName === fieldName
        ? !messagesSorting.isAscending
        : true;

    setMessagesSorting({
      isAscending,
      fieldName: fieldName,
    });
  };

  const tabslist = [
    {
      title: t('Actors'),
      data: narrativeSources,
      onPageChange: (newPage) => fetchSources(sourceType, sourceSubType, platform, countryID, sourcesSorting, audience, sourceGroupId, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes, newPage),
      content: (
        <NarrativeSourcesTable
          sources={narrativeSources}
          sorting={sourcesSorting}
          type={typesSelect.props.value.value}
          handleSourceSortingClick={(fieldname) =>
            handleSourcesSortingClick(fieldname)
          }
          isLoading={isSourcesLoading}
          isShare={isShare}
        />
      ),
    },
    {
      title: t('Content'),
      data: narrativeMessages,
      onPageChange: (newPage) => fetchNarrativeMessages(sourceType, sourceSubType, platform, countryID, messagesSorting, audience, sourceGroupId, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes, newPage),
      content: (
        <ModalMessagesTable
          messages={narrativeMessages}
          sorting={messagesSorting}
          handleMessagesSortingClick={(fieldname) =>
            handleMessagesSortingClick(fieldname)
          }
          handleTranslation={(messageId, text, nativeLang) => handleTranslation(messageId, text, nativeLang)}
          isLoading={isMessagesLoading}
          isShare={isShare}
          narrative={narrative}
          event={event}
          opinion={opinion}
          onMessageDelete={() => {
            fetchNarrativeMessages(sourceType, sourceSubType, platform, countryID, messagesSorting, audience, sourceGroupId, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes, tabslist[tablistIndex]?.data?.page)
            fetchSources(sourceType, sourceSubType, platform, countryID, sourcesSorting, audience, sourceGroupId, sourceId, eventIds, opinionIds, sentiment, start, end, contentTypes)
          }} 
        />
      ),
    },
  ];

  useEffect(() => {
    const container = document.querySelector('#treechart-modal-controls');
    if(typesSelectMenu === true ) {
      let content = document.querySelector(".chartSelect__menu");
      if(container.offsetHeight < content.offsetHeight) {
        container.style.height=container.offsetHeight + content.offsetHeight + "px";
      }
    } else if (selectMenu === true){
      let content = document.querySelector(".chart-select__menu");
      if(container.offsetHeight < content.offsetHeight) {
        container.style.height=container.offsetHeight + content.offsetHeight + "px";
      }
    } else {
      container.style.height = ' ';
    }

 }, [typesSelectMenu, selectMenu])


  return (
    <Modal
      isVisible={infoModal}
      title={t('Actors analysis')}
      content={
        <>
          {isChart ? '' : <div className={styles.modalSelects}>
            {select}
            {typesSelect}
          </div>}
          <div className={styles.modalControls} id='treechart-modal-controls'>
            <Tabs
              tabslist={tabslist}
              heading=""
              defaultIndex = {tabIndex ? tabIndex : sourceId || sourceGroupId ? 1 : 0}
              onIndexChange={(index) => {
                setTablistIndex(index);
                if (index === 1) {
                  ampli.track({
                    event_type: 'Open content tab in actors popup',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      narrative_id: narrative?.id,
                      actor_type: type?.value,
                    }
                  });
                }
              }}
            />
          </div>
        </>
      }
      footer={
        <>
          <Paginator
            size={10}
            page={tabslist[tablistIndex]?.data?.page}
            total={tabslist[tablistIndex]?.data?.total}
            onPageChange={(newPage) =>
              tabslist[tablistIndex]?.onPageChange(newPage)
            }
          />
        </>
      }
      onClose={() => {
        onChange(false);
      }}
      className={styles.chartTreeModal}
    />
  );
};
