export const initialState = {
  user: null,
  error: null,
};

const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER':
      return Object.assign({}, state, {
        user: action.payload,
      });
    case 'ERROR':
      return Object.assign({}, state, {
        error: action.payload,
      });
    default:
      return state;
  }
};

export default userSettingsReducer;
