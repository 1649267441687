import styles from './StoryCard.module.scss'

export const SkeletonStoryCard = () => {
  return(
    <div className={`${styles.skeletonCardContainer} ${styles.active}`}>
      <div className={styles.skeletonStoryCardDate}></div>
      <div className={styles.skeletonStoryCardHeader}></div>
      <div className={styles.skeletonStoryCardInfo}>
        <div className={styles.skeletonStoryCardRatingContainer}>
          <div className={styles.skeletonStoryCardRating}>
            <div className={styles.skeletonStoryCardRatingIcon}></div>
          </div>
        </div>
        <div className={styles.skeletonStoryCardSourcesContainer}>
          <div className={styles.skeletonAvatarGroup}></div>
        </div>
        <div className={styles.skeletonStoryCardOpinionBalanceContainer}>
          <div className={styles.skeletonStoryCardOpinionBalance}>
            <div className={styles.skeletonStoryCardOpinionBalancePositive}></div>
            {/* <div className={styles.skeletonStoryCardOpinionBalanceNegative}></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};