import { useTranslation } from 'react-i18next';

export const CaseTypesChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const caseTypes = [
    {name: 'Narrative', value: "NARRATIVE"},
    {name: 'Incident', value: "INCIDENT"},
  ]

  const handleSelect = () => {
    if (caseTypes.length !== selected.length) {
      onChange(caseTypes.map(type => type.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{caseTypes.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {caseTypes.map(type => {
          return(
            <li key={type.value}>
              <input 
                id={`type_${type.value}`}
                type='checkbox'
                checked={selected.includes(type.value)}
                onChange={() => {
                  if (selected.includes(type.value)) {
                    onChange(selected.filter(v => v !== type.value)) 
                  } else {
                    onChange([...selected, type.value]) 
                  }
                }}
              />
              <label htmlFor={`type_${type.value}`}>{t(type.name)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}

