export const getSelectedCountries = (selected, countries) => {
  let countriesList = selected.map(item => {
    let result = countries.filter(country => country.id == item)
    if (result.length > 0) {
      return {value: result[0].id, label: result[0].name}
    }
    return item;
  })
  return countriesList;
}

export const getFlagEmoji =(countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}