import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '../../contexts/CurrentUser';

import { Loader } from "../../components/Loader/Loader";

export function HomePage() {
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.workspace?.config?.homepage) {
      navigate(currentUser?.workspace?.config?.homepage, { replace: true });
    } else {
      navigate("/search", { replace: true });
    }
  }, [currentUser, navigate]);

  return <Loader />;
}
