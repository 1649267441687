import cn from 'classnames';
import { useState, useEffect, useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Modal from '../../components/Modal';
import { Loader } from '../../components/Loader/Loader';
import { API } from '../../API';
import { Paginator } from '../../components/Paginator/Paginator';
import { Search } from '../../components/Search/Search';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as ArrowIcon } from '../NarrativesPage/assets/arrow.svg';
import Tooltip from '../../components/Tooltip/Tooltip';
import styles from './ReportsPage.module.scss';

const sortingFieldNames = {
  NAME: 'name',
};

export function ReportsPage() {
  const { t } = useTranslation();
  const [reports, setReports] = useState(null);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isActive: false,
    id: null,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ?? 1;
  const searchQuery = searchParams.get('q');
  const [sorting, setSorting] = useState(null);

  const sortingQuery = sorting
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

  const fetchReports = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '100');
    urlParams.set('page', page);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    if (sortingQuery) {
      urlParams.set('sorting', sortingQuery);
    }

    API.fetch('GET', `/API/v1/reports?${urlParams.toString()}`).then((data) => {
      setReports(data);
    });
  }, [page, searchQuery, sortingQuery]);

  const handleDelete = useCallback(
    (id) => {
      setDeleteConfirmModal(true);
      API.fetch('DELETE', `/API/v1/reports/${id}`).then(() => {
        fetchReports();
      });
    },
    [fetchReports],
  );

  useEffect(fetchReports, [fetchReports]);

  if (!reports) {
    return <Loader />;
  }

  const handleSortingClick = (fieldName) => {
    const isAscending =
      sorting && sorting.fieldName === fieldName ? !sorting.isAscending : true;

    setSorting({
      isAscending,
      fieldName: fieldName,
    });
  };

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <div className="breadcrumb">
            <span>{t('Reports')}</span>
          </div>
        </div>
        <div className="controls">
          <Link to="/reports/new" className="button new-button">
            <PlusIcon />
            {t('Create report')}
          </Link>
        </div>
      </div>
      <div className="next-card-header">
        {reports?.objects?.length > 0 ? <div className='search-wrapper'>
          <Search
            onChange={value => {
              setSearchParams({q: value, page: 1})
            }}
          />
        </div> : ''}
      </div>
      
      {reports?.objects?.length > 0 ? <div className={styles.tableWrapper}>
        <table className={styles.reports}>
          <thead>
            <tr>
              <td>{t('Name')}</td>
              {/* <td>{t('Type')}</td> */}
              <td></td>
            </tr>
          </thead>
          <tbody>
            {reports?.objects?.map((report) => {
              return (
                <tr key={report.id}>
                  <td>
                    <div className={styles.reportNameWrapper}>
                      <div className={styles.reportNameContainer}>
                        <span>
                          <Link to={`/reports/${report.id}`}>{report.name}</Link>
                        </span>
                        <div className={styles.reportInfo}>
                          <span>#{report.id}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  {/* <td>{t(user.type)}</td> */}
                  <td>
                    <div className={styles.controls}>
                      <Tooltip content={t('Edit')} position="bottom">
                        <Link to={`/reports/${report.id}/edit`} className="new-button">
                          <i className="lar la-edit"></i>
                        </Link>
                      </Tooltip>

                      <Tooltip content={t('Delete')} position="bottom">
                        <button
                          className="btn-danger"
                          onClick={() =>
                            setDeleteConfirmModal({
                              isActive: true,
                              id: report.id,
                            })
                          }
                        >
                          <i className="las la-trash"></i>
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> : <div className='noData'>{t('There is no data to display')}</div>}
      <Paginator
        size={reports.size}
        page={reports.page}
        total={reports.total}
      />

      <Modal
        isVisible={deleteConfirmModal.isActive}
        title={t('Delete')}
        content={<p>{t('Are you sure you want to delete a report?')}</p>}
        footer={
          <>
            <button
              className="btn-danger"
              onClick={() => handleDelete(deleteConfirmModal.id)}
            >
              {t('Delete')}
            </button>
            <button
              className="btn-reset"
              onClick={() =>
                setDeleteConfirmModal({ isActive: false, id: null })
              }
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => setDeleteConfirmModal({ isActive: false, id: null })}
      />
    </div>
  );
}
