import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Tooltip from '../../components/Tooltip/Tooltip';

import { ReactComponent as NarrativesIcon } from '../Layout/assets/narratives.svg';
import { ReactComponent as CaseModerationIcon } from '../Layout/assets/caseModeration.svg';
import { API } from '../../API';
import { useCurrentUser } from '../../contexts/CurrentUser';
import styles from '../Layout/Layout.module.scss';

export const NarrativesModerationLink = ({ itemTextClassname, threatCounterClassname }) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();

  const [newMessages, setNewMessages] = useState(null);

  const fetchNarratives = () => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '1');

    let url;

    urlParams.set('is_threat', 'false');
    urlParams.set('review_status', 'PENDING');
    url = `/API/v1/narratives?${urlParams.toString()}`;

    API.fetch('GET', url).then(
      (data) => {
        setNewMessages(data.total);
      },
    );
  };

  useEffect(() => {
    fetchNarratives();

    const interval = setInterval(() => {
      fetchNarratives();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <NavLink to="/narratives_moderation">
      <Tooltip content={t('Cases moderation')} position='right'>
        <div className='iconWrapper'>
          <CaseModerationIcon />
          {newMessages ? <span className='threatCounter'>{newMessages}</span> : ''}
        </div>
      </Tooltip>
      
      <span className={itemTextClassname}>
        {t('Cases moderation')}
        {newMessages ? (
          <div className={threatCounterClassname}>{newMessages}</div>
        ) : (
          ''
        )}
      </span>
    </NavLink>
  );
};
