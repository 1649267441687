import { noop } from './utils/common';
import { session_id } from './utils/session_id';

export const API = {
  fetch: async function (
    method: Request['method'],
    url: Request['url'],
    headers?: Record<string, string>,
    data?: any,
    signal?: any,
    onError: (response: Response) => void = noop,
  ) {
    if (!headers) {
      headers = { 'Content-Type': 'application/json' };
    }

    const token = localStorage.getItem('token');

    if (token !== null) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    headers["X-Request-ID"] = session_id;

    const workspace_id = localStorage.getItem('workspace_id');

    if (workspace_id !== null) {
      headers['workspace-id'] = workspace_id;
    }

    const requestParameters: {
      method: string;
      headers: Record<string, string>;
      body?: any;
      signal?: any;
    } = {
      method: method,
      headers: headers,
    };

    if (signal) {
      requestParameters['signal'] = signal;
    }

    if (data) {
      requestParameters['body'] = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, requestParameters);

      if (!response.ok) {
        console.log('Failed to remove token. Got status: ', response.status);
      }

      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('workspace_id');
        localStorage.removeItem('workspace_name');
        window.location.href = '/';
      }

      if (!response.ok) {
        let error_text = "";

        try {
          const data = await response.json();
          console.log("Error", data)
          error_text = data?.error || data?.detail[0]?.msg || data?.detail;
        } catch {}

        if (response.status === 404) {
          throw new Error('Client error: ' + (error_text ?? 'Page not found...'));
        }
        if (response.status === 401) {
          throw new Error('Client error: ' + (error_text ?? 'Authentication error'));
        }

        if (response.status === 422) {
          throw new Error('Client error: ' + (error_text ?? 'Validation error'));
        }
        if (response.status >= 400 && response.status < 500) {
          throw new Error('Client error: ' + (error_text ?? 'Client error'));
        }

        throw new Error(error_text ?? 'Something went wrong...');
      }

      if (response.status === 204) {
        return
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  async fetchBlob(
    method: Request['method'],
    url: Request['url'],
    headers?: Record<string, string>,
    data?: any,
  ) {
    if (!headers) {
      headers = { 'Content-Type': 'application/json' };
    }

    let token = localStorage.getItem('token');
    if (token !== null) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const workspace_id = localStorage.getItem('workspace_id');
    if (workspace_id !== null) {
      headers['workspace-id'] = workspace_id;
    }

    const requestParameters: {
      method: string;
      headers: Record<string, string>;
      body?: any;
    } = {
      method: method,
      headers: headers,
    };

    if (data) {
      requestParameters['body'] = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, requestParameters);

      if (!response.ok) {
        console.log('Failed to remove token. Got status: ', response.status);
      }

      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('workspace_id');
        localStorage.removeItem('workspace_name');
        window.location.href = '/';
      }

      if (!response.ok) {
        return {};
      }

      return await response.blob();
    } catch (error) {
      return false;
    }
  },

  async Fetch(
    method: Request['method'],
    url: Request['url'],
    headers?: Record<string, string>,
    data?: any,
  ) {
    if (!headers) {
      headers = { 'Content-Type': 'application/json' };
    }

    headers["X-Request-ID"] = session_id;

    let token = localStorage.getItem('token');
    if (token !== null) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const workspace_id = localStorage.getItem('workspace_id');
    if (workspace_id !== null) {
      headers['workspace-id'] = workspace_id;
    }

    const requestParameters: {
      method: string;
      headers: Record<string, string>;
      body?: any;
    } = {
      method: method,
      headers: headers,
    };

    if (data) {
      requestParameters['body'] = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, requestParameters);

      if (!response.ok) {
        console.log('Failed to remove token. Got status: ', response.status);
      }

      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('workspace_id');
        localStorage.removeItem('workspace_name');
        window.location.href = '/';
      }

      if (response.status === 422) {
        let newError = await response.json();
        let errorMessage = newError?.detail[0]?.msg;
        throw new Error(errorMessage);
      }

      // if (response.status >= 400) {
      //   throw new Error('Validation error');
      // }

      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

export const ShareAPI = {
  async fetch(
    method: Request['method'],
    url: Request['url'],
    headers?: Record<string, string>,
    data?: any,
    signal?: any,
    onError: (response: Response) => void = noop,
  ) {
    if (!headers) {
      headers = { 'Content-Type': 'application/json' };
    }

    let token = localStorage.getItem('share_token');
    if (token !== null) {
      headers['Share-Authorization'] = `Bearer ${token}`;
    }

    const requestParameters: {
      method: string;
      headers: Record<string, string>;
      body?: any;
      signal?: any;
    } = {
      method: method,
      headers: headers,
    };

    if (signal) {
      requestParameters['signal'] = signal;
    }

    if (data) {
      requestParameters['body'] = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, requestParameters);

      if (!response.ok) {
        console.log('Failed to remove token. Got status: ', response.status);

        if (response.status === 404) {
          throw new Error('Page not found...')
        }
  
        if (response.status === 400) {
          throw new Error('Page not found...')
        }
      }

      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem('share_token');
        onError(response);
      }

      if (!response.ok) {
        onError(response);
        return {};
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  },
};
