import { Outlet } from 'react-router-dom';

export function LayoutShare() {
  return (
    <div className="share-layout">
      <div className="main-content">
        <header>
          <h1>
            <span>
              <img src="/static/logo-full.svg" alt="OSavul - logo" />
            </span>
          </h1>
        </header>
      </div>

      <main>
        <Outlet />
      </main>
    </div>
  );
}
