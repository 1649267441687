import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';

import Switch from '../../components/Switch/index';
import { UserSettingsContext } from './contexts/UserSettingsContext';
import { Enable2FA } from './Enable2FA';
import { Disable2FA } from './Disable2FA';

import styles from './UserSettingsPage.module.scss';

export const SecuritySettings = () => {
  const { user } = useContext(UserSettingsContext);

  const { t } = useTranslation();
  const [isTwoStepAuth, setIsTwoStepAuth] = useState(user?.otp_2fa_enabled);

  if (!user) {
    return <></>;
  }

  return (
    <>
      <h3>{t('Security')}</h3>
      <div className="form-wrapper">
        <div className="form-element">
          <label className={styles.label} htmlFor="2f-auth">
            {t('Use two-step verification')}
          </label>
          <Switch
            id="2f-auth"
            value={isTwoStepAuth}
            onChange={setIsTwoStepAuth}
          />
        </div>
        {isTwoStepAuth === user.otp_2fa_enabled ? (
          ''
        ) : isTwoStepAuth ? (
          <Enable2FA />
        ) : (
          <Disable2FA />
        )}
      </div>
    </>
  );
};
