import { createContext, useReducer } from "react";
import { API } from "../../../API";
import userSettingsReducer, { initialState } from "./UserSettingsReducer";

export const UserSettingsContext = createContext(initialState);

export const UserSettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userSettingsReducer, initialState);

  const fetchCurrentUser = () => {
    API.fetch('GET', '/API/v1/users/me')
    .then((data) => {
      dispatch({type: 'GET_USER', payload: data})
    })
    .catch(e => dispatch({type: 'ERROR', error: e.message}));
  }

  const enable2FA = () => {
    return API.fetch('POST', `/API/v1/2fa`);
  }
  const confirmEnable2FA = (code) => {
    return API.fetch('POST', `/API/v1/2fa/confirmation`, null, {
      one_time_password: code,
    });
  }

  const disable2FA = (code) => {
    return API.fetch('DELETE', `/API/v1/2fa`, null, {
      one_time_password: code,
    });
  };

  const setLoading = (bool) => {
    dispatch({type: 'SET_LOADING', isLoading: bool})
  }

  const value = {
    user: state.user,

    dispatch: dispatch,
    setLoading: setLoading,
    fetchCurrentUser: fetchCurrentUser,
    enable2FA: enable2FA,
    confirmEnable2FA: confirmEnable2FA,
    disable2FA: disable2FA,
  }

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
    </UserSettingsContext.Provider>
  )
}

