import { createContext, useReducer } from "react";
import { format } from 'date-fns';
import { API } from "../../../../API";
import { ShareAPI } from "../../../../API";
import { useTranslation } from 'react-i18next';
import breakdownsReducer, { initialState } from "./BreakdownsReducer";

export const BreakdownsContext = createContext(initialState);

export const BreakdownsProvider = ({isShare, narrative, children}) => {
  const [state, dispatch] = useReducer(breakdownsReducer, initialState);
  const { t } = useTranslation();

  const api = isShare ? ShareAPI : API;

  const openModal = () => {
    dispatch({type: 'OPEN_MODAL'})
  }

  const closeModal = () => {
    dispatch({type: 'CLOSE_MODAL'})
  }

  const setPlatformsFilter = (options) => {
    dispatch({type: 'SET_PLATFORMS_FILTER', platformsFilter: options})
  }

  const setCountriesFilter = (options) => {
    dispatch({type: 'SET_COUNTRIES_FILTER', countriesFilter: options})
  }

  const setTypesFilter = (options) => {
    dispatch({type: 'SET_TYPES_FILTER', typesFilter: options})
  }

  const setSubtypesFilter = (options) => {
    dispatch({type: 'SET_SUBTYPES_FILTER', subtypesFilter: options})
  }

  const setAffiliationCountryId = (countryId) => {
    dispatch({type: 'SET_AFFILIATION_COUNTRY_ID', countryId: countryId})
  }

  const setAudienceFilter = (range) => {
    dispatch({type: 'SET_AUDIENCE_FILTER', audienceRange: range})
  }

  const setSourceGroupFilter = (id) => {
    dispatch({type: 'SET_SOURCE_GROUP_FILTER', sourceGroupId: [id]})
  }

  const setSourceFilter = (id) => {
    dispatch({type: 'SET_SOURCE_FILTER', sourceId: [id]})
  }

  const value = {
    isModalOpen: state.isModalOpen,
    openModal: () => openModal(),
    closeModal: () => closeModal(),
    platformsFilter: state.platformsFilter,
    sourcesSubtypesFilter: state.sourcesSubtypesFilter,
    countriesFilter: state.countriesFilter,
    sourcesTypesFilter: state.sourcesTypesFilter,
    affiliationCountryId: state.affiliationCountryId,
    audienceFilter: state.audienceFilter,
    sourceGroupFilter: state.sourceGroupFilter,
    sourceFilter: state.sourceFilter,
    setPlatformsFilter: (options) => setPlatformsFilter(options), 
    setCountriesFilter: (options) => setCountriesFilter(options),
    setTypesFilter: (options) => setTypesFilter(options),
    setSubtypesFilter: (options) => setSubtypesFilter(options),
    setAffiliationCountryId: (countryId) => setAffiliationCountryId(countryId),
    setAudienceFilter: (range) => setAudienceFilter(range),
    setSourceGroupFilter: (id) => setSourceGroupFilter(id),
    setSourceFilter: (id) => setSourceFilter(id),
  }

  return (
    <BreakdownsContext.Provider value={value}>
      {children}
    </BreakdownsContext.Provider>
  )
}
