import { useState, useEffect } from 'react';
import { Input } from './Input';

import styles from './DoubleRangeSlider.module.scss';

export const DoubleRangeSlider = ({ min, max, step, manIndexRange, onChange }) => {
  const [minValue, setMinValue] = useState(manIndexRange[0]);
  const [maxValue, setMaxValue] = useState(manIndexRange[1]);

  const handleMinChange = (inputMin) => {
    let newMinVal = Math.min(+parseFloat(inputMin), (maxValue || max) - 0.001);
    if (newMinVal < min) {
      newMinVal = min;
    }
    setMinValue(newMinVal);
  };

  const handleMaxChange = (inputMax) => {
    let newMaxVal = Math.max(+parseFloat(inputMax), (minValue || min) + 0.001);
    if (newMaxVal > max) {
      newMaxVal = max;
    }
    setMaxValue(newMaxVal);
  };

  useEffect(() => {
    onChange([minValue, maxValue])
  }, [minValue, maxValue]);

  useEffect(() => {
    setMinValue(manIndexRange[0])
    setMaxValue(manIndexRange[1])
  }, [manIndexRange])

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

  return (
    <div className={styles.rangeSlider}>
      <Input
        min={min}
        max={max}
        step={step}
        manIndexRange={manIndexRange}
        handleMinChange={(min) => handleMinChange(min)}
        handleMaxChange={(max) => handleMaxChange(max)}
      />

      <div className={styles.sliderContainer}>
        <span className={styles.heading}>{min}</span>
        <div className={styles.wrapper}>
          <div className={styles.inputWrapper}>
            <input
              className={styles.input}
              type="range"
              value={minValue}
              min={min}
              max={max}
              step={step}
              onChange={e => {
                handleMinChange(e.target.value)
              }}
            />
            <input
              className={styles.input}
              type="range"
              value={maxValue}
              min={min}
              max={max}
              step={step}
              onChange={e => {
                handleMaxChange(e.target.value)
              }}
            />
          </div>

          <div className={styles.controlWrapper}>
            <div className={styles.control} style={{ left: `${minPos}%` }} />
            <div className={styles.rail}>
              <div
                className={styles.innerRail}
                style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
              />
            </div>
            <div className={styles.control} style={{ left: `${maxPos}%` }} />
          </div>

        </div>
        <span className={styles.heading}>{max}</span>
      </div>

    </div>
  )
}