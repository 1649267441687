import { createContext, useReducer, useCallback, useRef, useEffect } from "react";
import { API } from "../../../API";
import threatsReducer, { initialState } from "./ThreatsReducer";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ampli } from "../../../ampli";
import { useCurrentUser } from '../../../contexts/CurrentUser';

export const ThreatsContext = createContext(initialState);

export const ThreatsProvider = ({children}) => {
  const [state, dispatch] = useReducer(threatsReducer, initialState);
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const abortControllerRef = useRef();

  const fetchInboxThreats = (searchQuery, sorting, showRead, incidentType, topics, types, geoScope, page, narrativeID) => {
    const urlParams = new URLSearchParams();

    urlParams.set('size', '20');
    urlParams.set('page', page);

    if(types) {
      types.forEach(type => urlParams.append('types', type))
    } else {
      urlParams.set('types', 'INCIDENT')
    }
   

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    if (narrativeID) {
      urlParams.set('narrative_id', narrativeID);
    }

    if (sorting) {
      urlParams.set('sorting', sorting);
    } 

    if(showRead !== null) {
     urlParams.set('read', showRead)
    }

    if(incidentType && incidentType?.length > 0) {
     incidentType.forEach(type => urlParams.append('incident_type', type));
    }

    if(topics && topics?.length > 0) {
      topics.forEach(subject => urlParams.append('topic', subject))
    }

    if(geoScope) {
      geoScope.forEach(scope => urlParams.append('geo_scope', scope))
    }

    // Check if there's an existing request and abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    API.fetch('GET', `/API/v1/threats/inbox/?${urlParams.toString()}`, null, null, signal).then(
      (data) => {
        dispatch({type: 'GET_THREATS', newThreats: data})
      }
    );
  };

  const fetchReportedThreats = (searchQuery, sorting, incidentType, topics, statuses, types, geoScope, narrativeID, page) => {
    const urlParams = new URLSearchParams();

    urlParams.set('size', '20');
    urlParams.set('page', page);

    if (narrativeID) {
      urlParams.set('narrative_id', narrativeID);
    }

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }
  
    if (sorting) {
      urlParams.set('sorting', sorting);
    }

    if(types) {
      types.forEach(type => urlParams.append('types', type))
    }
    
    if(incidentType && incidentType?.length > 0) {
      incidentType.forEach(type => urlParams.append('incident_type', type));
     }
 
     if(topics && topics?.length > 0) {
       topics.forEach(subject => urlParams.append('topic', subject))
     }

     if(statuses && statuses?.length > 0) {
      statuses.forEach(status => urlParams.append('review_status', status));
    } else {
      urlParams.append('review_status', 'PENDING')
    }

    if(geoScope) {
      geoScope.forEach(scope => urlParams.append('geo_scope', scope))
    }
    
     // Check if there's an existing request and abort it
     if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    API.fetch('GET', `/API/v1/threats/reported/?${urlParams.toString()}`, null, null, signal).then(
      (data) => {
        dispatch({type: 'GET_THREATS', newThreats: data})
      },
    );
  };

  const fetchModerationThreats = (searchQuery, sorting, page, incidentType, topics, statuses, types, geoScope, narrativeID) => {
    const urlParams = new URLSearchParams();

    urlParams.set('size', '20');
    urlParams.set('page', page);

    if (narrativeID) {
      urlParams.set('narrative_id', narrativeID);
    }

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }
  
    if (sorting) {
      urlParams.set('sorting', sorting);
    }

    if(types) {
      types.forEach(type => urlParams.append('types', type))
    }
    
    if(incidentType && incidentType?.length > 0) {
      incidentType.forEach(type =>urlParams.append('incident_type', type));
     }
 
     if(topics && topics?.length > 0) {
       topics.forEach(subject => urlParams.append('topic', subject))
     }

    if(statuses && statuses?.length > 0) {
      statuses.forEach(status => urlParams.append('review_status', status));
    } else {
      urlParams.append('review_status', 'PENDING')
    }

    if(geoScope) {
      geoScope.forEach(scope => urlParams.append('geo_scope', scope))
    }

     // Check if there's an existing request and abort it
     if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    API.fetch('GET', `/API/v1/threats/moderation/?${urlParams.toString()}`, null, null, signal).then(
      (data) => {
        dispatch({type: 'GET_THREATS', newThreats: data})
      },
    );
  }

  const getNarratives = (searchQuery, sorting, page, incidentType, topics, statuses, types, geoScope) => {
    const urlParams = new URLSearchParams();

    urlParams.set('size', '20');
    urlParams.set('page', page);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    if (sorting) {
      urlParams.set('sorting', sorting);
    }

    urlParams.append('types', 'NARRATIVE')

    if(incidentType && incidentType?.length > 0) {
      incidentType.forEach(type =>urlParams.append('incident_type', type));
     }

     if(topics && topics?.length > 0) {
       topics.forEach(subject => urlParams.append('topic', subject))
     }

    if(statuses && statuses?.length > 0) {
      statuses.forEach(status => urlParams.append('review_status', status));
    }

    if(geoScope) {
      geoScope.forEach(scope => urlParams.append('geo_scope', scope))
    }

     // Check if there's an existing request and abort it
     if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    API.fetch('GET', `/API/v1/threats/narratives/?${urlParams.toString()}`, null, null, signal).then(
      (data) => {
        dispatch({type: 'GET_NARRATIVES', payload: data})
      },
    );
  }

  const getNarrative = (narrativeID) => {
    API.fetch('GET', `/API/v1/narratives/${narrativeID}`).then(
      (data) => {
        dispatch({type: 'GET_NARRATIVE', payload: data})
      },
    );
  }

  const clearNarrative = () => {
    dispatch({type: 'GET_NARRATIVE', payload: null});
  }

  const saveAsNarrative = (threat) => {
    let parameters = null;

    if (threat.parameters !== null) {
      parameters = {
        keywords: threat.parameters.keywords || null,
        examples: threat.parameters.examples || null,
        start_date: threat.parameters.start_date || null,
        end_date: threat.parameters.end_date || null,
        apply_supervised_classifier: threat.parameters.apply_supervised_classifier,
        source_types: threat.parameters.source_types || [],
        source_group_ids: threat.parameters.source_group_ids || [],
        disable_filters: threat.parameters.disable_filters,
        languages: threat.parameters.languages || [],
        source_ids: threat.parameters.source_ids || [],
        source_origin_country_ids: threat.parameters.source_origin_country_ids || [],
        sentiment: threat.parameters.sentiment || [],
        translate_keywords_query: threat.parameters.translate_keywords_query,
        keywords_query_origin_language: threat.parameters.keywords_query_origin_language || '',
        content_types: threat.parameters.content_types || [],
        similarity_threshold: threat.parameters.similarity_threshold || null,
      }
    }

    API.fetch('POST', `/API/v1/narratives/${threat.id}/copies`, null, {
      name: threat.name,
      description: threat.description,
      is_active: threat.is_active,
      is_manual: threat.parameters === null,  // TODO is_manual ?
      incident_type: threat.incident_type,
      topic: threat.topic,
      parameters: parameters,
      adjustment_suggestions: null,
      type: 'NARRATIVE',
    }).then((data) => {
      ampli.track({
        event_type: 'Save threat',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          threat_id: threat.id,
        }
      });

      navigate('/narratives');
    });
  }

  const patchThreat = ({threat, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic, searchQuery, sorting, page, workspaceId, mode}) => {
    let data = {
      name: threatName,
      description: threatDescription,
      public_name_en: threatPublicNameEn,
      public_name_uk: threatPublicNameUk,
      public_description_en: threatPublicDescriptionEn,
      public_description_uk: threatPublicDescriptionUk,
      is_public: threatIsPublic,
    };

    const headers = { 'Content-Type': 'application/json' };

    if (workspaceId) {
      headers['workspace-id'] = workspaceId;
    }

    if(caseType) {
      data.type = caseType.value;
    }

    if(incidentType) {
      data.incident_type = incidentType.map(v => v.value);
    }

    if(topics) {
      data.topic = topics.map(v => v.value);
    }

    if(geoScope) {
      data.geo_scope = geoScope.map(v => v.value);
    }

    if(narratives) {
      data.narrative_ids = narratives.map(v => v.value);
    }

    if(campaigns) {
      data.campaign_ids = campaigns.map(v => v.value);
    }

    if(counterEntities) {
      data.counter_entity_ids = counterEntities.map(v => v.value);
    }

    if(reviewStatus) {
      data.review_status = reviewStatus;
    }

    if(declineReason) {
      data.decline_reason = declineReason;
    }

    if(organizations?.length > 0) {
      data.workspace_ids = organizations.map( v => v.value);
    }

    data.is_created_by_ucbi_reporter = isCreatedByUcbiReporter

    API.fetch('PATCH', `/API/v1/narratives/${threat.id}`, headers, data).then((data) => {
      dispatch({type: 'TOGGLE_TAB'})
      if (mode === 'Moderation') {
        fetchModerationThreats(searchQuery, sorting, page)
      } else if (mode === 'Reported'){
        fetchReportedThreats(searchQuery, sorting, null, null, null, null, null, null, page)
      } else if (mode === 'Narratives') {
        getNarratives(searchQuery, sorting, page)
      }
      
    });
  }

  const deleteThreat = (id, searchQuery, sorting, page, mode) => {
    API.fetch('DELETE', `/API/v1/narratives/${id}`).then(() =>{
      dispatch({type: 'TOGGLE_TAB'})
      if (mode === 'Moderation') {
        fetchModerationThreats(searchQuery, sorting, page)
      } else if (mode === 'Reported'){
        fetchReportedThreats(searchQuery, sorting, null, null, null, null, null, null, page)
      } else if (mode === 'Narratives') {
        getNarratives(searchQuery, sorting, page)
      }
    } 
    );
  }

  const toggleTab = () => {
    dispatch({type: 'TOGGLE_TAB'})
  }

  const cancelRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }

  const value = {
    threats: state.threats,
    narratives: state.narratives,
    narrative: state.narrative,
    getInboxThreats: fetchInboxThreats,
    getReportedThreats: fetchReportedThreats,
    getModerationThreats: fetchModerationThreats,
    getNarratives: getNarratives,
    getNarrative: getNarrative,
    clearNarrative: clearNarrative,
    saveAsNarrative: saveAsNarrative,
    deleteThreat: deleteThreat,
    patchThreat:  patchThreat,
    toggleTab: toggleTab,
    cancelRequest: cancelRequest,
  }

  return (
    <ThreatsContext.Provider value={value}>
      {children}
    </ThreatsContext.Provider>
  )
}