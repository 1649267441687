import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import './modal.scss';

export default function Modal({
  isVisible = false,
  title,
  content,
  footer,
  onClose,
  className,
  doNotCloseOnOutsideClick=false,
}) {
  const keydownHandler = ({ key }) => {
    switch (key) {
      case 'Escape':
        onClose();
        break;
      default:
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => document.removeEventListener('keydown', keydownHandler);
  });

  const modalRoot = document.getElementById('modal-root');

  return !isVisible
    ? null
    : ReactDOM.createPortal(
        <div
          className={className ? `modal ${className}` : 'modal'}
          onClick={doNotCloseOnOutsideClick ? '' : onClose}
        >
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h2 className="modal-title">{title}</h2>
              <span className="modal-close" onClick={onClose}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="modal-content">{content}</div>
            </div>
            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>,
        modalRoot,
      );
}
