import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState, useContext } from 'react';
import { API } from '../../API';
import { Code } from '../../components/Code/Code';
import { UserSettingsContext } from './contexts/UserSettingsContext';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';

import styles from './UserSettingsPage.module.scss';

export const Disable2FA = () => {
  const { disable2FA, fetchCurrentUser } = useContext(UserSettingsContext);

  const { t } = useTranslation();
  const [code, setCode] = useState(['']);
  const [errors, setErrors] = useState({});

  const handleDisable2FA = () => {
    disable2FA(code.join(''))
      .then((data) => {
        fetchCurrentUser();
      })
      .catch((error) =>
        setErrors((errors) => ({ ...errors, general: error.message })),
      );
  };

  return (
    <div className={styles.enable2FAContainer}>
      <h3>{t('Disable 2FA')}</h3>
      <p>
        {t(
          `To disable 2FA enter the six-digit code generated by your authenticator`,
        )}
      </p>
      <div className={styles.codeForm}>
        <Code onChange={(c) => setCode(c)} />
      </div>

      <button
        className="new-button"
        onClick={() => handleDisable2FA()}
        disabled={code.join('').length < 6}
      >
        {t('Disable')}
      </button>
      {errors.general ? (
        <div className={styles.errorMessage}>
          {t(errors.general)}
          <span onClick={() => setErrors({ ...errors, general: null })}>
            {' '}
            <CloseIcon />{' '}
          </span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
