import { useState, useEffect } from 'react';
import { Input } from './Input';

import styles from './DoubleRangeSlider.module.scss';

export const DoubleRangeSlider = ({ min, max, step, audienceRange, onChange }) => {
  const [minValue, setMinValue] = useState(audienceRange[0]);
  const [maxValue, setMaxValue] = useState(audienceRange[1]);

  const handleMinChange = (inputMin) => {
    setMinValue(inputMin);
  };

  const handleMaxChange = (inputMax) => {
    setMaxValue(inputMax);
  };

  useEffect(() => {
    onChange([minValue, maxValue])
  }, [minValue, maxValue]);

  useEffect(() => {
    setMinValue(audienceRange[0])
    setMaxValue(audienceRange[1])
  }, [audienceRange])

  return (
    <div className={styles.rangeSlider}>
      <Input
        min={min}
        max={max}
        step={step}
        audienceRange={audienceRange}
        handleMinChange={(min) => handleMinChange(min)}
        handleMaxChange={(max) => handleMaxChange(max)}
      />

    </div>
  )
}