import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { useQuill } from 'react-quilljs';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'quill/dist/quill.snow.css';

import { isValidQuery, newKeywordsValidator } from './QueryInput.helpers';
import { useTranslation } from 'react-i18next';
import { useMultiClickOutside } from '../../../utils/useMultiClickOutside';
import { ReactComponent as SearchIcon } from '../../SearchPage/assets/search.svg';
import { ReactComponent as CloseIcon } from '../../SearchPage/assets/close.svg';
import styles from './QueryInput.module.scss';
import { useCurrentUser } from "../../../contexts/CurrentUser";

const Suggestion = {
  OR: 'OR',
  AND: 'AND',
  AND_NOT: 'AND NOT',
  STAR: '*',
  QUOTE: '""',
  BRACES: '()',
  BRACKETS: '[]',
  TOP_WORD: '<>'
};

const SuggestionAdvanced = {
  OR: '|',
  AND: '&',
  NOT: '!',
  STAR: '*',
  AT: '@',
  TILDE: '~',
  QUOTE: '""',
  BRACES: '()',
  BRACKET: ']',
  GREATER_THAN: '>',
  TOP_WORD: '<>'
}

const suggestions = [
  [
    Suggestion.AND_NOT,
    Suggestion.AND,
    Suggestion.OR,
    Suggestion.BRACES,
  ],
  [
    Suggestion.QUOTE,
    Suggestion.TOP_WORD,
    Suggestion.STAR,
    Suggestion.BRACKETS,
  ],
  [
    SuggestionAdvanced.AT,
    SuggestionAdvanced.TILDE,
    SuggestionAdvanced.GREATER_THAN,
  ]
];

const suggestionsAdvanced = [
  [
    SuggestionAdvanced.OR,
    SuggestionAdvanced.AND,
    SuggestionAdvanced.NOT,
    SuggestionAdvanced.BRACES,
  ],
  [
    SuggestionAdvanced.QUOTE,
    SuggestionAdvanced.TOP_WORD,
    SuggestionAdvanced.STAR,
    SuggestionAdvanced.BRACKET
  ]
]

export const QueryInput = forwardRef(
  ({
    keywords,
    onChange = () => { },
    className = '',
    isInvalid,
    disabled,
    onKeyDown = () => { },
    search = false,
    clear = () => { },
    isAdvanced = false,
    onClick = () => {}
  }, ref) => {

    const { t } = useTranslation();
    const [currentUser] = useCurrentUser();

    let guideLink;
    // console.log('QueryInput', keywords)

  if(
    localStorage.getItem('i18nextLng') === 'ua-UK' ||
    localStorage.getItem('i18nextLng') === 'ru-RU' ||
    localStorage.getItem('i18nextLng') === 'ru' ||
    localStorage.getItem('i18nextLng') === 'uk'
  ) {
    guideLink = 'https://maple-fuel-09c.notion.site/C-796a2dfb19ca40a1a03551df662d0bac'
  } else {
    guideLink = 'https://maple-fuel-09c.notion.site/Osavul-Search-syntax-02ce73ac32964c22b840d6e0583e7bcd'
  }

    const { quill, quillRef } = useQuill({
      modules: {
        toolbar: false,
        keyboard: {
          bindings: {
            linebreakEnter: {
              key: 'Enter',
              handler: function (range) { },
            }
          }
        }
      },
    });

    const dropdownRef = useRef(null);
    const [value, setValue] = useState(keywords ?  keywords : '');
    const [isFocus, setIsFocus] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    const workspaceConfig = currentUser?.workspace?.config;
    const featureFlags = workspaceConfig?.featureflags;
    const isNewKeywordsValidatorAvailable = featureFlags?.includes('validate_keywords');
    const [newValidationError, setNewValidationError] = useState(null);

    const handleSuggestionClick = useCallback(
      (event) => {
        const text = quill?.getText() ?? '';
        const index = quill?.getSelection()?.index ?? text?.length;
        const suggestion = event.target.dataset.suggestion;
        const spacePrefix =
          text.at(index) === '' ||
            (text.at(index - 1) === '' && text.at(index) === '\n') ||
            (text.at(index - 1) === '\n' && text.at(index) === '\n') ||
            (text.at(index - 1) === ' ') ||
            suggestion === ',' ||
            suggestion === '>' ||
            suggestion === ']' ||
            suggestion === '@' ||
            suggestion === '*' ||
            suggestion === '~'
            ? ''
            : ' ';

        const spaceSuffix = text.at(index) === ' ' || 
          suggestion === '>' ||
          suggestion === ']' ||
          suggestion === '@' ||
          suggestion === '*' ||
          suggestion === '~'
          ? '' 
          : ' ';

        if (suggestion === '()' || suggestion === '""' || suggestion === '[]' || suggestion === '<>') {
          quill.insertText(quill?.getSelection().index, suggestion[0]);
          quill.insertText(quill?.getSelection().index + quill?.getSelection().length, suggestion[1])
        } else {
          quill.insertText(index, `${spacePrefix}${suggestion}${spaceSuffix}`);
        }

        quillRef.current.focus();
        // setIsFocus(false);
      },
      [quill, quillRef],
    );

    const handeClickOutside = useCallback(() => {
      setTimeout(() => setIsFocus(false));
    }, []);

    useMultiClickOutside([quillRef, dropdownRef], handeClickOutside);

    useImperativeHandle(
      ref,
      () => ({
        set: setValue,
      }),
      [],
    );

    useEffect(() => {
      if (disabled) {
        quill?.disable();
      } else {
        quill?.enable();
      }
    }, [quill, disabled]);

    useEffect(() => {
      if (!quill) {
        return;
      }

      const format = (text) => {
        quill.removeFormat(0, quill.getLength() - 1);

        requestAnimationFrame(() => {
          const updates = [];
          let current = '';

          for (let i = 0; i < text.length; i += 1) {
            const char = text[i];

            if (
              char === ' ' ||
              char === ',' ||
              (char === '\n' && i + 1 !== text.length)
            ) {
              updates.push({
                pointer: i,
                keyword: char,
                color: 'purple',
              });
              current = '';
              continue;
            }

            const next = text[i + 1];
            const isEnd = next === ' ' || next === undefined || next === '\n';
            const isLastComma = text[i - current.length] === ',';
            current += char;

            if (isAdvanced) {
              if (current === SuggestionAdvanced.AND &&
                next === ' ' &&
                !isLastComma &&
                isEnd) {
                updates.push({
                  pointer: i,
                  keyword: SuggestionAdvanced.AND,
                  color: 'forestgreen',
                });
                current = '';
              } else if (current === SuggestionAdvanced.OR && next === ' ' && !isLastComma && isEnd) {
                updates.push({
                  pointer: i,
                  keyword: SuggestionAdvanced.OR,
                  color: 'orange',
                });
                current = '';
              } else if (current === SuggestionAdvanced.NOT &&
                next === ' ' &&
                !isLastComma &&
                isEnd) {
                updates.push({
                  pointer: i,
                  keyword: SuggestionAdvanced.NOT,
                  color: 'crimson',
                });
                current = '';
              }
            } else {
              if (
                current === Suggestion.AND &&
                next === ' ' &&
                !isLastComma &&
                text[i + 2] === 'N' &&
                text[i + 3] === 'O' &&
                text[i + 4] === 'T' &&
                (text[i + 5] === ' ' ||
                  text[i + 5] === '\n' ||
                  text[i + 6] === undefined)
              ) {
                updates.push({
                  pointer: i - 2,
                  keyword: Suggestion.AND_NOT,
                  color: 'crimson',
                });
                i += 4;
                current = '';
              } else if (current === Suggestion.AND && !isLastComma && isEnd) {
                updates.push({
                  pointer: i - 2,
                  keyword: Suggestion.AND,
                  color: 'forestgreen',
                });
                current = '';
              } else if (current === Suggestion.OR && !isLastComma && isEnd) {
                updates.push({
                  pointer: i - 1,
                  keyword: Suggestion.OR,
                  color: 'orange',
                });
                current = '';
              } else if (isEnd) {
                current = '';
              }
            }
          }

          for (const { pointer, keyword, color } of updates) {
            if (
              keyword === Suggestion.AND_NOT ||
              keyword === Suggestion.AND ||
              keyword === Suggestion.OR ||
              keyword === SuggestionAdvanced.NOT ||
              keyword === SuggestionAdvanced.AND ||
              keyword === SuggestionAdvanced.OR ||
              keyword === ','
            ) {
              quill.formatText(pointer, keyword.length, {
                color,
              });
            }
          }


        });
      };

      quill.setText(value);
      format(value);

      const handler = (delta) => {
        const text = quill.getText();

        requestAnimationFrame(() => {
          onChange(
            text,
            isNewKeywordsValidatorAvailable ?
              newKeywordsValidator(text, setNewValidationError, t) : isValidQuery(text, isAdvanced)
          )
          setIsFocus(true);
        });

        for (const op of delta.ops) {
          if (op.delete || op.insert) {
            format(text);
          }
        }
      };

      const handleSelection = (e) => {
        if (e && e.length > 0) {
          setIsSelected(true)
        } else {
          setIsSelected(false)
        }
      }

      quill.on('text-change', handler);
      quill.on('selection-change', handleSelection)

      return () => {
        quill.off('text-change', handler);
        quill.off('selection-change', handleSelection)
      };
    }, [quill, value, onChange, disabled]);

    const text = quill?.getText() ?? '';
    const isInvalidQuery = isInvalid ? isInvalid : isNewKeywordsValidatorAvailable ? newValidationError !== null : !isValidQuery(text, isAdvanced);
    const includesBackslash = useMemo(() => text.includes('\\'), [text]);

    return (
      <>
        <div
          className={cn(styles.root, className, {
            [styles.invalid]: isInvalidQuery,

          })}
        >
          {search ? <span className={styles.iconWrapper} onClick={e => onClick(e)}><SearchIcon className={styles.icon}/></span> : ''}
          <div
            ref={quillRef}
            className={search ? `${styles.input} ${styles.search}` : styles.input}
            style={isFocus && isInvalid ? { borderColor: 'crimson' } : isFocus ? { borderColor: '#2463eb' } : { borderColor: '#e1e5ee' }}
            onFocus={(e) => {
              setIsFocus(true)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && event.shiftKey === false) {
                onKeyDown(event)
                setIsFocus(false)
              }
              if (isSelected === true) {
                setIsSelected(false)
              }
            }}
          />
          <CloseIcon
            className={search ? styles.searchClose : styles.close}
            onClick={() => {
              quill?.setText('')
              clear()
            }}
          />
          <div
            ref={dropdownRef}
            className={cn(styles.dropdown, {
              [styles.visible]: text.length > 0 && (isInvalidQuery || isFocus),
              [styles.invalid]: isInvalidQuery,
            })}
          >
            <div className={styles.heading}>
              { isNewKeywordsValidatorAvailable && newValidationError !== null ? (
                <div className={styles.errorMessage}>
                { newValidationError}
                </div>
              ) : !isInvalid && includesBackslash ? (
                <div className={styles.errorMessage}>
                  {t('All brackets in the query must be closed')}
                </div>
                
              ) : isInvalid ? (
                <div className={styles.errorMessage}>
                  {t('All brackets in the query must be closed')}
                </div>

              ) : ''}
              <span>{t('Operators')}</span>
              <span>({t('e.g. Zelensky, president AND terrorism AND NOT Biden, Putin',)}). {t('See our ')} <a href={guideLink} target="_blank" rel="noreferrer">{t('search guide')}</a>. </span>
            </div>
            <div className={styles.suggestions}>
              {!isAdvanced ? suggestions.map((suggestionGroup, i) => (
                <div key={`operator_group_${i}`} className={styles.operatorGroup}>
                  {suggestionGroup.map(suggestion => (
                    <button
                      key={suggestion}
                      disabled={
                        (
                          isSelected && !(suggestion === '()' || suggestion === '""' || suggestion === '<>')
                        ) ? true : false
                      }
                      data-suggestion={suggestion}
                      type="button"
                      className={styles.suggestion}
                      onClick={handleSuggestionClick}
                    >
                      {suggestion}
                    </button>
                  ))}
                </div>
              )) : suggestionsAdvanced.map((suggestionGroup, i) => (
                <div key={`operator_group_${i}`} className={styles.operatorGroup}>
                  {suggestionGroup.map(suggestion => (
                    <button
                      key={suggestion}
                      disabled={
                        (
                          isSelected && !(suggestion === '()' || suggestion === '""' || suggestion === '<>')
                        ) ? true : false
                      }
                      data-suggestion={suggestion}
                      type="button"
                      className={styles.suggestion}
                      onClick={handleSuggestionClick}
                    >
                      {suggestion}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  },
);
