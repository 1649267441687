import React from 'react';
import styles from './Loader.module.scss';

export const Loader = () => (
  <div className={styles.root}>
    <div className={styles.container}>
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
    </div>
  </div>
);
