import { useTranslation } from 'react-i18next';

export const TypeChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const types = [
    {name: 'CASE', value: "case"},
  ]

  const handleSelect = () => {
    if (types.length !== selected.length) {
      onChange(types.map(type => type.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{types.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {types.map(type => {
          return(
            <li key={type.value}>
              <input 
                id={`type_${type.value}`}
                type='checkbox'
                checked={selected.includes(type.value)}
                onChange={() => {
                  if (selected.includes(type.value)) {
                    onChange(selected.filter(v => v !== type.value))
                  } else {
                    onChange([...selected, type.value])
                  }
                }}
              />
              <label htmlFor={`export_type_${type.value}`}>{t(`export_type_${type.value}`)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}
