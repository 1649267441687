import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import addDays from 'date-fns/addDays';

import { API } from '../../API';
import { ampli } from "../../ampli";
import { useCurrentUser } from '../../contexts/CurrentUser';
import Switch from '../Switch';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';
import { Tooltip } from 'react-tooltip';
import './index.css';

export default function Share({ isVisible, onClose, reportID, narrativeId }) {
  const { t } = useTranslation();
  const options = [
    { value: addDays(new Date(), 7), label: t('Two weeks') },
    { value: addDays(new Date(), 30), label: t('Month') },
    { value: addDays(new Date(), 90), label: t('Three Months') },
  ]

  
  const [currentUser] = useCurrentUser();
  const [showExpiration, setShowExpiration] = useState(false);
  const [expiration, setExpiration] = useState(options[0]);
  const [, setShare] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [copyButton, setCopyButton] = useState({
    icon: '/static/copy.svg',
    title: t('Create link'),
  });
  const [values, setValues] = useState({
    password: '',
  });

  const [shareLink, setShareLink] = useState('');

  const handleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShareLink('')

    const data = {
      password: values.password,
      expires: (showExpiration && expiration ) ? expiration?.value : addDays(new Date(), 365 * 2),
    };

    if (reportID) {
      data['report_id'] = reportID
    } else if (narrativeId) {
      data['narrative_id'] = narrativeId
    } else {
      console.warn('Neither reportID or narrativeId provided')
      return
    }

    setIsLoading(true)
    setCopyButton({ icon: '/static/copied.svg', title: t('Loading...1') });

    API.fetch('POST', `/API/v1/reports/shares`, null, data).then((data) => {
      setIsLoading(false);
      
      let shareLoginURL;
      if (reportID) {
        shareLoginURL = `${window.location.protocol}//${window.location.host}/reports/${reportID}/share/login?h=${data.hash}&with_password=${data.with_password}`
      } else if (narrativeId) {
        shareLoginURL = `${window.location.protocol}//${window.location.host}/narratives/${narrativeId}/share/login?h=${data.hash}&with_password=${data.with_password}`
      }

      // navigator.clipboard.writeText(shareLoginURL);
      setShareLink(shareLoginURL);
      setShare(data);

      setCopyButton({ icon: '/static/copied.svg', title: t('Copied') });

      if (narrativeId) {
        ampli.track({
          event_type: 'Share case',
          event_properties: {
            user_id: currentUser?.id,
            workspace_id: currentUser?.workspace_id,
            narrative_id: narrativeId,
          }
        });
      }
      if (reportID) {
        ampli.track({
          event_type: 'Share report',
          event_properties: {
            user_id: currentUser?.id,
            workspace_id: currentUser?.workspace_id,
            report_id: reportID,
          }
        });
      }
    });
  };

  return (
    <>
      <div className="option">
        <div>
          <div className="title">{t('Validity period')}</div>
          <div className="help">
            {t('Make sure this link stops working after certain period of time')}
          </div>
        </div>
        <div>
          <Switch
            value={showExpiration}
            onChange={(v) => setShowExpiration(v)}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {showExpiration ? (
          <div className="option">
            <div className="help">{t('Select a link duration period')}</div>
            <div>
              <Select
                onChange={(value) => setExpiration(value)}
                value={expiration}
                isSearchable={false}
                name='expiration'
                type='text'
                isClearable={false}
                placeholder={t('Select duration...')}
                options={options}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="option">
          <div>
            <div className="title">{t('Password')}</div>
            <div className="help">{t('Set password for the share link')}</div>
          </div>
          <div className="form-element">
            <input
              type="password"
              name="password"
              onChange={handleChange}
              autocomplete="new-password"
            />
          </div>
        </div>
        <div className="option">
          <div>
            <div className="title">{t('Link')}</div>
            <div className="help">{t('Copy the link to the report')}</div>
          </div>
          {shareLink ? <div className="form-element">
            <input
              type='text'
              defaultValue={shareLink}
              className='share-input'
            />
          </div> : ''}
          
          <div>
            {!shareLink ? <button disabled={isLoading}>
              <img src={copyButton.icon} alt="Copy icon" />
              {copyButton.title}
            </button> : ''}
            {shareLink 
              ? <button 
                  data-tooltip-id="copy-link"
                  data-tooltip-content={t("Copy link")}
                  className='input-button'
                  onClick={(e) => {
                    e.preventDefault();
                    navigator?.clipboard?.writeText(shareLink)
                }}>
                  <CopyIcon/>
                </button> : ''}
                <Tooltip id="copy-link" place="bottom"/>
          </div>
        </div>
      </form>
    </>
  );
}
