import React from 'react';
import cn from 'classnames';

import { Spinner } from '../Spinner/Spinner';
import styles from './Checkbox.module.scss';

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  isLoading?: boolean;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  className?: string;
}

export const Checkbox: React.FC<Props> = ({
  checked,
  onChange,
  isLoading,
  inputProps = {},
  className = '',
}) => {
  return (
    <span
      role="presentation"
      className={cn(styles.root, className, {
        [styles.isLoading]: isLoading,
      })}
    >
      <input
        className={styles.input}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
        {...inputProps}
      />
      <span role="presentation" className={styles.label} />

      {isLoading && <Spinner className={styles.spinner} />}
    </span>
  );
};
