import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';

import { API } from '../../API';
import { Loader } from '../../components/Loader/Loader';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as DownloadIcon } from './assets/download.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import styles from './ExportsPage.module.scss';
import {LoaderSmall} from "../../components/LoaderSmall/LoaderSmall";
import {Filter} from "./Filter/Filter";
import {Link, useSearchParams} from "react-router-dom";
import {Paginator} from "../../components/Paginator/Paginator";

export function ExportsPage() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const [exports, setExports] = useState(null);

  const [loader, setLoader] = useState(false);
  const [downloadLoaderId, setDownloadLoaderId] = useState(null);

  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [objectId, setObjectId] = useState(searchParams.get('object_id'));
  const [outputFormats, setOutputFormats] = useState(searchParams.getAll('output_formats') || []);
  const [types, setTypes] = useState(searchParams.getAll('types') || []);
  const [statuses, setStatuses] = useState(searchParams.getAll('statuses') || []);

  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState({isAscending: false, fieldName: 'id'});
  const sortingQuery = sorting ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}` : null;
  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'id',
    },
    {
      name: t('Created at'),
      value: 'created_at',
    },
    {
      name: t('Last downloaded at'),
      value: 'last_downloaded_at',
    },
  ]
  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]

  const fetchExports = useCallback(() => {
    setLoader(true);
    const urlParams = new URLSearchParams();

    urlParams.set('size', '10');
    urlParams.set('page', page);
    urlParams.set('sorting', sortingQuery);

    if (objectId) {
      urlParams.set('object_id', objectId);
    }

    outputFormats.forEach((outputFormat) => {
      urlParams.append('output_formats', outputFormat);
    });

    types.forEach((type) => {
      urlParams.append('types', type);
    });

    statuses.forEach((status) => {
      urlParams.append('statuses', status);
    });

    API.fetch('GET', `/API/v1/async-export?${urlParams.toString()}`).then(
      (data) => {
        setExports(data);
        setLoader(false);
      },
    ).catch(() => {
      setLoader(false);
    });
  }, [page, objectId, outputFormats, types, statuses, sortingQuery]);

  useEffect(fetchExports, [fetchExports]);

  return (
    <>
      <div className="list-content">
        <div className="page-header">
          <div className="breadcrumb">
            <span>{t('Exports')}</span>
          </div>
        </div>

        <div className="next-card-header">
          <div className={ styles.searchWrapper }>
            <DropdownMenu
              isOpen={dropdownMenu}
              header={t('Sort by')}
              onClick={() => setDropdownMenu(!dropdownMenu)}
              buttonName={t('Sort')}
              icon={<SortIcon/>}
            >
              <Radiobutton
                itemsList={sortingMenu}
                current={sorting.fieldName}
                onChange={(value) => setSorting({...sorting, fieldName: value})}
              />

              <SortingOrder
                itemsList={sortingOrder}
                onClick={value => setSorting({...sorting, isAscending: value})}
                current={sorting.isAscending}
              />
            </DropdownMenu>


            <Filter
              onChange={(objectId, types, statuses, outputFormats) => {
                setObjectId(objectId);
                setTypes(types);
                setStatuses(statuses);
                setOutputFormats(outputFormats);
              }}
              objectIdValue={objectId}
              typesValue={types}
              statusesValue={statuses}
              outputFormatsValue={outputFormats}
            />
          </div>
        </div>
        { loader ? <Loader /> : <>
          <table className={styles.exports}>
            <thead>
            <tr>
              <td>{t('ID')}</td>
              <td>{t('Status')}</td>
              <td>{t('Object')}</td>
              <td>{t('Output format')}</td>
              <td>{t('Created at')}</td>
              <td>{t('Last downloaded at')}</td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            { exports?.objects?.map((export_row) => {
              let export_object_title;

              switch (export_row.type) {
                case 'case':
                  export_object_title = <>
                    <span>
                      <Link to={`/narratives/${export_row.object_id}`}>
                        {t('Narrative')}: {export_row?.display_name}
                      </Link>
                    </span>
                    <span>#{export_row.object_id}</span>
                  </>
                  break;
                default:
                  export_object_title = export_row?.display_name ?? `${t('export_type_' + export_row.type)} id=${export_row.object_id}`
              }

              return (
                <tr>
                  <td>{export_row.id}</td>
                  <td>{t(`export_status_${export_row.status}`)}</td>
                  <td className={styles.exportObject}>{export_object_title}</td>
                  <td>{t(`export_output_format_${export_row.output_format}`)}</td>
                  <td>{format(parseISO(export_row.created_at + 'Z'), 'dd LLL yyyy HH:mm')}</td>
                  <td>{export_row.last_downloaded_at ? format(parseISO(export_row.last_downloaded_at + 'Z'), 'dd LLL yyyy HH:mm') : '-'}</td>
                  <td className={styles.downloadColumn}>
                    { export_row.status !== 'completed' ? '' : downloadLoaderId === export_row.id ? <LoaderSmall/> : <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setDownloadLoaderId(export_row.id);
                        API.fetch('GET', `/API/v1/async-export/${export_row.id}`).then((download_link) => {
                          const link = document.createElement('a');
                          link.href = download_link;
                          link.click();
                          setDownloadLoaderId(null);
                          fetchExports();
                        }).catch(() => {
                          setDownloadLoaderId(null);
                        });
                      }}
                    >
                      <DownloadIcon/>
                    </a> }
                  </td>
                </tr>
              )
            }) }
            </tbody>
          </table>
        </> }
      </div>

      <Paginator
        size={exports?.size}
        page={page}
        total={exports?.total}
        onPageChange={setPage}
      />
    </>
  );
}
