import { useTranslation } from 'react-i18next';

import './Filter.scss';


export const IoCChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const IoCTypeList = [
    {name: 'Sanctions', value: 'SANCTIONS'},
    {name: 'Disinformation', value: 'DISINFORMATION'},
    {name: 'Hacked / Stolen', value: 'HACKED_OR_STOLEN'},
    {name: 'Influence operations', value: 'INVOLVED_IN_INFLUENCE_OPERATIONS'},
  ]

  const handleSelect = () => {
    if (IoCTypeList.length !== selected.length) {
      onChange(IoCTypeList.map(ioc => ioc.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{IoCTypeList.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {IoCTypeList.map(ioc => {
          return(
            <li key={ioc.value}>
              <input 
                id={`ioc_${ioc.value}`}
                type='checkbox'
                checked={selected.includes(ioc.value)}
                onChange={() => {
                  if (selected.includes(ioc.value)) {
                    onChange(selected.filter(v => v !== ioc.value))
                  } else {
                    onChange([...selected, ioc.value])
                  }
                }}
              />
              <label htmlFor={`ioc_${ioc.value}`}>{t(ioc.name)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}

