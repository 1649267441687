import { useState, useCallback, useEffect } from 'react';
import { parseISO, format, formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import { API } from '../../API';
import { Paginator } from '../../components/Paginator/Paginator';
import cn from 'classnames';
import * as d3 from 'd3';
import { ExpandableText } from '../../components/CollapsibleText/ExpandableText';
import { ReactComponent as ArrowIcon } from '../NarrativesPage/assets/arrow.svg';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import {ReactComponent as CalendarIcon} from '../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../assets/engagement.svg';
import { ReactComponent as SimilarityIcon } from '../../assets/similarity.svg';
import { ReactComponent as ManipulationIcon } from '../../assets/manipulation.svg';
import { ReactComponent as FollowersIcon } from '../../assets/followers.svg';
import { ReactComponent as TranslationIcon } from '../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../assets/originalText.svg';
import { ReactComponent as ActorIcon } from '../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../pages/SearchPage/assets/person.svg';
import { ReactComponent as SearchIcon } from '../../pages/SearchPage/assets/search.svg';
import { getSentimentName, getSentimentIcon } from "../../utils/sentiment";
import { decodeSourceName } from '../../utils/decodeURI';
import {platformIconsMapping, platformNamesMapping} from '../../utils/platforms';
import {contentTypesIconsMapping, contentTypesMapping} from '../../utils/contentTypes';
import { LoaderSmall } from '../../components/LoaderSmall/LoaderSmall';
import { Loader } from '../../components/Loader/Loader';
import Tooltip from '../../components/Tooltip/Tooltip';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { replaceNewlinesWithBreaks } from '../../utils/replaceNewlinesWithBreaks';

import messageStyles from "../../components/MessagesTable/ExtendedMessageView.module.scss";
import styles from '../../pages/NarrativePage/NarrativePage.module.scss';

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);

export const MessagesModal = ({
  infoModal,
  sourceId,
  narrativeId,
  onChange,
  isShare=false,
}) => {
  const { t } = useTranslation();
  const [narrativeMessages, setNarrativeMessages] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser] = useCurrentUser();

  const nativeLang = currentUser?.translation_language ? currentUser?.translation_language : window.clientInformation.language.split('-', 1)[0].toUpperCase();

  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showNer = featureFlags?.includes('ner_widget');
  


  const nerTypesList = [
    {value: 'KEYWORDS', label: t('Matched keywords')},
  ]

  if(showNer) {
    nerTypesList.push(
      {value: 'PERSON', label: t('Persons')},
      {value: 'ORGANIZATION', label: t('Organizations')},
      {value: 'LOCATION', label: t('Locations')}
    )
  }
  
  const [highlights, setHighlights] = useState(['KEYWORDS']);

  const fetchNarrativeMessages = useCallback((page=1) => {
    if (!narrativeId || !sourceId) {
      return;
    }

    setIsLoading(true);

    API.fetch('GET', `/API/v1/narratives/${narrativeId}/messages?source_id=${sourceId}&page=${page}&size=20`).then(
      (data) => {
        setNarrativeMessages(data);
        setIsLoading(false);
      },
    );
    },
    [sourceId, narrativeId],
  );

  useEffect(() => {
    fetchNarrativeMessages()
  }, []);


  const translateMessage = (messageId, text, nativeLang, sourceLang='') => {
    let body;
    if(sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang
      }
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      }
    }
    API.fetch('POST', '/API/v1/translations/translate', null, body).then((data) => {
      setNarrativeMessages({...narrativeMessages, objects: narrativeMessages.objects.map(message => {
      if(message.message.id === messageId) {
        message.message.isTranslation = true;
        message.message.translated = data.destination_text;
        return message;
      } else {
        return message;
      }
      })})
    })
    .catch(e => {
      setNarrativeMessages({...narrativeMessages, objects: narrativeMessages.objects.map(message => {
        if(message.message.id === messageId) {
          message.message.isTranslation = true;
          message.message.error = true;
          message.message.translated = t('We were not able to translate this text.');
          return message;
        } else {
          return message;
        }
        })})
    });
  }
  
  const toggleTranslate = (messageId, isTranslation) => {
    setNarrativeMessages({...narrativeMessages, objects: narrativeMessages.objects.map(message => {
      if(message.message.id === messageId) {
        message.message.isTranslation = isTranslation;
        return message;
      } else {
        return message;
      }
    })})
  }
  
  const handleTranslation = (messageId, text, isTranslation) => {
    const target = narrativeMessages.objects.find(message => message.message.id === messageId);
    if (target.message.translated) {
      toggleTranslate(messageId, isTranslation)
    } else {
      toggleTranslate(messageId, true)
      translateMessage(messageId, text, nativeLang)
    }
  }

  return (
    <Modal
      isVisible={infoModal}
      title={'Narrative messages'}
      content={
      <>
        {!narrativeMessages || isLoading ? <Loader/> : narrativeMessages.objects.length > 0 ? <div>
          <table className={messageStyles.extendedMessages}>
              <thead>
                <tr>
                  <td className={messageStyles.headerInfo}>
                    <span>{t('Total messages')}: {formatNumber(narrativeMessages.total)}</span>
                  </td>
                  <td className='message-controlls'>
                  </td>
                </tr>
              </thead>
              <tbody>
                {narrativeMessages.objects.map(message => {
                  let followers_cnt = message.message.source.audience;
                  let textClass = styles.extendedMessage;
        
                  if(message.message.error && message.message.isTranslation) {
                    textClass = `${styles.extendedMessage} ${styles.error}`
                  } else if (message.message.error && !message.message.isTranslation) {
                    textClass = styles.extendedMessage;
                  }

                  const textForTranslation = message.message.highlighted_text ? replaceNewlinesWithBreaks(message.message.highlighted_text) : replaceNewlinesWithBreaks(message.message.text);
        
                  return(
                    <tr key={message.id}>
                      <td>
                        <div className={messageStyles.messageInfoWrapper}>
                          <div className={messageStyles.messageInfo}>
                            {message.message.content_type ? <div className={messageStyles.contentType}>
                              <Tooltip content={t(contentTypesMapping[message.message.content_type])} position='bottom'>
                                {contentTypesIconsMapping[message.message.content_type]}
                              </Tooltip>
                            </div> : ''}
                            <div className={messageStyles.date}>
                              <span  className={messageStyles.anchor}>
                                <Tooltip content={t('Publication date')} position='bottom'><CalendarIcon /></Tooltip>
                              </span>
                              {format(parseISO(message.message.date_publicated + 'Z'), 'dd LLL yyyy HH:mm',)}
                            </div>
                            <div className={messageStyles.platform}>
                              <span className={messageStyles.anchor}>
                                <Tooltip content={platformNamesMapping[message.message.source.source_type]} position='bottom'>
                                  {platformIconsMapping[message.message.source.source_type]}
                                </Tooltip>
                              </span>
                              {followers_cnt ? <span className={messageStyles.anchor}>
                                  <Tooltip content={t('Followers')} position='bottom'>
                                    <FollowersIcon />
                                  </Tooltip>
                                  <span>{formatNumberSignificant(followers_cnt)}</span>
                                </span> : ''}
                            </div>
                            <div className={messageStyles.sourceActorWrapper}>
                              <span className={messageStyles.anchor}>
                                <Tooltip content={decodeSourceName(message.message.source.name)} position='bottom'>
                                  <ActorIcon />
                                  <span className={messageStyles.cutText}>
                                    {isShare ? decodeSourceName(message.message.source.name) : message.message.source ? (
                                      <Link 
                                        to={`/sources/${message.message.source?.id}`}
                                      >
                                        {decodeSourceName(message.message.source.name)}
                                      </Link>
                                    ) : t('N/A')}
                                  </span>
                                </Tooltip>
                                { message.message.actor && message.message.actor.id !== message.message.source?.id ?
                                  <Tooltip content={decodeSourceName(message.message.actor?.name)} position='bottom'>
                                    <SourceIcon />
                                    <span className={messageStyles.cutText}>
                                    {<Link to={`/sources/${message.message.actor?.id}`}>{decodeSourceName(message.message.actor?.name)}</Link>}
                                    </span>
                                  </Tooltip> : ''}
                                <a 
                                  href={message.message.url}
                                  className={messageStyles.externalLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LinkIcon/>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        
                        <div className={messageStyles.messageContainer}>
                          <div className={messageStyles.messageMetricsWrapper}>
                            <div className={messageStyles.messageMetrics}>
                              {message.sentiment_score !== null ? <Tooltip content={t(getSentimentName(message.message.sentiment_score))} position='bottom'>
                                {getSentimentIcon(message.message.sentiment_score)}
                              </Tooltip> : ''}
                              <span className={messageStyles.anchor}>
                                <Tooltip content={t('Views')} position='bottom'>
                                  <ViewsIcon/>
                                </Tooltip>
                                {formatNumberSignificant(message.message.impressions)}
                              </span>
                              <span className={messageStyles.anchor}>
                                <Tooltip content={t('Reactions')} position='bottom'>
                                  <EngagementIcon />
                                </Tooltip>
                                {formatNumberSignificant(message.message.engagement)}
                              </span>
                              <span className={messageStyles.anchor}>
                                <Tooltip content={t('Manipulation')} position='bottom'>
                                  <ManipulationIcon />
                                </Tooltip>
        
                                {message.message.manipulation_index ? message.message.manipulation_index.toFixed(2) : '0'}
                                </span>
                              {message.message.similarity_score ?
                              <span className={messageStyles.anchor}>
                                <Tooltip content={t('Similarity1')} position='bottom'>
                                  <SimilarityIcon />
                                </Tooltip>
                                {(message.message.similarity_score + "").slice(0, 4)}
                                </span>
                              : ''}
                            </div>
                            <div className={messageStyles.similarMessagesSearch}>
                              <Tooltip content={ t('Find similar messages') } position='bottom'>
                                <span className={messageStyles.cutText}>
                                  <Link to={`/search?similar_to=${message.message.composite_id}`} onClick={() => window.location.href = `/search?similar_to=${message.message.composite_id}` }><SearchIcon /></Link>
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                          
                          <div className={messageStyles.textBlock}>
                            
                            
                            {message.message.isTranslation === true && !message.message.translated ?  
                            <div className={messageStyles.loaderContainer}><LoaderSmall /></div> : 
                            <ExpandableText
                              length={100}
                              highlights={highlights}
                              text={message.message.isTranslation ? message.message.translated : message.message.highlighted_text || message.message.text}
                              textClassName={textClass}
                            />}

                            
                          </div>
                        </div>
                      </td>
                      <td className='message-controlls'>
                        {/* <div className={messageStyles.controls}>
                          <Feedback
                            context={ feedbackContexts.STORIES_MESSAGE }
                            feedbackParams={{
                              narrative_id: narrative.id,
                              anchor_message_id: infoModal.id,
                              selected_message_id: message.id,
                              selected_message_similarity: message?.duplicate_to_similarity,
                            }}
                          />
                        </div> */}
                        <div className={messageStyles.translationWrapper}>
                          <span 
                            className={message?.message.isTranslation ? '' : messageStyles.active}
                            onClick={() =>  handleTranslation(message.message.id, textForTranslation, false)}
                          >
                            <OriginalTextIcon />
                          </span>
                          <span 
                            className={message?.message.isTranslation ? messageStyles.active : '' }
                            onClick={() => handleTranslation(message.message.id, textForTranslation, true)}
                          >
                            <TranslationIcon />
                          </span> 
        
                        </div>
                        
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div> : <div className={styles.noData}>{t('There is no data to display')}</div>}
        </>
      }
      footer={
        <>
          <Paginator
            size={20}
            page={page}
            total={narrativeMessages?.total}
            onPageChange={(newPage) =>{
              setPage(newPage)
              fetchNarrativeMessages(newPage)
            }}
          />
        </>
      }
      onClose={() => {
        onChange(false);
      }}
      className={styles.chartTreeModal}
    />
  );
};
