import { useContext, useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThreatsTable } from '../../components/ThreatsTable/ThreatsTableNew';
import { Paginator } from '../../components/Paginator/Paginator';
import { Loader } from '../../components/Loader/Loader';
import { Filter } from './Filter/Filter';
import { Search } from '../../components/Search/Search';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { ThreatsContext } from './contexts/ThreatsContext';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';

import styles from './ThreatsPage.module.scss';

export const ReportedThreats = ({ narrativeID }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { threats, getReportedThreats } = useContext(ThreatsContext);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [sorting, setSorting] = useState({isAscending: false, fieldName: 'id'});
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const searchQuery = searchParams.get('q');

  const [incidentType, setIncidentType] = useState(null);
  const [topics, setTopics] = useState(null);
  const [statuses, setStatuses] = useState(['PENDING', 'ACCEPTED', 'DECLINED']);
  const [caseTypes, setCaseTypes] = useState(null);
  const [geoScope, setGeoScope] = useState(null);

  useEffect(() => {
    if(threats === null) {
      setIncidentType(null);
      setTopics(null);
      setStatuses(['PENDING', 'ACCEPTED', 'DECLINED']);
      setCaseTypes(null);
      setGeoScope(null);
    } else {
      return
    }
  },[threats])

  const sortingQuery = sorting.fieldName
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;


  useEffect(() => {
    getReportedThreats(searchQuery, sortingQuery, incidentType, topics, statuses, caseTypes, geoScope, narrativeID, page)
  }, [page, searchQuery, sorting, incidentType, topics, statuses, caseTypes, geoScope, narrativeID])

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'id'
    },
    {
      name: t('Name'),
      value: 'name'
    },
    {
      name: t('Status'),
      value: 'review_status'
    },
    {
      name: t('Views'),
      value: 'views'
    },
    {
      name: t('Author'),
      value: 'last_name'
    }
    
  ]

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]

  const searchPanel = <div className={styles.sortingControls}>
    <div className={styles.search}>
      <Search 
        onChange={value => {
          setSearchParams({q: value, page: 1})
        }}
      />
    </div>

    <DropdownMenu  
      isOpen={dropdownMenu}
      header={t('Sort by')}
      onClick={() => setDropdownMenu(!dropdownMenu)}
      buttonName={t('Sort')}
      icon={<SortIcon/>}
    >
      <Radiobutton 
        itemsList={sortingMenu}
        current={sorting.fieldName}
        onChange={(value) => setSorting({...sorting, fieldName: value})}
      />

      <SortingOrder 
        itemsList={sortingOrder}
        onClick={(value) => setSorting({...sorting, isAscending: value})}
        current={sorting.isAscending}
      />
    </DropdownMenu>
    <Filter 
      onChange={(incidentType, topics, statuses, caseTypes, geoScope) => {
        setIncidentType(incidentType);
        setTopics(topics);
        setStatuses(statuses);
        setCaseTypes(caseTypes);
        setGeoScope(geoScope);
        searchParams.set('page', 1);
        navigate(`?${searchParams.toString()}`);
      }}
    />
  </div>

  if(!threats) {
    return <Loader/>
  }

  return (
    <>
      {searchPanel ? searchPanel : ''}
      <ThreatsTable threats={threats} threatsMode='Reported' page={threats.page}/>
      <Paginator
        size={threats.size}
        page={threats.page}
        total={threats.total}
        onPageChange={(newPage) => {
          const params = new URLSearchParams();

          if (searchQuery) {
            params.set('q', searchQuery);
          }

          params.set('page', newPage);
          navigate(`?${params.toString()}`);
        }}
      />
    </>
  )
}