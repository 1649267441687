import * as d3 from 'd3';
import { Link } from 'react-router-dom';
import { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MessagesContext } from '../../contexts/MessagesContext';
import { useCurrentUser } from '../../../../contexts/CurrentUser';
import { ampli } from '../../../../ampli';
import { Loader } from '../../../../components/Loader/Loader';
import { LoaderSmall } from '../../../../components/LoaderSmall/LoaderSmall';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { format, parseISO } from 'date-fns';
import { ReactComponent as CalendarIcon } from '../../../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../../../assets/engagement.svg';
import { ReactComponent as ManipulationIcon } from '../../../../assets/manipulation.svg';
import { ReactComponent as TranslationIcon } from '../../../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../../../assets/originalText.svg';
import { ReactComponent as ActorIcon } from '../../../SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../../SearchPage/assets/person.svg';
import { ReactComponent as FollowersIcon } from '../../../../assets/followers.svg';
import { ReactComponent as SimilarityIcon } from '../../../../assets/similarity.svg';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import { Sentiment } from '../../../../components/Sentiment/Sentiment';
import { ExpandableText } from '../../../../components/CollapsibleText/ExpandableText';
import {
  platformIconsMapping,
  platformNamesMapping,
} from '../../../../utils/platforms';
import {
  contentTypesIconsMapping,
  contentTypesMapping,
} from '../../../../utils/contentTypes';
import styles from '../../../../components/MessagesTable/ExtendedMessageView.module.scss';
// import messageStyles from '../../../../components/MessagesTable/ExtendedMessageView.module.scss';
import { decodeSourceName } from '../../../../utils/decodeURI';
import messageStyles from '../../NarrativePage.module.scss';
import Switch from '../../../../components/Switch';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import Markdown from 'react-markdown';
import { cutString } from '../../../../utils/cutString';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');

export const Messages = ({
  narrative,
  searchQuery,
  start_date,
  end_date,
  sorting = '-engagement',
  page = 1,
  size = 10,
  isShare,
  notes,
  settings,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const {
    messages,
    getMessages,
    toggleTranslate,
    dispatch,
    translateMessage,
    error,
  } = useContext(MessagesContext);
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const componentRef = useRef(null);

  const featureFlags = currentUser?.workspace?.config.featureflags;
  const showNer = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes(
    'show_manipulation_index',
  );

  const [showOnlyFavorites, setShowOnlyFavorites] = useState(settings?.show_only_favorites);
  const [messagesStatus, setMessagesStatus] = useState(settings?.messages_status || []);
  const [showHighlight, setShowHighlight] = useState(settings?.show_highlights);
  const [note, setNote] = useState(notes?.length > 0 ? notes[0] : {text: "", position: "bottom"});
  const [isNote, setIsNote] = useState(notes?.length > 0);
  const [title, setTitle] = useState(settings.title || t('Top messages'));

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : t('Top messages');
    setTitle(newTitle);
    setMessagesStatus(settings?.messages_status || []);
    setShowHighlight(settings?.show_highlights);
    setShowOnlyFavorites(settings?.show_only_favorites);
  }, [settings]);

  useEffect(() => {
    setWidgetSettings({...widgetSettings, show_only_favorites: showOnlyFavorites, messages_status: messagesStatus, show_highlights: showHighlight})
  }, [showOnlyFavorites, messagesStatus, showHighlight]);

  const statusesOptions = [
    {name: 'New', value: "NEW"},
    {name: 'Approved', value: "READ"},
    {name: 'Deleted', value: "DELETED"},
  ]

  useEffect(() => {
    getMessages(
      narrative,
      searchQuery,
      sorting,
      messagesStatus,
      false,
      page,
      showOnlyFavorites,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      size,
    );
  }, [
    narrative,
    searchQuery,
    start_date,
    end_date,
    messagesStatus,
    sorting,
    page,
    size,
    showOnlyFavorites,
  ]);

  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);

  const nerTypesList = [{ value: 'KEYWORDS', label: t('Matched keywords') }];

  if (showNer) {
    nerTypesList.push(
      { value: 'PERSON', label: t('Persons') },
      { value: 'ORGANIZATION', label: t('Organizations') },
      { value: 'LOCATION', label: t('Locations') },
    );
  }

  const [highlights, setHighlights] = useState(['KEYWORDS']);

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messages.objects.find((message) => message.id === messageId);
    if (target.translated) {
      toggleTranslate(messageId, isTranslation);
    } else {
      const nativeLanguage = currentUser?.translation_language ? currentUser?.translation_language : narrative.parameters?.translate_keywords_query
        ? narrative.parameters.keywords_query_origin_language
        : window.clientInformation.language.split('-', 1)[0];
      toggleTranslate(messageId, true);
      translateMessage(messageId, text, nativeLanguage)
        .then((data) => {
          dispatch({
            type: 'TRANSLATE_MESSAGE',
            payload: { data: data, messageId: messageId },
          });
        })
        .catch((e) => {
          dispatch({
            type: 'TRANSLATION_ERROR',
            payload: {
              data: t('We were not able to translate this text.'),
              messageId: messageId,
            },
          });
        });

      ampli.track({
        event_type: 'Translate message',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        },
      });
    }
  };

  if (!narrative) {
    return <Loader />;
  }

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t('Top messages')}</div>
          <form
            onSubmit={() => {
              let newNotes;
              setIsEditingMode(false);
              if (note.text.length > 0) {
                newNotes = [note];
              } else {
                newNotes = [];
              }
              onSettingsChange(widgetSettings, newNotes, componentRef.current);
            }}
            onReset={() => {
              componentRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
              setTitle(settings.title || t('Top messages'));
              setIsEditingMode(false);
              setWidgetSettings(settings);
              setNote(
                notes?.length > 0 ? notes[0] : { text: '', position: 'bottom' },
              );
              setShowHighlight(settings?.show_highlights);
              setMessagesStatus(settings?.messages_status || []);
              setShowOnlyFavorites(settings?.show_only_favorites);
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <label htmlFor="full-text" className={styles.label}>
                {t('Show full text')}
              </label>
              <Switch
                id="full-text"
                value={widgetSettings.show_full_text}
                onChange={(value) => {
                  setWidgetSettings({
                    ...widgetSettings,
                    show_full_text: value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <label htmlFor="text-length">{t('Text length(characters)')}</label>
              <input
                type="number"
                id="text-length"
                value={widgetSettings.text_length}
                disabled={widgetSettings.show_full_text}
                onChange={(e) => {
                  setWidgetSettings({
                    ...widgetSettings,
                    text_length: parseInt(e.target.value),
                  });
                }}
              />
            </div>
            <div className="form-element">
              <label htmlFor="highlights">{t('Highlights')}</label>
              <ul>
                <li>
                  <input
                    id="show_highlights"
                    type="checkbox"
                    checked={showHighlight}
                    onChange={() => {
                      setShowHighlight(!showHighlight);
                    }}
                  />
                  <label htmlFor="show_highlights">
                    {t('Show highlights')}
                  </label>
                </li>
              </ul>
            </div>

            <div className="form-element">
              <label htmlFor="favorites">{t('Favorite messages')}</label>
              <ul>
                <li>
                  <input
                    id="show_only_favorite"
                    type="checkbox"
                    checked={showOnlyFavorites}
                    onChange={() => {
                      setShowOnlyFavorites(!showOnlyFavorites);
                    }}
                  />
                  <label htmlFor="show_only_favorite">
                    {t('Show only favorite')}
                  </label>
                </li>
              </ul>
            </div>
            <div className="form-element">
              <label htmlFor="messagesStatus">{t('Messages status')}</label>
              <ul>
                {statusesOptions.map((status) => {
                  return (
                    <li key={status.value}>
                      <input
                        id={`status_${status.value}`}
                        type="checkbox"
                        checked={messagesStatus.includes(status.value)}
                        onChange={() => {
                          if (messagesStatus.includes(status.value)) {
                            setMessagesStatus(
                              messagesStatus.filter((v) => v !== status.value),
                            );
                          } else {
                            setMessagesStatus([
                              ...messagesStatus,
                              status.value,
                            ]);
                          }
                        }}
                      />
                      <label htmlFor={`status_${status.value}`}>
                        {t(status.name)}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
            {messages.objects.length > 0 ? (
              <div className="form-element">
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      id="isNote"
                      checked={isNote}
                      onChange={() => {
                        setIsNote(!isNote);
                      }}
                    />
                    <label htmlFor="create-note">{t('Create a note')}</label>
                  </li>
                </ul>
              </div>
            ) : (
              ''
            )}
            {isNote ? (
              <div className="form-element">
                <div className="form-element">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        id="platforms"
                        checked={note?.position === 'top'}
                        onChange={() => {
                          if (note?.position === 'top') {
                            setNote({ ...note, position: 'bottom' });
                          } else {
                            setNote({ ...note, position: 'top' });
                          }
                        }}
                      />
                      <label htmlFor="note-position">
                        {t('Place a note on the top of the widget')}
                      </label>
                    </li>
                  </ul>
                </div>

                <label htmlFor="note">{t('Note')}</label>
                <TextEditor
                  id={'note-editor'}
                  value={note.text}
                  onChange={(text) => setNote({ ...note, text: text })}
                  isOpen={true}
                />
              </div>
            ) : (
              ''
            )}
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="report-section widget-container">
        <div className="report-section__header">
          <h3>
            {widgetSettings.title ? widgetSettings.title : t('Top messages')}
          </h3>
          <div className="report-section__controls">
            <button
              className="report-control-button"
              onClick={() => setIsEditingMode(true)}
            >
              <EditIcon />
            </button>
            <button
              className="report-control-button danger"
              onClick={() => onWidgetStatusChange(false)}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>

        {error ? (
          <div className={styles.noData}>{t(error)}</div>
        ) : !messages ? (
          <Loader />
        ) : messages?.objects?.length > 0 ? (
          <>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'top' ? (
                      <div className="report-note-container note-position-top">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}
          <table
            className={`${styles.extendedMessages} ${messageStyles.extendedMessages} ${styles.pdfMode}`}
          >
            <tbody>
              {messages.objects.map((message) => {
                let followers_cnt = message?.source?.audience;

                let textClass = messageStyles.extendedMessage;

                if (message.error && message.isTranslation) {
                  textClass = `${messageStyles.extendedMessage} ${styles.error}`;
                } else if (message.error && !message.isTranslation) {
                  textClass = messageStyles.extendedMessage;
                }

                return (
                  <tr key={message.id}>
                    <td>
                      <div className={styles.messageInfoWrapper}>
                        <div className={styles.messageInfo}>
                          {message.content_type ? (
                            <div className={styles.contentType}>
                              <Tooltip
                                content={t(
                                  contentTypesMapping[message.content_type],
                                )}
                                position="bottom"
                              >
                                {contentTypesIconsMapping[message.content_type]}
                              </Tooltip>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className={styles.date}>
                            <span className={styles.anchor}>
                              <Tooltip
                                content={t('Publication date')}
                                position="bottom"
                              >
                                <CalendarIcon />
                              </Tooltip>
                            </span>
                            {format(
                              parseISO(message.date_publicated + 'Z'),
                              'dd LLL yyyy HH:mm',
                            )}
                          </div>
                          <div className={styles.platform}>
                            <span className={styles.anchor}>
                              <Tooltip
                                content={
                                  platformNamesMapping[
                                    message.source.source_type
                                  ]
                                }
                                position="bottom"
                              >
                                {
                                  platformIconsMapping[
                                    message.source.source_type
                                  ]
                                }
                              </Tooltip>
                            </span>
                            {followers_cnt ? (
                              <span className={styles.anchor}>
                                <Tooltip
                                  content={t('Followers')}
                                  position="bottom"
                                >
                                  <FollowersIcon />
                                </Tooltip>
                                <span>
                                  {formatNumberSignificant(followers_cnt)}
                                </span>
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className={styles.sourceActorWrapper}>
                            <span className={styles.anchor}>
                              <Tooltip
                                content={decodeSourceName(message.source.name)}
                                position="bottom"
                              >
                                <ActorIcon />
                                {isShare ? (
                                  cutString(
                                    decodeSourceName(message.source.name),
                                    20,
                                  )
                                ) : message.source ? (
                                  <Link
                                    to={`/sources/${message.source?.id}`}
                                    onClick={() => {
                                      ampli.track({
                                        event_type: 'Open source profile',
                                        event_properties: {
                                          user_id: currentUser?.id,
                                          workspace_id:
                                            currentUser?.workspace_id,
                                          source_id: message.source.id,
                                        },
                                      });
                                    }}
                                  >
                                    {cutString(
                                      decodeSourceName(message.source.name),
                                      20,
                                    )}
                                  </Link>
                                ) : (
                                  t('N/A')
                                )}
                              </Tooltip>
                              {message.actor &&
                              message.actor.id !== message.source?.id ? (
                                <Tooltip
                                  content={decodeSourceName(
                                    message.actor?.name,
                                  )}
                                  position="bottom"
                                >
                                  <SourceIcon />
                                  <Link
                                    to={`/sources/${message.actor?.id}`}
                                  >
                                    {cutString(
                                      decodeSourceName(message.source.name),
                                      20,
                                    )}
                                  </Link>
                                </Tooltip>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className={styles.messageContainer}>
                        <div className={styles.messageMetricsWrapper}>
                          <div className={styles.messageMetrics}>
                            {message.sentiment_score !== null ? (
                              <Sentiment
                                sentimentScore={message.sentiment_score}
                                messageId={message.id}
                                narrative={narrative}
                                isShare={isShare}
                              />
                            ) : (
                              ''
                            )}
                            <span className={styles.anchor}>
                              <Tooltip content={t('Views')} position="bottom">
                                <ViewsIcon />
                              </Tooltip>
                              {formatNumberSignificant(message.impressions)}
                            </span>
                            <span className={styles.anchor}>
                              <Tooltip
                                content={t('Reactions')}
                                position="bottom"
                              >
                                <EngagementIcon />
                              </Tooltip>
                              {formatNumberSignificant(message.engagement)}
                            </span>
                            {showMessageManipulationIndex ? (
                              <span className={styles.anchor}>
                                <Tooltip
                                  content={t('Manipulation')}
                                  position="bottom"
                                >
                                  <ManipulationIcon />
                                </Tooltip>

                                {message.manipulation_index
                                  ? message.manipulation_index.toFixed(2)
                                  : '0'}
                              </span>
                            ) : (
                              ''
                            )}
                            {message.similarity_score ? (
                              <span className={styles.anchor}>
                                <Tooltip
                                  content={t('Similarity1')}
                                  position="bottom"
                                >
                                  <SimilarityIcon />
                                </Tooltip>
                                {(message.similarity_score + '').slice(0, 4)}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        <div className={styles.textBlock}>
                          {message.isTranslation === true &&
                          !message.translated ? (
                            <div className={styles.loaderContainer}>
                              <LoaderSmall />
                            </div>
                          ) : (
                            <ExpandableText
                              length={settings?.text_length}
                              highlights={highlights}
                              showFullText={settings?.show_full_text}
                              text={
                                message.isTranslation
                                  ? message.translated
                                  : showHighlight
                                  ? message.highlighted_text
                                    ? message.highlighted_text
                                    : message.text
                                  : message.text
                              }
                              textClassName={textClass}
                              onExpand={(expanded) => {
                                ampli.track({
                                  event_type: expanded
                                    ? 'Expand message in-place'
                                    : 'Collapse message in-place',
                                  event_properties: {
                                    user_id: currentUser?.id,
                                    workspace_id: currentUser?.workspace_id,
                                    narrative_id: narrative.id,
                                  },
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="message-controlls">
                      <div className={styles.translationWrapper}>
                        <span
                          className={
                            message?.isTranslation ? '' : styles.active
                          }
                          onClick={() =>
                            handleTranslation(message.id, message.text, false)
                          }
                        >
                          <OriginalTextIcon />
                        </span>
                        <span
                          className={
                            message?.isTranslation ? styles.active : ''
                          }
                          onClick={() =>
                            handleTranslation(message.id, message.text, true)
                          }
                        >
                          <TranslationIcon />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'bottom' ? (
                      <div className="report-note-container note-position-bottom">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}
          </>
        ) : (
          <div className={styles.noData}>
            {t('There is no data to display')}
          </div>
        )}
      </div>
    );
  }
};
