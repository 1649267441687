import * as d3 from 'd3';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { API, ShareAPI } from '../../../../API';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const SentimentBar = ({ report, mode, isShare }) => {
  const { t } = useTranslation();
  const api = isShare ? ShareAPI : API;
  const [sentimentData, setSentimentData] = useState(null);

  console.log('sentimentData', sentimentData)

  let chartTitle;
  if (mode === 'messages') {
    chartTitle = t('Sentiment: Messages');
  } else if (mode === 'views') {
    chartTitle = t('Sentiment: Views');
  } else if (mode === 'likes') {
    chartTitle = t('Sentiment: Reactions');
  }

  const fetchNarrativesShare = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    if (!report) return;

    const urlParams = new URLSearchParams();
    report.parameters.narrative_ids.forEach((narrative_id) =>
      urlParams.append('narrative_id', narrative_id),
    );
    if (report.parameters.start_date !== null) {
      urlParams.set(
        'start',
        report.parameters.start_date.split('T')[0] + 'T00:00:00'
      );
    }
    if (report.parameters.end_date !== null) {
      urlParams.set(
        'end',
        report.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    urlParams.set('aggregation', 'DAY');

    api
      .fetch(
        'GET',
        `/API/v1/stats/narratives/shares?${urlParams.toString()}`,
        null,
        null,
        null,
      )
      .then(data => {
        // data?.objects.sort((a, b) => (a.id - b.id));
        setSentimentData(data);
      });
  }, [isShare, report]);

  useEffect(fetchNarrativesShare, [fetchNarrativesShare]);

  if (!sentimentData) {
    return <Loader />;
  }

  return (
    <div className="list-content">
      <div className="report-section">
        <h3>{chartTitle}</h3>
        <div className="card card-bar">
          {/* <div className="card-header" /> */}
          <div className="card-body">
            <div className="sentiment-bar-container">
              {sentimentData?.sentiment?.positive[mode] === 0 ? (
                ''
              ) : (
                <span
                  className="sentiment-bar-positive"
                  style={{
                    width: `${sentimentData?.sentiment?.positive[mode + '_share']}%`,
                  }}
                >
                  {formatWithCustomGigaBillion(sentimentData?.sentiment?.positive[mode])}
                </span>
              )}
              {sentimentData?.sentiment?.neutral[mode] === 0 ? (
                ''
              ) : (
                <span
                  className="sentiment-bar-neutral"
                  style={{
                    width: `${sentimentData?.sentiment?.neutral[mode + '_share']}%`,
                  }}
                >
                  {formatWithCustomGigaBillion(sentimentData?.sentiment?.neutral[mode])}
                </span>
              )}
              {sentimentData?.sentiment?.negative[mode] === 0 ? (
                ''
              ) : (
                <span
                  className="sentiment-bar-negative"
                  style={{
                    width: `${sentimentData?.sentiment?.negative[mode + '_share']}%`,
                  }}
                >
                  {formatWithCustomGigaBillion(sentimentData?.sentiment?.negative[mode])}
                </span>
              )}
            </div>
            <div className="sentiment-bar-stats">
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color positive"></span>
                <span className="sentiment-bar-stats-data">{`${t(
                  'Positive',
                )}: ${
                  sentimentData?.sentiment?.positive[mode + '_share'] === 0
                    ? '0'
                    : sentimentData?.sentiment?.positive[mode + '_share'].toFixed(2)
                }%`}</span>
              </div>
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color neutral"></span>
                <span className="sentiment-bar-stats-data">{`${t('Neutral')}: ${
                  sentimentData?.sentiment?.neutral[mode + '_share'] === 0
                    ? '0'
                    : sentimentData?.sentiment?.neutral[mode + '_share'].toFixed(2)
                }%`}</span>
              </div>
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color negative"></span>
                <span className="sentiment-bar-stats-data">{`${t(
                  'Negative',
                )}: ${
                  sentimentData?.sentiment?.negative[mode + '_share'] === 0
                    ? '0'
                    : sentimentData?.sentiment?.negative[mode + '_share'].toFixed(2)
                }%`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
