
export const buildImageUrl = (svgRef, canvasRef, documentType, name) => {
  console.log('svg ref', svgRef)
  const svg = svgRef.current;
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

  const domUrl = window.URL || window.webkitURL || window;
  const imageUrl = domUrl.createObjectURL(new Blob([svg.outerHTML], { type: 'image/svg+xml' }));

  if(documentType === 'PNG') {
    const image = new Image();
    image.onload = () => {
        const canvas = canvasRef.current;
        console.log('canvas', canvas)
        console.log('svg', svg)
        if (canvas && canvas.getContext) {

          let context = canvas.getContext('2d');
            
            canvas.setAttribute('width', parseFloat(svg.getAttribute('width')));
            canvas.setAttribute('height', parseFloat(svg.getAttribute('height')));
            // canvas.setAttribute('background', 'white')
            context.fillStyle = "lightblue";
            context.fillRect(0, 0, parseFloat(svg.getAttribute('width')), parseFloat(svg.getAttribute('height')))
            context.drawImage(image, 0, 0);
            

            downloadImage(canvas.toDataURL('image/png'), `${name}.png`);
            domUrl.revokeObjectURL(imageUrl);
        } else {
            console.error('Canvas element or getContext not found');
        }
    };
    image.src = imageUrl;
  } else if (documentType === 'SVG') {
    downloadImage(imageUrl, `${name}.svg`, 'image/svg+xml');
  }
};

const downloadImage = (imageUrl, documentName, fileType='image/png') => {
  const browserIsIE = false || !!document.documentMode;

  if (browserIsIE) {
      window.navigator.msSaveBlob(base64ToBlob(imageUrl.substr(imageUrl.indexOf(',') + 1), fileType), documentName);
  } else {
      const downloadLink = document.createElement('a');
      downloadLink.href = imageUrl;
      downloadLink.download = documentName;
      raiseEvent(downloadLink, 'click');
  }
};

const base64ToBlob = (base64, type) => {
  const binary = atob(base64);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: type });
};

const raiseEvent = (element, eventType) => {
  let eventRaised;
  if (document.createEvent) {
      eventRaised = document.createEvent('MouseEvents');
      eventRaised.initEvent(eventType, true, false);
      element.dispatchEvent(eventRaised);
  } else if (document.createEventObject) {
      eventRaised = document.createEventObject();
      element.fireEvent('on' + eventType, eventRaised);
  }
};
