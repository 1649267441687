import { useState, useEffect, useMemo } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Switch from '../../components/Switch';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { languageNamesMapping } from '../../utils/languages';
import { changeLanguage } from 'i18next';

import styles from './UserEditPage.module.scss';

import { API } from '../../API';

export function UserEditPage() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { userId } = useParams();
  const [currentUser, setCurrentUser] = useCurrentUser();

  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [type, setType] = useState({ value: 'STANDARD', label: t('Standard') });
  const [userLanguage, setUserLanguage] = useState('');
  const [translationLanguage, setTranslationLanguage] = useState('');
  const [error, setError] = useState(null);

  const languageOptions = [
    {value: null, label: t('Not selected')},
    {value: 'EN', label: t('English')},
    {value: 'UK', label: t('Ukrainian')}
  ]

  const languageTranslationOptions = [
    {value: null, label: t('Not selected')},
    {value: 'EN', label: t('English')},
    {value: 'UK', label: t('Ukrainian')},
    {value: 'FR', label: t('French')},
    {value: 'PL', label: t('Polish')},
    {value: 'RO', label: t('Romanian')},
  ]

  const types = useMemo(() => {
    return [
      { value: 'UNDERPRIVILEGED', label: t('Underprivileged') },
      { value: 'STANDARD', label: t('Standard') },
      { value: 'MODERATOR', label: t('Moderator1') },
    ];
  }, [t]);

  useEffect(() => {
    if (!currentUser.is_admin) {
      navigate('/');
      return;
    }

    API.fetch('GET', `/API/v1/users/${userId}`).then((data) => {
      setValues({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
      });
      if(data.language === null) {
        setUserLanguage({value: null, label: t('Not selected')})
      } else {
        setUserLanguage({value: data.language, label: t(languageNamesMapping[data.language])})
      }

      if(data.translation_language === null) {
        setTranslationLanguage({value: null, label: t('Not selected')})
      } else {
        setTranslationLanguage({value: data.translation_language, label: t(languageNamesMapping[data.translation_language])})
      }
      setIsAdmin(data.is_admin);
      setType(types.find((el) => el.value === data.type));
    });
  }, [userId, types]);

  const handleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    API.fetch('PATCH', `/API/v1/users/${userId}`, null, {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      type: type.value,
      is_admin: isAdmin,
      language: userLanguage.value,
      translation_language: translationLanguage?.value,
    }).then((data) => {
      if(currentUser.id == userId) {
        API.fetch('GET', '/API/v1/users/me').then((data) => {
          setCurrentUser(data);
          if(data.language === null) {
            return
          }

          changeLanguage(data.language.toLowerCase())
        });

      }
      navigate(`/users`);
    }).catch(e => {
      setError(e.message)
    })
  };

  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/users">{t('Users')}</Link>
          </span>
          <span>
            <Link to={`/users/${userId}`}>
              {values.first_name} {values.last_name}
            </Link>
          </span>
        </div>
      </div>
      <div className="form-wrapper">
        <form method="post" onSubmit={handleSubmit}>
          <div className="form-element">
            <label htmlFor="name">{t('First name')}</label>
            <input
              className=""
              id="first_name"
              name="first_name"
              type="text"
              onChange={handleChange}
              value={values.first_name}
              required
            />
          </div>

          <div className="form-element">
            <label htmlFor="name">{t('Last name')}</label>
            <input
              className=""
              id="last_name"
              name="last_name"
              type="text"
              onChange={handleChange}
              value={values.last_name}
              required
            />
          </div>

          <div className={error ? 'form-element error' : 'form-element'}>
            <label htmlFor="name">{t('Email')}</label>
            <input
              className=""
              id="email"
              name="email"
              type="text"
              onChange={handleChange}
              value={values.email}
              required
            />
            <div className="error-message">{t(error)}</div>
          </div>
          <div className="form-element">
            <label htmlFor="language">{t('System language')}</label>
            <Select
              className="chart-select"
              onChange={(data) => {
                setUserLanguage(data);
              }}
              value={userLanguage}
              options={languageOptions}
            />
          </div>

          <div className="form-element">
            <label htmlFor="language">{t('Language for translation')}</label>
            <Select
              className="chart-select"
              onChange={(data) => {
                setTranslationLanguage(data);
              }}
              value={translationLanguage}
              options={languageTranslationOptions}
            />
          </div>

          <div className="form-element">
            <label htmlFor="name">{t('User type')}</label>
            <Select
              className="chart-select"
              onChange={(data) => {
                setType(data);
              }}
              value={type}
              options={types}
            />
          </div>

          <div className="form-element">
            <label htmlFor="case-active" className={styles.label}>
              {t('Admin')}
            </label>
            <Switch id="case-active" value={isAdmin} onChange={setIsAdmin} />
          </div>

          <div className="form-element">
            <div className="submit-wrapper">
              <input type="submit" value={t('Save')} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
