import { useTranslation } from 'react-i18next';
import SwitchSmall from "../../../components/SwitchSmall";
import styles from './Filter.module.scss';

export const ExcludeSwitch = ({id, value, onChange, color, direction}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.switchContainer} style={{'flexDirection': direction ? direction : ''}}>
      <SwitchSmall
        id={id}
        value={value}
        onChange={(bool => onChange(bool))}
        color={color}
      />
      <span className={styles.switchLabel} style={{'color': value === true ? '#DC2625' : ''}}>{t('Exclude')}</span>

    </div>
  )
}