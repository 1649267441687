import { useTranslation } from 'react-i18next';

export const StatusChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const statuses = [
    {name: 'Active', value: "ACTIVE"},
    {name: 'Paused', value: "PAUSED"},
    {name: 'Archived', value: "ARCHIVED"},
  ]

  const handleSelect = () => {
    if (statuses.length !== selected.length) {
      onChange(statuses.map(status => status.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{statuses.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {statuses.map(status => {
          return(
            <li key={status.value}>
              <input 
                id={`status_${status.value}`}
                type='checkbox'
                checked={selected.includes(status.value)}
                onChange={() => {
                  if (selected.includes(status.value)) {
                    onChange(selected.filter(v => v !== status.value)) 
                  } else {
                    onChange([...selected, status.value]) 
                  }
                }}
              />
              <label htmlFor={`status_${status.value}`}>{t(status.name)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}
