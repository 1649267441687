import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';

export function ShareLoginPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { reportId, narrativeId } = useParams();

  const hash = searchParams.get('h');
  const withPassword = searchParams.get('with_password');

  const getShareToken = useCallback(async (hash, password) => {

    try {
      const resp = await fetch('/API/v1/reports/shares/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          password: password,
          hash: hash,
        }),
      });
      if (!resp.ok) {
        console.log('Failed to get share token. Got status: ', resp.status);
      }

      if (resp.status === 401 || resp.status === 403) {
        localStorage.removeItem('share_token');
      }

      const data = await resp.json();
      localStorage.setItem('share_token', data.access_token);

      if (reportId) {
        navigate(`/reports/${reportId}/share?h=${hash}`);
      } else if (narrativeId) {
        navigate(`/narratives/${narrativeId}/share?h=${hash}`);
      }
    } catch (error) {
      return;
    }

  }, [navigate, reportId, narrativeId]);

  useEffect(() => {

    if (withPassword === 'false') {
      getShareToken(hash, null);
    }

  }, [getShareToken, hash, withPassword]);

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const password = formData.get('password');

    getShareToken(hash, password);
  }

  if (withPassword === 'false') {
    return <Loader />
  }

  return (
    <div className="center">
      <div className="login-form-wrapper">
        <h1>{t('Share')}</h1>

        <form method="post" onSubmit={handleSubmit}>
          <div className="txt_field">
            <input
              className="input_filed"
              name="password"
              type="password"
              autoComplete="off"
              placeholder=" "
              required
            />
            <span></span>
            <label>{'Password'}</label>
          </div>
          <input type="submit" value={t('Login')} />
        </form>
      </div>
    </div>
  );
}
