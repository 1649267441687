import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { API } from '../../../API';

import styles from './AdjustmentSuggestion.module.scss';

export function AdjustmentSuggestion({
  narrative,
  suggestion,
  onSuggestionApply = null,
}) {
  const { t } = useTranslation();

  if (suggestion === null) {
    return <></>;
  }

  const handleApplyClick = () => {
    let parameters = narrative.parameters;

    for (const [key, value] of Object.entries(suggestion.actions)) {
      if (key === 'start_date' || key === 'end_date' || key === 'disable_filters') {
        parameters[key] = value
      } else {
        parameters[key] = []
      }
    }

    API.fetch('PATCH', `/API/v1/narratives/${narrative.id}`, null, {
      on_edit: {apply_suggestion: true},
      adjustment_suggestions: null,
      parameters: parameters,
    }).then((data) => {
      if (onSuggestionApply) {
        onSuggestionApply();
      }
    });
  }

  return (
    <div className={styles.root}>
      <div>
        {suggestion.text} {t('to find')} <b>{suggestion.messages_found}</b> {t('messages')}
      </div>
      <button onClick={() => handleApplyClick()}>{t('Apply')}</button>
    </div>
  );
}
