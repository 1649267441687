export const palette = ['#2463eb', '#40b870', '#dc2625', '#8825eb', '#F5D90A', '#09A9A9', '#C11574'];

export const sentimentPalette = ['#D1FAE5', '#FFFBD1', '#FEE2E2'];

export const sentimentPaletteMap = {
  'NEGATIVE': '#FEE2E2',
  'NEUTRAL': '#FFFBD1',
  'POSITIVE': '#D1FAE5'
}

export const colorIndexes = Array.from({ length: 8 }, (value, index) => index);
export const colorClasses = colorIndexes.map((value) => `color${value}`);
