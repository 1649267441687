import { RefObject, useEffect } from 'react';

export const useMultiClickOutside = (
  refs: RefObject<any>[],
  onClickOutside: (event: Event) => void,
) => {
  useEffect(() => {
    if (!onClickOutside) {
      return;
    }

    const listener: EventListenerOrEventListenerObject = (event: Event) => {
      if (!event.target) {
        return;
      }

      const node = event.target;

      for (const ref of refs) {
        if (ref.current && ref.current.contains(node)) {
          return;
        }
      }

      onClickOutside(event);
    };

    document.addEventListener('click', listener);

    return () => document.removeEventListener('click', listener);
  }, [refs, onClickOutside]);
};
